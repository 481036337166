import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import MUISelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        //margin: theme.spacing.unit,
        minWidth: 60,
        maxWidth: 300,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        width: 'calc(100% - 110px)', //FIXME: have an eye on this
        marginRight: theme.spacing.unit * 4
    },
    chip: {
        margin: `${theme.spacing.unit / 4}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
        overflow: 'hidden',
        width: 'calc(100% - 8px)',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 9999,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        border: '1px solid rgba(229, 221, 243, 0.9)'
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography title={props.children} className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            title={props.children}
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};

const ITEM_HEIGHT = 32;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const getLabelForValue = (options = [], value) => {
    for(let i = 0; i < options.length; i++){
        if(options[i].value === value){
            return options[i].label;
        }
    }
    return value;
};

class IntegrationReactSelect extends React.Component {

    constructor(props){
        super(props);
        this._id = "react-select-chip" + Math.random();
    }

    state = {
        open: false,
        inputValue: ''
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    render() {
        const {
            classes, theme,
            fullWidth = true, id = this._id,
            options = [], value = [], handleChange,
            placeholder = "Select", name = 'react-select-chip',
            onInputChange = () => null, single = false, disabled = false, style = {},
            clearable, error = false, margin, label, withCheckbox = false,
            valueRenderer = value => value.join(', '),
            isCreatable=false, isRangeSelection = false, handleApply = () => null
        } = this.props;
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };
        const valueSize = Array.isArray(value) ? value.length : 1;
        if(withCheckbox){
            return (
                <FormControl style={{...style, maxWidth: fullWidth ? '100%' : undefined}} fullWidth={fullWidth} disabled={disabled} className={classes.formControl} margin={margin}>
                    { label && <InputLabel htmlFor={id}>{label}</InputLabel> }
                    <MUISelect
                        multiple
                        value={value}
                        onChange={e => handleChange(e.target.value)}
                        input={<Input id={id} placeholder={placeholder}/>}
                        renderValue={valueRenderer}
                        MenuProps={MenuProps}
                        open={this.state.open}
                        onClose={e => {
                            this.handleClose();
                            handleApply(value);
                        }}
                        onOpen={this.handleOpen}
                        displayEmpty
                    >
                        {options.map((option, index) => {
                            const label = option.hasOwnProperty("label") ? option.label : option;
                            const optValue = option.hasOwnProperty("value") ? option.value : option;
                            const valueIndex = value.indexOf(optValue);
                            const selected = valueIndex > -1;
                            const disabled = isRangeSelection && valueSize > 1 && valueIndex > 0 && valueIndex < valueSize - 1;
                            return <MenuItem disabled={disabled} selected={selected} key={label + '-' + optValue} value={optValue}>
                                <Checkbox disabled={disabled} checked={selected}/>
                                <ListItemText title={label} primary={label}/>
                            </MenuItem>
                        })}
                    </MUISelect>
                </FormControl>
            )
        }
        const selectValue = Array.isArray(value) ? value.map(v => ({label: getLabelForValue(options, v), value: v})) : (value !== null && value !== "" ? {label: getLabelForValue(options, value), value: value} : value);

        const selectableProps = {
            classes: classes,
            style: selectStyles,
            textFieldProps: {
                label: label,
                fullWidth: fullWidth,
                InputLabelProps: {
                    shrink: true,
                },
                error: error,
                margin: margin,
                disabled: disabled,
                name: name
            },
            options: options.map(({label, value, lingual})=> { return { label: lingual? label +` (${lingual})` : label, value: value}}),
            components:components,
            value: selectValue,
            onChange: obj => {
                const actualValue = single ? (obj ? obj.value || "" : "") : (Array.isArray(obj) ? obj.map(o => o.value) : []);
                handleChange(actualValue);
            },
            placeholder:placeholder,
            isMulti: !single,
            isDisabled: disabled,
            onInputChange: inputValue => {
                this.setState({inputValue});
                onInputChange(inputValue);
            },
            createOptionPosition: "first",
            isClearable: clearable,
            inputValue: this.state.inputValue
        }

        return (
            <div className={classes.root} style={style} onClick={e => e.stopPropagation()}>
                <NoSsr>
                    {!isCreatable ? <Select {...selectableProps}/> : <CreatableSelect {...selectableProps}/>}
                </NoSsr>
            </div>
        );
    }
}

IntegrationReactSelect.propTypes = {
    classes: PropTypes.object,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    fullWidth: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number
    ]),
    single: PropTypes.bool,
    isCreatable: PropTypes.bool,
    onInputChange: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    clearable: PropTypes.bool,
    error: PropTypes.bool,
    withCheckbox: PropTypes.bool,
    margin: PropTypes.string,
    valueRenderer: PropTypes.func,
    isRangeSelection: PropTypes.bool,
    handleApply: PropTypes.func //Only for withCheckbox
};

export default withStyles(styles, {withTheme: true})(IntegrationReactSelect);