/**
 * Created by Araja Jyothi Babu on 03-Nov-16.
 */
import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Dimensions from 'react-dimensions';
import SortedData from '../../../../../../../../components/reusable/SortedData';
import {SortHeaderCell, SortTypes } from '../../../../../../../../components/reusable/SortHeaderCell';
import Placeholder from '../../../../../../../../components/reusable/Placeholder';
import Loading from '../../../../../../../../components/reusable/Loading';
import {indexSorter, roundOffNumber, formatTime } from '../../../../../../../../utils'
import {SortUtils, APXOR_SDK_CATEGORY_CONSTANTS} from '../../../../../../../../constants';
import Snackbar from '@material-ui/core/Snackbar';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import Chip from '@material-ui/core/Chip';
import Apxor from 'apxor';
const TextCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props} onClick={() => props.onClick(data[rowIndex])} style={{cursor: "pointer"}}>
        { data[rowIndex][col] }
    </Cell>
);

const FloatCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props} onClick={() => props.onClick(data[rowIndex])} style={{cursor: "pointer"}}>
        { data[rowIndex][col] === 0 ? "NA" : roundOffNumber(data[rowIndex][col]) }
    </Cell>
);

const IS_FIRST_SESSION_KEY = "is_first_session";
const IS_UNINSTALL_BEFORE_SESSION_KEY = "is_uninstall_before_session";

const TimeCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props} onClick={() => props.onClick(data[rowIndex])} style={{cursor: "pointer"}}>
        <span style={{display: 'flex'}}>{ formatTime(data[rowIndex][col]) }
            <span style={{marginLeft: '10px'}}>
                { data[rowIndex][IS_FIRST_SESSION_KEY] && <Chip label="FIRST SESSION" /> }
                { data[rowIndex][IS_UNINSTALL_BEFORE_SESSION_KEY] && <Chip label="UNINSTALL SESSION" /> }
            </span>
        </span>
    </Cell>
);

//FIXME: highlight row on click
class SessionsDataTable extends React.Component{

    constructor(props){
        super(props);
        this._defaultSortIndexes = [];
        let size = 0;
        let {session_list, meta_info} = this.props.data;
        this._dataList = session_list;
        if(session_list){
            size = session_list.length
        }
        for (let index = 0; index < size; index++) {
            this._defaultSortIndexes.push(index);
        }

        this.state = {
            sortedDataList: session_list,
            meta: meta_info,
            currentPage: 1,
            colSortDirs: {
                time : SortTypes.DESC
            },
            orderBy: null,
            sortOrder: -1,
            isEmptySession: false
        };

        this._onSortChange = this._onSortChange.bind(this);
    }

    _onSortChange(columnKey, sortDir) {
        const {sortedDataList} = this.state;
        let size = sortedDataList.length;
        this._defaultSortIndexes = [...Array(size).keys()];
        if(this.state.currentPage === this.state.meta.total_pages){ //if all pages loaded, calls for client side sorting
            let sortIndexes = indexSorter(this._defaultSortIndexes, sortedDataList, columnKey, sortDir, SortTypes);
            this.setState({
                sortedDataList: SortedData(sortIndexes, sortedDataList),
            });
        }else {
            this.props.getPagedData(1, columnKey, SortUtils[sortDir]); //FIXME:when called sort updating page to 1
            this.setState({
                currentPage: 1
            });
        }
        this.setState({
            colSortDirs: {
                [columnKey]: sortDir,
            },
            orderBy: columnKey,
            sortOrder: SortUtils[sortDir]
        });
    }

    _onScrollEnd(scrollX, scrollY){
        let dataVersion = +this.state.currentPage + 1;
        const {total_pages} = this.state.meta;
        if(total_pages >= dataVersion) { //calling only for legitimate dataVersion
            this.props.getPagedData(dataVersion, this.state.orderBy, this.state.sortOrder);
            this.setState({
                currentPage: dataVersion
            });
        }
    }

    componentWillReceiveProps(nextProps){
        const { data: {session_list, meta_info} } = nextProps;
        if(this.state.sortedDataList.length < meta_info.total_sessions) {//if not all sessions loaded
            this.setState({
                sortedDataList: session_list,
                meta: meta_info,
            });
        }
    }

    handleRowClick(rowData){
        if(rowData.navigation_count === 0 && rowData.events === 0){
            this.setState({isEmptySession: true});
        }else {
            Apxor.logEvent("SelectSession", {session_time: rowData.length, navigation_count: rowData.navigation_count, event_count: rowData.events}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
            this.props.onSessionClick(rowData.session_id, rowData.time, rowData); //sending session id and session start time
            this.setState({isEmptySession: false});
        }
    }

    handleRequestClose = () => {
        this.setState({isEmptySession: false});
    };

    render(){
        const {sortedDataList, colSortDirs} = this.state;
        const {sessions_pending, sessions_failed} = this.props;
        let rowHeight = 0, height = 100,  length = 0;
        rowHeight = 50;
        if(sortedDataList) {

            length = sortedDataList.length;
            height = rowHeight * (length > 10 ? 10 : length + 1);
        }
        return (
            <div>
                {sessions_failed && <Placeholder height={200}/>}
                <Table
                    rowHeight={rowHeight}
                    headerHeight={rowHeight}
                    rowsCount={length}
                    width={this.props.containerWidth || 800}
                    height={height}
                    onScrollEnd={this._onScrollEnd.bind(this)}
                    {...this.props}>
                    <Column fixed={true} columnKey="time" header={<SortHeaderCell
                        columnKey="time"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.time}>
                        Time
                    </SortHeaderCell>} cell={<TimeCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="time" />} width={350}/>
                    <Column flexGrow={1} columnKey="length" header={<SortHeaderCell
                        columnKey="length"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.length}>
                        Duration <span className="small">(Secs)</span>
                    </SortHeaderCell>} cell={<FloatCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="length" />} width={100}/>
                    <Column flexGrow={1} columnKey="navigation_count" header={<SortHeaderCell
                        columnKey="navigation_count"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.navigation_count}>
                        Navigations
                    </SortHeaderCell>} cell={<TextCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="navigation_count" />} width={100}/>
                    <Column flexGrow={1} columnKey="events" header={<SortHeaderCell
                        columnKey="events"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.events}>
                        Events
                    </SortHeaderCell>} cell={<TextCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="events" />} width={100}/>
                    <Column flexGrow={1} columnKey="crashes" header={<SortHeaderCell
                        columnKey="crashes"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.crashes}>
                        Crashes/Hangs
                    </SortHeaderCell>} cell={<TextCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="crashes" />} width={100}/>
                </Table>
                {sessions_pending && <Loading/>}
                <Snackbar
                    open={this.state.isEmptySession}
                    message="Timeline not available for Empty Session..!"
                    autoHideDuration={4000}
                    onRequestClose={this.handleRequestClose}
                />
            </div>
        );
    }

}

export default Dimensions()(SessionsDataTable);