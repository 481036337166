/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 1:41 PM
 */

import {Fab, Grid, Tooltip, Typography, withStyles} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import debounce from 'lodash/debounce';
import React from 'react';
import MultiSelect from "../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {
    CONDITIONS_ENUM,
    CONDTIONAL_OPERATORS,
    CONTEXT_EVENT_TYPES_ENUM,
    APXOR_SDK_CATEGORY_CONSTANTS
} from "../../../../../../../../../../constants";
import generateShortID from "../../../utils";
import ClassicCard from "../../ClassicCard";
import ActionsTextField from "../components/ActionsTextField";
import ContextEventSelector from "../components/ContextEventSelector";
import DummyInsetContainer from "../components/DummyInsetContainer";
import FieldDescription from "../components/FieldDescription";
import FieldSection from "../components/FieldSection";
import OptionSelect from "../components/OptionSelect";
import AddIcon from '@material-ui/icons/Add';
import {getScreenNameForPlatform} from "../utils";
import Apxor from 'apxor';

const page3_styles = (theme) => ({
    deviceListItem: {
        marginTop: 4,
        marginBottom: 4,
        borderRadius: 4,
        padding: 0
    },
    deviceListItem_checkBox: {
        padding: 4
    },
    deviceListItem_text: {
        padding: "2px 12px"
    }
});

const CONTEXT_TYPES = [
    {
        title: "Multi-step Trigger",
        description: "Whose behavior is defined by the following rules ",
        disabled: false,
        key: "ordering"
        // sequence_enabled: false,
        // combine_operator: CONDITIONS_ENUM.OR
    },
    {
        title: "Event based Trigger",
        description: "Who performs selected Event.",
        disabled: false,
        key: "n-th-event"
        // sequence_enabled: false,
        // combine_operator: CONDITIONS_ENUM.AND,
    },
    {
        title: "On Launch Trigger",
        description: "Who launched the app (No Specific Context)",
        disabled: true,
        key: "zero-context"
        // sequence_enabled: true,
        // combine_operator: CONDITIONS_ENUM.AND,
    }
];

const TRIGGER_CONFIG = {
    "trigger": { // DEFAULT FOR ALL (app_start)
        "event_type": "app_start",
        "activity": "",
        "details": {
            "name": "",
            "additional_info": {}
        },
        "time_bounds": {
            "lower": 0,
            "upper": 60000000
        }
    }
};

const nThEventDefaultConfig = {
    event_type: "app_event",
    dummy_event_type: "nth",
    time_bounds: {
        lower: 0,
        upper: 6000000
    },
    count_config: {
        count: 1,
        operator: "GTE"
    },
    combine_operator: CONDTIONAL_OPERATORS.OR,
    activity: "",
    details: {
        additional_info: {},
        name: ""
    },
    ...TRIGGER_CONFIG
};

const getDefaultCombineOperator = (messagesConditions) => {
    if (messagesConditions.length > 0 && !messagesConditions[0].hasOwnProperty("dummy_event_type")) {
        return messagesConditions[0].combine_operator
    }
    return CONDITIONS_ENUM.OR;
};

class Page3 extends React.Component {
    constructor(props) {
        super(props);
        let initialConditions = this.props.messages2.new_message.conditions;
        let isNthEvent = initialConditions && initialConditions.length > 0
            && (initialConditions[0].dummy_event_type === "nth" || initialConditions[0].event_type === "nth");
        // console.log(this.props.messages2.new_message);
        this.state = {
            selectedContextType: isNthEvent ? 1 : 0,
            contextConditions: initialConditions || [],
            // combineOperator: (!isNthEvent) ? initialConditions[0].combine_operator : CONDITIONS_ENUM.OR,
            combineOperator: getDefaultCombineOperator(initialConditions),
            enableSequence: (!isNthEvent) ? initialConditions.sequence > 0 : false,
            nThEventConfig: {
                event: isNthEvent ? initialConditions[0].details.name : null,
                count: isNthEvent ? initialConditions[0].details.additional_info.apx_lt_count + 1 : -1,
            },
            testing: this.props.messages2.new_message.testing,
        };

        this.debounce = debounce((fn, data) => {
            fn(data)
        }, 300);
    }

    handleAddNewEvent = (event_type) => {
        const {contextConditions = [], enableSequence, combineOperator} = this.state;
        Apxor.logEvent("AddContextEvent", {type: event_type}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
        const new_condition_config =
            {
                _id: generateShortID(),
                event_type: CONTEXT_EVENT_TYPES_ENUM[event_type],
                context_rule_type: event_type,
                sequence: enableSequence ? contextConditions.length + 1 : -1,
                time_bounds: {
                    lower: 0,
                    upper: 6000000
                },
                count_config: {
                    count: 1,
                    operator: "GTE"
                },
                combine_operator: combineOperator,
                activity: "",
                details: {
                    additional_info: {},
                    name: ""
                },
                ...TRIGGER_CONFIG
            };
        this.setState({
            contextConditions: [...contextConditions, new_condition_config]
        }, () => {
            this.props.updateInFlow({
                conditions: [...contextConditions, new_condition_config]
            })
        })
    };

    updateOperator = (operator) => {
        let updatedContextConditions = this.state.contextConditions.map(o => ({
            ...o,
            combine_operator: operator
        }));
        this.setState({
            combineOperator: operator,
            contextConditions: updatedContextConditions
        });
        this.props.updateInFlow({
            conditions: updatedContextConditions
        })
    };

    removeCondition = (index) => {
        this.setState((oldState) => ({
            contextConditions: [
                ...oldState.contextConditions.slice(0, index),
                ...oldState.contextConditions.slice(index + 1, oldState.contextConditions.length)
            ]
        }), () => {
            this.setState((updatedState) => ({
                contextConditions: updatedState.contextConditions.map((condition, idx) => ({
                    ...condition,
                    sequence: updatedState.enableSequence ? idx + 1 : -1,
                }))
            }), () => {
                this.props.updateInFlow({
                    conditions: [
                        ...this.state.contextConditions
                    ]
                })
            });
        })
    };

    handleUpdateCondition = (index, data) => {
        const {contextConditions} = this.state;
        this.setState({
            contextConditions: [
                ...contextConditions.slice(0, index),
                {...contextConditions[index], ...data},
                ...contextConditions.slice(index + 1, contextConditions.length)
            ]
        }, () => {
            this.props.updateInFlow({
                conditions: [
                    ...this.state.contextConditions,
                ]
            })
        })
    };

    handleContextType = (selectedIndex) => {
        Apxor.logEvent("AddContext", {context: CONTEXT_TYPES[selectedIndex].title}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
        this.setState((oldState) => ({
            selectedContextType: selectedIndex,
            contextConditions: oldState.selectedContextType === selectedIndex ? oldState.contextConditions : []
        }), () => {
            if (selectedIndex === 1) {
                this.props.updateInFlow({
                    conditions: [
                        {
                            ...nThEventDefaultConfig,
                            details: {
                                additional_info: {
                                    "apx_lt_count": this.state.nThEventConfig.count,
                                },
                                name: this.state.nThEventConfig.event
                            }
                        }
                    ]
                })
            } else {
                this.props.updateInFlow({
                    conditions: [
                        ...this.state.contextConditions,
                    ]
                })
            }
        })
    };

    handleToggleAddTestDeviceToList = (id) => {
        const {testing: {devices = []}} = this.state;
        let updatedDevicesList = [];
        let indexOfID = devices.indexOf(id);
        if (indexOfID > -1) {
            updatedDevicesList = devices.filter(o => o !== id);
        } else {
            updatedDevicesList = [...devices, id]
        }
        let disableTesting = updatedDevicesList.length === 0;
        this.setState({
            testing: {
                enabled: !disableTesting,
                devices: updatedDevicesList || []
            }
        }, () => {
            this.props.updateInFlow({
                testing: {
                    enabled: !disableTesting,
                    devices: updatedDevicesList || []
                }
            })
        })
    };

    render() {
        const {
            classes, hidden,
            appState: {appEvents = []}, appTestDevices,
        } = this.props;
        const {selectedContextType, contextConditions = [], combineOperator, nThEventConfig, testing: {devices = []} = {}} = this.state;
        const sequenceEnabled = selectedContextType === 0;
        const {meta: {platform}} = this.props.messages2.new_message;
        return (
            <div>
                {!hidden && <Grid container spacing={32}>
                    <Grid item xs={9}>
                        <FieldSection>
                            <FieldDescription
                                title={"Trigger"}
                                description={"Choose the right kind of Trigger for Live Users"}
                            />
                            <OptionSelect
                                noneSelectable={false}
                                direction={"horizontal"}
                                numPerRow={3}
                                value={selectedContextType}
                                radioOptions={{
                                    hasCheckboxOrRadio: true,
                                    radioFirst: true
                                }}
                                radioStyles={{
                                    marginRight: 8
                                }}
                                handler={(selectedIndex) => this.handleContextType(selectedIndex)}
                                options={CONTEXT_TYPES}
                                cardStyles={{minWidth: 200, paddingLeft: 8}}
                                style={{marginBottom: 12, marginTop: 12, width: "100%"}}
                            />
                            <DummyInsetContainer withInnerShadow style={{borderRadius: 6}}>
                                {
                                    selectedContextType === 1
                                    && <ClassicCard style={{display: "inline-block"}}>
                                        <Grid container spacing={8} alignItems={"center"}>
                                            <Grid item>
                                                <Typography>
                                                    When User did
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <MultiSelect
                                                    options={appEvents.map(eventName => ({
                                                        label: eventName,
                                                        value: eventName
                                                    }))}
                                                    single
                                                    placeholder={"Select Event"}
                                                    // disabled
                                                    value={nThEventConfig.event}
                                                    handleChange={(event) => {
                                                        this.setState({
                                                            nThEventConfig: {
                                                                ...nThEventConfig,
                                                                event: event
                                                            }
                                                        }, () => {
                                                            this.props.updateInFlow({
                                                                // update n-th Event in superState
                                                                conditions: [{
                                                                    ...nThEventDefaultConfig,
                                                                    details: {
                                                                        name: event,
                                                                        additional_info: {
                                                                            "apx_lt_count": nThEventConfig.count <= 0 ? -1 : nThEventConfig.count - 1
                                                                        }
                                                                    },
                                                                    ...TRIGGER_CONFIG
                                                                }]
                                                            })
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    for
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <ActionsTextField
                                                    value={nThEventConfig.count < 0 ? 0 : nThEventConfig.count}
                                                    style={{maxWidth: 72, marginBottom: 0}}
                                                    InputProps={{min: 0}}
                                                    type={"number"}
                                                    onChange={(e) => {
                                                        let fieldValue = e.target.value;
                                                        this.setState({
                                                            nThEventConfig: {
                                                                ...nThEventConfig,
                                                                count: fieldValue
                                                            }
                                                        }, () => {
                                                            this.debounce(this.props.updateInFlow, {
                                                                conditions: [{
                                                                    ...nThEventDefaultConfig,
                                                                    details: {
                                                                        name: nThEventConfig.event,
                                                                        additional_info: {
                                                                            "apx_lt_count": fieldValue - 1
                                                                        }
                                                                    },
                                                                    ...TRIGGER_CONFIG
                                                                }]
                                                            })
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    {nThEventConfig.count > 1 ? "times" : "time"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ClassicCard>}
                                {selectedContextType === 0 && <>
                                    {contextConditions.map((condition, index) => {
                                        return (
                                            <ContextEventSelector
                                                {...this.props}
                                                key={selectedContextType + "-" + condition._id}
                                                index={index}
                                                sequenceEnabled={sequenceEnabled}
                                                combineOperator={combineOperator}
                                                updateOperator={this.updateOperator}
                                                condition={condition}
                                                removeCondition={() => this.removeCondition(index)}
                                                handleUpdateCondition={(data) => this.handleUpdateCondition(index, data)}
                                            />
                                        )
                                    })}

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        padding: "12px 0",
                                        border: "2px dashed darkgray",
                                        borderRadius: 12
                                    }}>
                                        <Tooltip title={"User opened the Screen"}>
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    padding: "0 16px",
                                                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
                                                }}
                                                onClick={(e) => {
                                                    this.handleAddNewEvent("ACTIVITY")
                                                }}
                                            >
                                                <AddIcon style={{marginRight: 8}}/> Screen
                                            </Fab>
                                        </Tooltip>

                                        <Tooltip title={"User completed a Campaign"}>
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    padding: "0 16px",
                                                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
                                                }}
                                                onClick={(e) => {
                                                    this.handleAddNewEvent("MESSAGE")
                                                }}
                                            >
                                                <AddIcon style={{marginRight: 8}}/> Cascade
                                            </Fab>
                                        </Tooltip>

                                        <Tooltip title={"User performed the Client Event"}>
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    padding: "0 16px",
                                                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
                                                }}
                                                onClick={(e) => {
                                                    this.handleAddNewEvent("CLIENT")
                                                }}
                                            >
                                                <AddIcon style={{marginRight: 8}}/> Client Event
                                            </Fab>
                                        </Tooltip>

                                        <Tooltip title={"User performed the App Event"}>
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    padding: "0 16px",
                                                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
                                                }}
                                                onClick={(e) => {
                                                    this.handleAddNewEvent("APP")
                                                }}
                                            >
                                                <AddIcon style={{marginRight: 8}}/> App Event
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                </>}
                            </DummyInsetContainer>
                        </FieldSection>
                    </Grid>
                    <Grid item xs={3}>
                        <FieldSection>
                            <FieldDescription
                                title={"Test with Context"}
                                description={"Test this walkthrough on selected devices\n"}
                            />
                            <ClassicCard style={{minHeight: 96, marginTop: 12}}>
                                <List
                                    style={{
                                        maxHeight: 360,
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                        padding: 0,
                                        paddingRight: 8
                                    }}>
                                    {appTestDevices.map(testDevice => {
                                        const isSelected = devices.includes(testDevice.id);
                                        return (
                                            <ListItem
                                                key={testDevice.id}
                                                role={undefined} dense button
                                                className={classes.deviceListItem}
                                                style={{background: isSelected ? "#ced5ff70" : "transparent"}}
                                                onClick={() => this.handleToggleAddTestDeviceToList(testDevice.id)}
                                            >
                                                <Checkbox
                                                    color={"primary"}
                                                    className={classes.deviceListItem_checkBox}
                                                    checked={isSelected}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                                <ListItemText
                                                    className={classes.deviceListItem_text}
                                                    primary={`${testDevice.hasOwnProperty("nick_name") ? testDevice.nick_name : testDevice.model}`}
                                                    secondary={`${testDevice.id}` + (testDevice.hasOwnProperty("nick_name") ? " · " + testDevice.model : "")}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                {/* FIXME: save for testing */}
                                {/*<Divider style={{marginTop: 8}}/>*/}
                                {/*<CardActions*/}
                                {/*    disableActionSpacing={true}*/}
                                {/*    style={{paddingBottom: 0, justifyContent: "space-between"}}*/}
                                {/*>*/}
                                {/*    <Typography variant={"subtitle1"}>*/}

                                {/*    </Typography>*/}
                                {/*    <Button*/}
                                {/*        disabled={(devices.length <= 0)} variant={"contained"} size={"small"}*/}
                                {/*        color={"primary"} onClick={() => {*/}
                                {/*        bindedDispatch(sendAsTestMessage(app_id, new_message))*/}
                                {/*    }}>*/}
                                {/*        <OfflineBoltIcon style={{marginRight: 8}}/> Test*/}
                                {/*    </Button>*/}
                                {/*</CardActions>*/}
                            </ClassicCard>
                        </FieldSection>
                    </Grid>
                </Grid>}
            </div>
        );
    }
}

export default withStyles(page3_styles)(Page3);