/**
 * Created by Rakesh Peela
 * Date: 31-July-2019
 * Time: 6:22 PM
 */

import React from 'react';
import {connect} from 'react-redux';
import {Typography, withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {APXOR_ROLES} from "../../../../../constants";
import {PasswordEditSection, PendingAppsApproval, ProfileSettings} from "./components";
import {updateCustomerProfileInfo, updatePassword, resetToDefaults, getUserRoleAction} from "./actions";
import Snackbar from "../../../../../components/reusable/MaterialUi/Snackbar";
import Dialog from "../../../../../components/reusable/MaterialUi/Dialog";

function mapStateToProps(state) {
    return {
        auth: state.auth,
        appState: state.app,
        meta: state.meta,
        customerProfile: state.customerProfile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updatePassword: (body) => dispatch(updatePassword(body)),
        updateUserProfileInfo: (body) => dispatch(updateCustomerProfileInfo(body)),
        resetToDefaults: () => dispatch(resetToDefaults()),
        getUserRole: (userId) => dispatch(getUserRoleAction(userId)),
        boundDispatch: dispatch
    }
}

const styles = {
    bigAvatar: {
        margin: "auto",
        width: 120,
        height: 120,
        marginBottom: 16
    }
};

class Profile extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            auth: {
                user: {email}
            },
            getUserRole,
        } = this.props;
        getUserRole(email)
    }

    componentWillMount() {
        resetToDefaults();
    }

    componentWillUnmount() {
        resetToDefaults();
    }

    render() {
        const {
            customerProfile: {
                update_password_success = false, update_profile_success = false,
                update_password_failed = false, update_profile_failed = false,
                role = APXOR_ROLES.REGULAR_USER,
                pending_app_approvals = []
            },
            boundDispatch,
            resetToDefaults
        } = this.props;
        const {auth:{user:{email}}} = this.props;
        return (
            <section style={{width: '100%', marginTop: 80}} className="content">
                <Grid container spacing={16} justify={"center"}>
                    <Grid item xs={12} sm={10} md={8}>
                        <Grid container spacing={16}>
                            <Grid item xs={6}>
                                <ProfileSettings {...this.props}/>
                            </Grid>
                            <Grid item xs={6}>
                                <PasswordEditSection {...this.props}/>
                            </Grid>
                        </Grid>
                        <hr style={{marginTop: 16, marginBottom: 16}}/>
                        <Grid container spacing={32}>
                            {
                                role === APXOR_ROLES.APX_ADMIN
                                && <Grid item>
                                    <PendingAppsApproval userId={email} boundDispatch={boundDispatch} pendingApps={pending_app_approvals}/>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    status={Boolean(update_profile_success)}
                    title="Profile Updated"
                    confirmLabel={"Ok"}
                    handleConfirm={() => resetToDefaults()}
                >
                    <Typography>Your <strong>Profile</strong> has been successfully updated</Typography>
                </Dialog>

                {update_profile_failed &&
                <Snackbar>There was a problem updating your profile</Snackbar>}

                {update_password_failed &&
                <Snackbar>{update_password_failed}</Snackbar>}
                <Dialog
                    noActions={true}
                    status={Boolean(update_password_success)}
                    title="Logout Warning"
                >
                    <Typography>Password change <strong>successful</strong>.</Typography>
                    <Typography>Please login again.</Typography>
                </Dialog>
            </section>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Profile));
