import React, { Component } from 'react';
import {Button, Grid, Paper } from "@material-ui/core";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import IconButton from '@material-ui/core/IconButton';
import Switch from "../../../../../../../components/reusable/Switch";
import {readableUserGroupName, USER_GROUPS, APXOR_SDK_CATEGORY_CONSTANTS} from "../../../../../../../constants";
import Tooltip from '@material-ui/core/Tooltip';
import FilterIcon from '@material-ui/icons/FilterList';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from "../../../../../../../components/reusable/MaterialUi/Dialog";
import {UserAndSessionProperties} from "../../Segments/NewSegment/components";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import UsersList from "../../../../../../../components/reusable/UsersList";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import {downloadJSONasCSVorXLS, getGroupNameFromList, withQueryStrings} from "../../../../../../../utils";
import Apxor from 'apxor';

export class UserSearch extends Component {

    constructor(props){
        super(props);
        this.state = {
            field: "",
            q: "",
            filterTriggered: false,
            user: [],
            session: [],
            showLoadMoreMessage: false,
            downloadClicked: false,
            exhausted: false
        }
    }

    handleFieldChange = (field) => {
        this.setState({field});
    };

    handleQueryChange = (q) => {
        this.setState({q}, () => {
            const { field, q } = this.state;
            this.props.handleQueryUpdate(q, field);
        });
    };

    handleSearchValue = (user) => {
        this.setState({user}, () => {
            const { q, field } = this.state;
            this.props.handleQueryUpdate(this.props.params.appId, q, field);
        });
    };


    downloadUsersForPush = () => { //FIXME: Not here
        const {
            filters, params: { appId },
            userExplorer: { segmentId, group },
            auth: { user: { email } }
        } = this.props;
        window.open(window.API_BASE + window.API_ROOT + 'segments/download?' + withQueryStrings({
            ...filters, appId, customerId: email, segmentId, group
        }));
    };

    render(){
        const { filterTriggered, exhausted, downloadClicked } = this.state;
        const {
            params: { appId },
            appState: {
                attributes = { user: [], session: [] },
                appTestDevices = []
            },
            userExplorer: {
                user_profiles,
                group, segmentId, q, field, user = [], session = [],
                field_values = {}
            },
            segment: { segment_list = [] },
            updateFilters, updateGroup, updateSearchField,
            updateSearchQueryTerm, updateSegment, handleSearch,
            getRecommendations
        } = this.props;
        let autoCompleteValues = [];
        if(field_values.hasOwnProperty(field)){
            autoCompleteValues = field_values[field].map(o => ({label: o, value: o}));
        }
        const enableDownloadUsersForPush = segmentId && !field && user.length === 0 && session.length === 0; //FIXME: Might change
        //FIXME: download enabling for eazy diner only
        const availableForDownload = ["2f41d301-d99d-4bb9-b83f-b4127f40d7b8", "70efe874-e351-4fb1-9b50-c2579ce05155", "b571ecdc82247c3e8eca898ee3a93cdd", "1bf10a55-6c63-4bd3-89e1-3dc98a3fa32b", "d0a0caad5476d7144c8426b48fe3d03c", "e67e3d74-58aa-4464-9f7c-df00fd63b567"].includes(appId)
        return(
            <Paper elevation={1} style={{padding: 20}}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Switch
                            data={USER_GROUPS}
                            value={group}
                            handleChange={updateGroup}
                            containerStyles={{margin: '0 auto', justifyContent: 'center'}}
                        />
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                        <MultiSelect
                            options={segment_list.map(o => ({label: o.name, value: o._id}))}
                            value={segmentId}
                            handleChange={(segment) => {
                                updateSegment(segment);
                                Apxor.logEvent("SelectSegmentClicked", {segment: segment}, APXOR_SDK_CATEGORY_CONSTANTS.USER_EXPLORER);
                                this.setState({downloadClicked: false});
                            }}
                            placeholder={"Select Segment"}
                            single
                        />
                    </Grid>
                    <br/>
                    <Grid item xs={12} md={3}>
                        <MultiSelect
                            options={attributes.user.map(o => ({label: o, value: o}))}
                            value={field}
                            handleChange={field => {
                                updateSearchField(field);
                                Apxor.logEvent("SelectSegmentPropertyClicked", {property: field}, APXOR_SDK_CATEGORY_CONSTANTS.USER_EXPLORER);
                                getRecommendations(appId, field, ""); //default recommendations
                            }}
                            placeholder={"Property"}
                            single
                        />
                    </Grid>
                    <Grid item xs={12} md>
                        <MultiSelect
                            options={autoCompleteValues}
                            value={q}
                            disabled={!(field && field.length > 0)}
                            handleChange={(q) => {
                                updateSearchQueryTerm(q);
                                if(q && q.length > 0) {
                                    updateSearchQueryTerm(q);
                                    getRecommendations(appId, field, q);
                                }
                            }}
                            placeholder={"Search..."}
                            onInputChange={(q) => {
                                if(q && q.length > 0) {
                                    updateSearchQueryTerm(q);
                                    Apxor.logEvent("SelectSegmentPropertyValue", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_EXPLORER);
                                    getRecommendations(appId, field, q);
                                }
                            }}
                            single
                            /*inputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        style={{marginTop: 20}}
                                        onClick={() => this.handleQueryChange(q)}
                                        //onMouseDown={this.handleMouseDownPassword}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }}*/
                        />
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <Tooltip id="tooltip-apply-more" title="Apply More Filters" placement="top">
                            <IconButton color="secondary" aria-label="Delete"
                                        onClick={() => this.setState({filterTriggered: true})}>
                                <FilterIcon/>
                            </IconButton>
                        </Tooltip>
                        <Dialog
                            status={ filterTriggered }
                            handleConfirm={() => {
                                this.setState({filterTriggered: false});
                            }}
                            title="Apply Filters"
                            handleClose={() => this.setState({filterTriggered: false})}
                            confirmLabel="Apply"
                            allowCancelLabel="Cancel"
                            dialogProps={{
                                maxWidth: 'md',
                                fullWidth: true
                            }}
                        >
                            <Grid container justify="center">
                                <Grid item xs>
                                    <UserAndSessionProperties
                                        {...this.props}
                                        handleQueryUpdate={filters => {
                                            this.props.handleQueryUpdate(filters);
                                            updateFilters(filters);
                                            this.setState(filters);
                                        }}
                                        noReset
                                    />
                                </Grid>
                            </Grid>
                        </Dialog>
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                Apxor.logEvent("GetUsersSelected", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
                                handleSearch(appId);
                                this.setState({showLoadMoreMessage: false});
                            }}
                            color="primary"
                            style={{margin: 10, float: 'right'}}
                        >Get Users</Button>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <UsersList data={user_profiles} appId={appId} testDevices={appTestDevices}/>
                        {
                            user_profiles.length === 10 &&
                            <Button disabled={exhausted} size="small"
                                    style={{margin: '10px auto', display: 'block'}}
                                    color="primary"
                                    onClick={() => {
                                        handleSearch(appId, 50);
                                        this.setState({exhausted: true});
                                    }}>Load more...</Button>
                        }
                        {/*{
                            user_profiles.length > 10 &&
                            <Button size="small"
                                    style={{margin: '10px auto', display: 'block'}}
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({showLoadMoreMessage: true}, () => {
                                            setTimeout(() => {
                                                this.setState({showLoadMoreMessage: false});
                                            }, 5000);
                                        });
                                    }}>Load more...</Button>
                        }*/}
                        {/*{ showLoadMoreMessage && <Snackbar autoHideDuration={6000}>For more users, please reach out to us.</Snackbar> }*/}
                        {
                            enableDownloadUsersForPush && availableForDownload &&
                            <Tooltip id="users-download-for-push" title="Download users to send Push notifications" placement="top">
                                <Button
                                    variant="contained"
                                    disabled={downloadClicked}
                                    onClick={() => {
                                        this.setState({downloadClicked: true});
                                        setTimeout(() => {
                                            this.setState({downloadClicked: false});
                                        }, 45000);
                                        this.downloadUsersForPush();
                                    }}
                                    color="primary"
                                    style={{margin: 10, float: 'left'}}
                                >
                                    { downloadClicked && 'Download starting...' }
                                    { !downloadClicked && 'Download'}
                                </Button>
                            </Tooltip>
                        }
                        {
                            !this.props.meta.api_pending && (!Array.isArray(user_profiles) || user_profiles.length === 0) && <Placeholder
                                text="No users found. Try changing filters"
                                withIcon
                                icon={<PeopleOutlineIcon style={{width: 100, height: 100, opacity: 0.2}}/>}
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>
        )
    }

}