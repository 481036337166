/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 2:57 PM
 */

import React from 'react'

class FieldSection extends React.Component {
    render() {
        return (
            <section style={{
                paddingTop: 16,
                paddingBottom: 12,
                borderBottom: '1px solid #cccccc33'
            }}>
                {this.props.children}
            </section>
        );
    }
}

export default FieldSection;