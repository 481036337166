/**
 * Created by Rakesh Peela
 * Date: 14-Nov-2019
 * Time: 2:30 PM
 */

import {ButtonBase, Grid, IconButton, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import styled from 'styled-components';
import MultiSelect from "../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {EventAttributeQuery} from "../../../../../../../../../../components/reusable/QueryBuilder";
import {
    CONDITIONS_ENUM,
    LOGICAL_OPERATORS,
    MESSAGE_ENUM,
    MESSAGE_EVENTS
} from "../../../../../../../../../../constants";
import ClassicCard from "../../ClassicCard";
import {getScreenNameForPlatform} from "../utils";
import FieldDescription from "./FieldDescription";

/*
    {
        event_type: CONTEXT_EVENT_TYPES_ENUM[event_type],
        context_rule_type: event_type,
        sequence: selectedType.sequence_enabled ? contextConditions.length + 1 : -1,
        time_bounds: {
            lower: 0,
            upper: 6000000
        },
        count_config: {
            count: 1,
            operator: "GTE"
        },
        combine_operator: selectedType.combine_operator,
        activity: "",
        details: {
            additional_info: {},
            name: ""
        },
    };
*/

const Operators = [
    {label: ">", value: LOGICAL_OPERATORS.GT},
    {label: "≥", value: LOGICAL_OPERATORS.GTE},
    {label: "<", value: LOGICAL_OPERATORS.LT},
    {label: "≤", value: LOGICAL_OPERATORS.LTE},
    {label: "=", value: LOGICAL_OPERATORS.EQ},
    {label: "!=", value: LOGICAL_OPERATORS.NEQ}
];

class CountConfigModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            countConfig: {count = 1, operator = LOGICAL_OPERATORS.GTE},
            handleUpdateCondition
        } = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item xs>
                    <MultiSelect
                        options={Operators}
                        label={"Operator"}
                        value={operator}
                        single
                        handleChange={(op) => handleUpdateCondition({
                            count_config: {
                                operator: op,
                                count
                            }
                        })}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        label="Count"
                        value={count}
                        onChange={e => handleUpdateCondition({
                            count_config: {
                                operator,
                                count: Number(e.target.value)
                            }
                        })}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

class AppEventContextModule extends React.Component {
    render() {
        const {condition: {details: {name}, details, count_config}, handleUpdateCondition, disabled} = this.props;
        return (
            // TODO:<R> Rewrite this
            <div>
                <EventAttributeQuery
                    {...this.props}
                    {...details}
                    disabled={disabled}
                    handleUpdate={details => handleUpdateCondition({
                        details
                    })}
                />
                {
                    name
                    && <Grid container spacing={16}>
                        <Grid item xs={3}/>
                        <Grid item>
                            <CountConfigModule
                                countConfig={{...count_config}}
                                handleUpdateCondition={handleUpdateCondition}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

class ClientContextModule extends React.Component {
    render() {
        const {condition: {details: {name}, count_config}, handleUpdateCondition} = this.props;
        // const {messages2: {client_events_list}} = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item xs={6} md={6}>
                    <MultiSelect
                        options={["apx_hard_back_button_pressed"].map(o => ({label: o, value: o}))}
                        label={"Select Client Event"}
                        value={name}
                        single
                        handleChange={(client_event) => handleUpdateCondition({
                            details: {
                                additional_info: {},
                                name: client_event
                            }
                        })}
                        style={{marginRight: 8}}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    {name && <CountConfigModule
                        countConfig={{...count_config}}
                        handleUpdateCondition={handleUpdateCondition}
                    />}
                </Grid>
            </Grid>
        );
    }
}

const getMessagesListFromType = (messageType, messages) => {
    if (messageType === MESSAGE_EVENTS.INLINE_SHOWN || messageType === MESSAGE_EVENTS.INLINE_DISMISSED) {
        return messages.filter(o => o.uis.some(k => k.type === MESSAGE_ENUM.IN_LINE));
    } else {
        return messages.filter(o => o.uis.some(k => k.type === MESSAGE_ENUM.IN_APP));
    }
};

const MESSAGE_EVENTS_ENUM = {
    "INAPP_SHOWN": "inapp_shown",
    "INAPP_DISMISSED": "inapp_dismissed",
    "INLINE_SHOWN": "inline_shown",
    "INLINE_DISMISSED": "inline_dismissed"
};

class MessageContextModule extends React.Component {
    render() {
        const {
            condition: {
                details: {name},
                details,
                count_config
            },
            handleUpdateCondition,
            disabled
        } = this.props;
        return (
            <>
                <div>
                    <EventAttributeQuery
                        {...this.props}
                        {...details}
                        filteredEvents={["inline_shown", "inapp_shown", "inline_dismissed", "inapp_dismissed"]}
                        disabled={disabled}
                        handleUpdate={details => handleUpdateCondition({
                            details
                        })}
                    />
                    {
                        name
                        && <Grid container spacing={16}>
                            <Grid item xs={3}/>
                            <Grid item>
                                <CountConfigModule
                                    countConfig={{...count_config}}
                                    handleUpdateCondition={handleUpdateCondition}
                                />
                            </Grid>
                        </Grid>
                    }
                </div>
            </>
        );
    }
}

class ActivityContextModule extends React.Component {
    render() {
        const {condition: {activity, count_config}, handleUpdateCondition} = this.props;
        const {appState: {appScreenNames = []}} = this.props;
        const {messages2: {new_message: {meta: {platform}}}} = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item xs={6} md={6}>
                    <MultiSelect
                        options={appScreenNames.map(o => ({label: o, value: o}))}
                        label={"Select Screen"}
                        value={activity}
                        single
                        handleChange={(activity) => handleUpdateCondition({
                            activity, details: {
                                additional_info: {},
                                name: activity
                            }
                        })}
                        style={{marginRight: 8}}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    {activity &&
                    <CountConfigModule countConfig={{...count_config}} handleUpdateCondition={handleUpdateCondition}/>}
                </Grid>
            </Grid>
        );
    }
}

const StyledOperatorContainer = styled.div`
    display: inline-block;
    padding: 4px;
    background-color: #dcdcdc;
    border-radius: 36px;
    border: 2px dashed darkgray;
    margin: 16px 0;
    &::before {
        content: '';
        border: 2px dashed darkgray;
        background-color: transparent;
        position: relative;
        top: 30px;
        left: 50%;
        border-width: 1px;
        border-style: dashed;
        border-color: darkgray;
    }
    &::after {
        content: '';
        border: 2px dashed darkgray;
        background-color: transparent;
        position: relative;
        top: -30px;
        left: -49%;
        border-width: 1px;
        border-style: dashed;
        border-color: darkgray;
    }
`;

class CombineOperatorComponent extends React.Component {
    handleOperatorClick = (e, selectedOperator) => {
        const {updateOperator, operator} = this.props;
        updateOperator(selectedOperator || operator);
    };

    render() {
        const {operator, sequenceEnabled} = this.props;
        return (
            <div style={{display: 'flex', justifyContent: "center"}}>
                <StyledOperatorContainer>
                    {
                        Object.keys(CONDITIONS_ENUM).map(operatorE => (
                            <ButtonBase
                                key={"operator_btn_" + operatorE}
                                style={{
                                    borderRadius: 36,
                                    margin: "0 6px",
                                }}
                                disableRipple disableTouchRipple
                                disabled={operator === operatorE}
                                onClick={(e) => this.handleOperatorClick(e, operatorE)}
                            >
                                <ClassicCard
                                    style={{minWidth: 64, borderRadius: 36, padding: "8px 4px"}}
                                    selected={operator === operatorE}
                                    disabled={operator !== operatorE}
                                >
                                    {CONDITIONS_ENUM[operatorE]}
                                </ClassicCard>
                            </ButtonBase>
                        ))
                    }
                </StyledOperatorContainer>
            </div>
        );
    }
}

function getTermForHeading(ctx_type) {
    switch (ctx_type) {
        case "MESSAGE":
            return "Campaign Event";
        case "APP":
            return "App Event";
        case "ACTIVITY":
            return "Screen";
        case "CLIENT":
            return "Client Event";
        default:
            return "Event";
    }
}

class ContextEventSelectorHeader extends React.Component {
    render() {
        const {
            index,
            condition: {
                context_rule_type
            },
            removeCondition
        } = this.props;
        const {messages2: {new_message: {meta: {platform}}}} = this.props;
        return (
            <Grid container justify={"space-between"}>
                <Grid item xs>
                    <FieldDescription
                        title={getTermForHeading(context_rule_type)}/>
                </Grid>
                <Grid item xs={1} md={1} style={{textAlign: "right"}}>
                    <IconButton style={{color: "red"}} aria-label="Delete Condition" onClick={() => {
                        removeCondition(index)
                    }}>
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

class ContextEventSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    pickContextModule = (context_rule_type, props) => {
        switch (context_rule_type) {
            case "CLIENT":
                return <ClientContextModule {...props}/>;
            case "APP":
                return <AppEventContextModule {...props}/>;
            case "MESSAGE":
                return <MessageContextModule {...props}/>;
            default:
                return <ActivityContextModule {...props}/>;
        }
    };

    render() {
        const {
            condition: {
                context_rule_type = "ACTIVITY"
            },
            updateOperator,
            combineOperator,
            sequenceEnabled,
        } = this.props;
        return (
            <div>
                <ClassicCard>
                    <ContextEventSelectorHeader {...this.props}/>
                    {this.pickContextModule(context_rule_type, this.props)}
                </ClassicCard>
                <CombineOperatorComponent operator={combineOperator}
                                          sequenceEnabled={sequenceEnabled}
                                          updateOperator={updateOperator}
                />
            </div>
        );
    }
}

export default ContextEventSelector;