import styled from "styled-components";
import tinyColor2 from "tinycolor2";
import React from "react";

const getFontStyle = (option) => {
    switch (option) {
        case "bold":
            return {
                fontWeight: 600,
            };
        case "bold_italic":
            return {
                fontWeight: 600,
                fontStyle: "italic"
            };
        case "italic":
            return {
                fontStyle: "italic"
            };
        default:
            return {};
    }
};

export const translateToFontConfig = (text_config) => {
    const {color, font_family, size, style} = text_config;
    return {
        color,
        "fontFamily": font_family,
        "fontSize": `${size}px`,
        "fontStyle": style !== "bold" ? style : "normal",
        "fontWeight": style === "bold" ? style : "normal",
        margin: 0,
        padding: 0,
        lineHeight: `${(Number(size)) + 2}px`
    };
};

export const StyledHolder = styled.div`
    user-select: none;
    padding: 4px;
    transition: all 0.1s ease-in-out;
    ${props => props.selected ? "border: 1px dashed #2979ff;" : "border: 1px solid transparent;"}
    &:hover {
        box-shadow: 0 3px 3px rgba(18,66,74,0.25);
        cursor: pointer;
        background: ${props => props.bgColor ? tinyColor2(props.bgColor).complement().toHexString() : "#fff"};
        border: 1px solid #2979ff;
    }
`;


function renderMacros(text) {
    let renderedText = text;
    const regex = /(\[(user|session)_[\w\s]+\([\w\s]+\)\])+/gm;
    let m;
    while ((m = regex.exec(text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        m.forEach((match, groupIndex) => {
            if (groupIndex == 0) {
                const macro = match;
                const defaultValue = match.substring(match.indexOf("(") + 1, match.indexOf(")"));
                renderedText = renderedText.replace(macro, defaultValue);
            }
        });
    }
    return renderedText;
}

class TextHolder extends React.Component {
    render() {
        const {isPure, bgColor, config, handleModuleSelection, selected, style = {}} = this.props;
        const {
            text,
            ...others
        } = config;
        return (
            isPure ?
                <p style={{...translateToFontConfig(others), ...style}}>
                    {renderMacros(text)}
                </p>
                :
                <StyledHolder bgColor={bgColor}
                              selected={selected}
                              onClick={handleModuleSelection}>
                    <p style={{...translateToFontConfig(others), ...style}}>
                        {renderMacros(text)}
                    </p>
                </StyledHolder>
        );
    }
}

class ImageHolder extends React.Component {
    render() {
        const {
            isPure, style, newUI,
            config = "",
            handleModuleSelection = null,
            selected = false,
            type = "FILE",
            useImgTag = true
        } = this.props;

        const hasImageSize = newUI && newUI.image_size;

        return (
            isPure ?
                <div style={{
                    width: 64,
                    height: 64,
                    padding: 0,
                    minWidth: type === "FILE" ? 0 : 64,
                    minHeight: type === "FILE" ? 0 : 64, ...style
                }}>
                    <img alt={"image"} src={type === "FILE" ? "data:image/png;base64," + config : config}
                         style={{width: "100%", height: "100%"}}/>
                </div>
                :
                <StyledHolder
                    style={{
                        width: 64, height: 64,
                        padding: 0,
                        minWidth: type === "FILE" ? 0 : 64,
                        minHeight: type === "FILE" ? 0 : 64,
                        backgroundSize: !useImgTag ? (hasImageSize && (newUI.image_size === 'Stretch' ? '100% 100%' : newUI.image_size.toLowerCase())) : '',
                        backgroundPosition: !useImgTag ? 'center' : '',
                        backgroundRepeat: !useImgTag ? 'no-repeat' : '',
                        backgroundImage: !useImgTag ? 'url("' + config + '")' : '',
                        ...style,
                    }}
                    selected={selected}
                    onClick={handleModuleSelection}>
                    {
                        useImgTag
                        && <img alt={"image"} src={type === "FILE" ? "data:image/png;base64," + config : config}
                                style={{width: "100%", height: "100%"}}/>
                    }
                </StyledHolder>
        );
    }
}

class TooltipButton extends React.Component {
    render() {
        const {buttonConfig, style} = this.props;
        const {text_config, color} = buttonConfig;
        return (
            <div style={{
                padding: "2px 12px",
                background: color,
                color: text_config.color || "#fff",
                fontFamily: text_config.font_family || "sans-serif",
                borderRadius: 4,
                fontSize: Number(text_config.size) || 12,
                ...style,
                ...getFontStyle(text_config.style.toLowerCase())
            }}>
                {text_config.text}
            </div>
        );
    }
}

class ButtonsHolder extends React.Component {
    render() {
        const {bgColor, config, handleModuleSelection, selected, style, buttonHolderStyle} = this.props;
        return (
            <StyledHolder bgColor={bgColor}
                          selected={selected}
                          style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              ...buttonHolderStyle
                          }}
                          onClick={handleModuleSelection}
            >
                {
                    config && Array.isArray(config) && config.map((oneButton, index) => (
                            <TooltipButton style={{marginRight: (config.length > 1 && index === 0) ? 8 : '', ...style}}
                                           buttonConfig={oneButton}
                                           key={index + "__button"}
                            />
                        )
                    )
                }
            </StyledHolder>
        );
    }
}

export {ImageHolder, TextHolder, ButtonsHolder, TooltipButton};