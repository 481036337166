/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import React from 'react';
import { Link } from 'react-router';
import { ApxorAppInfo } from '../../../config';
import Dialog from '../../../components/reusable/MaterialUi/Dialog';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { NON_DASHBOARD_ENDPOINTS } from "../../../constants/EndPoints";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {DASHBOARD_ENDPOINTS} from "../../../constants/EndPoints/index";

const ITEM_HEIGHT = 48;
export class UserMenu extends React.Component {

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const {user, loggedIn, location} = this.props;
        const open = Boolean(this.state.anchorEl);

        return loggedIn ?
            <div style={{padding: '0 10px'}}>
                <Typography title={user.name} style={{display: 'inline', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ user.name }</Typography>
                <IconButton
                    aria-label="User Menu"
                    aria-owns={open ? 'long-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 3.5,
                        },
                    }}
                >
                    {location.pathname !== `/${DASHBOARD_ENDPOINTS.PROFILE}` &&
                    <Link to="/profile">
                        <MenuItem key="profile" onClick={this.handleClose}>
                            Profile
                        </MenuItem>
                    </Link>}
                    {location.pathname !== `/${DASHBOARD_ENDPOINTS.APPS}` &&
                    <Link to="/apps">
                        <MenuItem key="home" onClick={this.handleClose}>
                            Home
                        </MenuItem></Link>}
                    <Link to="/logout">
                        <MenuItem key="logout" onClick={this.handleClose}>
                            Logout
                        </MenuItem>
                    </Link>
                </Menu>
            </div>
            :
            <Link to="/login" className="dropdown-toggle" style={{padding: '0 10px'}}>
                <Typography variant="h6" color="inherit">SignIn</Typography>
            </Link>
    }
}

export class Logo extends React.Component {

    getImage = () => {
        const { birthDaySrc, logo } = ApxorAppInfo;
        let src = logo;
        const date = new Date();
        if(date.getDate() === 6 && date.getMonth() === 3){ //if it's April 6th
            src = birthDaySrc;
        }
        return src;
    };

    render(){
        const { name, logo } = ApxorAppInfo;
        return (
            <Link to="/apps" style={{margin: '0 10px'}}>
                <img alt={name} src={logo} style={{width: '65%'}}/>
            </Link>
        )
    }

}

const drawerWidth = 240;
const shortNavWidth = 60;

const styles = theme => ({
    appBar: {
        position: 'fixed',
        zIndex: 1300, //FIXME:
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: theme.palette.background.paper//'white'
    },
    onDashboard: {
        width: `calc(100% - ${shortNavWidth}px)`,
        marginLeft: shortNavWidth
    },
    appBarShift: {
        marginLeft: drawerWidth,
        //left: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
        color: '#333333'
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        height: '100%',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        width: 60,
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerInner: {
        // Make the items inside not wrap when transitioning:
        width: drawerWidth,
    },
    heading: {
        marginLeft: theme.spacing.unit * 4
    }
});

const NO_CONNECTION_MESSAGE = "No internet connection..!";

function notOnDashboard(location){
    for(let i = 0; i < NON_DASHBOARD_ENDPOINTS.length; i++){
        if(location.pathname === `/${DASHBOARD_ENDPOINTS.APPS}`) return true; //FIXME: need proper check
        if(location.pathname === `/${DASHBOARD_ENDPOINTS.PROFILE}`) return true;
        if(location.pathname === `/${DASHBOARD_ENDPOINTS.RESET_PASSWORD}`) return true; //FIXME: need proper check
        if(location.pathname.indexOf(NON_DASHBOARD_ENDPOINTS[i]) > -1){
            return true;
        }
    }
    return false;
}

class AppHeaderWrapper extends React.Component {

    state = {
        isOffline: false,
        onDashboard: false
    };

    componentWillMount(){
        if(!notOnDashboard(this.props.location)){
            this.setState({onDashboard: true});
            const {params: {appId}} = this.props;
            const tags = [appId];
            if (this.props.location.pathname.includes('walkthroughs')) {
                tags.concat("Walkthroughs");
            }
            if (this.props.location.pathname.includes('messages')) {
                tags.concat("Messages");
            }
            if (this.props.location.pathname.includes('surveys')) {
                tags.concat("surveys");
            }
            window.hj('tagRecording', tags);
        }
    }

    componentDidMount(){
        window.addEventListener("online", () => this.setState({isOffline: false}));
        window.addEventListener("offline", () => this.setState({isOffline: true}));
    }

    componentWillReceiveProps(nextProps){
        if(this.props.location.pathname !== nextProps.location.pathname) {
            if(notOnDashboard(nextProps.location)){
                nextProps.updateSidebar(false);
                this.setState({onDashboard: false});
            }else{
                this.setState({onDashboard: true});
            }
        }

    }

    render() {
        const {
            classes,
            navigation: { isSidebarOpen, pinnedSidebar, heading }
        } = this.props;
        const { isOffline, onDashboard } = this.state;
        return (
            <AppBar className={classNames(classes.appBar, {[classes.appBarShift]: pinnedSidebar, [classes.onDashboard]: onDashboard})} elevation={1}>
                <Toolbar disableGutters={onDashboard}>
                    {!onDashboard && <Logo />}
                    { onDashboard && <Typography className={classes.heading} variant="h5" title={heading} style={{lineHeight: '2.35em'}} noWrap>{ heading }</Typography> }
                    <div style={{flex: 1}}/>
                    <UserMenu {...this.props}/>
                </Toolbar>
                <Dialog status={isOffline} noActions>
                    <Typography variant="h6" >{ NO_CONNECTION_MESSAGE }</Typography>
                </Dialog>
            </AppBar>
        );
    }
}

AppHeaderWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export const AppHeader = withStyles(styles, { withTheme: true })(AppHeaderWrapper);