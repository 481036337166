/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
/*import Knob from 'react-canvas-knob';FIXME: need alternative*/
import SessionsList from './SessionsList';
import {
    displayTimeFromNow, getHighestFromList, readableDeviceName,
    copyToClipboard, minMaxAverageOf, roundOffNumber, hasAndIsDefined
} from '../../../../../../../../utils';
import Box from "../../../../../../../../components/reusable/Box";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import {Grid, Tooltip, IconButton} from "@material-ui/core";
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import DevicesIcon from '@material-ui/icons/Devices';
import MultiUtilityChart from "../../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import {APP_PLATFORMS} from "../../../../../../../../constants";
import NickNameDialog from "../../../../../../../../components/reusable/NickNameDialog";
import Typography from "@material-ui/core/Typography";


const renderValue = (value) => <strong style={{float: 'right'}}>{value || "NA"}</strong>;


export class UserProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nick_name: "",
            showDialog: false,
        };
    }

    onNickChange = (event) => {
        this.setState({nick_name: event.target.value});
    };

    onNickCancel = () => {
        this.setState({showDialog: false, nick_name: ""})
    };

    onMarkClicked = () => {
        this.setState({showDialog: true, nick_name: ""})
    };

    onNickDone = () => {
        const {data, params} = this.props;
        const {appId, userId} = params;
        const hardware_model = data.static_basic_info.device_info.hardware_model;
        const customerId = data.dynamic_basic_info.customerId;

        this.props.markAsTestDevice(appId, {
            id: userId,
            nick_name: this.state.nick_name,
            model: hardware_model,
            custom_id: customerId
        });

        this.setState({showDialog: false, nick_name: ""})
    };

    onRemoveClicked = () => {
        const {appId, userId} = this.props.params;
        this.props.removeAsTestDevice(appId, userId);
    };

    render() {
        const {appId, userId} = this.props.params;
        const {dynamic_basic_info, static_basic_info} = this.props.data;
        const {
            installation_date, uninstall_date, last_seen,
            device_info: {
                os_version, hardware_model, device_dimensions: {width = 0, height = 0} = {},
                dpi, dimensions_in_pixels: {width: pWidth = 0, height: pHeight = 0} = {}
            } = {},
            location_info: {
                city, countryCode
            } = {}
        } = static_basic_info;
        const {
            state, user_info, customerId = undefined
        } = dynamic_basic_info;
        const {
            app: {basic_info: {platform} = {}} = {},
            appTestDevices = []
        } = this.props.appState;
        const isTestDevice = appTestDevices.findIndex(device => device.id === userId) > -1;
        const isWeb = platform === APP_PLATFORMS.web;
        const device = appTestDevices.find(device => device.id === userId) || {};
        const title = hasAndIsDefined(device, "nick_name") ? device.nick_name : userId;
        const subtitle = hasAndIsDefined(device, "nick_name") ? userId : device.nick_name;

        const titleComponent = (
            <div>
                <Typography variant="subheading" noWrap style={{marginLeft: 5}}>{title}</Typography>
                {subtitle && <Typography variant="caption" noWrap style={{marginLeft: 5}}>{subtitle}</Typography>}
            </div>
        );
        return (
            <Box
                title={titleComponent}
                icon={<img src="/assets/img/user.png" alt="User Avatar" style={{width: '40px'}}/>}
                controls={
                    <div>
                        {
                            !isTestDevice &&
                            <Tooltip id="add-test-device" title="Click to add as Test Device" placement="top">
                                <IconButton aria-label="Add" onClick={this.onMarkClicked}>
                                    <DevicesIcon color="secondary"/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            isTestDevice &&
                            <Tooltip id="remove-test-device" title="Click to remove as Test Device" placement="top">
                                <IconButton aria-label="Remove" onClick={this.onRemoveClicked}>
                                    <ImportantDevicesIcon color="primary"/>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                }
            >
                <List>
                    <ListItem>
                        <ListItemText primary={
                            <span title={hardware_model}>Device {renderValue(readableDeviceName(hardware_model))}</span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={
                            <span>OS {renderValue(os_version)}</span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={
                            <span title={isWeb ? "Device Pixel Ratio" : "Dots Per Inch"}>
                                {isWeb ? "DPR" : "DPI"} {renderValue(roundOffNumber(dpi))}
                                </span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={
                            <span>Resolution {renderValue(`${pWidth} X ${pHeight}`)}</span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={
                            <span>Dimensions {renderValue(`${width} X ${height}`)}</span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={
                            <span>Location {renderValue(`${city} (${countryCode})`)}</span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={
                            <span>Installation Date {renderValue(displayTimeFromNow(installation_date))}</span>
                        }/>
                    </ListItem>
                    {
                        !isWeb &&
                        <ListItem>
                            <ListItemText primary={
                                <span>Uninstall Date {renderValue(displayTimeFromNow(uninstall_date))}</span>
                            }/>
                        </ListItem>
                    }
                    <ListItem>
                        <ListItemText primary={
                            <span>Last Seen {renderValue(displayTimeFromNow(last_seen))}</span>
                        }/>
                    </ListItem>
                </List>
                {this.state.showDialog &&
                <NickNameDialog
                    nick_name={this.state.nick_name}
                    onCancel={this.onNickCancel} onDone={this.onNickDone} onChange={this.onNickChange}
                    model={readableDeviceName(hardware_model)} deviceId={userId}
                />}
            </Box>
        )
    }

}

export class UserUsage extends React.Component{

    render(){
        const {session_info, crash_info } = this.props.data;
        const { total_session_count, average_event_count } = session_info;
        return (
            <Box title="Usage Information">
                <List>
                    <ListSubheader color="inherit">Total Sessions {renderValue(total_session_count)}</ListSubheader>
                    <ListItem>
                        <ListItemText secondary={
                            <span>Avg. Screens {renderValue(total_session_count)}</span>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText secondary={
                            <span>Avg. Events {renderValue(average_event_count)}</span>
                        }/>
                    </ListItem>
                    <ListSubheader color="inherit">Crashes {renderValue(crash_info.count || 0)}</ListSubheader>
                    <ListItem>
                        <ListItemText secondary={
                            <span>Last Crash seen {renderValue(displayTimeFromNow(crash_info.latest_occurrence))}</span>
                        }/>
                    </ListItem>
                </List>
            </Box>
        )
    }

}

export class UserSessionsGraph extends React.Component {

    render(){
        const { data } = this.props;
        const stats = minMaxAverageOf(data, "Sessions");
        return(
            <MultiUtilityChart
                height={220}
                valueLabel="Sessions"
                dataKey="key"
                data={data}
                areaDataKeys={["Sessions"]}
                /*lineDataKeys={["Avg", "All Avg"]}*/
                title="Sessions"
                withHeader={true}
                color="#519674"
                dot={false}
                stats={stats}
            />
        );
    }

}

export class UserUSP extends React.Component {

    renderColor(value){
        return value > 50 ? "#00AA00" : (value > 25? "#AAAACC" : "#DD5523")
    }

    render(){

        //TODO: need different colors for
        return (
            <Grid container>
                {/*<Grid item xs xl lg md sm>
                    <Knob width={70} value={roundOffNumber(functionality, 1)} fgColor={this.renderColor(functionality)} onChange={null} readOnly={true}/>
                    <Typography variant="subtitle1" color="inherit">Functionality</Typography>
                </Grid>
                <Grid item xs xl lg md sm>
                    <Knob width={70} value={roundOffNumber(usability, 1)} fgColor={this.renderColor(usability)} onChange={null} readOnly={true}/>
                    <Typography variant="subtitle1" color="inherit">Usability</Typography>
                </Grid>
                <Grid item xs xl lg md sm>
                    <Knob width={70} value={roundOffNumber(stability, 1)} fgColor={this.renderColor(stability)} onChange={null} readOnly={true}/>
                    <Typography variant="subtitle1" color="inherit">Stability</Typography>
                </Grid>
                <Grid item xs xl lg md sm>dynamic_basic_info
                    <Knob width={70} value={roundOffNumber(performance, 1)} fgColor={this.renderColor(performance)} onChange={null} readOnly={true}/>
                    <Typography variant="subtitle1" color="inherit">Performance</Typography>
                </Grid>*/}
            </Grid>
        )
    }

}

export class UserInfo extends React.Component {

    render(){
        const {data} = this.props;
        const keys = Object.keys(data);
        return (
            <div className="row">
                <div className="col-md-12">
                    <Box
                        collapsible
                        title="User Custom Information"

                    >
                        <List>
                            {
                                keys && keys.map(key =>
                                    <ListItem key={key}>
                                        <ListItemText primary={
                                            <span><strong>{key}</strong>
                                                <span title={`Click to copy: ${data[key]}`} style={{float: 'right'}} onClick={copyToClipboard(data[key])}>{ data[key] }</span>
                                            </span>
                                        }/>
                                    </ListItem>
                                )
                            }
                        </List>
                    </Box>
                </div>
            </div>
        )
    }

}

export class SessionsTable extends React.Component {

    render(){
        const {session_list, meta_info} = this.props.user.sessions;
        return (
            <Box
                withPadding
                title={`${(session_list && session_list.length )|| 0 } of ${meta_info.total_sessions} Sessions`}
            >
                <SessionsList data={ this.props.user.sessions } {...this.props}/>
            </Box>
        )
    }

}