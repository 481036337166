/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {UserProfile, UserSessionsGraph, UserUsage, SessionsTable, UserInfo} from './components/components'
import SessionTimeline from '../../../../../../../components/reusable/Timeline';
import {
    loadUserProfile, resetUserProfile, getUserProfileSessionTimeLine,
    getUserProfileSessions, markAsTestDevice, removeAsTestDevice
} from './actions';
import { doSaveState } from '../../../../../../../actions';
import Grid  from "@material-ui/core/Grid";
import {updateHeading} from "../../../Navigation/actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadData: (appId, userId) => {
            dispatch(loadUserProfile(appId, userId));
            dispatch(doSaveState());//trigger save state for users page
        },
        resetData: () => dispatch(resetUserProfile()),
        getTimeline: (appId, sessionId) => dispatch(getUserProfileSessionTimeLine(appId, sessionId)),
        getPagedSessionList: (appId, userId, dataVersion, orderBy, sortOrder) => dispatch(getUserProfileSessions(appId, userId, dataVersion, orderBy, sortOrder)),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        markAsTestDevice: (appId, testDevice) => dispatch(markAsTestDevice(appId, testDevice)),
        removeAsTestDevice: (appId, deviceId) => dispatch(removeAsTestDevice(appId, deviceId))
    }
}

class User extends Component {

    constructor(props){
        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            timestamp: new Date(), //currently selected session start time
            duration: 0
        }
    }

    componentWillMount(){
        this.props.updateHeading("User Profile");
        this.props.loadData(this.appId, this.props.params.userId);
    }

    componentWillUnmount(){
        this.props.resetData();
    }

    onSessionClick = (sessionId, time, sessionData) => {
        this.props.getTimeline(this.appId, sessionId);
        this.setState({
            timestamp: time, //sets currently selected session start time
            duration: sessionData.length
        });
    };

    getPagedData = (dataVersion, orderBy, sortOrder) => {
        const {appId, userId} = this.props.params;
        this.props.getPagedSessionList(appId, userId, dataVersion, orderBy, sortOrder);
    };

    render() {
        const { user,
            user: {
                session_time_series = [], dynamic_basic_info = {},
                timeline_pending, timeline_failed, timeline, actual_timeline
            }
        } = this.props;
        const { timestamp, duration } = this.state;
        return (
            <section className="content">
                <Grid container spacing={16}>
                    <Grid item xs={12} md={3}>
                        <UserProfile data={user} {...this.props}/>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <UserUsage data={user}/>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <UserSessionsGraph data={session_time_series}/>
                            </Grid>
                            <Grid item xs={12}>
                                <UserInfo data={dynamic_basic_info}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <SessionsTable onSessionClick={this.onSessionClick} getPagedData={this.getPagedData} {...this.props}/>
                { user.timeline.length > 0 && <SessionTimeline
                        timestamp={timestamp}
                        pending={timeline_pending}
                        failed={timeline_failed}
                        timeline={timeline}
                        actual_timeline={actual_timeline}
                        duration={duration}
                    />
                }
            </section>
        );
    }
}

User.propTypes = {

};

User.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);