/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, { Component, Fragment } from "react";
import HTML5Backend from "react-dnd-html5-backend";
import Grid from "@material-ui/core/Grid";
import { DragDropContext } from "react-dnd";
import Button from "@material-ui/core/Button";
import { Divider, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import WidgetSource from "../components/WidgetSource";
import WidgetsContainer from "../components/WidgetsContainer";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Components from "../../SelfComponents/index";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import {
  downloadAsFile,
  goToByScroll,
  isUserRestricted,
  isValidEmail,
  sortArrayByFrequency,
  uniqueId,
} from "../../../../../../../utils";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import CustomQuery from "../CustomQuery";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import LabelIcon from "@material-ui/icons/Label";
import { UserAndSessionProperties } from "../../Segments/NewSegment/components";
import {
  USER_GROUPS,
  APXOR_SDK_CATEGORY_CONSTANTS,
} from "../../../../../../../constants";
import Switch from "../../../../../../../components/reusable/Switch";
import { session } from "../../../../../../../utils/Storage";
import Dialog from "../../../../../../../components/reusable/MaterialUi/Dialog";
import Checkbox from "../../../../../../../components/reusable/MaterialUi/Checkbox";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import VOV from "../components/VOV";
import FilterList from "@material-ui/icons/FilterList";
import InfoHelper from "../../../../../../../components/reusable/InfoHelper";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ConversionRate from "../components/ConversionRate";
import moment from "moment";
import Apxor from "apxor";

class ShareDashboard extends Component {
  constructor(props) {
    super(props);
    const {
      appState: {
        app: {
          basic_info: { customers = [] },
        },
      },
    } = props;
    this.state = {
      shareTriggered: false,
      customer_id: "",
      limited_access: true,
      hasError: false,
      newCustomer: "",
      customerSuggestions: [
        ...customers
          .map((o) => o.customer_id)
          .map((o) => ({ label: o, value: o })),
      ],
    };
  }

  render() {
    const {
      params: { appId, dashboardId },
      meta: { api_pending },
      appState: {
        app: {
          basic_info: { customers = [] },
        },
      },
      inEditMode,
      addDashboardToCustomer,
      iam: { add_dashboard_to_customer, add_dashboard_to_customer_failed },
    } = this.props;
    const {
      shareTriggered,
      customer_id,
      limited_access,
      hasError,
      customerSuggestions,
    } = this.state;
    return (
      <span>
        <Button
          title="Share this dashboard"
          size="small"
          disabled={true}
          color="secondary"
          onClick={(e) => {
            Apxor.logEvent("ShareDashboard");
            this.setState({ shareTriggered: true });
          }}
          style={{ margin: 5 }}
        >
          <ShareIcon /> Share
        </Button>
        {add_dashboard_to_customer && (
          <Snackbar>Dashboard shared successfully.</Snackbar>
        )}
        {add_dashboard_to_customer_failed && (
          <Snackbar>Something went wrong, try again.</Snackbar>
        )}
        <Dialog
          status={shareTriggered}
          handleConfirm={() => {
            if (isValidEmail(customer_id)) {
              if (limited_access) {
                addDashboardToCustomer(appId, customer_id, dashboardId);
              } /*else{
                                addCustomer(appId, { //FIXME: May overrides if calling for existing one
                                    customer_id,
                                    limited_access: false,
                                    dashboards: [ dashboardId ]
                                });
                            }*/
              this.setState({
                shareTriggered: false,
                customer_id: "",
                limited_access: true,
                hasError: false,
              });
            } else {
              setTimeout(() => {
                this.setState({ hasError: true });
              }, 4000);
            }
          }}
          title="Share Confirmation"
          handleClose={() => {
            this.setState({
              shareTriggered: false,
              customer_id: "",
              limited_access: true,
              hasError: false,
            });
          }}
          confirmLabel="Confirm"
          allowCancelLabel="Cancel"
        >
          <MultiSelect
            placeholder="User Email"
            value={customer_id}
            handleChange={(customer_id) => {
              this.setState({ customer_id });
              if (isUserRestricted(customers, customer_id)) {
                this.setState({ limited_access: true });
              }
              if (!isValidEmail(customer_id)) {
                this.setState({ hasError: true });
              }
            }}
            onInputChange={(newCustomer) => {
              this.setState({ newCustomer });
              if (newCustomer.length > 0) {
                this.setState({
                  customerSuggestions: [
                    ...customers
                      .map((o) => o.customer_id)
                      .map((o) => ({ label: o, value: o })),
                    { label: newCustomer.trim(), value: newCustomer.trim() },
                  ],
                  hasError: false,
                });
              }
            }}
            fullWidth
            options={customerSuggestions}
            error={hasError}
            single
            clearable={false}
            style={{ minWidth: 280 }}
          />
          <br />
          <Checkbox
            disabled={true}
            checked={limited_access}
            handleChange={(limited_access) => this.setState({ limited_access })}
            label="Share only this dashboard."
          />
        </Dialog>
      </span>
    );
  }
}

class RenameDashboard extends Component {
  constructor(props) {
    super(props);
    const { heading = "" } = props;
    this.state = {
      reNameTriggered: false,
      name: heading,
    };
  }

  render() {
    const {
      params: { appId },
      meta: { api_pending },
      inEditMode,
      dashboard,
      updateDashboard,
      updateHeading,
    } = this.props;
    const { reNameTriggered, name } = this.state;
    return (
      <span>
        <Button
          title="Rename this Dashboard"
          size="small"
          disabled={inEditMode || api_pending}
          color="secondary"
          onClick={(e) => this.setState({ reNameTriggered: true })}
          style={{ margin: 5 }}
        >
          <LabelIcon /> Rename
        </Button>
        <Dialog
          status={reNameTriggered}
          handleConfirm={() => {
            if (name && name.length > 1) {
              updateDashboard(appId, { ...dashboard, name });
              updateHeading(name); //FIXME: updating without status check
              this.setState({ reNameTriggered: false });
            }
          }}
          title="Rename this Dashboard"
          handleClose={() => {
            this.setState({ reNameTriggered: false });
          }}
          confirmLabel="Confirm"
          allowCancelLabel="Cancel"
        >
          <TextField
            value={name}
            fullWidth
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
            label="Dashboard Name"
            type="text"
            required
          />
        </Dialog>
      </span>
    );
  }
}

const drawerWidth = 240;

const styles = (theme) => ({
  paper: {
    padding: 5,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  drawerPaper: {
    position: "relative",
    height: "100%",
    width: drawerWidth,
  },
  details: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 10,
  },
  root: {
    margin: "5px 2px",
    /*background: theme.palette.primary.light,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        '&:focus': {
            background: theme.palette.primary.main,
        }*/
  },
  colorDefault: {
    /*color: theme.palette.secondary.dark,
        background: theme.palette.primary.main*/
  },
  controls: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px 0",
    position: "relative",
  },
  filtersButton: {
    position: "absolute",
    left: 0,
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const components = props.components || [];
    this.state = {
      widgets: [...Components.widgets],
      components: components.map((component) =>
        Components.get(component.componentId)
      ),
      inEditMode: false,
      justRemoved: false,
      persistableComponents: components, //from saved config
      showFilters: false,
    };
    this.groupChangedOnce = false;
    this.updateDefaultGroupIfAvailable(components);
  }

  updateDefaultGroupIfAvailable = (components = []) => {
    if (components.length === 0) {
      return;
    }
    const groups = components
      .map((component) => {
        const {
          selfParams: { queryParams: { group } = {} } = {},
        } = component || { selfParams: { queryParams: {} } };
        if (group) {
          return group;
        } else {
          return false;
        }
      })
      .filter((o) => !!o);
    const groupsByFrequency = sortArrayByFrequency(groups);
    if (groupsByFrequency.length > 0) {
      this.props.updateGroup(groupsByFrequency[0]);
    }
    this.groupChangedOnce = true;
  };

  componentWillReceiveProps(nextProps) {
    const {
      components,
      dashboards: { config_pending },
    } = nextProps;
    const { justRemoved } = this.state;
    session.clear();
    if (
      !config_pending &&
      this.state.persistableComponents.length < components.length &&
      !justRemoved
    ) {
      //FIXME: not sure
      if (!this.groupChangedOnce) {
        this.updateDefaultGroupIfAvailable(components);
      }
      this.setState({
        persistableComponents: components,
        components: components.map((component) =>
          Components.get(component.componentId)
        ),
      });
    }
    components.map((component) => {
      session.set(component.selfParams.customLabel, "{}");
    });
  }

  handleDrop = (widget) => {
    const id = uniqueId("widget");
    const widgets = this.state.widgets.filter(
      (w) => !widget.isUnique || w.componentId !== widget.componentId
    );
    const widgetWithId = { ...widget, _id: id };
    const components = [...this.state.components, widgetWithId];
    const { component, icon, ...withoutComponent } = Components.get(
      widget.componentId
    );
    const persistableComponent = withoutComponent.isSelectable
      ? { ...withoutComponent, _id: id }
      : { ...withoutComponent, _id: id, selfParams: widget.selfParams }; //For widgets from Custom Query Builder
    const persistableComponents = [
      ...this.state.persistableComponents,
      persistableComponent,
    ];
    this.setState(
      { widgets, components, persistableComponents, justRemoved: false },
      () => {
        setTimeout(() => {
          goToByScroll(id);
        }, 1000);
      }
    );
  };

  resetAll = () => {
    this.setState(
      {
        widgets: Components.widgets,
        components: [],
        persistableComponents: [],
      },
      this.handleSave
    );
  };

  handleRemove = (index, widget) => {
    const widgets = !widget.isUnique
      ? this.state.widgets
      : [...this.state.widgets, Components.get(widget.componentId)];
    const { components, persistableComponents } = this.state;
    this.setState({
      widgets,
      components: [
        ...components.slice(0, index),
        ...components.slice(index + 1),
      ],
      persistableComponents: [
        ...persistableComponents.slice(0, index),
        ...persistableComponents.slice(index + 1),
      ],
      justRemoved: true,
    });
  };

  moveWidget = (id, atIndex) => {
    const { widget, index } = this.findWidget(id);
    const { components, persistableComponents } = this.state;
    let updatedComponents = [
      ...components.slice(0, index),
      ...components.slice(index + 1, components.length),
    ];
    updatedComponents = [
      ...updatedComponents.slice(0, atIndex),
      components[index],
      ...updatedComponents.slice(atIndex, updatedComponents.length),
    ];
    let updatedPersistableComponents = [
      ...persistableComponents.slice(0, index),
      ...persistableComponents.slice(index + 1, persistableComponents.length),
    ];
    updatedPersistableComponents = [
      ...updatedPersistableComponents.slice(0, atIndex),
      widget,
      ...updatedPersistableComponents.slice(atIndex, updatedComponents.length),
    ];
    this.setState({
      components: updatedComponents,
      persistableComponents: updatedPersistableComponents,
    });
  };

  findWidget = (id) => {
    const { persistableComponents } = this.state;
    const widget = persistableComponents.filter((c) => c._id === id)[0];
    return {
      widget,
      index: persistableComponents.indexOf(widget),
    };
  };

  handleWidgetUpdate = (widget) => (props) => {
    //console.error(widget, props);
    //TODO:
  };

  handleEdit = () => {
    this.setState({ inEditMode: !this.state.inEditMode });
  };

  handleSave = () => {
    Apxor.logEvent("SaveDashboard", {}, APXOR_SDK_CATEGORY_CONSTANTS.DASHBOARD);
    const { persistableComponents } = this.state;
    const config = {
      components: persistableComponents,
    };
    const {
      params: { appId },
      dashboard,
    } = this.props;
    this.props.updateDashboard(appId, { ...dashboard, config });
    //this.props.getCustomOverviewConfig(appId); //call after updating config
  };

  updateWidgetProps = (index) => (props) => {
    const { persistableComponents = [] } = this.state;
    const component = {
      ...persistableComponents[index],
      selfParams: props,
      ...props,
    };
    this.setState({
      persistableComponents: [
        ...persistableComponents.slice(0, index),
        component,
        ...persistableComponents.slice(index + 1, persistableComponents.length),
      ],
      //components: [...components.slice(0, index), component, ...components.slice(index + 1, components.length)]
    });
  };

  flatten = (data) => {
    let result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (let i = 0, l = cur.length; i < l; i++) {
          recurse(cur[i], prop + "[" + i + "]");
          if (l === 0) result[prop] = [];
        }
      } else {
        let isEmpty = true;
        for (let p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }

    recurse(data, "");
    return result;
  };

  ObjToCSV = (obj) => {
    let keys = [];
    let values = [];
    Object.keys(obj).map((key) => {
      keys.push(key);
      values.push(obj[key]);
    });
    return [keys.join(), values.join()].join("\r\n");
  };

  isNested = (obj) => {
    return Object.keys(obj).some(function (key) {
      try {
        return JSON.parse(obj[key]) && typeof JSON.parse(obj[key]) === "object";
      } catch (e) {
        // Do Nothing;
      }
      return obj[key] && typeof obj[key] === "object";
    });
  };

  callR = (data) => {
    let csv_string = "";
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== "{}") csv_string += key + "\n";
        try {
          const flat1 = this.flatten(
            JSON.parse(data[key].replace(/(?:\r\n|\r|\n)/g, "").trim())
          );
          try {
            if (this.isNested(flat1)) {
              this.flatten(flat1);
            } else {
              csv_string += this.ObjToCSV(flat1) + ",\n\n";
            }
          } catch (e) {
            // Do Nothing
          }
        } catch (er) {
          // Do Nothing
        }
      }
    }
    return csv_string;
  };

  handleDownload = (e) => {
    Apxor.logEvent(
      "DownloadDashboardSelected",
      {},
      APXOR_SDK_CATEGORY_CONSTANTS.DASHBOARD
    );
    e.preventDefault();
    const { persistableComponents = [] } = this.state;
    const {
      heading,
      filters: { since, till },
    } = this.props;
    let validSessionObjs = {};
    const date_string =
      moment(since).format("DD_MMM_YYYY") +
      "-" +
      moment(till).format("DD_MMM_YYYY");
    const labels = [];

    persistableComponents.forEach((component) => {
      const { selfParams: { customLabel } = {} } = component;
      labels.push(customLabel.trim().replace(/[",']g/, "_"));
    });

    const validSessionKeys = Object.keys(session.storage).filter((ob) => {
      return labels.includes(ob);
    });
    let sortOrder = labels.reduce(
      (obj, item, index) => ({
        ...obj,
        [item]: index,
      }),
      {}
    );
    validSessionKeys
      .sort((a, b) => sortOrder[a] - sortOrder[b])
      .forEach((key) => {
        validSessionObjs[key] = session.storage.getItem(key);
      });

    const report =
      "Report for " +
      heading +
      " during " +
      date_string +
      ",\r\n" +
      "-----,\r\n" +
      this.callR(validSessionObjs);
    downloadAsFile(report, heading + "_" + date_string, "csv"); //downloading as XLS
  };

  componentWillUnmount() {
    const { persistableComponents = [] } = this.state;
    persistableComponents.forEach((component) => {
      const { selfParams: { customLabel } = {} } = component;
      session.remove(customLabel);
    });
  }

  render() {
    const {
      widgets,
      components,
      inEditMode,
      persistableComponents,
      showFilters,
    } = this.state;
    const {
      appState: {
        app: {
          basic_info: { customers },
        },
      },
      classes,
      dashboards: { config_updated, group },
      meta: { api_pending },
      triggerGlobalFilters,
      updateGroup,
      session: {
        user: { email },
      },
    } = this.props;
    const isAllowed = !isUserRestricted(customers, email);
    return (
      <Grid container>
        <Grid item xs>
          {isAllowed && showFilters && (
            <Fragment>
              <div style={{ width: "100%" }}>
                <Switch
                  data={USER_GROUPS}
                  value={group}
                  handleChange={(group) => {
                    updateGroup(group);
                    triggerGlobalFilters(true);
                  }}
                />
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">Filter by</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <UserAndSessionProperties {...this.props} withoutBox />
                  </ExpansionPanelDetails>
                  <ExpansionPanelActions>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={inEditMode}
                      color="primary"
                      onClick={triggerGlobalFilters}
                    >
                      Apply
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
                <br />
                <Widgets
                  {...this.props}
                  widgets={widgets}
                  inEditMode={inEditMode}
                  defaultExpanded={
                    !api_pending && persistableComponents.length === 0
                  }
                  onAdd={this.handleDrop}
                />
              </div>
              <br />
              <Divider />
            </Fragment>
          )}
          <div className={classes.controls}>
            {isAllowed && (
              <Button
                title="Filters / Add to Dashboard"
                name="Filters / Add to Dashboard"
                size="small"
                disabled={api_pending}
                color="primary"
                onClick={(e) => this.setState({ showFilters: !showFilters })}
                style={{ margin: 5 }}
                className={classes.filtersButton}
              >
                <FilterList />
                {showFilters ? (
                  <ArrowDropUp color="primary" />
                ) : (
                  <ArrowDropDown color="primary" />
                )}
              </Button>
            )}
            {isAllowed && (
              <ShareDashboard {...this.props} inEditMode={inEditMode} />
            )}
            <Button
              title="Download as CSV"
              size="small"
              disabled={inEditMode || api_pending}
              color="primary"
              onClick={this.handleDownload}
              style={{ margin: 5 }}
            >
              <CloudDownloadIcon />
              &nbsp;Download
            </Button>
            {isAllowed && (
              <RenameDashboard {...this.props} inEditMode={inEditMode} />
            )}
            {isAllowed && (
              <Button
                size="small"
                onClick={this.handleEdit}
                variant={inEditMode ? "raised" : undefined}
                style={{ margin: 5 }}
              >
                <EditIcon />
                &nbsp;
                {inEditMode ? "Stop Editing" : "Edit"}
              </Button>
            )}
            {isAllowed && (
              <Button
                size="small"
                disabled={inEditMode}
                color="primary"
                onClick={this.handleSave}
                style={{ margin: 5 }}
              >
                <SaveIcon />
                &nbsp; Save
              </Button>
            )}
          </div>
          <WidgetsContainer
            {...this.props}
            canDrag={inEditMode}
            widgets={components}
            persistableComponents={persistableComponents}
            onDrop={this.handleDrop}
            inEditMode={inEditMode}
            handleRemove={this.handleRemove}
            updateWidgetProps={this.updateWidgetProps}
            findWidget={this.findWidget}
            moveWidget={this.moveWidget}
          />
          {config_updated && <Snackbar>Saved successfully.</Snackbar>}
        </Grid>
      </Grid>
    );
  }
}

const WIDGETS = {
  THREE_360: "THREE_360",
  VOV: "VOV",
  CONVERSION_RATE: "CONVERSION_RATE",
};

class Widgets extends Component {
  state = {
    expanded: null,
  };

  handleExpansion = (element) => () => {
    const expanded = element === this.state.expanded ? null : element;
    this.setState({ expanded });
  };

  render() {
    const {
      classes,
      defaultExpanded = false,
      widgets = [],
      onAdd,
    } = this.props;
    const { expanded } = this.state;
    return (
      <Grid container>
        <Grid item xs>
          <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Add to Dashboard</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              {/*                            {
                                widgets.map( (widget, index) =>
                                    widget.isSelectable && <WidgetSource {...this.props} key={widget.componentId + index} {...widget} onAdd={() => onAdd(widget)}/>
                                )
                            }*/}
              <Chip
                clickable
                avatar={
                  <Avatar className={classes.colorDefault}>
                    {expanded === WIDGETS.THREE_360 ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )}
                  </Avatar>
                }
                label="360°"
                style={{ marginLeft: 5 }}
                onClick={this.handleExpansion(WIDGETS.THREE_360)}
                className={classes.root}
                onDelete={() => null}
                deleteIcon={
                  <InfoHelper>
                    Trends and Habits of users across different segments
                  </InfoHelper>
                }
              />
              <Chip
                clickable
                avatar={
                  <Avatar className={classes.colorDefault}>
                    {expanded === WIDGETS.VOV ? <RemoveIcon /> : <AddIcon />}
                  </Avatar>
                }
                label="Version On Version"
                style={{ marginLeft: 5 }}
                onClick={this.handleExpansion(WIDGETS.VOV)}
                className={classes.root}
              />
              {/*                            <Chip
                                clickable
                                avatar={
                                    <Avatar className={classes.colorDefault}>
                                        {expanded === WIDGETS.CONVERSION_RATE ? <RemoveIcon/> : <AddIcon/>}
                                    </Avatar>
                                }
                                label="Conversion Rate"
                                style={{marginLeft: 5}}
                                onClick={this.handleExpansion(WIDGETS.CONVERSION_RATE)}
                                className={classes.root}
                            />*/}
              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs>
                  {expanded === WIDGETS.THREE_360 && (
                    <CustomQuery
                      {...this.props}
                      context={this.context}
                      handleCancel={() =>
                        this.setState({ moreExpanded: false })
                      }
                      handleAdd={onAdd}
                    />
                  )}
                  {expanded === WIDGETS.VOV && <VOV {...this.props} />}
                  {expanded === WIDGETS.CONVERSION_RATE && (
                    <ConversionRate {...this.props} handleAdd={onAdd} />
                  )}
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default DragDropContext(HTML5Backend)(withStyles(styles)(Dashboard));
