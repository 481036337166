/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component} from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import Box from "../../../../../../components/reusable/Box";
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import "react-vis/dist/style.css";
import TextField from '@material-ui/core/TextField';
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import moment from "moment";
import DateRangePicker from "../../../../../../components/reusable/DateRangePicker";
import ToggleSwitch from "../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Timelapse from '@material-ui/icons/Timelapse';
import {DEMO_APP_ID, DEMO_END_DATE, DEMO_START_DATE} from "../../../../../../config";
import Dialog from "../../../../../../components/reusable/MaterialUi/Dialog";
import Apxor from 'apxor';
import { APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../../constants';
import { getGroupNameFromList } from '../../../../../../utils';

const APX_APP_OPENED = "apx_app_opened";
const APX_UNINSTALL = "apx_uninstall";

const DEFAULT_START_TIME = moment().subtract(4, 'days').toISOString();
const DEFAULT_END_TIME = moment().subtract(3, 'days').toISOString();

export class PreprocessForm extends React.Component {

    constructor(props) {
        super(props);
        const { params: {appId}, eventAnalysis: { top_events = [] } = {} } = props;
        this.state = {
            name: 'Day-0 Events Vs Day-1 Retention',
            startTime: appId === DEMO_APP_ID ? moment(DEMO_START_DATE).add(14, 'days').toISOString() : DEFAULT_START_TIME,
            endTime: appId === DEMO_APP_ID ? moment(DEMO_START_DATE).add(24, 'days').toISOString() : DEFAULT_END_TIME,
            preStart: 0,
            preEnd: 0,
            postStart: 1,
            postEnd: 1,
            postEvent: APX_APP_OPENED,
            events: top_events.slice(0, 20).map(o => o.key),
            attributes: [],
            eventAttributes: [],
            withEventAttributes: false,
            current: 0,
            selectedCohort: null
        };
    }

    saveConfig = () => {
        const { params: { appId }, savePreprocess } = this.props;
        const { current, topN, selectedCohort, withEventAttributes, eventAttributes, ...others } = this.state;
        const config = others;
        if(withEventAttributes){
            config.attributes = [...config.attributes, ...eventAttributes ];
        }
        savePreprocess(appId, config, selectedCohort);
    };

    handleChange = (event, current) => {
        let postEvent = APX_APP_OPENED;
        let postStart = 1, postEnd = 1;
        let name = "Day-0 Events Vs Day-1 Retention";
        if(current === 1){
            Apxor.logEvent("ChooseTemplate", {template_type: "Uninstall"}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
            postEvent = APX_UNINSTALL;
            postStart = 0;
            postEnd = 0;
            name = "Day-0 Uninstall Analysis";
        }else if(current === 2){
            Apxor.logEvent("ChooseTemplate", {template_type: "Conversion"}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
            name = "Day 0 Behaviour Vs Conversion";
            postEvent = "";
        } else {
            Apxor.logEvent("ChooseTemplate", {template_type: "Retention"}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
        }
        this.setState({ current, postEvent, postStart, postEnd, name, selectedCohort: null });
    };

    handleSelectCohort = (event) => {
        this.setState({
            selectedCohort: event
        })
    };

    handleCloseDialog = () => {
        this.props.resetPreprocess();
    };

    render(){
        const {
            params: { appId },
            appState: { appEvents = [], appScreenNames = [], attributes: { user = [], session = [] } = {} },
            preprocess: { preprocess_failed, preprocess = false, preprocess_configs = [] },
            eventAnalysis: { top_events },
            queryBuilder: { event_attributes = {} },
            getEventAttributes, cohort: {cohorts = []}
        } = this.props;
        const {
            name,
            startTime, endTime,
            preStart, preEnd, postStart, postEnd,
            postEvent, events, attributes, topN,
            withEventAttributes, eventAttributes,
            current, selectedConfig, selectedTemplate = "", selectedCohort
        } = this.state;
        const topEventOptions = top_events.map(o => ({label: o.key, value: o.key}));
        const eventOptions = appEvents.map(o => ({label: o, value: o}));
        const attributeOptions = [...user, ...session].map(o => ({label: o, value: o}));
        let eventAttributeOptions = [];
        if(withEventAttributes && Array.isArray(event_attributes[events[0]])){
            eventAttributeOptions = event_attributes[events[0]].map(o => ({label: o, value: o}));
        }
        return(
            <Box title="Choose Template"
                 icon={<Timelapse />}
                 withPadding
            >
                <Dialog handleConfirm={this.handleCloseDialog} status={!!preprocess} handleClose={this.handleCloseDialog}>
                    Analysis report is being generated. It takes a while to display the results!
                    Check the report
                    <Button
                        variant="text"
                        color="primary"
                        component="a"
                        onClick={() => Apxor.logEvent("ViewReportClicked", {}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK)}
                        target="_blank"
                        href={`https://reports.apxor.com/apps/${appId}/preprocessed/${preprocess}`}>here</Button>
                </Dialog>
                {preprocess_failed && <Snackbar>Failed to submit, try again.</Snackbar>}
                <AppBar position="static">
                    <Tabs value={current} onChange={this.handleChange}>
                        <Tab label="Retention" />
                        <Tab label="Uninstall" />
                        <Tab label="Conversion" />
                    </Tabs>
                </AppBar>
                <Grid container spacing={16} justify="center" style={{marginTop: 16}}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Name"
                            placeholder="Some Name"
                            type="text"
                            onChange={e => {
                                this.setState({name: e.target.value});
                            }}
                            value={name}
                            style={{minWidth: 320}}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} style={{display: "flex", alignItems: "flex-end"}}>
                        <MultiSelect
                            label={"Select Cohort"}
                            clearable={true}
                            handleChange={e => this.handleSelectCohort(e)}
                            options={cohorts.map(o => ({value: o.cohort_id || null, label: o.name}))}
                            placeholder="Select Cohort"
                            single
                            style={{marginRight: 12}}
                            value={selectedCohort}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography>Select Cohort Install Dates</Typography>
                        <DateRangePicker
                            small
                            disableAfter={appId !== DEMO_APP_ID}
                            anchorDirection="left"
                            startDate={moment(startTime)}
                            endDate={moment(endTime)}
                            handleChange={(startTime, endTime) => {
                                Apxor.logEvent("InstallCohortDatesSet", {
                                    start_date: moment(startTime).format("DD-MM-YYYY"),
                                    end_date: moment(endTime).format("DD-MM-YYYY")
                                }, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                this.setState({startTime, endTime})
                            }}
                            enabledBetweenDates={appId === DEMO_APP_ID ? [moment(DEMO_START_DATE), moment(DEMO_END_DATE)] : undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{padding: '40px 0'}}>
                            <Typography style={{marginBottom: 30}}> Pre (Pre Start and Pre End)</Typography>
                            <InputRange
                                maxValue={90}
                                minValue={0}
                                value={{min: preStart, max: preEnd}}
                                onChange={({min, max}) => {
                                    this.setState({preStart:min, preEnd: max})
                                }}
                                allowSameValues
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{padding: '40px 0'}}>
                            <Typography style={{marginBottom: 30}}> Post (Post Start and Post End)</Typography>
                            <InputRange
                                maxValue={90}
                                minValue={0}
                                value={{min: postStart, max: postEnd}}
                                onChange={({min, max}) => {
                                    this.setState({postStart:min, postEnd: max})
                                }}
                                allowSameValues
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleSwitch
                            handleChange={withEventAttributes => {
                                Apxor.logEvent("WithEventAttributesToggle", {selected: withEventAttributes}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                this.setState({withEventAttributes});
                            }}
                            label="With Event Attributes"
                            checked={withEventAttributes}
                            value={withEventAttributes}
                        />
                    </Grid>
                    <Grid item xs={12} md={withEventAttributes ? 4 : 6}>
                        <MultiSelect
                            withCheckbox={!withEventAttributes}
                            options={topEventOptions}
                            label="Select Events"
                            placeholder="Select Events"
                            value={withEventAttributes ? events[0] : events}
                            handleChange={events => {
                                Apxor.logEvent("SelectEventsCount", {count: events.length}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                if(withEventAttributes){
                                    this.setState({events: [events]});
                                    getEventAttributes(appId, events);
                                }else{
                                    this.setState({events});
                                }
                            }}
                            margin="normal"
                            fullWidth
                            single={withEventAttributes}
                        />
                    </Grid>
                    <Grid item xs={12} md={withEventAttributes ? 4 : 6}>
                        <MultiSelect
                            options={attributeOptions}
                            label="Select Attributes"
                            placeholder="Select Attributes"
                            value={attributes}
                            handleChange={attributes => {
                                Apxor.logEvent("SelectAttributeCount", {count: attributes.length}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                this.setState({attributes});
                            }}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    {
                        withEventAttributes && <Grid item xs={12} md={4}>
                            <MultiSelect
                                options={eventAttributeOptions}
                                label="Select Event Attributes"
                                placeholder="Select Event Attributes"
                                value={eventAttributes}
                                handleChange={eventAttributes => {
                                    Apxor.logEvent("SelectEventAttributesCount", {count: eventAttributes.length}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                    this.setState({eventAttributes});
                                }}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    }
                    {
                        !withEventAttributes && <Grid item xs={12} md={2}>
                            <TextField
                                label="Select Top N Events"
                                placeholder="10"
                                type="number"
                                onChange={e => {
                                    const number = Number(e.target.value);
                                    if(number > 0){
                                        const events = topEventOptions.slice(0, number).map(o => o.value);
                                        Apxor.logEvent("TopEventCountChanged", {count: number}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                        this.setState({events});
                                    }
                                }}
                                defaultValue={20}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={4}>
                        <MultiSelect
                            label="Events From Saved Templates"
                            placeholder="From Saved Templates"
                            options={preprocess_configs.map(o => ({label: o.name, value: o.report_id}))}
                            handleChange={configId => {
                                const config = preprocess_configs.find(o => o.report_id === configId);
                                if(config){
                                    const events = appEvents.filter(event => config.events.includes(event));
                                    Apxor.logEvent("SavedTemplateChosen", {template_name: getGroupNameFromList(preprocess_configs, configId)}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                    this.setState({events, selectedTemplate: configId});
                                }
                            }}
                            value={selectedTemplate || ""}
                            single
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            options={eventOptions}
                            label="Select Post Event"
                            placeholder="Select Conversion Event"
                            value={postEvent}
                            handleChange={postEvent => {
                                Apxor.logEvent("SelectConversionEvent", {post_event: postEvent}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                                this.setState({postEvent});
                            }}
                            single
                            margin="normal"
                            disabled={current !== 2}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    </Grid>
                </Grid>
                <Button
                    style={{margin: '30px 0 0 auto', display: 'block'}}
                    variant="contained"
                    disabled={false}
                    onClick={e => {
                        Apxor.logEvent("SubmitButtonClicked", {preStart, preEnd, postStart, postEnd}, APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK);
                        this.saveConfig();
                        this.setState({showDialog: true});
                    }}
                    color="primary"
                >
                    Submit
                </Button>
            </Box>
        )
    }
}