/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Fragment} from 'react';
import { Link } from 'react-router';
import Apxor from 'apxor';
import {CohortFilter, VersionFilter} from '../Filters/components';
import {  ALL_USERS, ALL_SESSIONS, MODES_ENUM, APP_PLATFORMS, APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../constants';
import { DASHBOARD_ENDPOINTS } from '../../../../../constants/EndPoints';
import {getGroupIdFromList,  isUserRestricted} from '../../../../../utils';
import Snackbar from '../../../../../components/reusable/MaterialUi/Snackbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import MessageIcon from '@material-ui/icons/Message';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import FilterListIcon from '@material-ui/icons/FilterList'
import NotificationsIcon from '@material-ui/icons/Notifications';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Collapse } from "@material-ui/core";
import Checkbox from "../../../../../components/reusable/MaterialUi/Checkbox";
import classNames from 'classnames';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import WidgetsIcon from '@material-ui/icons/Widgets';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import PresetDateRangePicker from '../../../../../components/reusable/DateRangePicker';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import Timelapse from '@material-ui/icons/Timelapse';
import AccessTime from '@material-ui/icons/AccessTime';
import Highlight from '@material-ui/icons/Highlight';
import moment from 'moment';
import {DEMO_APP_ID, DEMO_END_DATE, DEMO_START_DATE} from "../../../../../config";

const getBotName = (bots = [], versions = []) => {
    if (versions.length > 0 && Array.isArray(bots)) {
        const bot = bots.find(o => o.version === versions[0]);
        return (bot && bot.name) ? bot.name : "Unnamed Bot";
    }
    return "Unnamed Bot";
};

export class AppPanel extends React.Component {

    render(){
        const {
            app : {app_id, basic_info },
            appsState: { bots = {} },
            filters: { versions }
        } = this.props;
        let imgSrc = ""; //TODO:
        let appName = "AppName";
        let botName = "Unnamed Bot";
        try{
            imgSrc = basic_info.icon_url;
            appName = basic_info.app_name;
            botName = getBotName(bots[app_id], versions);
        }catch(e){
            //For sanity
        }
        return (
            <ListItem>
                <Avatar
                    alt={appName}
                    src={imgSrc}
                    imgProps={{onError: e => {e.target.src = window.apxorAppInfo.favicon}}}
                />
                <ListItemText
                    primary={<Typography title={appName} noWrap variant="subtitle2">{appName}</Typography>}
                    secondary={<Typography title={botName} noWrap variant="caption">{botName}</Typography>}
                />
            </ListItem>
        )
    }

}

export class AppInstallPanel extends React.Component {

    render(){
        const { appState } = this.props;
        return (
            <div className="sidebar-form">
                <div className="pull-left image">
                    <span className="text-navy text-bold">Installs <span className="pull-right"> : { appState.installs }</span></span><br/>
                    <span className="text-navy text-bold">Uninstalls <span className="pull-right"> : { appState.uninstalls || "NA" }</span></span>
                </div>
            </div>
        )
    }

}

const modeFilterStyles = (theme) => ({
    root: {
        padding: '0px 10px',
        marginRight: 0
    },
    label: {
        fontSize: 10
    }
});

export class ActualModeFilter extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            error: false,
            production: true, //Default Production
            nonProduction: false
        };
    }

    updateFromProps = (props) => {
        const { modes = [] } = props.filters;
        this.setState({
            production: modes.includes(MODES_ENUM.PRODUCTION),
            nonProduction: modes.includes(MODES_ENUM.NON_PRODUCTION)
        });
    };

    componentWillMount(){
        this.updateFromProps(this.props);
    }

    handleModeChange = (control) => {
        const { production, nonProduction } = this.state;
        const modes = [];
        production && modes.push(MODES_ENUM.PRODUCTION);
        nonProduction && modes.push(MODES_ENUM.NON_PRODUCTION);
        if(modes.length > 0){
            this.setState({ error: false });
            this.props.updateModes(modes);
            this.props.applyGlobalFilter(); //applies to global filter change
        }else{
            this.setState({[control]: true, error: true}); //shows snackbar when trying to empty modes
        }
    };

    handleChange = name => checked => {
        this.setState({ [name]: checked }, () => this.handleModeChange(name));
    };

    render(){
        const { error, production, nonProduction } = this.state;
        const { classes } = this.props;
        return (
            <div style={{padding: '0 20px'}}>
                <Grid container spacing={0}>
                    <Grid item xs xl lg md sm>
                        <Checkbox
                            checked={production}
                            value={MODES_ENUM.PRODUCTION}
                            handleChange={this.handleChange('production')}
                            label={<Typography className={classes.label}>Production</Typography>}
                        />
                    </Grid>
                    <Grid item xs xl lg md sm>
                        <Checkbox
                            checked={nonProduction}
                            handleChange={this.handleChange('nonProduction')}
                            value={MODES_ENUM.NON_PRODUCTION}
                            label={<Typography className={classes.label}>Non-Production</Typography>}
                        />
                    </Grid>
                </Grid>
                {
                    error && <Snackbar
                        autoHideDuration={4000}>
                        <span>At least one Mode required..!</span>
                    </Snackbar>
                }
            </div>
        )
    }

}

const ModeFilter = withStyles(modeFilterStyles)(ActualModeFilter);

const NAV_HEIGHT = 0;

const COLLAPSE_KEYS = {
    CUSTOM: "CUSTOM",
    USERS: "USERS",
    ANALYSIS: "ANALYSIS",
    ACTIONS: "ACTIONS",
    NEW_ACTIONS: "NEW_ACTIONS",
    SETTINGS: "SETTINGS",
    INSIGHTS: "INSIGHTS"
};

const {
    USER_EXPLORER, SEGMENTS,
    EVENT_ANALYSIS, RETENTION, UNINSTALLS,  FUNNELS, CONTEXTS,
    MESSAGES, ALERTS,
    MESSAGES2,
    SETTINGS_SDK, SETTINGS_TEST_DEVICES, SETTINGS_IAM, SETTINGS_DATA,
    DASHBOARDS, PUSH, CUSTOM_REPORTS, PATH, SURVEYS, PREPROCESS
} = DASHBOARD_ENDPOINTS;

export class SideBar extends React.Component {

    state = {
        USERS: false,
        ANALYSIS: false,
        ACTIONS: false,
        NEW_ACTIONS: false,
        SETTINGS: false,
        CUSTOM: false,
        INSIGHTS: false,
    };

    onNavigationClick = (e, to) => {
        const {navigation: {heading}} = this.props;
        // Apxor.logEvent("SideBarNavigation", {from: heading, to}, APXOR_SDK_CATEGORY_CONSTANTS.NAVIGATION);
        this.props.handleNavigationClick(); //using this to set nav reference TODO: use this properly
    };

    componentDidMount(){

    }

    navigationLink = (endPoint) => {
        const { appId } = this.props;
        return { pathname: `/apps/${appId}/${endPoint}`}; //query: {ref: NAVIGATION_REFERENCE}};
    };

    handleCollapseClick = (key) => {
        this.setState({
            CUSTOM: false,
            USERS: false,
            ANALYSIS: false,
            ACTIONS: false,
            SETTINGS: false,
            INSIGHTS: false,
            [key]: !this.state[key]
        });
    };

    render() {
        const {
            appState: { app, app: { basic_info: { platform, customers, customer_id } } },
            classes,
            isSidebarOpen,
            location : { pathname = "" } = {},
            session: { user: { email } }
        } = this.props;
        const isAndroidPlatform = platform === APP_PLATFORMS.android || platform === APP_PLATFORMS.omni;
        const isWebPlatform = platform === APP_PLATFORMS.web;
        const hasActions = !isWebPlatform;
        let navSize = 0;
        const isActiveLink = (endPoint) => pathname.includes(endPoint);
        const { USERS, ANALYSIS, ACTIONS, NEW_ACTIONS, SETTINGS, CUSTOM, INSIGHTS } = this.state;
        const CustomListItem = (props) => {
            const { endPoint, text, icon, notNested = false } = props;
            return(
                <Link to={this.navigationLink(endPoint)} style={{marginTop: navSize += NAV_HEIGHT}} onClick={(e) => {this.onNavigationClick(e, text)}}>
                    <ListItem button className={classNames(classes.container, {[classes.nested]: isSidebarOpen && !notNested})}>
                        <ListItemIcon>
                            { React.cloneElement(icon, {className: classNames({[classes.active]: isActiveLink(endPoint)})}) }
                        </ListItemIcon>
                        <ListItemText primary={
                            <span className={classNames({[classes.active]: isActiveLink(endPoint)})}>{text}</span>
                        } />
                    </ListItem>
                </Link>
            );
        };

        const CustomCollapseListItem = (props) => {
            const { endPoint, text, icon, collapseKey, subNavLinks = [] } = props;
            const isActive = subNavLinks.some(link => isActiveLink(link));
            return(
                <ListItem
                    button
                    onClick={() => this.handleCollapseClick(collapseKey)}
                    style={{marginTop: navSize += NAV_HEIGHT}}
                    className={classNames(classes.container, {[classes.parentActive]: isActive, [classes.active]: isActive})}
                >
                    <ListItemIcon>
                        { React.cloneElement(icon, {className: classNames({[classes.active]: isActive})}) }
                    </ListItemIcon>
                    <ListItemText inset primary={
                        /*<span className={classNames({[classes.active]: isActive})}>{text}</span>*/
                        text
                    }/>
                    <ListItemIcon>
                        {endPoint ?
                            <ExpandLess className={classNames({[classes.active]: isActive})} /> :
                            <ExpandMore className={classNames({[classes.active]: isActive})} />}
                    </ListItemIcon>
                </ListItem>
            );
        };

        const isAdmin = email === customer_id || email === window.masterEmail; //Restricting IAM to Admins

        return (
            <div>
                <AppPanel app={app} {...this.props}/>
                {/*<div style={{display: isSidebarOpen ? 'block' : 'none'}}>
                    <Divider/>
                    <ModeFilter {...this.props} classes={{}} />
                </div>*/}
                <Divider/>
                {
                    !isUserRestricted(customers, email) && <List className={classes.list} disablePadding>

                        {/* Dashboards */}
                        <CustomCollapseListItem icon={<WidgetsIcon />} endPoint={CUSTOM} text="Metrics" subNavLinks={[DASHBOARDS, CUSTOM_REPORTS, FUNNELS, CONTEXTS, PREPROCESS]} collapseKey={COLLAPSE_KEYS.CUSTOM}/>
                        <Collapse in={CUSTOM} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginTop: navSize += NAV_HEIGHT}}>
                                <CustomListItem icon={<DashboardIcon />} endPoint={DASHBOARDS} text="Dashboards" />
                                <CustomListItem icon={<AssignmentIcon />} endPoint={CUSTOM_REPORTS} text="Custom Reports" />
                                <CustomListItem icon={<AccessTime />} endPoint={CONTEXTS} text="Contexts" />
                            </List>
                        </Collapse>

                        {/* Users */}
                        <CustomCollapseListItem icon={<GroupIcon />} endPoint={USERS} text="Users" subNavLinks={[SEGMENTS, USER_EXPLORER]} collapseKey={COLLAPSE_KEYS.USERS}/>
                        <Collapse in={USERS} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginTop: navSize += NAV_HEIGHT}}>
                                <CustomListItem icon={<NaturePeopleIcon />} endPoint={SEGMENTS} text="Segments" />
                                <CustomListItem icon={<SearchIcon />} endPoint={USER_EXPLORER} text="User Explorer" />
                            </List>
                        </Collapse>

                        {/* Analysis */}
                        <CustomCollapseListItem icon={<MultilineChartIcon />} endPoint={ANALYSIS} text="Analysis" subNavLinks={[EVENT_ANALYSIS, RETENTION, UNINSTALLS]} collapseKey={COLLAPSE_KEYS.ANALYSIS}/>
                        <Collapse in={ANALYSIS} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginTop: navSize += NAV_HEIGHT}}>
                                <CustomListItem icon={<EventIcon />} endPoint={EVENT_ANALYSIS} text="Events" />
                                {/*<CustomListItem icon={<SystemUpdateAltIcon />} endPoint={DROP_OFF} text={<span>Drop Offs <sup style={{fontSize: 10}}>BETA</sup></span>} />*/}
                                <CustomListItem icon={<FavoriteIcon />} endPoint={RETENTION} text="Retention" />
                                { !isWebPlatform && <CustomListItem icon={<ExitToAppIcon />} endPoint={UNINSTALLS} text="Uninstalls" /> }
                                <CustomListItem icon={<TransferWithinAStation />} endPoint={PATH} text="Path" />
                                <CustomListItem icon={<FilterListIcon />} endPoint={FUNNELS} text="Funnels" />
                            </List>
                        </Collapse>
                        <CustomCollapseListItem icon={<Highlight />} endPoint={INSIGHTS} text="Insights" subNavLinks={[PREPROCESS]} collapseKey={COLLAPSE_KEYS.INSIGHTS}/>
                        <Collapse in={INSIGHTS} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginTop: navSize += NAV_HEIGHT}}>
                                <CustomListItem icon={<Timelapse />} endPoint={PREPROCESS} text="Behaviour" />
                            </List>
                        </Collapse>

                        {/* Actions */}
                        {
                            hasActions &&
                            <Fragment>
                                <CustomCollapseListItem icon={<CallToActionIcon />} endPoint={ACTIONS} text="Actions" subNavLinks={[MESSAGES, PUSH, ALERTS, SURVEYS]} collapseKey={COLLAPSE_KEYS.ACTIONS}/>
                                <Collapse in={ACTIONS} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding style={{marginTop: navSize += NAV_HEIGHT}}>
                                        <CustomListItem icon={<MessageIcon />} endPoint={MESSAGES} text="Messages" />
                                        {/*<CustomListItem icon={<NotificationsIcon />} endPoint={PUSH} text="Push" />*/}
                                        <CustomListItem icon={<ControlCameraIcon/>} endPoint={MESSAGES2}
                                                        text={<span>Walkthroughs <sup style={{
                                                            fontSize: 11,
                                                            color: "#fff",
                                                            backgroundColor: "#fb8c00",
                                                            padding: "1px 5px",
                                                            borderRadius: 12,
                                                            marginLeft: 4
                                                        }}>New</sup></span>}/>
                                        {/*<CustomListItem icon={<NotificationsIcon />} endPoint={PUSH} text="Push" />*/}
                                        {/*<CustomListItem icon={<NotificationsActiveIcon />} endPoint={ALERTS} text={<span>Alerts <sup style={{fontSize: 10}}>ALPHA</sup></span>} />*/}
                                        <CustomListItem icon={<QuestionAnswerIcon />} endPoint={SURVEYS} text="Surveys" />
                                    </List>
                                </Collapse>
                            </Fragment>
                        }

                        {/* Settings */}
                        <CustomCollapseListItem icon={<SettingsIcon />}
                                                endPoint={SETTINGS} text="Settings"
                                                subNavLinks={[SETTINGS_SDK, SETTINGS_TEST_DEVICES, SETTINGS_IAM]}
                                                collapseKey={COLLAPSE_KEYS.SETTINGS}
                        />
                        <Collapse in={SETTINGS} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginTop: navSize += NAV_HEIGHT}}>
                                { isAdmin && <CustomListItem icon={<DeveloperModeIcon />} endPoint={SETTINGS_SDK} text="SDK" /> }
                                <CustomListItem icon={<ImportantDevicesIcon />} endPoint={SETTINGS_TEST_DEVICES} text="Test Devices" />
                                <CustomListItem icon={<CloudDownloadIcon />} endPoint={SETTINGS_DATA} text="Downloads" />
                                { isAdmin && <CustomListItem icon={<PermIdentityIcon />} endPoint={SETTINGS_IAM} text="IAM" /> }
                            </List>
                        </Collapse>
                    </List>
                }
                <Divider />
            </div>
        );
    }
}

export class PageHeader extends React.Component {

    render(){
        const {
            updateDateTime, disableTimeFilter, applyGlobalFilter,
            filters: {since, till},
            appState: {app: {app_id, basic_info: {platform}} = {}} = {},
            location: {pathname}, navigation: {heading}
        } = this.props;

        const isNonWeb = platform !== APP_PLATFORMS.web;
        const isDemoApp = app_id === DEMO_APP_ID;

        const hideCohortFilterHere = /segments|users|user-explorer/.test(pathname);

        return(
            <Grid container style={{padding: '10px 30px'}} justify="space-between">
                <Grid item xs={12} sm md={3} xl />
                {
                    isNonWeb && <Grid item xs={12} sm md xl>
                        <Grid container>
                            <Grid item xs={6} md xl sm>
                                {!hideCohortFilterHere && <CohortFilter {...this.props} />}
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={3} xl={2} style={{textAlign: 'right'}}>
                    <PresetDateRangePicker
                        handleChange={(start, end) => {
                            updateDateTime(start, end);
                            applyGlobalFilter();
                            Apxor.logEvent("GlobalDateChanged", {
                                navigation: heading,
                                start: moment(start).format("DD-MM-YYYY"),
                                end: moment(end).format("DD-MM-YYYY")
                            }, "")
                        }}
                        disabled={disableTimeFilter}
                        small
                        startDate={moment(since)}
                        endDate={moment(till)}
                        showDefaultInputIcon
                        inputIconPosition="before"
                        disableAfter={!isDemoApp}
                        enabledBetweenDates={isDemoApp ? [moment(DEMO_START_DATE), moment(DEMO_END_DATE)] : undefined}
                        withoutPresets={isDemoApp}
                    />
                </Grid>
            </Grid>
        )
    }

}