/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 10:39 AM
 */
import moment from "moment";
import {browserHistory} from "react-router";
import {
    ART_CONFIG_TYPES,
    DELETE_MESSAGE2,
    DELETE_MESSAGE2_FAILED,
    DELETE_MESSAGE2_PENDING,
    GET_CLIENT_EVENTS,
    GET_CLIENT_EVENTS_FAILED,
    GET_CLIENT_EVENTS_PENDING,
    GET_MESSAGES,
    GET_MESSAGES_FAILED,
    GET_MESSAGES_PENDING,
    PAUSE_MESSAGE2,
    PAUSE_MESSAGE2_FAILED,
    PAUSE_MESSAGE2_PENDING,
    PUBLISH_MESSAGE2, PUBLISH_MESSAGE2_FAILED,
    PUBLISH_MESSAGE2_PENDING,
    RESUME_MESSAGE2,
    RESUME_MESSAGE2_FAILED,
    RESUME_MESSAGE2_PENDING, SAVE_MESSAGE2, SAVE_MESSAGE2_FAILED, SAVE_MESSAGE2_PENDING, SET_ACTING_ID
} from "./actionTypes";
import {deleteMessageAPI, getMessagesListAPI, publishMessageAPI, saveMessageAPI, updateMessageAPI} from "./api";

export const setActingID = (actingId) => {
    return (dispatch, getState) => {
        return dispatch({
            type: SET_ACTING_ID,
            payload: actingId,
            meta: {}
        })
    }
};

export const getMessagesList = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, configType: [ART_CONFIG_TYPES.RTA, ART_CONFIG_TYPES.WALKTHROUGH]};
        return dispatch({
            types: [
                GET_MESSAGES_PENDING,
                GET_MESSAGES,
                GET_MESSAGES_FAILED
            ],
            payload: {
                promise: getMessagesListAPI(appId, getState().auth, filters)
                .then((res) => {
                    const messagesList = res.messages.filter(message => message.meta.v2);
                    const updatedMessagesList = messagesList.map(messageItem => {
                        let overall_cfg = messageItem.overall_cfg;
                        if (Array.isArray(overall_cfg.events) && overall_cfg.events.length > 0) {
                            overall_cfg["events"] = overall_cfg.events.filter(item =>
                                item.hasOwnProperty("name") && item.hasOwnProperty("op")
                            )
                        }
                        const updatedUI = messageItem.uis.map(eachUI => {
                            return {
                                ...eachUI.ui,
                                type: eachUI.type,
                            }
                        });
                        return {
                            ...messageItem,
                            uis: updatedUI,
                            overall_cfg
                        }
                    });

                    if (res.hasOwnProperty("messages") && res.messages.length > 0) {
                        return {
                            messages: updatedMessagesList.reverse().sort((a, b) => {
                                if (a.track.hasOwnProperty("created_at")) {
                                    if (a.track.hasOwnProperty("updated_at")) {
                                        if (!b.track.hasOwnProperty("updated_at")) {
                                            return moment(a.track.updated_at).isAfter(moment(b.track.created_at))
                                        }
                                        return moment(a.track.updated_at).isAfter(moment(b.track.updated_at))
                                    } else {
                                        return moment(a.track.created_at).isAfter(moment(b.track.created_at))
                                    }
                                }
                                return 0;
                            }), metrics: res.metrics
                        };
                    }
                    return {
                        messages: [],
                        metrics: {}
                    };
                }),
            },
            meta: {}
        });
    };
};

export const deleteMessage = (appId, messageId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                DELETE_MESSAGE2_PENDING,
                DELETE_MESSAGE2,
                DELETE_MESSAGE2_FAILED
            ],
            payload: {
                promise: deleteMessageAPI(appId, getState().auth, messageId)
                .then(res => {
                    // if (res === true) {
                    dispatch(getMessagesList(appId));
                    return "Message Deleted Successfully"
                    // }
                }),
            },
            meta: {
                messageId
            }
        })
    }
};

const getModifiedMessageData = (messageData, trackKey) => {
    const updatedUIs = messageData.uis.map(ui => {
        return {
            name: "",
            type: ui.type,
            ui
        }
    });
    return {
        ...messageData,
        scheduling: {
            ...messageData.scheduling,
            "schedule_date": moment(messageData.validity["start_date"]).format("YYYY-MM-DD"),
            "schedule_time": moment(messageData.validity["start_date"]).format("HH:mm"),
        },
        ui: {
            delay: 1000,
            type: "walkthrough"
        },
        uis: updatedUIs,
        enabled: true,
        published: false,
        track: {
            ...messageData.track,
            [trackKey]: moment().toISOString()
        }
    }
};

export const updateMessageAction = (appId, messageData) => {
    const messageUpdatedData = getModifiedMessageData(messageData, "updated_at");
    return (dispatch, getState) => {
        return dispatch({
            types: [
                SAVE_MESSAGE2_PENDING,
                SAVE_MESSAGE2,
                SAVE_MESSAGE2_FAILED
            ],
            payload: {
                promise: updateMessageAPI(appId, getState().auth, messageData._id, messageUpdatedData)
                .then(res => {
                    if (res) {
                        dispatch(getMessagesList(appId));
                        setTimeout(() => {
                            browserHistory.push(`/apps/${appId}/walkthroughs?s=5`);
                        }, 1000);
                        return "Message Updated Successfully"
                    } else {
                        return "Message Update Failed"
                    }
                }),
            },
            meta: {}
        })
    }
};

export const saveMessageAction = (appId, messageData) => {
    const messageUpdatedData = getModifiedMessageData(messageData, "created_at");
    return (dispatch, getState) => {
        return dispatch({
            types: [
                SAVE_MESSAGE2_PENDING,
                SAVE_MESSAGE2,
                SAVE_MESSAGE2_FAILED
            ],
            payload: {
                promise: saveMessageAPI(appId, getState().auth, appId, messageUpdatedData)
                .then(res => {
                    if (res.hasOwnProperty("id")) {
                        dispatch(getMessagesList(appId));
                        dispatch(setActingID(res.id));
                        setTimeout(() => {
                            browserHistory.push(`/apps/${appId}/walkthroughs?s=5`);
                        }, 1000);
                        return "Message Saved Successfully"
                    } else {
                        return "Message Save Failed"
                    }
                }),
            },
            meta: {}
        })
    }
};

export const publishMessage = (appId, messageId, messageData) => {

    return (dispatch, getState) => {
        return dispatch({
            types: [
                PUBLISH_MESSAGE2_PENDING,
                PUBLISH_MESSAGE2,
                PUBLISH_MESSAGE2_FAILED,
            ],
            payload: {
                promise: publishMessageAPI(appId, getState().auth, messageId)
                .then(res => {
                    if (res === true) {
                        dispatch(getMessagesList(appId));
                        return "Message Published Successfully"
                    }
                }),
            },
            meta: {
                messageId
            }
        })
    }
};

export const pauseMessage = (appId, messageId, messageData, toggleOnly = false) => {
    const updatedUIs = messageData.uis.map(ui => {
        return {
            name: "",
            type: ui.type,
            ui
        }
    });
    const messageUpdatedData = {
        ...messageData,
        ui: {
            delay: 1000,
            type: "walkthrough"
        },
        uis: updatedUIs,
        enabled: false,
        published: !toggleOnly,
        track: {
            ...messageData.track,
            updated_at: moment().toISOString()
        }
    };
    return (dispatch, getState) => {
        return dispatch({
            types: [
                PAUSE_MESSAGE2_PENDING,
                PAUSE_MESSAGE2,
                PAUSE_MESSAGE2_FAILED
            ],
            payload: {
                promise: updateMessageAPI(appId, getState().auth, messageId, messageUpdatedData)
                .then(res => {
                    // if (res === true) {
                    // TODO: Get this fixed from Server side
                    dispatch(getMessagesList(appId));
                    return "Message Paused Successfully"
                    // }
                }),
            },
            meta: {
                messageId
            }
        })
    }
};

export const resumeMessage = (appId, messageId, messageData, toggleOnly = false) => {
    const updatedUIs = messageData.uis.map(ui => {
        return {
            name: "",
            type: ui.type,
            ui
        }
    });
    const messageUpdatedData = {
        ...messageData,
        ui: {
            delay: 1000,
            type: "walkthrough"
        },
        uis: updatedUIs,
        enabled: true,
        published: !toggleOnly,
        track: {
            ...messageData.track,
            updated_at: moment().toISOString()
        }
    };
    return (dispatch, getState) => {
        return dispatch({
            types: [
                RESUME_MESSAGE2_PENDING,
                RESUME_MESSAGE2,
                RESUME_MESSAGE2_FAILED
            ],
            payload: {
                promise: updateMessageAPI(appId, getState().auth, messageId, messageUpdatedData)
                .then(res => {
                    // if (res === true) {
                    // TODO: Get this fixed from Server side
                    dispatch(getMessagesList(appId));
                    return "Message Resumed Successfully"
                    // }
                }),
            },
            meta: {
                messageId
            }
        })
    }
};

export const getClientEventsList = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                GET_CLIENT_EVENTS_PENDING,
                GET_CLIENT_EVENTS,
                GET_CLIENT_EVENTS_FAILED
            ],
            payload: ["apx_hard_back_button_pressed"],
            meta: {}
        });
    };
};