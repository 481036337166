import React, { Component } from 'react';
import {
    graphDataToCSVString,
    minMaxAverageOf,
    addedWithAverageKeys
} from "../../../../../../../utils";
import {getEventRelatedActiveHourDistribution} from "../actions";
import withSelfComponent from "./SelfComponent";
import { session } from "../../../../../../../utils/Storage";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";

const timeZoneOffset = Math.round(new Date().getTimezoneOffset() / 60);

const normalizeDataForTimeZone = (data = []) => {
    if(Array.isArray(data)){
        return data.map(o => {
            const { hour, index, ...others } = o;
            return {
                ...others,
                hour: (24 + hour - timeZoneOffset) % 24
            };
        }).sort((a, b) => a.hour - b.hour);
    }else {
        return [];
    }
};

class HourlyEventDistribution extends Component {

    state = {
        showAggregations: false
    };

    hydrator = (props = this.props) => {
        const {
            event, dataKey,
            queryParams, extraFilters
        } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        dispatch(getEventRelatedActiveHourDistribution(appId, event, dataKey, queryParams, extraFilters));
    };

    render(){
        const {
            self, selfParams: {dataKey, customLabel},
            meta: { api_pending }, parentCompLabel
        } = this.props;
        let data = normalizeDataForTimeZone(self[dataKey] || []);
        const trendDataKey = "Event Count";
        if(!api_pending) {
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data, "Hour", 'hour');
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        const stats = minMaxAverageOf(data, trendDataKey, "hour");
        data = addedWithAverageKeys(data, [ trendDataKey ]);
        return(
            <MultiUtilityChart
                {...this.props}
                title={customLabel}
                height={320}
                withHeader
                dataKey="hour"
                yLabel={trendDataKey}
                xLabel={"Hour"}
                data={data}
                barDataKeys={[trendDataKey]}
                stats={stats}
                withoutAggregations={false}
                fullWidth
                showAverage
                showBrush={false}
                xLabelFormatter={x => x}
                showLabels
            />
        )

    }
}

HourlyEventDistribution.propTypes = {

};

export default withSelfComponent(HourlyEventDistribution);