/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import {
    UninstallTrends, UninstallDistributions,
    UserCounts
} from './components';
import {
    loadUninstallsData, resetUninstalls, updateUninstallDistributionRange,
    getUninstallUserDistribution, getUninstallUsageDistribution
} from './actions';
import { updateSessionGroupId, updateUserGroupId, setDefaultFilters } from '../../Filters/actions';
import {QueryBuilder} from "../../../../../../components/reusable/QueryBuilder/index";
import {
    getAttributes,
    getAttributeValues,
    getEventAttributes, getEventAttributeValues,
    resetQuery,
    updateQuery,
    getEvents
} from "../Segments/NewSegment/actions";
import {UserAndSessionProperties} from "../Segments/NewSegment/components";
import {Button} from "@material-ui/core";
import {arePropertyFiltersSame} from "../../../../../../utils";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        users: state.users,
        uninstalls: state.uninstalls,
        filters: state.filters,
        meta: state.meta,
        segmentBuilder: state.segmentBuilder,
        queryBuilder: state.queryBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId) => {
            dispatch(setDefaultFilters()); //updating defaults filters
            dispatch(loadUninstallsData(appId));
        },
        updateUserSelection: (groupId) => {
            dispatch(updateUserGroupId(groupId));
            dispatch(loadUninstallsData());
        },
        updateSessionSelection: (groupId) => {
            dispatch(updateSessionGroupId(groupId));
            dispatch(loadUninstallsData());
        },
        handleRangeUpdate: (appId, range, currentIndex) => {
            dispatch(updateUninstallDistributionRange(range));
            dispatch(getUninstallUserDistribution(appId, currentIndex));
            dispatch(getUninstallUsageDistribution(appId, currentIndex));
        },
        loadUninstallsData: (appId) => {
            dispatch(loadUninstallsData(appId))
        },
        getAttributes: (appId) => {
            dispatch(getAttributes(appId))
        },
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        getEvents: (appId) => {
            dispatch(getEvents(appId))
        },
        getEventAttributeValues: (appId, event, attribute, q) => dispatch(getEventAttributeValues(appId, event, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery()),
        getUninstallUserDistributionByIndex: (appId, currentIndex) => {
            dispatch(getUninstallUsageDistribution(appId, currentIndex))
        },
        resetData: () => dispatch(resetUninstalls()),
        bindedDispatch: dispatch
    };
}

class Uninstalls extends Component {

    constructor (props) {
        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            propertiesApplied: true,
            user: [],
            session: []
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.propertiesApplied) {
            const { segmentBuilder: { query: { user = [], session = [] } } }  = nextProps;
            const { user: sUser = [], session: sSession = [] }  = this.state;
            if(!arePropertyFiltersSame(user, sUser) || !arePropertyFiltersSame(session, sSession)){
                this.setState({propertiesApplied: false});
            }
        }
    }

    componentWillMount(){
        this.props.updateHeading("Uninstalls");
        this.props.getAttributes(this.appId);
        this.props.loadData(this.appId);
        this.props.getEvents(this.appId);
    }

    componentWillUnmount(){
        this.props.resetQuery();

    }

    handleUserSelection(groupId){
        this.props.updateUserSelection(this.appId, groupId);
    }

    handleSwitchValueChange = (switchValue) => {
        this.setState({currentType: switchValue});
        this.props.updateRetentionType(this.props.params.appId, switchValue.split(" ")[0]);
    };

    handleFirstQueryChange = (key) => (query) => {
        let value = {};
        if(query.event.length){
            this.setState({firstEvent: query.event});
            value[query.event] = query.value
        }else{
            this.setState({firstEvent: ""});
            delete value[key];
        }
        this.props.updateFirstQuery(this.appId, value);
    };

    handleNextQueryChange = (key) => (query) => {
        let value = {};
        if(query.event.length){
            this.setState({nextEvent: query.event});
            value[query.event] = query.value
        }else{
            this.setState({nextEvent: ""});
            delete value[key];
        }
        this.props.updateNextQuery(this.appId, value);
    };

    render() {
        const {params: {appId}, loadUninstallsData} = this.props;
        const {propertiesApplied, user, session} = this.state;
        return (
            <section className="content">
                {/*<ToolBar userData={appState.userGroupIds} userValue={filters.user_group.group_id} updateUserSelection={this.handleUserSelection.bind(this)}
                 sessionData={appState.sessionGroupIds} sessionValue={filters.session_group.group_id} updateSessionSelection={this.handleSessionSelection.bind(this)}/>*/}
                {/*<Recommendations/>*/}
                <UserCounts {...this.props}/>
                <UserAndSessionProperties {...this.props} withoutBox/>
                <Button
                    disabled={propertiesApplied}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        loadUninstallsData(appId);
                        this.setState({propertiesApplied: true, user, session});
                    }}
                    style={{display: 'block', margin: "10px 0 10px auto"}}
                >
                    Apply
                </Button>
                <UninstallTrends {...this.props}/>
                <UninstallDistributions {...this.props}/>
            </section>
        );
    }
}

Uninstalls.propTypes = {

};

Uninstalls.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Uninstalls);