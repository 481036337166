/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, {Component, Fragment,} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getAppVersions} from "../Apps/App/actions";
import { SideBar, PageHeader } from './components';
import { updateGlobalDateTime, updateGlobalModes, updateGlobalVersions, updateUserGroupId,
    updateSessionGroupId, applyGlobalFilterChanges, updateCohortFilter } from '../Filters/actions';
import { navigationClicked } from '../../../../../actions';
import {isDateFilterApplicable, isDateFilterDisabled} from '../../../../../utils';
import {DATE_FILTERS_DISABLED_PATHS, DATE_FILTERS_INVALID_PATHS} from '../../../../../constants/EndPoints';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider, withStyles, withTheme } from '@material-ui/core/styles';
import { darkTheme } from '../../../../../styles';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {getCohorts} from "../Dashboard/Cohorts/actions";

const pixelometryVersion = process.env.REACT_APP_VERSION;

const drawerWidth = 260;

function mapStateToProps(state) {
    return {
        session: state.auth,
        cohorts: state.cohorts,
        router: state.routing,
        appState: state.app,
        navigation: state.navigation,
        filters: state.filters,
        meta: state.meta
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCohortsData: (appId, filters) => dispatch(getCohorts(appId, filters)),
        updateDateTime: (startTime, endTime) => dispatch(updateGlobalDateTime(startTime, endTime)),
        updateVersions: (versions) => dispatch(updateGlobalVersions(versions)),
        updateCohortFilter: (cohortObject) => dispatch(updateCohortFilter(cohortObject)),
        updateModes: (modes) => dispatch(updateGlobalModes(modes)),
        updateUserGroupId: (groupId) => dispatch(updateUserGroupId(groupId)),
        updateSessionGroupId: (groupId) => dispatch(updateSessionGroupId(groupId)),
        applyGlobalFilter: () => dispatch(applyGlobalFilterChanges()),
        handleNavigationClick: () => dispatch(navigationClicked()),
        getAppVersions: (appId) => dispatch(getAppVersions(appId))
    };
}

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        //marginTop: apxTheme.spacing.unit * 3,
        zIndex: 1,
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    appBarShift: {
        //marginLeft: drawerWidth,
        //width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        //marginLeft: 12,
        //marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'fixed',
        zIndex: 10000,
        height: '100%',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'hidden',
        '&:hover': {
            overflowY: 'auto',
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    drawerPaperClose: {
        width: 60,
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerInner: {
        // Make the items inside not wrap when transitioning:
        width: drawerWidth,
        marginBottom: theme.spacing.unit * 4
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        width: '100%',
        flexGrow: 1,
        background: theme.palette.background.default,
        //padding: 15,
        height: 'calc(100% - 56px)',
        minHeight: `calc(100vh - ${56 + theme.spacing.unit}px)`,
        marginTop: 56,
        [theme.breakpoints.up('sm')]: {
            height: `calc(100% - ${64 + theme.spacing.unit}px)`,
            marginTop: 64,
        },
        marginLeft: 60,
        overflow: 'hidden'
    },
    pinnedContent: {
        marginLeft: drawerWidth,
    },
    list: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,

    },
    container: {
        //position: 'absolute',
        '&:hover': {
            //position: 'fixed',
            //maxWidth: drawerWidth,
            //backgroundColor: "rgba(255, 255, 255, 0.1)"
        }
    },
    active: {
        color: theme.palette.primary.main + ' !important'
    },
    parentActive: {
        backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    nested: {
        paddingLeft: `${theme.spacing.unit * 4}px !important`,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    pinButton: {
        marginLeft: 'auto'
    },
    sideLogo: {
        width: '65%',
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit * 2
    },
    sideLogoIcon: {
        width: 48,
        padding: theme.spacing.unit * 2
    }
});

/**
 * SectionHeader is a Component that holds the Filters and Heading of Page
 * In future It holds the view of Selected Filters
 */

class Navigation extends Component {

    state = {
        isSidebarOpen: false, pinnedSidebar: false
    };

    updateSidebar = (isSidebarOpen, pinnedSidebar) => {
        this.setState({isSidebarOpen, pinnedSidebar});
    };

    render() {
        const {
            appState,  appId,
            location, classes,
            children,
            updateModes
        } = this.props;
        const { isSidebarOpen, pinnedSidebar } = this.state;
        const isGlobalFiltersApplied = isDateFilterApplicable(location.pathname, DATE_FILTERS_INVALID_PATHS);
        const disableTimeFilter = isDateFilterDisabled(location.pathname, DATE_FILTERS_DISABLED_PATHS);
        const showSidebar = isSidebarOpen || pinnedSidebar;
        return (
            <div style={{display: 'flex', width: '100%'}}>
                <MuiThemeProvider theme={darkTheme}>
                    <ClickAwayListener onClickAway={() => {
                        if(isSidebarOpen){
                            this.updateSidebar(false, pinnedSidebar); //fire only if sidebar opens
                        }
                    }}>
                        <Drawer
                            variant="permanent"
                            classes={{
                                paper: classNames(classes.drawerPaper, !showSidebar && classes.drawerPaperClose),
                            }}
                            open={showSidebar}
                            onMouseEnter={() => this.updateSidebar(true, pinnedSidebar)}
                            onMouseLeave={() => this.updateSidebar(false, pinnedSidebar)}
                        >
                            <div className={classes.drawerInner}>
                                <div className={classes.drawerHeader}>
                                    { !showSidebar && <img src={window.apxorAppInfo.favicon}
                                                           className={classNames(classes.menuButton, classes.sideLogoIcon)} />
                                    }
                                    {
                                        showSidebar && <Link to={`/apps`}><img src={window.apxorAppInfo.whiteLogo} className={classes.sideLogo} /></Link>
                                    }
                                    <IconButton className={classes.pinButton} onClick={() => this.updateSidebar(isSidebarOpen, !pinnedSidebar)}>
                                        {pinnedSidebar ? <RadioButtonChecked color="primary" /> : <RadioButtonUnchecked />}
                                    </IconButton>
                                </div>
                                <Divider />
                                <SideBar
                                    appState={appState}
                                    appId={appId}
                                    updateModes={updateModes}
                                    {...this.props}
                                    {...this.context.router}
                                    isSidebarOpen={showSidebar}
                                    handleNavigationClick={() => {
                                        if(isSidebarOpen){
                                            this.updateSidebar(false, pinnedSidebar); //fire only if sidebar opens
                                        }
                                    }}
                                />
                            </div>
                            {
                                isSidebarOpen &&
                                <Typography
                                    style={{
                                        position: 'fixed',
                                        bottom: 0,
                                        padding: 5,
                                        marginLeft: 20}}
                                    variant="caption"
                                >
                                    Dashboard Version: <strong>{ pixelometryVersion }</strong>
                                </Typography>
                            }
                        </Drawer>
                    </ClickAwayListener>
                </MuiThemeProvider>
                <main className={classNames(classes.content, pinnedSidebar && classes.pinnedContent)}>
                    {
                        isGlobalFiltersApplied &&
                            <Fragment>
                                <PageHeader {...this.props} disableTimeFilter={disableTimeFilter}/>
                                <Divider/>
                            </Fragment>
                    }
                    { children }
                </main>
            </div>
        );
    }
}

Navigation.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    location: PropTypes.object,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

Navigation.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme()(withStyles(styles)(Navigation)));