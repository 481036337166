/**
 * Created by Rakesh Peela
 * Date: 31-Dec-2019
 * Time: 4:42 PM
 */

import {Typography, withStyles} from "@material-ui/core";
import React, {Fragment} from 'react';
import Loading from "../../../../../../../../../../components/reusable/Loading";
import {withQueryStrings} from "../../../../../../../../../../utils";
import {ORIENTATION_ENUM} from './DesignPreview/utils';

const SSE_API = window.SSE_ROOT + 'sse-layout?';

const getScaleFactors = (device_config, preview_config, orientation) => {
    const {image_width, image_height} = preview_config;
    const {device_width, device_height} = device_config;

    let w_scaleFactor = device_width / image_width,
        h_scaleFactor = device_height / image_height;

    return {
        w_scaleFactor: orientation === ORIENTATION_ENUM.PORTRAIT ? w_scaleFactor : h_scaleFactor,
        h_scaleFactor: orientation === ORIENTATION_ENUM.PORTRAIT ? h_scaleFactor : w_scaleFactor
    }
};

const resizeBounds = (bounds, ratio) => {
    const {top, bottom, left, right} = bounds;
    const {w_scaleFactor, h_scaleFactor} = ratio;
    let newBounds = {
        top: Math.round(top / h_scaleFactor),
        bottom: Math.round(bottom / h_scaleFactor),
        left: Math.round(left / w_scaleFactor),
        right: Math.round(right / w_scaleFactor),
    };

    return ({
        bounds: {
            ...newBounds,
            width: newBounds.right - newBounds.left,
            height: newBounds.bottom - newBounds.top
        }
    })
};

const layoutModifier = (layout, ratio) => {
    return layout.map(({bounds, views, ...others}) => ({
        ...others,
        ...resizeBounds(bounds, ratio),
        views: layoutModifier(views, ratio)
    }))
};

const styles = (theme) => ({
    layoutView: {
        border: "1px inset transparent",
        borderRadius: 4,
        '&:hover': {
            border: "1px inset red",
            backgroundColor: "#ff00031c"
        },
        cursor: "pointer"
    }
});

const WaitingForDevice = (props) => {
    const {withLoading, message} = props;
    return (
        <div>
            {withLoading && <Loading size={24}/>}
            <Typography variant={"subtitle2"}
                        color={"primary"}
                        style={{
                            marginTop: 16,
                            fontWeight: 600,
                            backgroundColor: "#3f50b5cc",
                            padding: "4px 12px",
                            borderRadius: 4,
                            color: "#fff"
                        }}>
                {message}
            </Typography>
        </div>
    )
};

class SSEDevicePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            img: null,
            orientation: ORIENTATION_ENUM.PORTRAIT,
            selectedHTMLView: null,
            layout: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedDevice !== this.props.selectedDevice) {
            if (this.eventSource) {
                this.eventSource.close();
            }
            this.setState({
                img: null,
                selectedHTMLView: null,
                layout: null
            });
            if (window.hasOwnProperty("EventSource")) {
                this.initEventSource(nextProps.selectedDevice);
            } else {
                console.log("EventSource API not available");
            }
        }
    }

    initEventSource = (selectedDevice) => {
        const {appId, imageConfig: {width, height}} = this.props;
        this.eventSource = new EventSource(SSE_API + withQueryStrings({appId}));

        this.eventSource.onmessage = (event) => {
            this.setState({selectedHTMLView: null});

            try {
                const data = JSON.parse(event.data);
                if (data.hasOwnProperty("device_info")) {
                    if (data.device_info.id === selectedDevice) {
                        const {layout, device_info: {width: device_width, height: device_height}, screen: {orientation = ORIENTATION_ENUM.PORTRAIT}} = data;
                        const ratio = getScaleFactors({device_width, device_height}, {
                            image_width: width,
                            image_height: height
                        }, orientation);

                        this.setState({
                            img: "data:image/png;base64," + data.screen.image,
                            orientation: orientation,
                            layout: layoutModifier(layout, ratio)
                        }, () => {
                            this.props.handleChange(data);
                        })
                    }
                }
            } catch (error) {
                console.log(selectedDevice);
                console.error(error);
            }
        }
    };

    componentWillUnmount() {
        // console.log("EventSource connection closed");
        if (this.eventSource)
            this.eventSource.close();
    }

    render() {
        const {imageConfig: {width, height}, handleViewSelect, selectedDevice, classes} = this.props;
        const {img, layout = null, selectedHTMLView} = this.state;
        const waitingState = selectedDevice && selectedDevice !== "";
        const EventSourceError = (
            <Typography variant={"subtitle2"} color={"error"}
                        style={{fontWeight: 600, backgroundColor: "#f0516566", padding: "4px 12px", borderRadius: 4}}>
                Your browser doesn't support this feature.
                <br/>
                Contact <a href={"mailto:support@apxor.com"}>support@apxor.com</a> for help.
            </Typography>
        );

        let computeStyles = (bounds, level, selected) => {
            const {width, height, left, top} = bounds;
            const selectedStyles = {
                border: "1px inset green",
                boxShadow: "0px 0px 5px 0px #545454",
                background: "#11ab3a6b"
            };
            const positionStyle = {
                cursor: "pointer",
                zIndex: level,
                position: "absolute",
                width, height, left, top,
            };
            return ({...positionStyle, ...(selected ? selectedStyles : {})})
        };

        let layoutGenerator = (views, level, parentBounds) => views.map((view, index) => {
            let HTML_ElementId = view.id + '-' + view.view + "-" + level + "-" + index;
            if (view.hasOwnProperty("path")) {
                HTML_ElementId = view.path
            }
            const HTML_ElementStyles = computeStyles({
                ...view.bounds,
                top: view.bounds.top - 1,
                bottom: view.bounds.bottom - 1,
                left: view.bounds.left - 1,
                right: view.bounds.right - 1
            }, level, HTML_ElementId === selectedHTMLView);

            return (
                <Fragment
                    key={HTML_ElementId}
                >
                    <div
                        id={HTML_ElementId}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                                selectedHTMLView: HTML_ElementId === selectedHTMLView ? null : HTML_ElementId
                            }, () => {
                                if (view.hasOwnProperty("id") && view.id !== "") {
                                    handleViewSelect(view.id, undefined)
                                } else if (view.hasOwnProperty("path") && view.path !== "") {
                                    handleViewSelect(undefined, view.path)
                                }
                            })
                        }}
                        className={classes.layoutView}
                        style={{...HTML_ElementStyles}}
                    >
                    </div>
                    {(view.views.length > 0) && layoutGenerator(view.views, level + index + 1, view.bounds)}
                </Fragment>
            );
        });

        return (
            <div style={{position: "relative", overflow: "hidden"}}>
                {img && <img style={{
                    borderRadius: 16,
                    width: width,
                    height: height
                }} src={img} alt={"screen_preview"}/>}
                {
                    !img && <div style={{
                        borderRadius: 16,
                        textAlign: "center",
                        maxWidth: "80%",
                        height: height,
                        margin: 'auto',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {
                            window.hasOwnProperty("EventSource")
                                ? <WaitingForDevice
                                    withLoading={waitingState}
                                    message={!waitingState ? "Please select a Test Device" : "Waiting for Device"}
                                />
                                : EventSourceError
                        }
                    </div>
                }
                {
                    layout !== null && layoutGenerator(layout, 1, {top: 0, left: 0, bottom: 0, right: 0})
                }
            </div>
        );
    }

}

export default withStyles(styles)(SSEDevicePreview);