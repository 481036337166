import React, { Component } from 'react';
import {
    getEventRelatedTimeSeries, getGlobalRelatedTimeSeries
} from '../actions';
import {
    getPercent, graphDataToCSVString, minMaxAverageOf, roundOffNumber, toTitleCase,
    addedWithAverageKeys
} from "../../../../../../../utils";
import {
    COUNT_TYPE_ENUM
} from "../../../../../../../constants";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Checkbox from "../../../../../../../components/reusable/MaterialUi/Checkbox";
import withSelfComponent from "./SelfComponent";
import {session} from "../../../../../../../utils/Storage";

const aggregatedTimeSeriesWithGroupBy = (data = {}, averagePer = COUNT_TYPE_ENUM.users) => {
    const { event, users, sessions } = data;
    let series = [];
    if([event, users, sessions].every(d => Array.isArray(d))){
        if(event.length === users.length && event.length === sessions.length){
            const keys = Object.keys(event[0]).filter(o => o !== "key");
            series = event.map((o, i) => {
                const obj = {...o};
                keys.forEach(key => {
                    if(data[averagePer][i].hasOwnProperty(key)){
                        obj[key] = roundOffNumber(o[key] / data[averagePer][i][key]);
                    }
                });
                return obj;
            });
        }
    }
    return series;
};

const aggregatedTimeSeriesWithDAUPercentageWithGroupBy = (data = {}) => {
    const { all, users} = data;
    let series = [];
    if(Array.isArray(all) && Array.isArray(users) && all.length === users.length){
        const keys = Object.keys(users[0]).filter(o => o !== "key");
        series = users.map((o, i) => {
            const obj = {...o};
            keys.forEach(key => {
                if(keys.length === 1 && all[i].hasOwnProperty("Users")){
                    obj[key] = getPercent(o[key], all[i]["Users"], true, 0); //FIXME: becareful with String [Users] key
                } else if(all[i].hasOwnProperty(key)){ //only if all have the property values
                    obj[key] = getPercent(o[key], all[i][key], true, 0);
                }
            });
            return obj;
        });
    }
    return series;
};

export const AGGREGATE_ENUM = {
    PER_USER: COUNT_TYPE_ENUM.users,
    PER_SESSION: COUNT_TYPE_ENUM.sessions,
    PERCENT_OF_DAU: "PERCENT_OF_DAU"
};

const DAYS = [0, 1, 2, 3, 4, 5, 6, 7, 15, 30, 45, 60, 90].map(i => ({label: `Day ${i}`, value: i}));

class AggregatedTrend extends Component {

    constructor(props){
        super(props);
        this.state = {
            groupBy: null,
            showAll: false,
            days: []
        };
    }

    daysChanged = false;

    hydrator = (props = this.props) => {
        const {
            event, dataKey,
            queryParams, extraFilters,
            aggregateType
        } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        const { groupBy, days = [] } = this.state;
        let extendedQueryParams = {...queryParams, days};
        if(groupBy){
            extendedQueryParams = {...extendedQueryParams, groupBy}
        }
        /*if(segment && segment.length > 0) {
            dispatch(getSegmentRelatedTimeSeries(appId, segment, dataKey, extendedQueryParams, extraFilters));
            return;
        }*/
        const { event: Event, sessions, users, all } = COUNT_TYPE_ENUM;
        if(event && event.length > 0){
            dispatch(getEventRelatedTimeSeries(appId, event, dataKey, { ...extendedQueryParams, of: Event}, extraFilters, Event));
            dispatch(getEventRelatedTimeSeries(appId, event, dataKey, { ...extendedQueryParams, of: users}, extraFilters, users));
            dispatch(getEventRelatedTimeSeries(appId, event, dataKey, { ...extendedQueryParams, of: sessions}, extraFilters, sessions));
            let postBody = {...extraFilters};
            if(aggregateType === AGGREGATE_ENUM.PERCENT_OF_DAU){
                postBody = {...extraFilters, event: []};
            }
            dispatch(getGlobalRelatedTimeSeries(appId, dataKey, { ...extendedQueryParams, of: users}, postBody, all));
        }
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel, segmentName, event, of, aggregateType}, parentCompLabel,
            appState: { attributes: { user = [], session: sessionAttributes = [], event: eventAttributes = [] } = {}, },
            meta: { api_pending }
        } = this.props;
        let trendDataKey = segmentName || event || toTitleCase(of);
        let dataKeys = [ trendDataKey ];
        const properties = [ ...user, ...sessionAttributes, ...eventAttributes ];
        const yLabel = aggregateType === AGGREGATE_ENUM.PERCENT_OF_DAU ? "Percentage of DAU" : 'Event Count';
        const { groupBy, showAll, days } = this.state;
        let data = aggregateType === AGGREGATE_ENUM.PERCENT_OF_DAU ? aggregatedTimeSeriesWithDAUPercentageWithGroupBy(self[dataKey]) : aggregatedTimeSeriesWithGroupBy(self[dataKey], aggregateType);
        if((groupBy || days.length > 0) && data.length > 0){
            trendDataKey = "all";
            dataKeys = Object.keys(data[0]).filter(o => o !== "key" && (o !== trendDataKey || showAll));
        }
        if(days.length === 1 && !groupBy){
            trendDataKey = "value";
        }
        if(!api_pending){
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data);
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        data = addedWithAverageKeys(data, dataKeys);
        const stats = minMaxAverageOf(data, trendDataKey);
        return (
            <div style={{position: 'relative', width: '100%'}}>
                {
                    Array.isArray(data) && data.length > 0 && <div style={{
                        position: 'absolute',
                        zIndex: 3,
                        right: 250,
                        minWidth: 400,
                        display: 'flex'
                    }}>
                        <MultiSelect
                            withCheckbox
                            options={DAYS}
                            handleChange={days => {
                                let tempGroupBy = groupBy;
                                if(days.length > 1){
                                    tempGroupBy = null;
                                }
                                this.daysChanged = true;
                                this.setState({days, groupBy: tempGroupBy});
                            }}
                            value={days}
                            label="Retained Day"
                            placeholder="Retained Day"
                            margin="dense"
                            valueRenderer={value => value.map(i => `D${i}`).join(', ')}
                            style={{marginRight: 8, width: '50%', display: 'none'}}
                            handleApply={days => { //FIXME: not sure with this
                                if(this.daysChanged){
                                    this.setState({days}, this.hydrator);
                                    this.daysChanged = false;
                                }
                            }}
                        />
                        {
                            (groupBy || days.length > 1) && <Checkbox
                                checked={showAll}
                                handleChange={(showAll) => this.setState({showAll})}
                                value="showAll"
                                label="All"
                            />
                        }
                        <MultiSelect
                            options={properties.map(o => ({label: o, value: o}))}
                            handleChange={groupBy => {
                                this.setState({groupBy}, this.hydrator);
                            }}
                            style={{marginRight: 8, width: '50%'}}
                            value={groupBy}
                            placeholder="Group By Property"
                            single
                            margin="normal"
                            disabled={days.length > 1}
                        />
                    </div>
                }
                <MultiUtilityChart
                    {...this.props}
                    title={customLabel}
                    height={300}
                    withHeader
                    dataKey="key"
                    yLabel={yLabel}
                    xLabel="Date"
                    data={ data }
                    lineDataKeys={dataKeys}
                    stats={stats}
                    fullWidth
                    showAverage
                />
            </div>
        )
    };
}

AggregatedTrend.propTypes = {

};

export default withSelfComponent(AggregatedTrend)