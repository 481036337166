/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../actions';

function mapStateToProps(state) {
    return {
        session: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutUser())
    };
}

class Logout extends Component{

    static propTypes = {
        session: PropTypes.object,
        logout: PropTypes.func.isRequired,
    };

    componentWillMount(){
        this.props.logout();
        this.context.router.push("/login");
    }

    render(){
        return null;
    }

}

Logout.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);