import { callApi } from '../../../../../../api';
import {makeDefaultQueryString} from '../../../../../../utils';
import { withQueryStrings} from "../../../../../../utils/index";

const CORRELATIONS_LIST_API = "funnels";
const ATTRIBUTES_API = "attributes";
const attributeValuesAPI = (attribute, q) => `attributes/${attribute}`;
const EVENTS_API = "events";
const EVENT_ATTRIBUTES_API = "events/attributes";
const SAVE_FUNNEL_API = "funnels/save";
const TIME_SERIES_FUNNEL_API = "funnels/time-series";

function getFunnelURL(funnelId, property){
    return "funnels/"+ funnelId + "/" + property;
}

function getCountAPI(funnelId){
    return "funnels/" + funnelId + "/count";
}

function getDeleteSegmentAPI(funnelId){
    return "funnels";
}

const DISTRIBUTION_API = "distribution";
const FUNNELS_API = "funnels";
const FUNNELS_GROUPED_BY_API = "funnels/groupby";
const COHORT_FUNNEL_API = "cohorts/funnels";

export function getFunnelListAPI(appId, auth, filters){
    const url = makeDefaultQueryString(CORRELATIONS_LIST_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json.reverse() : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFunnelCountAPI(appId, auth, funnelId, filters, body){
    const url = makeDefaultQueryString(getCountAPI(funnelId), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteFunnelAPI(appId, auth, filters, funnelId){
    const url = makeDefaultQueryString(FUNNELS_API, auth, appId) + withQueryStrings({funnelId});
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * New APIs
 */

export function getFunnelAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(FUNNELS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFunnelsListAPI(appId, auth, filters) {
    const url = makeDefaultQueryString(FUNNELS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function getFunnelGroupedByAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(FUNNELS_GROUPED_BY_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function funnelAttributeDistributionAPI(appId, auth, funnelId, filters, body) {
    const url = makeDefaultQueryString(getFunnelURL(funnelId, DISTRIBUTION_API), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAttributesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAttributeValuesAPI(auth, appId, attribute, filters) {
    const url = makeDefaultQueryString(attributeValuesAPI(attribute), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.filter(o => o && o.length > 0);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENTS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.localeCompare(b)); //default sorting on strings
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENT_ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributeValuesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(attributeValuesAPI(filters.attribute), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.filter(o => o && o.length > 0);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveFunnelAPI(auth, appId, filters, funnel) {
    const url = makeDefaultQueryString(SAVE_FUNNEL_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(funnel)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFunnelTimeseriesAPI(auth, appId, filters, funnelId) {
    const url = makeDefaultQueryString(TIME_SERIES_FUNNEL_API, auth, appId) + withQueryStrings({funnelId, ...filters})
    const config = {
        method: "GET",
        auth: auth,
    };
    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = res => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFunnelGroupByAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(FUNNELS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function createCohortFromFunnelAPI(appId, auth, filters, funnelConfig) {
    const {body, ...others} = funnelConfig;
    const url = makeDefaultQueryString(COHORT_FUNNEL_API, auth, appId)
        + withQueryStrings({
            ...filters,
            ...others
        });
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
