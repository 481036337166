
export const CUSTOM_EVENT_ENUM = {
    event: "event",
    sessions: "sessions",
    users: "users"
};

export const FUNNEL_ATTRIBUTES_PENDING = "FUNNEL_ATTRIBUTES_PENDING";
export const FUNNEL_ATTRIBUTES = "FUNNEL_ATTRIBUTES";
export const FUNNEL_ATTRIBUTES_FAILED = "FUNNEL_ATTRIBUTES_FAILED";

export const FUNNEL_ATTRIBUTE_VALUES_PENDING = "FUNNEL_ATTRIBUTE_VALUES_PENDING";
export const FUNNEL_ATTRIBUTE_VALUES = "FUNNEL_ATTRIBUTE_VALUES";
export const FUNNEL_ATTRIBUTE_VALUES_FAILED = "FUNNEL_ATTRIBUTE_VALUES_FAILED";

export const FUNNEL_SAVE_PENDING = "FUNNEL_SAVE_PENDING";
export const FUNNEL_SAVE = "FUNNEL_SAVE";
export const FUNNEL_SAVE_FAILED = "FUNNEL_SAVE_FAILED";

export const FUNNEL_EVENTS_PENDING = "FUNNEL_EVENTS_PENDING";
export const FUNNEL_EVENTS = "FUNNEL_EVENTS";
export const FUNNEL_EVENTS_FAILED = "FUNNEL_EVENTS_FAILED";

export const FUNNEL_EVENT_ATTRIBUTES_PENDING = "FUNNEL_EVENT_ATTRIBUTES_PENDING";
export const FUNNEL_EVENT_ATTRIBUTES = "FUNNEL_EVENT_ATTRIBUTES";
export const FUNNEL_EVENT_ATTRIBUTES_FAILED = "FUNNEL_EVENT_ATTRIBUTES_FAILED";

export const FUNNEL_EVENT_ATTRIBUTE_VALUES_PENDING = "FUNNEL_EVENT_ATTRIBUTE_VALUES_PENDING";
export const FUNNEL_EVENT_ATTRIBUTE_VALUES = "FUNNEL_EVENT_ATTRIBUTE_VALUES";
export const FUNNEL_EVENT_ATTRIBUTE_VALUES_FAILED = "FUNNEL_EVENT_ATTRIBUTE_VALUES_FAILED";

export const FUNNEL_UPDATE_QUERY = "FUNNEL_UPDATE_QUERY";

export const FUNNEL_RESET_QUERY = "FUNNEL_RESET_QUERY";

export const FUNNEL_UPDATE_QUERY_TYPE = "FUNNEL_UPDATE_QUERY_TYPE"; //users, sessions

export const RESET_FUNNEL = "RESET_FUNNEL";

export const CORRELATIONS_FUNNEL_RETENTION_PENDING = "CORRELATIONS_FUNNEL_RETENTION_PENDING";
export const CORRELATIONS_FUNNEL_RETENTION = "CORRELATIONS_FUNNEL_RETENTION";
export const CORRELATIONS_FUNNEL_RETENTION_FAILED = "CORRELATIONS_FUNNEL_RETENTION_FAILED";

export const CORRELATIONS_RETENTION_PENDING = "CORRELATIONS_RETENTION_PENDING";
export const CORRELATIONS_RETENTION = "CORRELATIONS_RETENTION";
export const CORRELATIONS_RETENTION_FAILED = "CORRELATIONS_RETENTION_FAILED";

export const CORRELATIONS_DAY1_UNINSTALLS_PENDING = "CORRELATIONS_DAY1_UNINSTALLS_PENDING";
export const CORRELATIONS_DAY1_UNINSTALLS_FAILED = "CORRELATIONS_DAY1_UNINSTALLS_FAILED";
export const CORRELATIONS_DAY1_UNINSTALLS = "CORRELATIONS_DAY1_UNINSTALLS";

export const CORRELATIONS_TIMESERIES_PENDING = "CORRELATIONS_TIMESERIES_PENDING";
export const CORRELATIONS_TIMESERIES_FAILED = "CORRELATIONS_TIMESERIES_FAILED";
export const CORRELATIONS_TIMESERIES = "CORRELATIONS_TIMESERIES";

export const FUNNEL_TIMESERIES_PENDING = "FUNNEL_TIMESERIES_PENDING";
export const FUNNEL_TIMESERIES = "FUNNEL_TIMESERIES";
export const FUNNEL_TIMESERIES_FAILED = "FUNNEL_TIMESERIES_FAILED";

export const CORRELATION_FUNNELLIST_PENDING = "CORRELATION_FUNNELLIST_PENDING";
export const CORRELATION_FUNNELLIST = "CORRELATION_FUNNELLIST";
export const CORRELATION_FUNNELLIST_FAILED = "CORRELATION_FUNNELLIST_FAILED";

export const UPDATE_FUNNELS = "UPDATE_FUNNELS";
export const UPDATE_FUNNELS_FAILED = "UPDATE_FUNNELS_FAILED";
export const UPDATE_FUNNELS_PENDING = "UPDATE_FUNNELS_PENDING";

export const FUNNEL_GRAPH_PENDING = "FUNNEL_GRAPH_PENDING";
export const FUNNEL_GRAPH_FAILED = "FUNNEL_GRAPH_FAILED";
export const FUNNEL_GRAPH = "FUNNEL_GRAPH";

export const UPDATE_CORRELATIONS_FUNNELS_LIST = "UPDATE_CORRELATIONS_FUNNELS_LIST";

export const FUNNEL_GROUPED_BY_PENDING = "FUNNEL_GROUPED_BY_PENDING";
export const FUNNEL_GROUPED_BY_FAILED = "FUNNEL_GROUPED_BY_FAILED";
export const FUNNEL_GROUPED_BY = "FUNNEL_GROUPED_BY";

export const FUNNEL_OPPORTUNITY_PENDING = "FUNNEL_OPPORTUNITY_PENDING";
export const FUNNEL_OPPORTUNITY_FAILED = "FUNNEL_OPPORTUNITY_FAILED";
export const FUNNEL_OPPORTUNITY = "FUNNEL_OPPORTUNITY";


export const DELETE_FUNNEL_PENDING = "DELETE_FUNNEL_PENDING";
export const DELETE_FUNNEL = "DELETE_FUNNEL";
export const DELETE_FUNNEL_FAILED = "DELETE_FUNNEL_FAILED";


export const FUNNEL_LIST_PENDING = "FUNNEL_LIST_PENDING";
export const FUNNEL_LIST_FAILED = "FUNNEL_LIST_FAILED";
export const FUNNEL_LIST = "FUNNEL_LIST";

export const FUNNEL_USER_ATTRIBUTE_DISTRIBUTION = "FUNNEL_USER_ATTRIBUTE_DISTRIBUTION";
export const FUNNEL_SESSION_ATTRIBUTE_DISTRIBUTION = "FUNNEL_SESSION_ATTRIBUTE_DISTRIBUTION";
export const FUNNEL_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION = "FUNNEL_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION";
export const FUNNEL_TIME_SERIES = "FUNNEL_TIME_SERIES";

export const FUNNEL_RETENTION_PENDING = "FUNNEL_RETENTION_PENDING";
export const FUNNEL_RETENTION = "FUNNEL_RETENTION";
export const FUNNEL_RETENTION_FAILED = "FUNNEL_RETENTION_FAILED";

export const UPDATE_FUNNEL_USER_GROUP = "UPDATE_FUNNEL_USER_GROUP";

export const UPDATE_FUNNEL_ATTRIBUTE_FILTERS = "UPDATE_FUNNEL_ATTRIBUTE_FILTERS";

export const UPDATE_FUNNEL_GROUP_BY_ATTRIBUTE = "UPDATE_FUNNEL_GROUP_BY_ATTRIBUTE";

export const FUNNEL_GROUP_BY_ATTRIBUTE = "FUNNEL_GROUP_BY_ATTRIBUTE";

export const UPDATE_FUNNEL_CURRENT_EVENT = "UPDATE_FUNNEL_CURRENT_EVENT";

export const FUNNEL_CURRENT_EVENT_ATTRIBUTES = "FUNNEL_CURRENT_EVENT_ATTRIBUTES";

export const CREATE_COHORT_FUNNEL_PENDING = "CREATE_COHORT_FUNNEL_PENDING";
export const CREATE_COHORT_FUNNEL = "CREATE_COHORT_FUNNEL";
export const CREATE_COHORT_FUNNEL_FAILED = "CREATE_COHORT_FUNNEL_FAILED";

export const TOGGLE_FUNNEL_DIALOG = "TOGGLE_FUNNEL_DIALOG";
export const SHOW_FUNNEL_SNACKBAR = "SHOW_FUNNEL_SNACKBAR";
export const HIDE_FUNNEL_SNACKBAR = "HIDE_FUNNEL_SNACKBAR";