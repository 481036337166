/**
 * Created by Rakesh Peela
 * Date: 10-Apr-2020
 * Time: 11:00 PM
 */

export const BOTS_PENDING = "BOTS_PENDING";
export const BOTS = "BOTS";
export const BOTS_FAILED = "BOTS_FAILED";

export const BOT_UPDATE_PENDING = "BOT_UPDATE_PENDING";
export const BOT_UPDATE = "BOT_UPDATE";
export const BOT_UPDATE_FAILED = "BOT_UPDATE_FAILED";

export const BOT_DELETE_PENDING = "BOT_DELETE_PENDING";
export const BOT_DELETE = "BOT_DELETE";
export const BOT_DELETE_FAILED = "BOT_DELETE_FAILED";