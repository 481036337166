/**
 * Created by Rakesh Peela
 * Date: 03-Dec-2019
 * Time: 6:54 PM
 */

import React from 'react';
import debounce from 'lodash/debounce';
import {Grid} from '@material-ui/core';
import ActionsTextField from "../ActionsTextField";
import ExpansionModuleConfig from "../ExpansionModuleConfig";
import ModuleDivider from "../ModuleDivider";
import CustomColorPickerModule from "./CustomColorPickerModule";
import CustomNativeSelect from "./CustomNativeSelect";
import ModuleConfigSection from "./ModuleConfigSection";

const FONT_FAMILY_ENUM = ["casual", "cursive", "monospace", "sans-serif"].map(f => ({label: f, key: f}));

const FONT_SIZES_ENUM = [12, 14, 16, 18, 20, 22, 24].map(f => ({label: f, key: f}));

const FONT_STYLES_ENUM = ["normal", "bold", "italic"].map(f => ({label: f, key: f}));

class Text_config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentConfig: this.props.config
        };

        this.debounce = debounce((fn, data, stepIndex) => {
            fn(data, stepIndex)
        }, 300);
    }

    handleCurrentChange = (data) => {
        const {message, isPure, onChange, dataKey, currentCampaignStep} = this.props;
        if (isPure) {
            this.setState({
                currentConfig: data
            });
            this.debounce(onChange, {...message, [dataKey]: data}, currentCampaignStep)
        } else {
            this.setState({
                currentConfig: data
            });
            onChange(data, currentCampaignStep);
        }
    };

    render() {
        const {config, isPure, titleC, propertiesForMacros, withPropertySelector = false} = this.props;
        const {currentConfig} = this.state;
        const {text, font_family, color, size, style} = currentConfig;
        const ReqComponent = (
            <div key={config["__id"]}>
                <ModuleConfigSection title={"Text"}>
                    <ActionsTextField
                        placeholder={"Text"}
                        value={text}
                        style={{margin: 0}}
                        required
                        withPropertySelector={withPropertySelector}
                        {...propertiesForMacros}
                        onChange={(e) => {
                            this.handleCurrentChange({
                                ...config,
                                text: e.target.value
                            })
                        }}/>
                </ModuleConfigSection>
                <ModuleDivider/>
                <Grid container spacing={32}>
                    <Grid item>
                        <ModuleConfigSection title={"Color"}>
                            <CustomColorPickerModule
                                color={color}
                                handleColorChange={(selectedColor) => this.handleCurrentChange({
                                    ...config,
                                    color: selectedColor.hex
                                })}
                            />
                        </ModuleConfigSection>
                    </Grid>
                    <Grid item>
                        <ModuleConfigSection title={"Font Style"}>
                            <CustomNativeSelect
                                options={FONT_FAMILY_ENUM}
                                defaultValue={font_family}
                                itemName={"font_family"}
                                handleChange={(event) => {
                                    this.handleCurrentChange({
                                        ...config,
                                        font_family: event.target.value
                                    })
                                }}
                            />
                        </ModuleConfigSection>
                    </Grid>
                    <Grid item>
                        <ModuleConfigSection title={"Font Size"}>
                            <CustomNativeSelect
                                options={FONT_SIZES_ENUM}
                                defaultValue={size}
                                itemName={"size"}
                                handleChange={(event) => {
                                    this.handleCurrentChange({
                                        ...config,
                                        size: Number(event.target.value)
                                    })
                                }}
                            />
                        </ModuleConfigSection>
                    </Grid>
                    <Grid item>
                        <ModuleConfigSection title={"Font Weight"}>
                            <CustomNativeSelect
                                options={FONT_STYLES_ENUM}
                                defaultValue={style}
                                itemName={"style"}
                                handleChange={(event) => {
                                    this.handleCurrentChange({
                                        ...config,
                                        style: event.target.value
                                    })
                                }}
                            />
                        </ModuleConfigSection>
                    </Grid>
                </Grid>
            </div>
        );

        return (isPure ? <ExpansionModuleConfig defaultExpanded title={titleC || "Text Configurator"}>
            {ReqComponent}
        </ExpansionModuleConfig> : ReqComponent)

    }
}

export default Text_config;