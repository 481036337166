/**
 * Created by jyothi on 26/10/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    LOGICAL_OPERATORS,
    CONDTIONAL_OPERATORS
} from '../../../constants';
import Loading from '../Loading';
import MultiSelect from '../MaterialUi/MultiSelect';
import {
    getAttributes, getAttributeValues,
    getEvents, getEventAttributes, getEventAttributeValues,
    updateQuery, saveSegment, getSegmentCount, resetQuery
} from './actions';
import Box from "../../../components/reusable/Box";
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FilterIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from "../Switch";
import ToggleSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Typography, Fab} from "@material-ui/core";

const Operators = [
    {label: ">", value: LOGICAL_OPERATORS.GT},
    {label: "≥", value: LOGICAL_OPERATORS.GTE},
    {label: "<", value: LOGICAL_OPERATORS.LT},
    {label: "≤", value: LOGICAL_OPERATORS.LTE},
    {label: "=", value: LOGICAL_OPERATORS.EQ},
    {label: "!=", value: LOGICAL_OPERATORS.NEQ}
];

export class EventAttributeQuery extends Component{

    constructor(props){
        super(props);
        const { name = "", additional_info = {} } = props;
        this.state = { name, additional_info };
    }

    componentWillMount(){
        const { name } = this.state;
        const { bindedDispatch, params: { appId } } = this.props;
        bindedDispatch(getEventAttributes(appId, name));
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    handleQueryChange  = (key) => (query) => {
        const { additional_info } = {...this.state};
        if(query.attribute.length){
            additional_info[query.attribute] = query.value;
        }else{
            delete additional_info[key]
        }
        this.setState({additional_info}, this.handleUpdate);
    };


    render(){
        const { name, additional_info } = this.state;
        const {
            appState: { appEvents = [] },
            params: { appId },
            bindedDispatch,
            queryBuilder: { event_attributes, event_attribute_values },
            disabled
        } = this.props;
        const {filteredEvents} = this.props;
        const attributes = event_attributes.hasOwnProperty(name) ? event_attributes[name] : [];
        const keys = Object.keys(additional_info);
        const remainingAttributes = attributes.filter(o => !keys.includes(o));
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={3}>
                    <MultiSelect
                        value={name}
                        options={filteredEvents ? filteredEvents.map(o => ({
                            label: o,
                            value: o
                        })) : appEvents.map(o => ({label: o, value: o}))}
                        placeholder="Select Event"
                        label="Select Event"
                        handleChange={(name) => {
                            this.setState({name}, this.handleUpdate);
                            if(name && name.length) {
                                bindedDispatch(getEventAttributes(appId, name));
                            }
                        }}
                        single
                        disabled={disabled}
                        clearable
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    {
                        attributes.length > 0 &&
                        <div>
                            {
                                keys.map(key =>
                                    <EventAttributeQueryHolder {...this.props} key={key} data={[key]} attribute={key} value={additional_info[key]}
                                                 handleChange={this.handleQueryChange(key)}
                                                 getRecommendations={(attribute, q) => getEventAttributeValues(appId, name, attribute, q)}
                                                 values={event_attribute_values[name]}
                                    />
                                )
                            }
                            {
                                !disabled && remainingAttributes.length > 0 &&
                                <EventAttributeQueryHolder {...this.props} data={remainingAttributes} attribute={""} value={""}
                                             handleChange={this.handleQueryChange(null)}
                                             values={{}}
                                             getRecommendations={(attribute, q) => getEventAttributeValues(appId, name, attribute, q)}
                                />
                            }
                        </div>
                    }
                </Grid>
            </Grid>
        )

    }

}

export class EventAttributeQueryHolder extends Component {

    constructor(props){
        super(props);
        this.state = {
            attribute: props.attribute || "",
            operator: LOGICAL_OPERATORS.EQ,
            value: props.value,
            q: ""
        }
    }

    render(){

        const {
            disabled, data,
            handleChange, attribute, value,
            operators = Operators, valueLabel="Search for values",
            bindedDispatch,
            values, getRecommendations
        } = this.props;
        const { operator } = this.state;
        return (
            <Grid container spacing={16}>
                <Grid item xs={12} md={3}>
                    <MultiSelect
                        disabled={disabled}
                        options={data.map(o => ({label: o, value: o}))}
                        placeholder="Select Property"
                        label="Select Property"
                        value={ attribute }
                        handleChange={(attribute) => {
                            this.setState({attribute}, () => handleChange(this.state));
                            bindedDispatch(getRecommendations(attribute, "")); //loading default values
                        }}
                        single
                        clearable
                    />
                </Grid>
                {
                    attribute.length > 0 && <Grid item xs={4} md={3}>
                        <MultiSelect
                            value={operator}
                            options={ operators }
                            placeholder="Condition"
                            label="Condition"
                            handleChange={(operator) => this.setState({operator}, () => handleChange(this.state))}
                            single
                            disabled={true /*FIXME: change this later*/}
                        />
                    </Grid>
                }
                {
                    values && attribute.length > 0 && values.hasOwnProperty(attribute) && <Grid item xs={8} md={6}>
                        <MultiSelect
                            placeholder={valueLabel}
                            label={valueLabel}
                            options={
                                [...(value ? [{label: value, value: value}] : []), ...values[attribute].map(item => ({label: item, value: item}))]
                            }
                            handleChange={(value) => this.setState({value}, () => handleChange(this.state))}
                            onInputChange={(q, callback) => {
                                if(q.length > 0) {
                                    bindedDispatch(getRecommendations(attribute, q));
                                }
                                //callback(values[attribute].map(o => ({label: o, value: o}))); //FIXME: need proper multiselect here
                            }}
                            isAsync
                            value={value}
                            single
                            disabled={disabled}
                        />
                    </Grid>
                }
            </Grid>
        )
    }

}

export class QueryHolder extends Component {

    constructor(props){
        super(props);
        this.state = {
            attribute: props.attribute || "",
            operator: LOGICAL_OPERATORS.EQ,
            value: props.value,
            q: ""
        }
    }

    render(){

        const {
            disabled, data,
            handleChange, attribute, value,
            operators = Operators, valueLabel="Search for values",
            bindedDispatch,
            values, getRecommendations
        } = this.props;
        const { operator } = this.state;
        return (
            <Grid container spacing={16}>
                <Grid item xs={12} md={4} >
                    <MultiSelect
                        disabled={disabled}
                        options={data.map(o => ({label: o, value: o}))}
                        placeholder="Select Property"
                        value={ attribute }
                        handleChange={(attribute) => {
                            this.setState({attribute}, () => handleChange(this.state));
                            bindedDispatch(getRecommendations(attribute, "")); //loading default values for attribute
                        }}
                        single
                    />
                </Grid>
                {
                    attribute.length > 0 && <Grid item xs={12} md={2} >
                        <MultiSelect
                            value={operator}
                            options={ operators }
                            placeholder="Condition"
                            handleChange={(operator) => this.setState({operator}, () => handleChange(this.state))}
                            single
                        />
                    </Grid>
                }
                {
                    values && attribute.length > 0 && values.hasOwnProperty(attribute) && <Grid item xs={12} md>
                        <MultiSelect
                            placeholder={valueLabel}
                            options={[...value.map(o => ({label: o, value: o})), ...values[attribute].map(item => ({label: item, value: item}))]}
                            handleChange={(value) => this.setState({value}, () => handleChange(this.state))}
                            onInputChange={(q, callback) => {
                                bindedDispatch(getRecommendations(attribute, q));
                                //callback(values[attribute].map(o => ({label: o, value: o}))); //FIXME: need proper multiselect here
                            }}
                            isAsync
                            value={value}
                        />
                    </Grid>
                }
            </Grid>
        )
    }

}

class EventQueryHolder extends Component {

    constructor(props){
        super(props);
        this.state = {
            event: props.event || "",
            value: props.value,
            q: ""
        }
    }

    handleQueryChange = (key) => (query) => {
        let value = {...this.state.value};
        if(query.attribute.length){
            value[query.attribute] = query.value
        }else{
            delete value[key];
        }
        this.setState({value}, () => {
            this.props.handleChange(this.state);
        });
    };

    render(){

        const {
            data,
            handleChange, event,
            bindedDispatch, params: { appId },
            queryBuilder: { event_attributes, query },
            condition, not=false, handleNegationForEvent
        } = this.props;
        const {values } = this.props;
        const keys = query.event.hasOwnProperty(event) ? Object.keys(query.event[event]) : [];
        const attributes = event_attributes.hasOwnProperty(event) ? event_attributes[event] : [];
        const remainingAttributes = attributes.filter(item => keys.indexOf(item) < 0);
        return (
            <Grid container spacing={16}>
                {
                    condition === CONDTIONAL_OPERATORS.NOT && event && event.length &&
                    <Grid item xs={1} xl={1} lg={1} md={1} sm={1} >
                        <FormControlLabel
                            control={
                                <ToggleSwitch
                                    checked={not}
                                    onChange={(e, checked) => handleNegationForEvent(checked)}
                                    aria-label="Negation"
                                />
                            }
                            label={<Typography variant="h5" color="inherit" title="Negation">!</Typography>}
                            style={{margin: '20px 0 auto'}}
                        />
                    </Grid>
                }
                <Grid item xs={3} xl={3} lg={3} md={3} sm={3} >
                    <MultiSelect
                        value={event}
                        options={data.map(o => ({label: o, value: o}))}
                        handleChange={(event) => {
                            this.setState({event}, () => handleChange(this.state));
                            if(event && event.length) {
                                bindedDispatch(getEventAttributes(appId, event));
                            }
                        }}
                        single
                        placeholder="Select Event"
                    />
                </Grid>
                <Grid item xs xl lg md sm>
                    {
                        attributes.length > 0 &&
                        <div>
                            {
                                keys.map(key =>
                                    <QueryHolder {...this.props} key={key} data={[key]} attribute={key} value={query.event[event][key]}
                                                 handleChange={this.handleQueryChange(key)}
                                                 getRecommendations={(attribute, q) => getEventAttributeValues(appId, event, attribute, q)}
                                                 values={values[event]}
                                    />
                                )
                            }
                            {
                                remainingAttributes.length > 0 &&
                                <QueryHolder {...this.props} data={remainingAttributes} attribute={""} value={[]}
                                             handleChange={this.handleQueryChange(null)}
                                             values={{}}
                                             getRecommendations={(attribute, q) => getEventAttributeValues(appId, event, attribute, q)}
                                />
                            }
                        </div>
                    }
                </Grid>
            </Grid>
        )
    }
}

class Query extends Component {

    constructor(props){
        super(props);
        this.state = {
            data : props.data || {}
        };
    }

    handleQueryChange = (key) => (query) => {
        let data = {...this.state.data};
        if(query.attribute.length){
            data[query.attribute] = query.value
        }else{
            delete data[key];
        }
        this.props.handleChange(data);
        this.setState({data});
    };

    render(){

        const {title, defaultCollapse, attributes=[], pending,data, params: { appId } } = this.props;
        const keys = Object.keys(data);
        const remainingAttributes = attributes.filter(item => keys.indexOf(item) < 0);
        return (
            <Box title={title}
                 collapsible={defaultCollapse}
                 icon={<FilterIcon/>}
                 withPadding
            >
                {pending && <Loading/>}
                {
                    keys.map(key =>
                        <QueryHolder
                            {...this.props} key={key} data={[key]} attribute={key} value={data[key]}
                            handleChange={this.handleQueryChange(key)}
                            getRecommendations={(attribute, q) => getAttributeValues(appId, attribute, q)}
                        />
                    )
                }
                {
                    remainingAttributes.length > 0 &&
                    <QueryHolder
                        {...this.props}
                        data={remainingAttributes}
                        attribute={""}
                        value={[]}
                        handleChange={this.handleQueryChange(null)}
                        getRecommendations={(attribute, q) => getAttributeValues(appId, attribute, q)}
                    />
                }
            </Box>
        )
    }

}

class EventQuery extends Component {

    constructor(props){
        super(props);
        this.state = {
            data : props.data || {}
        };
    }

    handleQueryChange = (key) => (query) => {
        let data = {...this.state.data};
        if(query.event.length){
            data[query.event] = query.value
        }else{
            delete data[key];
        }
        this.props.handleChange(data);
        this.setState({data});
    };

    render(){

        const {
            title, defaultCollapse, events,
            pending, data,
            handleNegationForEvent = () => null, negations = {},
            allowSingle = false, withoutBox = false
        } = this.props;
        const keys = Object.keys(data);
        const remainingEvents = events.filter(item => keys.indexOf(item) < 0);
        const renderEventSelections =
            <div>
                {pending && <Loading/>}
                {
                    keys.map(key =>
                        <EventQueryHolder
                            {...this.props}
                            key={key} data={[key]} event={key}
                            value={data[key]} handleChange={this.handleQueryChange(key)}
                            handleNegationForEvent={handleNegationForEvent(key)}
                            not={negations[key]}
                        />
                    )
                }
                {
                    (keys.length === 0 || !allowSingle) && remainingEvents.length > 0 && <EventQueryHolder
                        {...this.props}
                        data={remainingEvents} event={""} value={{}}
                        handleChange={this.handleQueryChange(null)}
                        handleNegationForEvent={handleNegationForEvent(null)}
                        not={false}
                    />
                }
            </div>;
        if(withoutBox){
            return renderEventSelections;
        }else {
            return (
                <Box
                    title={title}
                    collapsible={defaultCollapse}
                    icon={<FilterIcon/>}
                    withPadding
                >
                    { renderEventSelections }
                </Box>
            )
        }
    }

}

class QueryBuilderControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    render(){

        const { open } = this.state;
        const { classes } = this.props;
        return(
            <div style={{
                position: 'fixed',
                zIndex: 3000,
                right: 20,
                bottom: 20
            }}>
                <Tooltip id="tooltip-fab" title={ open ? "Close" : "New Query" } placement="bottom">
                    <Fab size="small" color="primary"
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: open,
                            })}
                            onClick={(e) => {
                                if(open){
                                    const { bindedDispatch }  = this.props;
                                    bindedDispatch(resetQuery()); //resetting query on close
                                }
                                this.setState({open: !open});
                            }}
                            aria-expanded={open}
                            aria-label="Add App">
                        {
                            open && <RemoveIcon/>
                        }
                        {
                            !open && <AddIcon />
                        }
                    </Fab>
                </Tooltip>
                <QueryBuilder {...this.props} open={open} handleDrawerClose={() => this.setState({open: false})}/>
            </div>
        )

    }

}

class SaveButton extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: "",
            disabled: true,
            submitted: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { queryBuilder: { saved } } = nextProps;
        if(this.state.submitted && saved._id){
            this.props.router.replace(`/apps/${nextProps.params.appId}/correlations/${saved._id}`);
        }
    }

    render(){
        return(
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.bindedDispatch(saveSegment(this.props.params.appId, this.state.name));
                this.setState({submitted: true});
            }}>
                <Grid container spacing={0}>
                    <Grid item xs xl lg md sm>
                        <TextField type="text" className="form-control" placeholder="Enter Name for Query" onChange={(e) => {
                            this.setState({name: e.target.value, disabled: e.target.value.trim().length < 2});
                        }}/>
                    </Grid>
                    <Grid item xs={2} xl={2} lg={2} md={2} sm={2} >
                        <Button variant="contained" color="primary" disabled={this.state.disabled} type="submit">
                            {this.state.submitted ? <i className="fa fa-spinner fa-pulse"></i> : "Save" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

const styles = theme => ({
    paper: {
        padding: 30
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
});

export class QueryBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            session: {},
            event: {},
            condition: CONDTIONAL_OPERATORS.AND,
            negations: {}
        };
    }

    componentWillMount() {
        const { bindedDispatch, params: { appId },
            queryBuilder: { events = [] }
        } = this.props;
        if(events.length === 0) {
            bindedDispatch(getAttributes(appId));
            bindedDispatch(getEvents(appId));
        }
    }

    handleUserAttributeQuery = (query) => {
        this.setState({user: query}, this.updateQuery);
    };

    handleSessionAttributeQuery = (query) => {
        this.setState({session: query}, this.updateQuery);
    };

    handleEventQuery = (query) => {
        let negations = {...this.state.negations};
        let newNegations = {};
        for(let event in query){
            if(query.hasOwnProperty(event)){
                newNegations[event] = negations.hasOwnProperty(event) ? negations[event] : false;
            }
        }
        this.setState({event: query, negations: newNegations}, this.updateQuery);
    };

    handleNegationForEvent = (event) => (isNot = false) => {
        this.setState({negations: {...this.state.negations, [event]: isNot}}, this.updateQuery);
    };

    updateQuery = () => {
        const { onQueryUpdate, bindedDispatch, params: { appId }, withoutCount = false } = this.props;
        bindedDispatch(updateQuery(appId, this.state));
        if(!withoutCount){
            bindedDispatch(getSegmentCount(appId));
        }
        if(typeof onQueryUpdate === 'function'){
            onQueryUpdate(this.state); //trigger query update
        }
    };

    componentWillUnmount(){
        const { bindedDispatch }  = this.props;
        bindedDispatch(resetQuery());
    }

    render() {
        const {
            queryBuilder: {
                user_attributes = [], session_attributes = [], attribute_values = [],
                events = [], event_attribute_values = [], query, count
            },
            onlyUserAndSessionAttributes = false, onlyEventAttributes = false,
            classes, open, handleDrawerClose
        } = this.props;
        const { negations = {} } = query;
        //const versions = filters.versions.length > 0 ? filters.versions.join(', ') : 'All';
        if (onlyEventAttributes) {
            return (
                <EventQuery
                    title="Event Properties"
                    {...this.props}
                    events={events}
                    data={query.event}
                    handleChange={this.handleEventQuery}
                    values={event_attribute_values}
                    condition={this.state.condition}
                    handleConditionChange={() => {
                        const condition = this.state.condition === CONDTIONAL_OPERATORS.AND ? CONDTIONAL_OPERATORS.OR : CONDTIONAL_OPERATORS.AND;
                        this.setState({condition}, this.updateQuery);
                    }}
                />
            )
        } else if (onlyUserAndSessionAttributes) {
            return (
                <Grid container style={{marginTop: 8}}>
                    <Grid item xs>
                        <Query
                            {...this.props}
                            title="User Properties"
                            attributes={user_attributes}
                            data={query.user}
                            handleChange={this.handleUserAttributeQuery}
                            values={attribute_values}
                            defaultCollapse
                        />
                        <Query
                            {...this.props}
                            title="Session Properties"
                            attributes={session_attributes}
                            data={query.session}
                            handleChange={this.handleSessionAttributeQuery}
                            values={attribute_values}
                            defaultCollapse
                        />
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={handleDrawerClose}
                    className={classes.paper}
                >
                    <div style={{padding: 30}}>
                        <Box
                            withPadding
                            title={`Users Count: ${count}`}
                            footer={
                                <Grid container>
                                    <Grid item xs>
                                        <SaveButton {...this.props}/>
                                    </Grid>
                                    <Grid item xs>
                                        <Button variant="contained" color="primary" disabled>Analyse</Button>
                                    </Grid>
                                </Grid>
                            }
                            controls={
                                <Switch
                                    data={Object.keys(CONDTIONAL_OPERATORS)}
                                    handleChange={(condition) => this.setState({condition}, this.updateQuery)}
                                    value={this.state.condition}
                                />
                            }
                        >
                            <EventQuery
                                title="Event Properties"
                                events={ events }
                                data={query.event}
                                handleChange={this.handleEventQuery}
                                {...this.props}
                                values={event_attribute_values}
                                condition={this.state.condition}
                                handleConditionChange={() => {
                                    const condition = this.state.condition === CONDTIONAL_OPERATORS.AND ? CONDTIONAL_OPERATORS.OR : CONDTIONAL_OPERATORS.AND;
                                    this.setState({condition: condition}, this.updateQuery);
                                }}
                                handleNegationForEvent={this.handleNegationForEvent}
                                negations={negations}
                            />
                        </Box>
                    </div>
                </Drawer>
            )
        }
    }
}

QueryBuilder.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default withStyles(styles)(QueryBuilderControl);