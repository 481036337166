/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 1:42 PM
 */

import MomentUtils from "@date-io/moment";
import {Divider, Grid, Typography, withStyles} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from "@material-ui/icons/Info";
import Apxor from 'apxor';
import debounce from 'lodash/debounce';
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker} from "material-ui-pickers";
import moment from "moment";
import React from 'react';
import MultiSelect from "../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {APXOR_SDK_CATEGORY_CONSTANTS, LOGICAL_OPERATORS} from "../../../../../../../../../../constants";
import SegmentConfig from "../../../../../Segments/SegmentConfig";
import ClassicCard from "../../ClassicCard";
import ActionsTextField from "../components/ActionsTextField";
import DummyInsetContainer from "../components/DummyInsetContainer";
import FieldDescription from "../components/FieldDescription";
import FieldSection from "../components/FieldSection";
import CustomNativeSelect from "../components/modules/CustomNativeSelect";
import OptionSelect from "../components/OptionSelect";

const page4_styles = (theme) => ({});

const AUDIENCE_TYPES = [
    {
        title: "All Users",
        // description: "All your users",
        disabled: false,
    },
    {
        title: "Segment",
        // description: "Select user segments",
        disabled: false
    }
];

const FREQUENCY_ENUM = [{label: "overall", key: "OVERALL"}, {label: "per session", key: "SESSION"}];
const RECUR_FREQUENCY_ENUM = [{label: "hour", key: "HOUR"}, {label: "day", key: "DAY"}, {label: "week", key: "WEEK"}];

class Page4 extends React.Component {
    constructor(props) {
        super(props);
        const {
            messages2: {
                new_message: {
                    audience: {
                        segment_id,
                        start_time = moment().subtract(7, "d"),
                        end_time = moment()
                    },
                    frequency,
                    overall_cfg,
                    scheduling,
                    time_limits, time_limits_in_day,
                    terminate_info,
                    validity: {
                        start_date,
                        end_date
                    },
                    goal_event = null
                }
            }
        } = this.props;

        this.state = {
            selectedAudience: segment_id ? 1 : 0,
            segment_id: segment_id || null,
            selectedEvents: overall_cfg.events.map(x => x.name) || [],
            displayFrequency: frequency,
            refreshToggle: false,
            overallConfig: overall_cfg,
            rules_section: {
                start_date: start_date ? moment(start_date) : moment(Date.now()).add(1, "d").startOf("day"),
                end_date: end_date ? moment(end_date) : moment(Date.now()).add(5, "d").startOf("day"),
            },
            segment_config: {start_time, end_time},
            schedulingConfig: scheduling,
            timeLimitsConfig: {
                time_limits,
                time_limits_in_day
            },
            terminationConfig: terminate_info,
            goal_event
        };

        this.debounce = debounce((fn, data) => {
            fn(data)
        }, 300);
    }

    handleUpdateAudienceType = (index) => {
        this.setState({
            selectedAudience: index,
            selectedEvents: index === 1 ? [] : this.state.selectedEvents,
            segment_id: index === 0 ? "" : this.state.segment_id,
            schedulingConfig: {
                ...this.state.schedulingConfig,
                rollSegment: index === 1 ? false : this.state.schedulingConfig.rollSegment,
            }
        }, () => {
            // Select All Users
            if (index === 0) {
                this.props.updateInFlow({
                    audience: {
                        ...this.props.messages2.new_message.audience,
                        segment_id: "",
                        audience_type: "ALL"
                    },
                    overall_cfg: {
                        ...this.props.messages2.new_message.overall_cfg,
                        events: []
                    },
                    scheduling: {
                        ...this.props.messages2.new_message.scheduling,
                        rollSegment: false
                    }
                })
            }

            // Select Segment
            if (index === 1) {
                this.props.updateInFlow({
                    audience: {
                        ...this.props.messages2.new_message.audience,
                        segment_id: "",
                        audience_type: "ALL"
                    },
                    overall_cfg: {
                        ...this.props.messages2.new_message.overall_cfg,
                        events: []
                    },
                })
            }
        })
    };

    getPropertiesOfSegment = (segment_id, category) => {
        const {appState: {appSegments = []}} = this.props;
        let pickedSegmentsList = appSegments.filter(o => o._id === segment_id);
        return pickedSegmentsList.length > 0 ? pickedSegmentsList[0][category] : [];
    };

    render() {
        const {hidden, appState: {appSegments = [], appEvents = []}} = this.props;
        const {selectedAudience, selectedEvents, displayFrequency, schedulingConfig, timeLimitsConfig, overallConfig, terminationConfig} = this.state;
        const {segment_config: {start_time, end_time}} = this.state;
        const {
            segment_id,
            goal_event,
            rules_section: {
                start_date, end_date
            }
        } = this.state;

        const pastDates = segment_id && !schedulingConfig.rollSegment;
        const pastDays = segment_id && schedulingConfig.isRecurEnabled && schedulingConfig.rollSegment;

        return (
            <div>
                {
                    !hidden && <Grid container spacing={32}>
                        <Grid item xs={6}>
                            <FieldSection>
                                <FieldDescription title={"Target Audience"}/>
                                <OptionSelect
                                    style={{paddingTop: 12, paddingBottom: 12}}
                                    noneSelectable={false}
                                    direction={"horizontal"}
                                    numPerRow={2}
                                    value={selectedAudience}
                                    radioOptions={{
                                        hasCheckboxOrRadio: true,
                                        radioFirst: true
                                    }}
                                    handler={(selectedIndex) => this.handleUpdateAudienceType(selectedIndex)}
                                    options={AUDIENCE_TYPES}
                                    cardStyles={{paddingLeft: 6, minWidth: 128}}
                                    fieldTitleStyles={{marginBottom: 0, marginLeft: 6}}
                                />
                                <DummyInsetContainer>
                                    {
                                        selectedAudience === 1 && <ClassicCard>
                                            <Grid container justify={"space-between"}>
                                                <Grid item xs>
                                                    <MultiSelect
                                                        options={appSegments.map(segment => ({
                                                            label: segment.name,
                                                            value: segment._id
                                                        }))}
                                                        placeholder={"Select Segment"}
                                                        label={"Select Segment"}
                                                        single
                                                        value={segment_id}
                                                        handleChange={(segmentId) => {
                                                            this.setState({
                                                                segment_id: segmentId
                                                            }, () => {
                                                                this.props.updateInFlow({
                                                                    audience: {
                                                                        ...this.props.messages2.new_message.audience,
                                                                        segment_id: segmentId
                                                                    }
                                                                })
                                                            })
                                                        }}
                                                    />
                                                    {
                                                        segment_id &&
                                                        <DummyInsetContainer style={{marginTop: 12, marginBottom: 0}}>
                                                            <Typography style={{
                                                                marginRight: 8,
                                                                marginBottom: 8,

                                                            }}>
                                                                Filter Audience By
                                                            </Typography>
                                                            <SegmentConfig
                                                                user={this.getPropertiesOfSegment(segment_id, "user")}
                                                                session={this.getPropertiesOfSegment(segment_id, "session")}
                                                                event={this.getPropertiesOfSegment(segment_id, "event")}
                                                            />
                                                        </DummyInsetContainer>
                                                    }
                                                    {
                                                        pastDates
                                                        && <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: 16
                                                                }}
                                                            >
                                                                <Typography style={{marginRight: 8}}>
                                                                    between
                                                                </Typography>
                                                                <MuiPickersUtilsProvider
                                                                    utils={MomentUtils}
                                                                    moment={moment}
                                                                >
                                                                    <DatePicker
                                                                        value={start_time}
                                                                        disableFuture
                                                                        maxDate={end_time}
                                                                        format={"LL"}
                                                                        autoOk
                                                                        onChange={date => {
                                                                            this.setState({
                                                                                segment_config: {
                                                                                    ...this.state.segment_config,
                                                                                    start_time: moment(date),
                                                                                    end_time
                                                                                }
                                                                            }, () => {
                                                                                const reqDate = moment(date);
                                                                                Apxor.logEvent("SelectCampaignStartDate", {date: reqDate.format("LLL")}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                                                                                this.props.updateInFlow({
                                                                                    audience: {
                                                                                        ...this.props.messages2.new_message.audience,
                                                                                        start_time: reqDate.toISOString(),
                                                                                        end_time
                                                                                    },
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                <Typography style={{marginRight: 8, marginLeft: 8}}>
                                                                    and
                                                                </Typography>
                                                                <MuiPickersUtilsProvider
                                                                    utils={MomentUtils}
                                                                    moment={moment}
                                                                >
                                                                    <DatePicker
                                                                        value={end_time}
                                                                        disableFuture
                                                                        format={"LL"}
                                                                        minDate={start_time}
                                                                        autoOk
                                                                        onChange={date => {
                                                                            this.setState({
                                                                                segment_config: {
                                                                                    ...this.state.segment_config,
                                                                                    start_time,
                                                                                    end_time: moment(date),
                                                                                }
                                                                            }, () => {
                                                                                const reqDate = moment(date);
                                                                                this.props.updateInFlow({
                                                                                    audience: {
                                                                                        ...this.props.messages2.new_message.audience,
                                                                                        start_time,
                                                                                        end_time: reqDate.toISOString(),
                                                                                    },
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                            <Divider style={{marginTop: 12}}/>
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                color: "#929292",
                                                                marginTop: 6
                                                            }}>
                                                                <InfoIcon style={{color: "#929292", marginRight: 6}}/>
                                                                <Typography style={{color: "#929292"}}>
                                                                    To enable Rolling Window of Users, select <b>Recur
                                                                    Campaign</b> & <b>Roll Segment</b>
                                                                </Typography>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        pastDays
                                                        && <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: 16
                                                                }}
                                                            >
                                                                <Typography>
                                                                    in the previous
                                                                </Typography>
                                                                <ActionsTextField
                                                                    value={schedulingConfig.rollInterval}
                                                                    style={{
                                                                        maxWidth: 72,
                                                                        marginBottom: 0,
                                                                        margin: "0 6px"
                                                                    }}
                                                                    InputProps={{min: 1}}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            schedulingConfig: {
                                                                                ...schedulingConfig,
                                                                                rollInterval: Number(e.target.value)
                                                                            }
                                                                        });
                                                                        this.debounce(this.props.updateInFlow, {
                                                                            scheduling: {
                                                                                ...schedulingConfig,
                                                                                rollInterval: Number(e.target.value)
                                                                            }
                                                                        })
                                                                    }}
                                                                    type={"number"}
                                                                />
                                                                <Typography>
                                                                    {schedulingConfig.rollInterval > 1 ? "days" : "day"}
                                                                </Typography>
                                                            </div>
                                                            <Divider style={{marginTop: 12}}/>
                                                            <div style={{
                                                                display: "flex",
                                                                color: "#929292",
                                                                alignItems: "center",
                                                                marginTop: 6
                                                            }}>
                                                                <InfoIcon style={{color: "#929292", marginRight: 6}}/>
                                                                <Typography style={{color: "#929292"}}>
                                                                    To pick Audience from a fixed time period,
                                                                    unselect <b>Roll Segment</b>
                                                                </Typography>
                                                            </div>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </ClassicCard>
                                    }
                                    {
                                        selectedAudience === 0 && <ClassicCard>
                                            <MultiSelect
                                                options={appEvents.map(eventName => ({
                                                    label: eventName,
                                                    value: eventName
                                                }))}
                                                value={selectedEvents}
                                                label={"Users who didn't perform"}
                                                handleChange={(events) => {
                                                    this.setState({
                                                        selectedEvents: events
                                                    }, () => {
                                                        let updatedEventsList = [];
                                                        if (Array.isArray(events)) {
                                                            events.forEach((event) => {
                                                                updatedEventsList.push({
                                                                    name: event,
                                                                    op: LOGICAL_OPERATORS.LT,
                                                                    value: 1
                                                                })
                                                            });
                                                        }
                                                        this.props.updateInFlow({
                                                            overall_cfg: {
                                                                ...this.props.messages2.new_message.overall_cfg,
                                                                events: updatedEventsList
                                                            }
                                                        })
                                                    })
                                                }}
                                            />
                                        </ClassicCard>
                                    }
                                </DummyInsetContainer>
                            </FieldSection>
                            <FieldSection>
                                <FieldDescription title={"Filter Users by Timeline"}/>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <FormControlLabel
                                        label={"Retention Day"}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={overallConfig.toggleRetDay}
                                                onChange={(e) => {
                                                    this.setState({
                                                        overallConfig: {
                                                            ...overallConfig,
                                                            ret_day: e.target.checked ? ({
                                                                from: 0,
                                                                to: 7
                                                            }) : {},
                                                            toggleRetDay: e.target.checked
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        overall_cfg: {
                                                            ...overallConfig,
                                                            ret_day: e.target.checked ? ({
                                                                from: 0,
                                                                to: 7
                                                            }) : {},
                                                            toggleRetDay: e.target.checked
                                                        }
                                                    })
                                                }}
                                                disableRipple
                                            />
                                        }
                                    />
                                    {
                                        overallConfig.toggleRetDay &&
                                        <>
                                            <Typography>
                                                between
                                            </Typography>
                                            <ActionsTextField
                                                value={overallConfig.ret_day.from}
                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                InputProps={{min: 0, max: overallConfig.ret_day.to}}
                                                placeholder={"from"}
                                                type={"number"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        overallConfig: {
                                                            ...overallConfig,
                                                            ret_day: {
                                                                ...overallConfig.ret_day,
                                                                from: Number(e.target.value)
                                                            }
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        overall_cfg: {
                                                            ...overallConfig,
                                                            ret_day: {
                                                                ...overallConfig.ret_day,
                                                                from: Number(e.target.value)
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            <Typography> and </Typography>
                                            <ActionsTextField
                                                value={overallConfig.ret_day.to}
                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                InputProps={{min: overallConfig.ret_day.from}}
                                                placeholder={"to"}
                                                type={"number"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        overallConfig: {
                                                            ...overallConfig,
                                                            ret_day: {
                                                                ...overallConfig.ret_day,
                                                                to: Number(e.target.value)
                                                            }
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        overall_cfg: {
                                                            ...overallConfig,
                                                            ret_day: {
                                                                ...overallConfig.ret_day,
                                                                to: Number(e.target.value)
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            <Typography>
                                                of Timeline
                                            </Typography>
                                        </>
                                    }
                                </div>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <FormControlLabel
                                        label={"User Sessions"}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={overallConfig.toggleSession}
                                                onChange={(e) => {
                                                    this.setState({
                                                        overallConfig: {
                                                            ...overallConfig,
                                                            session: e.target.checked ? {
                                                                from: 0,
                                                                to: 7, ...overallConfig.session
                                                            } : {},
                                                            toggleSession: e.target.checked
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        overall_cfg: {
                                                            ...overallConfig,
                                                            session: e.target.checked ? {
                                                                from: 0,
                                                                to: 7, ...overallConfig.session
                                                            } : {},
                                                            toggleSession: e.target.checked
                                                        }
                                                    })
                                                }}
                                                disableRipple
                                            />
                                        }
                                    />
                                    <Typography>
                                        {overallConfig.toggleSession ? "between" : ""}
                                    </Typography>
                                    {
                                        overallConfig.toggleSession &&
                                        <>
                                            <ActionsTextField
                                                value={overallConfig.session.from}
                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                InputProps={{min: 0, max: overallConfig.session.to}}
                                                placeholder={"from"}
                                                type={"number"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        overallConfig: {
                                                            ...overallConfig,
                                                            session: {
                                                                ...overallConfig.session,
                                                                from: Number(e.target.value)
                                                            }
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        overall_cfg: {
                                                            ...overallConfig,
                                                            session: {
                                                                ...overallConfig.session,
                                                                from: Number(e.target.value)
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            <Typography> and </Typography>
                                            <ActionsTextField
                                                value={overallConfig.session.to}
                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                InputProps={{min: overallConfig.session.from}}
                                                placeholder={"to"}
                                                type={"number"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        overallConfig: {
                                                            ...overallConfig,
                                                            session: {
                                                                ...overallConfig.session,
                                                                to: Number(e.target.value)
                                                            }
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        overall_cfg: {
                                                            ...overallConfig,
                                                            session: {
                                                                ...overallConfig.session,
                                                                to: Number(e.target.value)
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            <Typography>
                                                of Timeline
                                            </Typography>
                                        </>
                                    }
                                </div>
                            </FieldSection>
                        </Grid>
                        <Grid item xs={6} style={{borderLeft: "1px solid rgba(0,0,0,0.12)"}}>
                            <FieldSection>
                                <FieldDescription title={"Campaign Duration"}/>
                                <Grid container spacing={8}>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Typography variant={"subheading"}>
                                            Start Date
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                            <DateTimePicker
                                                value={moment(start_date)}
                                                disablePast
                                                ampm={false}
                                                format={"LLL"}
                                                onChange={date => {
                                                    this.setState({
                                                        rules_section: {
                                                            ...this.state.rules_section,
                                                            start_date: moment(date),
                                                        }
                                                    }, () => {
                                                        const reqDate = moment(date);

                                                        this.props.updateInFlow({
                                                            validity: {
                                                                ...this.props.messages2.new_message.validity,
                                                                start_date: reqDate.toISOString(),
                                                            },
                                                            scheduling: {
                                                                ...schedulingConfig,
                                                                schedule_date: reqDate.format("YYYY-MM-DD"),
                                                                schedule_time: reqDate.format("HH:mm")
                                                            }
                                                        })
                                                    })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Typography variant={"subheading"}>
                                            End Date
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                            <DateTimePicker
                                                value={moment(end_date)}
                                                disablePast
                                                minDate={start_date}
                                                minDateMessage={"End Date should not be earlier than Start Date"}
                                                ampm={false}
                                                format={"LLL"}
                                                onChange={date => {
                                                    this.setState({
                                                        rules_section: {
                                                            ...this.state.rules_section,
                                                            end_date: moment(date)
                                                        }
                                                    }, () => {
                                                        this.props.updateInFlow({
                                                            validity: {
                                                                ...this.props.messages2.new_message.validity,
                                                                end_date: moment(date).toISOString(),
                                                            },
                                                        })
                                                    })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>

                                <FormControlLabel
                                    label={"Respect DnD"}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={timeLimitsConfig.time_limits_in_day}
                                            onChange={(e) => {
                                                this.setState({
                                                    timeLimitsConfig: {
                                                        ...timeLimitsConfig,
                                                        time_limits_in_day: e.target.checked
                                                    }
                                                })
                                                this.debounce(this.props.updateInFlow, {
                                                    time_limits_in_day: e.target.checked
                                                })
                                            }}
                                            disableRipple
                                        />
                                    }
                                />
                                {
                                    timeLimitsConfig.time_limits_in_day
                                    && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Typography style={{marginRight: 8}}>
                                            Between
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                            <TimePicker
                                                value={moment(timeLimitsConfig.time_limits.start_time, "HH:mm")}
                                                onChange={(time) => {
                                                    const start_time = time.format("HH:mm");
                                                    this.setState({
                                                        timeLimitsConfig: {
                                                            ...timeLimitsConfig,
                                                            time_limits: {
                                                                ...timeLimitsConfig.time_limits,
                                                                start_time
                                                            }
                                                        }
                                                    })

                                                    this.debounce(this.props.updateInFlow, {
                                                        time_limits: {
                                                            ...timeLimitsConfig.time_limits,
                                                            start_time
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                        <Typography style={{marginRight: 8, marginLeft: 8}}>
                                            and
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                            <TimePicker
                                                value={moment(timeLimitsConfig.time_limits.end_time, "HH:mm")}
                                                onChange={(time) => {
                                                    const end_time = time.format("HH:mm");
                                                    this.setState({
                                                        timeLimitsConfig: {
                                                            ...timeLimitsConfig,
                                                            time_limits: {
                                                                ...timeLimitsConfig.time_limits,
                                                                end_time
                                                            }
                                                        }
                                                    })

                                                    this.debounce(this.props.updateInFlow, {
                                                        time_limits: {
                                                            ...timeLimitsConfig.time_limits,
                                                            end_time
                                                        }
                                                    })
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                }
                            </FieldSection>
                            <FieldSection>
                                <FieldDescription title={"Campaign Recurring"}/>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <FormControlLabel
                                        label={"Recur Campaign"}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={schedulingConfig.isRecurEnabled}
                                                onChange={(e) => {
                                                    this.setState({
                                                        schedulingConfig: {
                                                            ...schedulingConfig,
                                                            isRecurEnabled: e.target.checked
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        scheduling: {
                                                            ...schedulingConfig,
                                                            isRecurEnabled: e.target.checked
                                                        }
                                                    })
                                                }}
                                                disableRipple
                                            />
                                        }
                                    />
                                    {
                                        schedulingConfig.isRecurEnabled &&
                                        <>
                                            <Typography>
                                                every
                                            </Typography>
                                            <ActionsTextField
                                                value={schedulingConfig.step}
                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                InputProps={{min: 0}}
                                                onChange={(e) => {
                                                    this.setState({
                                                        schedulingConfig: {
                                                            ...schedulingConfig,
                                                            step: Number(e.target.value)
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        scheduling: {
                                                            ...schedulingConfig,
                                                            step: Number(e.target.value)
                                                        }
                                                    })
                                                }}
                                                type={"number"}/>
                                            <CustomNativeSelect
                                                options={RECUR_FREQUENCY_ENUM}
                                                defaultValue={schedulingConfig.recur}
                                                style={{margin: "0 6px"}}
                                                itemName={"style"}
                                                handleChange={(event) => {
                                                    this.setState({
                                                        schedulingConfig: {
                                                            ...schedulingConfig,
                                                            recur: event.target.value
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        scheduling: {
                                                            ...schedulingConfig,
                                                            recur: event.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                            <Typography> during the campaign period</Typography>
                                        </>
                                    }
                                </div>
                                {
                                    segment_id !== ""
                                    && schedulingConfig.isRecurEnabled
                                    && <FormControlLabel
                                        label={"Roll Segment"}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={schedulingConfig.rollSegment}
                                                onChange={(e) => {
                                                    this.setState({
                                                        schedulingConfig: {
                                                            ...schedulingConfig,
                                                            rollSegment: e.target.checked
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        scheduling: {
                                                            ...schedulingConfig,
                                                            rollSegment: e.target.checked
                                                        }
                                                    })
                                                }}
                                                disableRipple
                                            />
                                        }
                                    />
                                }
                            </FieldSection>

                            <FieldSection>
                                <FieldDescription title={"Campaign Limits"}/>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <Typography>Limit to displaying </Typography>
                                    <ActionsTextField
                                        value={displayFrequency.count}
                                        style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                        InputProps={{min: 0}}
                                        onChange={(e) => {
                                            this.setState({
                                                displayFrequency: {
                                                    ...displayFrequency,
                                                    count: Number(e.target.value)
                                                }
                                            });
                                            this.debounce(this.props.updateInFlow, {
                                                frequency: {
                                                    ...displayFrequency,
                                                    count: Number(e.target.value)
                                                }
                                            })
                                        }}
                                        type={"number"}/>
                                    <Typography>time(s)</Typography>
                                    <CustomNativeSelect
                                        options={FREQUENCY_ENUM}
                                        defaultValue={displayFrequency.validity}
                                        style={{margin: "0 6px"}}
                                        itemName={"style"}
                                        handleChange={(event) => {
                                            this.setState({
                                                displayFrequency: {
                                                    ...displayFrequency,
                                                    validity: event.target.value
                                                }
                                            });
                                            this.debounce(this.props.updateInFlow, {
                                                frequency: {
                                                    ...displayFrequency,
                                                    validity: event.target.value
                                                }
                                            })
                                        }}
                                    />
                                    <Typography>per User</Typography>
                                </div>
                                <div
                                    style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: 6}}>
                                    <FormControlLabel
                                        label={"Auto Dismiss Campaign"}
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={terminationConfig.auto_dismiss}
                                                onChange={(e) => {
                                                    this.setState({
                                                        terminationConfig: {
                                                            ...terminationConfig,
                                                            auto_dismiss: e.target.checked
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        terminate_info: {
                                                            ...terminationConfig,
                                                            auto_dismiss: e.target.checked
                                                        }
                                                    })
                                                }}
                                                disableRipple
                                            />
                                        }
                                    />
                                    {
                                        terminationConfig.auto_dismiss
                                        && <>
                                            <Typography>
                                                within
                                            </Typography>
                                            <ActionsTextField
                                                value={terminationConfig.duration / 1000}
                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                InputProps={{min: 1, max: 10}}
                                                onChange={(e) => {
                                                    this.setState({
                                                        terminationConfig: {
                                                            ...terminationConfig,
                                                            duration: Number(e.target.value) * 1000
                                                        }
                                                    });
                                                    this.debounce(this.props.updateInFlow, {
                                                        terminate_info: {
                                                            ...terminationConfig,
                                                            duration: Number(e.target.value) * 1000
                                                        }
                                                    })
                                                }}
                                                type={"number"}
                                            />
                                            <Typography>
                                                {terminationConfig.duration / 1000 === 1 ? "second" : "seconds"}
                                            </Typography>
                                        </>
                                    }
                                </div>
                            </FieldSection>

                            <FieldSection>
                                <FieldDescription title={"Goal Event"}/>
                                <DummyInsetContainer>
                                    <ClassicCard>
                                        <Grid container justify={"space-between"}>
                                            <Grid item xs>
                                                <MultiSelect
                                                    options={appEvents.map(event => ({label: event, value: event}))}
                                                    placeholder={"Select Goal Event"}
                                                    single
                                                    value={goal_event}
                                                    handleChange={(goal_event) => {
                                                        this.setState({
                                                            goal_event
                                                        }, () => {
                                                            this.props.updateInFlow({
                                                                goal_event
                                                            })
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ClassicCard>
                                </DummyInsetContainer>
                            </FieldSection>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

export default withStyles(page4_styles())(Page4);