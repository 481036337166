/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 11:03 AM
 */

import React from 'react';
import {updateHeading} from "../../../../../../Navigation/actions";
import ClassicCard from "../../ClassicCard";
import {APP_PLATFORMS} from "../../../../../../../../../../constants";
import {withStyles, Grid, Icon, ButtonBase} from "@material-ui/core";
import ActionsTextField from "../components/ActionsTextField";
import FieldDescription from "../components/FieldDescription";
import FieldSection from "../components/FieldSection";
import debounce from 'lodash/debounce';
import {PLATFORM_IMAGES} from "../utils";

const MESSAGE_MODES = {
    "INAPP": "INAPP",
    "COACHMARK": "COACHMARK",
    "TOOLTIP": "TOOLTIP",
    "PASSIVE_NUDGE": "PASSIVE_NUDGE",
    "WALKTHROUGH": "WALKTHROUGH"
};

const TEMPLATES = {
    "INAPP": [
        "MODAL",
        "COVER",
        "INTERSTITIAL",
        "STICKY_TOP",
        "STICKY_BOTTOM"
    ],
    "COACHMARK": [
        "COACHMARK_TYPE_2",
        "COACHMARK_TYPE_1"
    ],
    "TOOLTIP": [
        "TOOLTIP_TYPE_1",
        "TOOLTIP_TYPE_2"
    ],
    "PASSIVE_NUDGE": [
        "NUDGE_TYPE_1"
    ],
    "WALKTHROUGH": [
        "WALKTHROUGH_TYPE_1"
    ]
};

const page1_styles = (theme) => ({});

const getDefaultPlatform = (appPlatform, messagePlatform) => {
    if (messagePlatform !== "") {
        return messagePlatform
    } else if (appPlatform === APP_PLATFORMS.omni) {
        return APP_PLATFORMS.android
    }
};

class Page1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageMode: null,
            messageTemplate: null,
            meta: {
                platform: null,
                name: "",
                description: ""
            }
        };

        this.debounce = debounce((fn, data) => {
            fn(data)
        }, 300)
    }

    handleStateChange = (data) => {
        this.setState({
            ...this.state, ...data
        });
        this.debounce(this.props.updateInFlow, {...data})
    };

    handleUpdateCampaignName = (name) => {
        if (name !== "") {
            this.debounce(this.props.bindedDispatch, updateHeading("*" + name));
        } else {
            this.debounce(this.props.bindedDispatch, updateHeading("New Message Campaign"));
        }
    };

    UNSAFE_componentWillMount() {
        const {appState: {app: {basic_info: {platform: AppPlatform}}}} = this.props;
        const {messages2: {new_message: {meta: {name = "", description = "", platform}, meta}}} = this.props;
        this.handleStateChange({
            meta: {
                ...meta,
                platform: getDefaultPlatform(AppPlatform, platform),
                name,
                description
            }
        });
    }

    render() {
        const {messageMode, messageTemplate, meta: {name = "", description = "", platform}} = this.state;
        const {hidden, appPlatform} = this.props;
        return (
            <div>
                {!hidden &&
                <FieldSection>
                    <Grid container>
                        <Grid item xs={6} md={6}>
                            <FieldDescription
                                title={"Platform"}
                                description={"Select the target Platform for this Campaign"}
                            />
                            <Grid container spacing={32} style={{marginTop: 8}}>
                                {
                                    Object.keys(APP_PLATFORMS)
                                    .filter(platform => {
                                        return [APP_PLATFORMS.ios, APP_PLATFORMS.android].includes(platform);
                                    })
                                    .map((plat) => {
                                        const fontColor = plat === platform ? "#4177F6" : "#6f6f6f";
                                        return (
                                            <Grid item key={"select-card-" + plat}>
                                                <ButtonBase
                                                    style={{
                                                        borderRadius: 10,
                                                    }}
                                                    disableRipple disableTouchRipple
                                                    disabled={appPlatform !== APP_PLATFORMS.omni}
                                                    onClick={() => {
                                                        this.handleStateChange({
                                                            meta: {
                                                                ...this.props.messages2.new_message.meta,
                                                                platform: plat
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <ClassicCard
                                                        style={{minWidth: 64}}
                                                        selected={plat === platform}
                                                        disabled={appPlatform !== APP_PLATFORMS.omni}
                                                    >
                                                        {/*<i style={{fontSize: 32, color: fontColor}}*/}
                                                        {/*   className={PLATFORM_IMAGES[plat]}/>*/}
                                                        {PLATFORM_IMAGES(plat, fontColor)}
                                                    </ClassicCard>
                                                </ButtonBase>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <section style={{maxWidth: 480}}>
                                <FieldDescription title={"Campaign Name"} required/>
                                <ActionsTextField
                                    placeholder={"Enter Campaign Name"}
                                    value={name}
                                    required
                                    onChange={(e) => {
                                        this.handleStateChange({
                                            meta: {
                                                ...this.props.messages2.new_message.meta,
                                                name: e.target.value
                                            }
                                        });
                                    }}/>
                                <FieldDescription title={"Campaign Description"}/>
                                <ActionsTextField
                                    placeholder={"Add more details like Purpose of this Campaign, etc."}
                                    value={description}
                                    onChange={(e) => {
                                        this.handleStateChange({
                                            meta: {
                                                ...this.props.messages2.new_message.meta,
                                                description: e.target.value
                                            }
                                        })
                                    }}/>
                            </section>
                        </Grid>
                    </Grid>
                </FieldSection>
                }
            </div>
        )
    }
}

export default withStyles(page1_styles)(Page1);