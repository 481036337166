/**
 * Created by Rakesh Peela
 * Date: 10-Apr-2020
 * Time: 11:00 PM
 */
import {
    Button,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    withStyles
} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import {connect} from "react-redux";
import Loading from "../../../../../../components/reusable/Loading";
import {deleteBot, getBots, updateBot} from "./actions";

function mapDispatchToProps(dispatch) {
    return {
        getBots: (appId) => dispatch(getBots(appId)),
        updateBot: (appId, bot) => dispatch(updateBot(appId, bot)),
        deleteBot: (appId, bot) => dispatch(deleteBot(appId, bot)),
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        meta: state.meta,
        appsState: state.apps,
        appState: state.app,
    }

}

const botsListStyle = () => {
    return {
        botItemSelected: {
            borderRadius: 4,
            transition: "all 0.1s ease-in-out",
            background: "#f6f7ff",
            border: "1px solid #9fa8da",
        },
        botItem: {
            borderRadius: 4,
            transition: "all 0.1s ease-in-out",
            border: '1px solid transparent',
            '&:hover': {
                background: "#f6f7ff",
                border: "1px solid #9fa8da",
            }
        }
    }
}

class BotsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBot: null,
            transitionPending: false
        }
    }

    componentDidMount() {
        const {appId, getBots} = this.props;
        getBots(appId);
    }

    render() {
        const {
            appsState: {
                bots = [],
                bots_pending = false
            }, appId, classes, app,
        } = this.props;
        const {selectedBot, transitionPending} = this.state;
        return (
            <div>
                <div style={{textAlign: "center"}}>
                    {
                        bots_pending && <div style={{display: "flex", flexDirection: "column"}}>
                            <Loading size={15}/>
                            <Typography variant={"subheading"} style={{marginTop: 12, marginBottom: 12}}>
                                Loading ...
                            </Typography>
                        </div>
                    }
                    {
                        !bots_pending && bots && (!bots[appId] || bots[appId].length === 0)
                        && <Typography variant={"subtitle1"}> No Bots found </Typography>
                    }
                    {
                        !bots_pending && bots && bots[appId] && bots[appId].length > 0
                        && <List>
                            {
                                bots[appId].map(bot =>
                                    <ListItem
                                        classes={{root: (selectedBot === bot.version) ? classes.botItemSelected : classes.botItem}}
                                        key={bot.version}
                                        button
                                        disableRipple
                                        disableTouchRipple
                                        focusRipple={false}
                                        onClick={() => {
                                            this.setState({
                                                selectedBot: bot.version
                                            })
                                        }}
                                    >
                                        <ListItemText
                                            primary={bot.name}
                                            secondary={bot.version}
                                        />
                                        {/*<div style={{display: "flex", justifyContent: "space-between"}}>*/}
                                        {/*<Button aria-label="Edit">*/}
                                        {/*    <EditIcon/>*/}
                                        {/*</Button>*/}
                                        {/*</div>*/}
                                        {(selectedBot === bot.version) && <ListItemSecondaryAction>
                                            <Checkbox color={"primary"} checked={selectedBot === bot.version}/>
                                        </ListItemSecondaryAction>}
                                    </ListItem>
                                )
                            }
                        </List>
                    }
                </div>
                <Divider/>
                <div style={{display: "flex", justifyContent: "space-between", paddingTop: 12}}>
                    <Button disabled={transitionPending} onClick={() => {
                        this.props.handleClose(null);
                    }}>
                        Cancel
                    </Button>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        disabled={(transitionPending || !selectedBot)}
                        onClick={() => {
                            this.setState({
                                transitionPending: true
                            })
                            this.props.handleNavigation(app, selectedBot)
                        }}
                    >
                        Analyse {transitionPending ? <Loading size={16} style={{marginLeft: 8}}/> :
                        <ArrowForwardIcon style={{marginLeft: 8}}/>}
                    </Button>
                </div>
            </div>
        );
    }
}

BotsList = withStyles(botsListStyle)(BotsList);
export default connect(mapStateToProps, mapDispatchToProps)(BotsList);