/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, {Component, Fragment,} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../../Navigation/actions';
import { SurveyHolder } from './components';
import {
    getSurveys, updateSurvey,
    saveSurvey, updateCurrentSurvey, resetSurveys
} from '../actions';
import ActionCreator from "../../../../../../../components/reusable/ActionCreator";
import {ART_CONFIG_TYPES} from "../../../../../../../constants";
import Button from '@material-ui/core/Button';
import {getGroupFromList} from "../../../../../../../utils";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import {Link} from "react-router";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        filters: state.filters,
        meta: state.meta,
        surveys: state.surveys
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        saveSurvey: (appId, survey) => dispatch(saveSurvey(appId, survey)),
        updateCurrentSurvey: (survey) => dispatch(updateCurrentSurvey(survey)),
        getSurveys: (appId) => dispatch(getSurveys(appId)),
        updateSurvey: (appId, surveyId, survey) => dispatch(updateSurvey(appId, surveyId, survey)),
        resetSurveys: () => dispatch(resetSurveys())
    };
}

class Survey extends Component {

    constructor(props){
        super(props);
        const { surveys: { survey } } = props;
        this.state = {
            ...survey,
            updated: false
        };
    }

    componentWillMount(){
        const { params: { appId, surveyId }, updateHeading, surveys: { surveys = [] }, getSurveys } = this.props;
        updateHeading("New Survey");
        if(surveys.length === 0){
            getSurveys(appId);
        }else{
            if(surveyId){
                const survey = getGroupFromList(surveys, surveyId);
                if(survey){
                    updateCurrentSurvey(survey);
                    updateHeading(survey.meta.name);
                }else{
                    this.context.router.push(`/apps/${appId}/surveys`);
                }
            }else{
                updateHeading("New Survey");
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { params: {appId, surveyId }, surveys: { surveys = [], survey, survey_saved}, updateHeading, updateCurrentSurvey } = nextProps;
        if(surveyId){
            if(surveys.length > 0 && (!survey.hasOwnProperty("_id") || surveyId !== survey._id)){
                const survey = getGroupFromList(surveys, surveyId);
                if(survey){
                    updateCurrentSurvey(survey);
                    updateHeading(survey.meta.name);
                }else{
                    this.context.router.push(`/apps/${appId}/surveys`);
                }
            }
        }
        if(survey_saved){
            setTimeout(() => {
                this.context.router.push(`/apps/${appId}/surveys?saved=true&surveyName=${this.state.meta.name}`);
            }, 2000);
        }
    }

    componentWillUnmount(){
        this.props.resetSurveys();
    }

    saveSurvey = () => {
        const { meta, conditions = [], updated, ...others } = this.state;
        if(conditions.length > 0){
            const { params: { appId }, saveSurvey } = this.props;
            meta.config_type = ART_CONFIG_TYPES.SURVEY;
            meta.type = "SURVEY"; //FIXME: get enum
            saveSurvey(appId, {...others, conditions, meta, published: false});
        }
    };

    updateSurvey = () => {
        const { meta, conditions = [], updated, published = false, ...others } = this.state;
        if(conditions.length > 0){
            const { params: { appId, surveyId }, updateSurvey } = this.props;
            meta.config_type = ART_CONFIG_TYPES.SURVEY;
            meta.type = "SURVEY"; //FIXME: get enum idiot
            updateSurvey(appId, surveyId, {...others, conditions, meta, published});
        }
    };

    isReadyToSend = () => {
        const { meta, conditions, ui } = this.state;
        const hasName = meta && meta.name && meta.name.length > 0;
        const hasConditions = Array.isArray(conditions) && conditions.length > 0;
        const hasUI = ui && Array.isArray(ui.questions) && ui.questions.length > 0;
        return hasName && hasConditions && hasUI;
    };

    handleExistingSurveyUpdate = (survey) => {
        this.setState({...survey, updated: true});
    };

    handleNewSurveyUpdate = (survey) => {
        this.setState(survey);
    };

    render() {
        const {
            params: { appId, surveyId },
            surveys: {
                survey, survey_updated, survey_saved,
                survey_save_failed, survey_update_failed,
                survey_save_pending, survey_update_pending
            }
        } = this.props;
        const { updated } = this.state;
        return (
            <section className="content">
                {
                    !surveyId && <Fragment>
                        <ActionCreator {...this.props} action={this.state} handleActionUpdate={this.handleNewSurveyUpdate} enableAutoDismiss={false} requireRetentionConfig>
                            <SurveyHolder {...this.props} />
                        </ActionCreator>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={`/apps/${appId}/surveys`}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    style={{display: 'block', marginLeft: 'auto'}}
                                >Back</Button>
                            </Link>
                            <Button
                                disabled={survey_save_pending || !this.isReadyToSend() && !survey_saved }
                                color="primary"
                                variant="contained"
                                style={{marginLeft: 16}}
                                onClick={this.saveSurvey}
                            >Save</Button>
                        </div>
                    </Fragment>
                }
                {
                    surveyId && survey.hasOwnProperty("_id") && <Fragment>
                        <ActionCreator {...this.props} action={survey} handleActionUpdate={this.handleExistingSurveyUpdate} requireRetentionConfig>
                            <SurveyHolder {...this.props} ui={survey.ui} published={survey.published} />
                        </ActionCreator>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={`/apps/${appId}/surveys`}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    style={{display: 'block', marginLeft: 'auto'}}
                                >Back</Button>
                            </Link>
                            <Button
                                disabled={survey_update_pending || !this.isReadyToSend() && !updated }
                                color="primary"
                                variant="contained"
                                style={{marginLeft: 16}}
                                onClick={this.updateSurvey}
                            >Update</Button>
                        </div>
                        { survey_updated && <Snackbar>Survey Updated.</Snackbar> }
                    </Fragment>
                }
                {
                    survey_saved && <Snackbar>Survey saved successfully.</Snackbar>
                }
                {
                    survey_save_failed && <Snackbar>Saving Survey failed.</Snackbar>
                }
                {
                    survey_updated && <Snackbar>Survey updated successfully.</Snackbar>
                }
                {
                    survey_update_failed && <Snackbar>Updating Survey failed.</Snackbar>
                }
            </section>
        );
    }
}

Survey.propTypes = {

};

Survey.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Survey);