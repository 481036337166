import React, { Component, Fragment } from 'react';
import Switch from '../../../components/reusable/Switch';
import moment from 'moment';
import Placeholder from '../../../components/reusable/Placeholder';
import Loading from '../../../components/reusable/Loading';
import Snackbar from '@material-ui/core/Snackbar';
import ReactCohortGraph from 'react-cohort-graph';
import ComposedChart from '../../../components/reusable/Recharts/ComposedChart';
import Box from "../../reusable/Box";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Checkbox from '../../reusable/MaterialUi/Checkbox';

const DEFAULTS = {
    DEFAULT_SWITCH_DATA: [
        {
            name: "Days",
            value: "Day"
        },
        {
            name: "Weeks",
            value: "Week",
        },
        {
            name: "Months",
            value: "Month"
        }
    ],
    DEFAULT_TITLE: "Retention analysis",
    DEFAULT_DATE_DISPLAY_FORMAT: "MMM DD YYYY",
    DEFAULT_SWITCH_VALUE: "Days",
    DEFAULT_DATA_DISPLAY_FORMAT: "percent",
    DEFAULT_VALUE: "7 Days"
};

const getLabelText = (dataIndex, windowSize, timeframeWindow) => {
    if(windowSize > 1) {
        const upperLimit = dataIndex * windowSize + windowSize - 1;
        // return (dataIndex * windowSize) + "-" + (timeframeWindow < upperLimit ? timeframeWindow : upperLimit);
        return (dataIndex * windowSize) + "-" + upperLimit;
    }
    return dataIndex;
};

const formattedValue = (value) => {
    if(value.split(" ")[1])
     return value.split(" ")[1].toLowerCase();
    else
       return `${value.toLowerCase()}s`;
};

const formatDate = (date, dateDisplayFormat, inputDateFormat = null) => {
    if(! Date.parse(date))
        return date;
    return inputDateFormat ? moment(date, inputDateFormat).format(dateDisplayFormat) : moment(date).format(dateDisplayFormat);
};

const isCellCompleted = (date, index, dayWindow) => {
    return moment().diff(moment(date).add(dayWindow * index, "days")) < 0 ? " *" : "";
};

const formatter = (cellData, dayWindow, dateDisplayFormat, timeframeWindow, retention_header) => {
    const {
        isHeader, isLabel, isCell, index,
        label, valueType, isDate, valueFor, isHeaderValue,
        value, isTotal, isFixed
    } = cellData;

    if (isHeader && !isHeaderValue) {
        if (isHeader && isFixed) {
            return "Users";
        }
        if (dayWindow > 1) {
            const [first, index] = label.split(" ");
            if (index) {
                const numIndex = Number(index);
                return `${first} ` + getLabelText(numIndex - 1, dayWindow, timeframeWindow);
            }
            return first;
        }
        const [first, index] = label.split(" ");
        return first + " " + (Number(index) - 1);
    }

    if (isDate) {
        return formatDate(valueFor, dateDisplayFormat);
    }

    if (isHeader && isHeaderValue) {
        if (isFixed) {
            return cellData["value"];
        }
        if (index === retention_header.length) {
            return "NA";
        }
        const getLeast = (a, b) => {
            return a < b ? a : b
        };
        let starFixedValue = 0;
        if (retention_header[index-1] || retention_header.length < index - 1) {
            starFixedValue = getLeast(cellData["value"], retention_header[index - 1]["value"]);
            const starFixedFinalResult = (index === retention_header.length && starFixedValue === 0) ? "NA" : (starFixedValue + (starFixedValue < cellData["value"] ? "+" : ""));
            if (valueType === "percent") {
                return retention_header[index - 1]["percent"] + (` % (${starFixedFinalResult})`)
            } else {
                return starFixedFinalResult
            }
        }
        return "NA";
    }

    if (isCell) {
        return cellData[valueType] + (valueType === 'percent' ? " %" : "") + isCellCompleted(valueFor, index, dayWindow);
    }

    if (cellData["index"] === 0 && !isLabel) {
        return cellData["value"] + isCellCompleted(valueFor, index, dayWindow);
    }

    if (isTotal) {
        return value;
    }
};

const collateData = (data, dayWindow) => {
    if (dayWindow <= 1) return data;
    let keys = Object.keys(data.days);
    let newKeys = [];
    let obj = {};
    let res = {};

    for (let i = 0; i < keys.length; i = i + dayWindow) {
        newKeys.push(keys[i]);
        keys.slice(i, i + dayWindow).forEach((dateKey, index) => {
            try {
                obj[i].push(data.days[dateKey])
            } catch (e) {
                obj[i] = [];
                obj[i].push(data.days[dateKey])
            }
        })
    }
    Object.keys(obj).forEach((arr, index) => {
        res[newKeys[index]] = obj[arr].reduce((a, b) => a.map((x, i) => x + (b[i] ? b[i] : 0)))
    });

    return {
        days: {...res},
        weeks: {},
        months: {}
    }
};

//const daysRetention = [7, 30];

const DATA_DISPLAY_FORMAT_ENUM = {
    PERCENT: "percent",
    VALUE: "value"
};

const { VALUE, PERCENT } = DATA_DISPLAY_FORMAT_ENUM;

export default class RetentionGraph extends Component{

    constructor(props){
        super(props);
        this.state = {
            switchValue: props.switchValue || "Day",
            dataDisplayFormat: props.dataDisplayFormat || PERCENT,
            dataNotAvailable: false
        }
    }

    componentWillReceiveProps (newProps) {
        if(newProps.data !== this.props.data) {
            this.setState({
                dataDisplayFormat: PERCENT
            })
        }
    }

    getDataDisplayFormat = (value) => {
        this.setState({dataDisplayFormat: value});
    };

    getSwitchValue = (value) => {
        this.setState({switchValue: value});
    };

    handleDataAvailability = () => {
        this.setState({dataNotAvailable: true});
    };

    handleToggleClick = (format) => {
        const {getDataDisplayFormat = () => null} = this.props;
        this.setState({dataDisplayFormat: format}, () => getDataDisplayFormat(format));
    };

    render(){
        const { pending, failed, title, inputDateFormat, data, header, dateDisplayFormat, enableTooltip, groupWindow=false} =  this.props;
        const {switchValue, dataDisplayFormat} = this.state;
        const isDataAvailable = Object.keys(data).length > 0; //FIXME: Need proper check than this.
        return(
            <div className="row">
                <div className="col-md-12">
                    <Box title={title || DEFAULTS.DEFAULT_TITLE}
                         icon={<PeopleOutlineIcon/>}
                         withPadding
                         controls={
                             <Switch handleChange={this.handleToggleClick}
                                     data={[{name: "#", value: VALUE}, {name: "%", value: PERCENT}]}
                                     value={dataDisplayFormat}
                                     containerStyles={{margin: 0, minWidth: 120}}
                                     groupStyles={{minWidth: 10}}
                             />
                         }
                    >
                        {!pending && !failed && isDataAvailable &&
                        <RetentionTableBody
                            {...this.props}
                            dataDisplayFormat={dataDisplayFormat || DEFAULTS.DEFAULT_DATA_DISPLAY_FORMAT}
                            switchValue={switchValue || DEFAULTS.DEFAULT_SWITCH_VALUE}
                            inputDateFormat={inputDateFormat || null}
                            data={data}
                            header={header}
                            dateDisplayFormat={dateDisplayFormat || DEFAULTS.DEFAULT_DATE_DISPLAY_FORMAT}
                            enableTooltip={enableTooltip || false}
                            getDataDisplayFormat={this.getDataDisplayFormat}
                        />
                        }
                        { failed && <Placeholder/> }
                        { pending && <Loading/> }
                        <Snackbar
                            open={this.state.dataNotAvailable}
                            message="Data not available..!"
                            autoHideDuration={4000}
                            onRequestClose={() => this.setState({dataNotAvailable: false})}
                        />
                    </Box>
                </div>
            </div>
        )
    }
}

class RetentionTableBody extends Component{

    constructor(props){
        super(props);
        this.state = {
            retentionData: this.props.data || {},
            collatedData: collateData(this.props.data, this.props.windowSize) || {},
            groupWindows: false
        }
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.data !== null && nextProps.data !== undefined){
            let retentionData = nextProps.data;
            this.setState({retentionData: retentionData, collatedData: collateData(retentionData, nextProps.windowSize)});
        }
    };

    toggleGroupWindows = () => {
        this.setState({groupWindows: !this.state.groupWindows});
    };

    render(){
        const { dataDisplayFormat="percent", header, switchValue, windowSize, dateDisplayFormat, handleRetentionTrend, overallRetention, filters} = this.props;
        const { retentionData, collatedData, groupWindows } = this.state;
        let data = retentionData;
        let dataType = formattedValue(switchValue);
        const timeframeWindow = (moment(filters.till).diff(moment(filters.since), 'days'));

        return <Fragment>
            <ReactCohortGraph
                data={!groupWindows ? collatedData : data}  // FIXME: Inverted Behavior
                valueType={dataDisplayFormat}
                dataType={dataType}
                // checkboxHandler={this.toggleGroupWindows}
                cellFormatter={(cellData) => {
                    return formatter(cellData, windowSize || 1, dateDisplayFormat, timeframeWindow, header);
                }}
                onStoreUpdate={handleRetentionTrend}
                headerCellStyles={{
                    borderColor: '#FFFFFF'
                }}
                bodyCellStyles={{
                    padding: '15px 30px',
                    borderColor: '#FFFFFF',
                    textTransform: 'uppercase'
                }}
                shadeColor="#8E8FBF"
                tableStyles={{
                    borderLeft: 'none'
                }}
                tableHeadingStyles={{
                    border: 'none'
                }}
                showHeaderValues
            >
                <RetentionTrend overallRetention={overallRetention} windowSize={windowSize} timeframeWindow={timeframeWindow} headerData={header}/>
                <Checkbox label="Group Windows" checked={groupWindows} handleChange={this.toggleGroupWindows} disabled={windowSize <= 1}/>
            </ReactCohortGraph>
        </Fragment>
    }
}

class RetentionTrend extends Component {

    render(){
        const { dataStore, currentType,  overallRetention = [], windowSize, timeframeWindow, headerData } = this.props;
        let data = [], labelText="Day ";
        const lineDataKeys = [ "Retention" ];
        if(typeof dataStore.getHeader === 'function'){
            try {
                // const headers = dataStore.getHeader(currentType).slice(1); //removing first item
                if(Array.isArray(overallRetention) && overallRetention.length === headerData.length){
                    lineDataKeys.push("Overall Retention");
                }
                data = headerData.slice(1, headerData.length - 1).map((item, i) => ({
                    key: labelText + getLabelText(i, windowSize, timeframeWindow),
                    Retention: item["percent"],
                    "Overall Retention": overallRetention[i]
                }));
            } catch(e){
                console.error(e);
                data = []
            }
        }
        return(
            <ComposedChart
                height={350}
                valueLabel="Events"
                dataKey="key"
                data={data}
                lineDataKeys={lineDataKeys}
                title="Retention"
                withHeader={false}
                color="#519674"
                dot={true}
                showAverage={false}
                xLabelFormatter={key => key}
            />
        )
    }

}