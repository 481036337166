/**
 * Created by Rakesh Peela
 * Date: 13-Aug-2019
 * Time: 1:18 PM
 */

import {callApi} from "../../../../../api";
import {makeDefaultQueryString, withQueryStrings} from "../../../../../utils";

const UPDATE_PASSWORD_ENDPOINT = "update/password";
const UPDATE_PROFILE_INFO_ENDPOINT = "update/customerInfo";

export function updatePasswordAPI(auth, body) {
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json
    };
    const onFailureCallback = response => {
        return response
    };
    return callApi(UPDATE_PASSWORD_ENDPOINT, config, onSuccessCallback, onFailureCallback)
}

export function updateProfileDetailsAPI(auth, body) {
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        let profile = {
            email: json.userId,
            name: json.name,
        };
        return {
            profile: profile
        }
    };
    const onFailureCallback = response => {
        return response
    };
    return callApi(UPDATE_PROFILE_INFO_ENDPOINT, config, onSuccessCallback, onFailureCallback)
}

export function getUserRoleAPI(auth, userId) {
    const url = makeDefaultQueryString("profile", auth, null);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json
    };
    const onFailureCallback = response => {
        return response
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function fetchPendingAppApprovalsAPI(auth, userId) {
    const url = makeDefaultQueryString("pending-approvals", auth, null);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json
    };
    const onFailureCallback = response => {
        return response
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback)
}