/**
 * Created by Rakesh Peela
 * Date: 13-Aug-2019
 * Time: 11:48 AM
 */

export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export const PASSWORD_UPDATE_FAILED = "PASSWORD_UPDATE_FAILED";
export const PASSWORD_UPDATE_PENDING = "PASSWORD_UPDATE_PENDING";

export const PROFILE_DATA_UPDATE = "PROFILE_DATA_UPDATE";
export const PROFILE_DATA_UPDATE_FAILED = "PROFILE_DATA_UPDATE_FAILED";
export const PROFILE_DATA_UPDATE_PENDING = "PROFILE_DATA_UPDATE_PENDING";

export const PROFILE_PHOTO_UPDATE = "PROFILE_PHOTO_UPDATE";
export const PROFILE_PHOTO_UPDATE_FAILED = "PROFILE_PHOTO_UPDATE_FAILED";
export const PROFILE_PHOTO_UPDATE_PENDING = "PROFILE_PHOTO_UPDATE_PENDING";

export const PROFILE_PHOTO_DELETE = "PROFILE_PHOTO_DELETE";
export const PROFILE_PHOTO_DELETE_FAILED = "PROFILE_PHOTO_DELETE_FAILED";
export const PROFILE_PHOTO_DELETE_PENDING = "PROFILE_PHOTO_DELETE_PENDING";

export const RESET_STATE = "RESET_STATE";

export const GET_USER_ROLE = "GET_USER_ROLE";
export const GET_USER_ROLE_PENDING = "GET_USER_ROLE_PENDING";
export const GET_USER_ROLE_FAILED = "GET_USER_ROLE_FAILED";

export const FETCH_PENDING_APP_APPROVALS = "FETCH_PENDING_APP_APPROVALS";
export const FETCH_PENDING_APP_APPROVALS_PENDING = "FETCH_PENDING_APP_APPROVALS_PENDING";
export const FETCH_PENDING_APP_APPROVALS_FAILED = "FETCH_PENDING_APP_APPROVALS_FAILED";