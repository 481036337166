/**
 * Created by Rakesh Peela
 * Date: 13-Dec-2019
 * Time: 11:58 AM
 */

import {Button, ButtonBase, IconButton, Typography, withStyles} from "@material-ui/core";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import React from "react";
import Loading from "../../../../../../../../../../components/reusable/Loading";
import ClassicCard from "../../ClassicCard";
import {ACTION_CLASSES} from "../models/AvailableOptions";
import {getActionClassLogo} from "../utils";
import {APXOR_SDK_CATEGORY_CONSTANTS} from '../../../../../../../../../../constants';
import Apxor from 'apxor';

const styles = (theme) => ({
    buttonBaseStyle: {
        border: 0,
        // cursor: "pointer",
        display: "inline-flex",
        outline: "none",
        padding: 0,
        position: "relative",
        alignItems: "center",
        userSelect: "none",
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
        backgroundColor: "transparent",
        textAlign: "initial",
        borderRadius: 12,
        minWidth: 280,
        minHeight: (props) => props.editable ? 168 : "auto",
        margin: 12,
        transition: "all 0.2s ease-in-out",
        "&:hover $overlayDiv": {
            transition: "all 0.2s ease-in-out",
            display: "inline-block",
            position: "absolute",
            top: -24,
            left: -24
        }
    },
    classicCardActions: {
        display: "flex",
        flexDirection: "row",
    },
    buttonIcon: {
        marginRight: 12
    },
    actionButtons: {
        background: "#fff",
        margin: 6,
        lineHeight: "16px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        "&:hover": {
            backgroundColor: "#dcdcdc"
        }
    },
    actionButtonDisabled: {
        margin: 6,
        pointerEvents: "none",
        cursor: "default"
    },
    overlayDiv: {
        display: "none",
        transition: "all 0.2s ease-in-out",
    },
    classicStepCardStyle: {
        width: "100%",
        height: "100%",
        padding: 8,
    }
});

class CampaignStepCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleting: false,
        }
    }

    render() {
        const {
            classes,
            createNew = false,
            editable = true,
            itemIndex = 0,
            selected = false,
            ui = {},
            deleteHandler = (index) => null,
            handler = () => null,
            showDialog = () => null
        } = this.props;
        const {isDeleting} = this.state;
        const {layout_type, action_class, display_text} = ui;
        return (
            <div>
                {
                    !createNew &&
                    (<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <div
                            className={classes.buttonBaseStyle}
                        >
                            <ClassicCard
                                style={{
                                    minWidth: 280,
                                    minHeight: editable ? 168 : "auto",
                                    padding: "8px 8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    width: editable ? "100%" : 480
                                }}
                                selected={selected}
                            >
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <div style={{
                                        background: "#f2f2f2",
                                        borderRadius: 8,
                                        display: "flex",
                                        minWidth: 96,
                                        padding: 12,
                                    }}>
                                        {getActionClassLogo(action_class, layout_type, true)}
                                    </div>
                                    <div style={{marginLeft: 16}}>
                                        {/*<Typography variant={"subtitle2"}>*/}
                                        {/*    Step {itemIndex + 1}*/}
                                        {/*</Typography>*/}
                                        <Typography variant={"body2"} style={{fontWeight: 800}}>
                                            {display_text}
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            {action_class}
                                        </Typography>
                                    </div>
                                </div>
                                {
                                    editable
                                    && <div style={{
                                        marginTop: 6,
                                        paddingTop: 6,
                                        borderTop: "1px solid #e8e8e8",
                                        width: "100%"
                                    }}>
                                        <div className={classes.classicCardActions}>
                                            {
                                                action_class !== ACTION_CLASSES.INAPP
                                                && selected
                                                && <Button
                                                    onClick={() => showDialog()}
                                                >
                                                    <RemoveRedEyeIcon
                                                        fontSize={"small"}
                                                        color={"action"}
                                                        style={{marginRight: 6}}
                                                    />
                                                    Preview
                                                </Button>
                                            }
                                            {
                                                !selected && <Button
                                                    onClick={() => handler(itemIndex)}
                                                >
                                                    <EditIcon
                                                        fontSize={"small"}
                                                        color={"action"}
                                                        style={{marginRight: 6}}
                                                    />
                                                    Edit
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                }
                            </ClassicCard>
                            {
                                editable
                                && <div className={classes.overlayDiv}>
                                    <IconButton
                                        classes={{
                                            root: classes.actionButtons
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                isDeleting: true
                                            });
                                            setTimeout(() => {
                                                deleteHandler(itemIndex)
                                            }, 1000)
                                        }}
                                    >
                                        {isDeleting ? <Loading size={20}/> :
                                            <DeleteIcon fontSize={"small"} color={"error"}/>}
                                    </IconButton>
                                </div>
                            }
                        </div>
                        {
                            editable
                            && <IconButton disabled>
                                <ArrowForwardIcon fontSize={"small"}/>
                            </IconButton>
                        }
                    </div>)
                }
                {
                    createNew && <div>
                        <ButtonBase
                            style={{
                                textAlign: "initial",
                                margin: 12,
                                borderRadius: 12,
                                minWidth: 280,
                                minHeight: 168
                            }}
                            disabled={false}
                            onClick={() => {
                                Apxor.logEvent("AddNewStep", {}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                                handler(itemIndex)
                            }}
                        >
                            <ClassicCard placeholderType style={{
                                minWidth: 280,
                                minHeight: 168,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around"
                                }}>
                                    <AddCircleOutlinedIcon color={"primary"} fontSize={"large"}
                                                           style={{margin: "16px auto"}}/>
                                    <Typography style={{marginBottom: 24}}>
                                        Add New Step
                                    </Typography>
                                </div>
                            </ClassicCard>
                        </ButtonBase>
                    </div>
                }
            </div>
        );
    }

}

export default withStyles(styles)(CampaignStepCard)