/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ActionCreators from "./actions";
import { resetAppData } from "../Apps/App/actions";
import AppsComponent from "./components";
import logger from "../../../../../utils/Logger";
import { enterDashboard, getMetaDataOfApp } from "../Apps/App/actions";
import Snackbar from "../../../../../components/reusable/MaterialUi/Snackbar";
import Grid from "@material-ui/core/Grid";
import Loading from "../../../../../components/reusable/Loading";
import { DASHBOARD_ENDPOINTS } from "../../../../../constants/EndPoints";
import { getPreviousDashboard } from "../../../../../utils";
import { setDefaultFilters, updateGlobalVersions } from "../Filters/actions";

function mapStateToProps(state) {
  return {
    auth: state.auth,
    meta: state.meta,
    router: state.routing,
    appsState: state.apps,
    appState: state.app,
    dashboards: state.dashboards,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApps: () => dispatch(ActionCreators.getApps()),
    addApp: (app) => dispatch(ActionCreators.addApp(app)),
    deleteApp: (appId) => dispatch(ActionCreators.deleteApp(appId)),
    enableMonitoring: (appId) =>
      dispatch(ActionCreators.enableMonitoring(appId)),
    disableMonitoring: (appId) =>
      dispatch(ActionCreators.disableMonitoring(appId)),
    setDefaultFilters: () => dispatch(setDefaultFilters()),
    resetAppData: () => dispatch(resetAppData()),
    updateGlobalVersions: (versions) =>
      dispatch(updateGlobalVersions(versions)),
    hydrateApp: (appId) => {
      dispatch(enterDashboard(appId));
      dispatch(getMetaDataOfApp(appId));
    },
    boundDispatch: dispatch,
  };
}

class Apps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentApp: null,
    };
  }

  componentWillMount() {
    this.props.resetAppData(); //resets existing app data and DashboardData
    this.props.getApps();
  }

  handleNavigation = (app, botId) => {
    const { router } = this.context;
    if (
      (app.app_versions && Object.keys(app.app_versions).length === 0) ||
      !app.app_versions
    ) {
      router.push("/apps/" + app.app_id + "/integration");
      //router.push("/apps/"+ app.app_id + "/overview");
    } else {
      const { hydrateApp, updateGlobalVersions } = this.props;
      if (botId) {
        updateGlobalVersions([botId]);
      } else {
        updateGlobalVersions([]);
      }
      hydrateApp(app.app_id); //hydrating app meta data
      this.setState({ currentApp: app });
      // if (process.env.NODE_ENV === "production" && app.domain !== window.location.href.split('/')[2]) {
      //     window.location.assign(`https://${app.domain || "asia.art.apxor.com"}/apps/${app.app_id}/event-analysis`);
      // } else {
      router.push(`/apps/${app.app_id}/event-analysis`);
      // }
    }
  };

  componentWillReceiveProps(nextProps) {
    const { currentApp } = this.state;
    if (currentApp) {
      if (!nextProps.meta.api_pending) {
        //if no APIs pending
        // const {dashboards = [], dashboards_loaded } = nextProps.dashboards;
        // if(dashboards_loaded) {
        //     this.redirectIfPreviousDashboardExists(dashboards.map(o => o._id), currentApp.app_id);
        // }
      }
    }
  }

  render() {
    const {
      appsState,
      appState,
      addApp,
      deleteApp,
      auth,
      updateGlobalVersions,
    } = this.props;
    const { appsLoading, apps } = appsState;
    const { addingFailed, addingSuccess, addingApp } = appState;
    const { router } = this.context;
    if (auth.user.email === "info@ucobot.com") {
      updateGlobalVersions(["6534f47e-df64-4ff5-b8e7-996c44912d43"]);
      this.setState({
        currentApp: apps.filter(function (eachApp) {
          return eachApp.app_id === "4fdaf91a-df1b-418d-907c-2b600c33105d";
        }),
      });
      router.push(
        `apps/4fdaf91a-df1b-418d-907c-2b600c33105d/dashboards/f952ed46-256f-425d-8de0-9878a2e165e3`
      );
    } else if (
      auth.user.email === "info@optibot.com" ||
      auth.user.email === "prabhu@apxor.com" ||
      auth.user.email === "prabhukonchada@gmail.com" ||
      auth.user.email === "infodemo@optibot.com"
    ) {
      updateGlobalVersions(["3fddfe37-b81d-4e90-907e-acc79fd83e09"]);
      this.setState({
        currentApp: apps.filter(function (eachApp) {
          return eachApp.app_id === "9d2a2727-12af-4e27-b15c-ca6d0b5f982e";
        }),
      });
      router.push(
        `apps/9d2a2727-12af-4e27-b15c-ca6d0b5f982e/dashboards/0f4d8c42-73ab-4c5d-93b4-f8b75c04909a`
      );
    } else if (
      auth.user.email === "marketingexecutive-2@gem.gov.in" ||
      auth.user.email === "iamrohitganesh@gmail.com"
    ) {
      updateGlobalVersions(["ddf2af37-d142-42b3-b0bf-282661bfebe3"]);
      this.setState({
        currentApp: apps.filter(function (eachApp) {
          return eachApp.app_id === "87d0102c-64de-47c6-b28d-75ffe43d10a2";
        }),
      });
      router.push(
        `apps/87d0102c-64de-47c6-b28d-75ffe43d10a2/dashboards/00638d2d-b1b0-4b6d-9b4c-b1cc546d2ab9`
      );
    }
    return (
      <section style={{ width: "100%", marginTop: 50 }} className="content">
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={8}>
            <AppsComponent
              {...this.props}
              size={apps.length}
              apps={apps}
              addApp={addApp}
              isDeleteEnabled={false}
              deleteApp={deleteApp}
              handleNavigation={this.handleNavigation}
              currentApp={this.state.currentApp}
            />
          </Grid>
        </Grid>
      </section>
    );
  }
}

Apps.propTypes = {
  addApp: PropTypes.func.isRequired,
  deleteApp: PropTypes.func.isRequired,
  enableMonitoring: PropTypes.func.isRequired,
  disableMonitoring: PropTypes.func.isRequired,
};

Apps.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Apps);
