import { IconButton } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import RemoveIcon from '@material-ui/icons/Clear';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FilterIcon from '@material-ui/icons/FilterList';
import PeopleIcon from '@material-ui/icons/People';
import SaveIcon from '@material-ui/icons/Save';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import React from 'react';
import { Link } from 'react-router';
import Box from "../../../../../../../components/reusable/Box";
import FormSelect from "../../../../../../../components/reusable/MaterialUi/FormSelect";
import MultiSelect from '../../../../../../../components/reusable/MaterialUi/MultiSelect';
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import { CONDTIONAL_OPERATORS, CONTAINS_OPERATORS, LOGICAL_OPERATORS, APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../../../constants';
import { DASHBOARD_ENDPOINTS } from "../../../../../../../constants/EndPoints";
import debounce from 'lodash/debounce';
import Apxor from 'apxor';

const Operators = [
    {label: ">", value: LOGICAL_OPERATORS.GT, lingual: "greater"},
    {label: "≥", value: LOGICAL_OPERATORS.GTE, lingual: "greater or equal"},
    {label: "<", value: LOGICAL_OPERATORS.LT, lingual: "less"},
    {label: "≤", value: LOGICAL_OPERATORS.LTE, lingual: "less or equal"},
    {label: "=", value: LOGICAL_OPERATORS.EQ, lingual: "is"},
    {label: "≠", value: LOGICAL_OPERATORS.NEQ, lingual: "is not"}
];

const EqualityOperators = [
    {label: "≠", value: LOGICAL_OPERATORS.NEQ, lingual: "is not"},
    {label: "=", value: LOGICAL_OPERATORS.EQ, lingual: "is"}
];

let ContainsOperators = [
    ...Operators,
    // {label: "≠", value: LOGICAL_OPERATORS.NEQ, lingual: "is not"},
    {label: "∋", value: CONTAINS_OPERATORS.CONTAINS, lingual: "contains"},
    {label: "∌", value: CONTAINS_OPERATORS.NOTCONTAINS, lingual: "does not contain"}
];

export class AttributeBuilder extends React.Component {

    constructor(props){
        super(props);
        const { name, value = [], operator } = props;
        this.state = {
            name, value, operator
        };
        this.debounce = debounce((fn, ...args) => {
            fn(...args)
        }, 300);
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const {
            attributes = [], values = [],
            disabled = false,
            getRecommendations, deleteAttribute, index,
            forFunnels, forSegments,
            onlyEquality = false
        } = this.props;
        const { name, value, operator} = this.state;
        const attributeSelected = name && name.length > 0;
        let containsType = (operator === CONTAINS_OPERATORS.CONTAINS) || (operator === CONTAINS_OPERATORS.NOTCONTAINS);

        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={3}>
                    <MultiSelect
                        value={name}
                        options={attributes.map(o => ({label: o, value: o}))}
                        placeholder="Select Property"
                        handleChange={(name) => {
                            this.setState({name}, () => {
                                this.handleUpdate();
                                if(name && name.length) {
                                    this.debounce(getRecommendations, name, "");
                                }
                            }, this.handleUpdate);
                        }}
                        single
                        disabled={disabled}
                        margin="dense"
                    />
                </Grid>
                {
                    attributeSelected && <Grid item xs={12} md={2}>
                        <MultiSelect
                            value={operator}
                            options={(onlyEquality ? EqualityOperators : ContainsOperators)}
                            placeholder="Condition"
                            handleChange={operator => this.setState({operator}, this.handleUpdate)}
                            single
                            disabled={disabled}
                            margin="dense"
                        />
                    </Grid>
                }
                {
                    attributeSelected && <Grid item xs={12} md>
                        <MultiSelect
                            placeholder= { containsType ? "Enter Key Phrase..." : "Search..." }
                            options={
                                [...value.map(o => ({label: o, value: o})), ...values.map(item => ({label: item, value: item}))]
                            }
                            handleChange={(value) => {
                                this.setState({value}, this.handleUpdate);
                            }}
                            onInputChange={(q, callback) => {
                                this.debounce(getRecommendations, name, q);
                            }}
                            isAsync
                            isCreatable={forFunnels || forSegments}
                            value={value}
                            disabled={disabled}
                            margin="dense"
                        />
                    </Grid>
                }
                {
                    !disabled && <Grid item xs={12} md={1}>
                        <Tooltip id={"attribute-filter-remove-" + name + index} title="Remove Filter" placement="top">
                            <IconButton aria-label="Remove" onClick={() => deleteAttribute(index)}>
                                <RemoveIcon color="secondary"/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        )
    }

}

export class EventBuilder extends React.Component {

    constructor(props) {
        super(props);
        const { name } = props;
        const { count, attributes = [], days, start_time, end_time, title = name } = props;
        this.state = {
            name, count, attributes,
            days, start_time, end_time, title
        };
    }

    componentWillMount(){
        const {
            name, params: { appId }, getEventAttributes
        } = this.props;
        if(name && name.length) {
            getEventAttributes(appId, name);
        }
    }

    handleUpdate = () => {
        const { attributes = [], ...others } = this.state;
        const validAttributes = attributes.filter(o => o.name && o.name.length > 0); //checking for empty name
        this.props.handleUpdate({attributes: validAttributes, ...others});
    };

    handleAttributeUpdate = (index) => (attribute) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), attribute, ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    addAttribute = () => {
        const newAttributeFilter = {name: "", operator: LOGICAL_OPERATORS.EQ, value: []};
        this.setState({attributes: [...this.state.attributes, newAttributeFilter]});
    };

    deleteAttribute = (index) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            params: { appId },
            disabled = false,
            getEventAttributes, getEventAttributeValues
        } = this.props;
        const {
            segmentBuilder: {
                events = [], event_attributes = {}, event_attribute_values = {},
            },
            positive = false, clearable = false
        } = this.props;
        const {
            name, count: { value, operator = LOGICAL_OPERATORS.EQ } = {}, count,
            attributes = [], title } = this.state;
        const attributesNamesList = attributes.map(x=> x.name);
        return(
            <Box
                title={title || name}
                collapsible
                defaultExpanded
            >
                <Grid container spacing={16}>
                    <Grid item xs={12} md={3}>
                        <MultiSelect
                            value={name}
                            options={events.map(o => ({label: o, value: o}))}
                            placeholder="Select Event"
                            handleChange={(name) => {
                                this.setState({name}, this.handleUpdate);
                                if(name && name.length) {
                                    getEventAttributes(appId, name);
                                }else{
                                    this.setState({attributes: []}, this.handleUpdate); //resetting attributes on empty event
                                }
                            }}
                            single
                            disabled={disabled}
                            margin="dense"
                            clearable={clearable}
                        />
                    </Grid>
                    <Grid item xs={12} md>
                        {/*TODO: uncomment this when available from server side*/}
                        {/*<Grid container>
                            <Grid item xs={3}>
                                <ToggleSwitch
                                    disabled={disabled}
                                    label="Days of the week"
                                    handleChange={checked => {
                                        this.setState({days: checked ? [] : undefined }, this.handleUpdate)
                                    }}
                                    value={Boolean(days)}
                                    checked={Boolean(days)}
                                    styles={{height: 48}}
                                />
                            </Grid>
                            <Grid item xs>
                                {
                                    days && <CheckboxGroup options={DAYS_IN_WEEK} value={days} handleChange={days => this.setState({days}, this.handleUpdate)}/>
                                }
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid item xs={3}>
                                <ToggleSwitch
                                    disabled={disabled}
                                    label="Time of the day"
                                    handleChange={checked => {
                                        this.setState({
                                            start_time: checked ? moment().hours() : undefined,
                                            end_time: checked ? moment().hours() : undefined
                                        }, this.handleUpdate)
                                    }}
                                    value={Boolean(start_time)}
                                    checked={Boolean(start_time)}
                                    styles={{height: 48}}
                                />
                            </Grid>
                            <Grid item xs>
                                { start_time && end_time &&
                                <div style={{display: 'flex', margin: 5}}>
                                    <TimePicker
                                        value={moment().hours(start_time)}
                                        handleChange={start_time => this.setState({start_time: start_time.hours()}, this.handleUpdate)}
                                    />
                                    &nbsp;&nbsp;
                                    <TimePicker
                                        value={moment().hours(end_time)}
                                        handleChange={end_time => this.setState({end_time: end_time.hours()}, this.handleUpdate)}
                                    />
                                </div>
                                }
                            </Grid>
                        </Grid>*/}
                        {
                            attributes.map((attribute, index) =>
                                <div key={name + attribute.name + index}>
                                    <AttributeBuilder
                                        {...this.props}
                                        {...attribute}
                                        deleteAttribute={this.deleteAttribute}
                                        index={index}
                                        attributes={
                                            event_attributes[name]
                                            && event_attributes[name].filter(item => !attributesNamesList.includes(item))
                                            || []
                                        }
                                        values={event_attribute_values[name] && event_attribute_values[name][attribute.name]}
                                        handleUpdate={this.handleAttributeUpdate(index)}
                                        getRecommendations={(attribute, q) => getEventAttributeValues(appId, name, attribute, q)}
                                    />
                                </div>
                            )
                        }
                        {
                            event_attribute_values.hasOwnProperty(name) && <Button color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                                Add Filter
                                <FilterIcon />
                            </Button>
                        }
                    </Grid>
                </Grid>
                {
                    positive && <Grid container spacing={24}>
                        <Grid item xs={12} md={3}>
                            <Typography style={{textAlign: 'right', marginTop: '1em', fontSize: 18}} variant="subtitle1">where count </Typography>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <FormSelect
                                value={operator}
                                options={ Operators }
                                placeholder="="
                                label="Condition"
                                handleUpdate={operator => this.setState({count: {...count, operator}}, this.handleUpdate)}
                                single
                                disabled={disabled}
                                idKey="value"
                                nameKey="label"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <div style={{display: 'flex'}}>
                            <TextField
                                type="number"
                                required
                                placeholder="2"
                                value={value}
                                style={{maxWidth: 60}}
                                onChange={e => {
                                    const value = Number(e.target.value);
                                    if(value > 0){
                                        this.setState({count: {...count, value}}, this.handleUpdate);
                                    }
                                }}
                                disabled={disabled}
                                margin="normal"
                            />
                            <Typography style={{ fontSize: 18, display: 'inline-block', lineHeight: '3.5em'}} variant="subtitle1"> time (s)</Typography>
                            </div>
                        </Grid>
                    </Grid>
                }
            </Box>
        )
    }

}

export class AttributeBuilderWrapper extends React.Component {

    constructor(props) {
        super(props);
        const { attributes = [] } = props;
        this.state = { attributes };
    }

    componentWillMount(){
        const { attributes = [] } = this.props;
        this.setState({ attributes });
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state.attributes);
    };

    handleAttributeUpdate = (index) => (attribute) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), attribute, ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    addAttribute = () => {
        const newAttributeFilter = {name: "", operator: LOGICAL_OPERATORS.EQ, value: []};
        this.setState({attributes: [...this.state.attributes, newAttributeFilter]});
    };

    deleteAttribute = (index) => {
        const { attributes = [] } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            params: { appId },
            title, disabled = false, data, values,
            getAttributeValues, withoutBox = false, addPropertyKey = "Add Property",
        } = this.props;
        const { attributes = [] } = this.state;
        if(withoutBox){
            return(
                <div style={{padding: 10}}>
                    {
                        attributes.map((attribute, index) =>
                            <div key={attribute.name + index}>
                                <AttributeBuilder
                                    {...this.props}
                                    {...attribute}
                                    deleteAttribute={this.deleteAttribute}
                                    index={index}
                                    attributes={data}
                                    values={values[attribute.name] || []}
                                    handleUpdate={this.handleAttributeUpdate(index)}
                                    getRecommendations={(attribute, q) => getAttributeValues(appId, attribute, q)}
                                />
                            </div>
                        )
                    }
                    { !disabled &&
                        <Button color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                            {addPropertyKey}
                            <WebAssetIcon />
                        </Button>
                    }
                </div>
            )
        }
        return(
            <Box
                title={title}
                collapsible
                withPadding
                defaultExpanded={attributes.length > 0}
            >
                {
                    attributes.map((attribute, index) =>
                        <div key={attribute.name + index}>
                            <AttributeBuilder
                                {...this.props}
                                {...attribute}
                                deleteAttribute={this.deleteAttribute}
                                index={index}
                                attributes={data}
                                values={values[attribute.name] || []}
                                handleUpdate={this.handleAttributeUpdate(index)}
                                getRecommendations={(attribute, q) => getAttributeValues(appId, attribute, q)}
                            />
                        </div>
                    )
                }
                {
                    !disabled && <Button color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                        {addPropertyKey}
                        <WebAssetIcon />
                    </Button>
                }
            </Box>
        )
    }

}

export class HybridAttributeBuilderWrapper extends React.Component {

    constructor(props) {
        super(props);
        const { attributes = [] } = props;
        this.state = { attributes };
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state.attributes);
    };

    handleAttributeUpdate = (index) => (attribute) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), attribute, ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    addAttribute = () => {
        const newAttributeFilter = {name: "", operator: LOGICAL_OPERATORS.EQ, value: []};
        this.setState({attributes: [...this.state.attributes, newAttributeFilter]});
    };

    deleteAttribute = (index) => {
        const { attributes = [] } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            title, disabled = false, data, values,
            withoutBox = false, addPropertyKey = "Add Property",
            forFunnels
        } = this.props;
        const { attributes = [] } = this.state;
        const showAddButton = attributes.length === 0 || attributes[attributes.length - 1].value.length > 0;
        if(withoutBox){
            return(
                <div style={{padding: 10}}>
                    {
                        attributes.map((attribute, index) =>
                            <div key={attribute.name + index} style={{marginLeft: index * 32}}>
                                <AttributeBuilder
                                    {...this.props}
                                    {...attribute}
                                    deleteAttribute={this.deleteAttribute}
                                    index={index}
                                    attributes={data}
                                    values={values[attribute.name] || []}
                                    handleUpdate={this.handleAttributeUpdate(index)}
                                />
                            </div>
                        )
                    }
                    {
                        forFunnels && showAddButton && <Button disabled={disabled} color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                            {addPropertyKey}
                            <WebAssetIcon />
                        </Button>
                    }
                </div>
            )
        }
        return(
            <Box
                title={title}
                collapsible
                withPadding
            >
                {
                    attributes.map((attribute, index) =>
                        <div key={attribute.name + index}>
                            <AttributeBuilder
                                {...this.props}
                                {...attribute}
                                deleteAttribute={this.deleteAttribute}
                                index={index}
                                attributes={data}
                                values={values[attribute.name] || []}
                                handleUpdate={this.handleAttributeUpdate(index)}
                            />
                        </div>
                    )
                }
                {
                    !disabled && <Button color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                        {addPropertyKey}
                        <WebAssetIcon />
                    </Button>
                }
            </Box>
        )
    }

}

class Condition extends React.Component {

    render(){
        const { index, condition, handleUpdate, disabled = false } = this.props;
        const { AND, OR } = CONDTIONAL_OPERATORS;
        return(
            <Tooltip id={"attribute-condition-" + index} title="Click to change" placement="top">
                <Button
                    disabled={disabled}
                    style={{margin: '10px auto', display: 'block'}}
                    size="small"
                    variant="contained"
                    color={condition === AND ? "secondary" : "primary"}
                    onClick={() => {
                        handleUpdate({condition: condition === AND ? OR : AND});
                    }}>
                    { condition }
                </Button>
            </Tooltip>
        )
    }

}

export class SegmentBuilder extends React.Component {

    constructor(props){
        super(props);
        const {
            name = "",
            user = [],
            session = [],
            event = [],
            condition = CONDTIONAL_OPERATORS.AND,
            params: { appId }
        } = props;
        this.state = {
            name, user, session, event, condition,
            positiveEvents: event.filter(o => o.count.value > 0),
            negativeEvents: event.filter(o => o.count.value === 0),
            saveClicked: false
        };
        this.appId = appId;
    }

    handleUpdate = (getCount = true) => {
        const { positiveEvents = [], negativeEvents = [], user = [], session = [], ...others } = this.state;
        const { handleQueryUpdate, getSegmentCount  } = this.props;
        //below lines to ensure everything name selected for each filter
        const validUserProperties = user.filter(o => o.name && o.name.length > 0);
        const validSessionProperties = session.filter(o => o.name && o.name.length > 0);
        const validPositiveEvents = positiveEvents.filter(o => o.name && o.name.length > 0);
        const validNegativeEvents = negativeEvents.filter(o => o.name && o.name.length > 0);
        handleQueryUpdate({
            ...others,
            user: validUserProperties,
            session: validSessionProperties,
            event: [ ...validPositiveEvents, ...validNegativeEvents ]
        });
        this.setState({saveClicked: false});
        if(getCount){
            getSegmentCount(this.appId);
        }
    };

    addEvent = (countValue = 1) => {
        const newEvent = {
            name: "",
            count: {value: countValue, operator: countValue === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ},
            attributes: []
        };
        if(countValue > 0){
            Apxor.logEvent("AddedWhoDidEvent", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
            this.setState({positiveEvents: [...this.state.positiveEvents, newEvent]});
        }else{
            Apxor.logEvent("AddedWhoDidntDoEvent", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
            this.setState({negativeEvents: [...this.state.negativeEvents, newEvent]});
        }
    };

    handlePositiveEventUpdate = (index) => (event) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), event, ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    handleNegativeEventUpdate = (index) => (event) => {
        const { negativeEvents } = this.state;
        this.setState({negativeEvents: [...negativeEvents.slice(0, index), event, ...negativeEvents.slice(index + 1)]}, this.handleUpdate);
    };

    deletePositiveEvent = (index) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    deleteNegativeEvent = (index) => {
        const { negativeEvents } = this.state;
        this.setState({negativeEvents: [...negativeEvents.slice(0, index), ...negativeEvents.slice(index + 1)]}, this.handleUpdate);
    };

    handleSave = () => {
        Apxor.logEvent("SaveSegmentClicked", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
        this.setState({saveClicked: true});
        this.props.saveSegment(this.appId);
    };

    componentWillReceiveProps(nextProps){
        const { saved } = nextProps.segmentBuilder;
        if(this.state.saveClicked && saved && saved._id){
            //this.props.resetQuery();
        }
    }

    render(){
        const {
            segmentBuilder: {
                count, saved, save_failed,
                user_attributes, session_attributes, attribute_values,
            }
        } = this.props;
        const {
            name, user = [], session = [], condition,
            positiveEvents = [], negativeEvents = [],
            saveClicked
        } = this.state;
        return(
            <Box

                headerWithBorder
                footer={
                    <Grid container>
                        <Grid item xs={8} md={4}>
                            <TextField
                                id="name"
                                label="Name of the Segment"
                                value={name}
                                onChange={e => this.setState({name: e.target.value}, () => this.handleUpdate(false))}
                                placeholder=""
                                margin="dense"
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} md>
                            <Button
                                disabled={!(name && name.length > 0)}
                                variant="contained"
                                color="primary"
                                onClick={this.handleSave}
                                style={{marginTop: 10}}
                            >
                                <SaveIcon />
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                }
                controls={
                    <div style={{display: 'flex', margin: 10}}>
                        {/*<Typography variant="h6" style={{marginRight: 10}}>Users in Segment</Typography>*/}
                        <Tooltip id="users in segment" title="Users in segment" placement="top">
                            <PeopleIcon color="primary"/>
                        </Tooltip>
                        <Typography variant="h6" style={{margin: '0 10px'}}>{ count }</Typography>
                    </div>
                }
            >
                { saveClicked && saved && saved._id && <Snackbar action={
                    <Link key="analyse-button" to={`/apps/${this.appId}/${DASHBOARD_ENDPOINTS.SEGMENTS}/${saved._id}`}>
                        <Button color="primary">
                            Analyse
                        </Button>
                    </Link>
                }>Segment saved successfully..!</Snackbar> }
                { saveClicked && save_failed && <Snackbar>Segment saving failed..!</Snackbar> }
                <Box
                    title={
                        <Typography variant="h6">Users having </Typography>
                    }
                    collapsible
                    defaultExpanded
                >
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="User Properties"
                        attributes={user}
                        data={user_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({user: attributes}, this.handleUpdate)}
                    />
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="Session Properties"
                        attributes={session}
                        data={session_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({session: attributes}, this.handleUpdate)}
                    />
                </Box>
                <Typography variant="h6" style={{margin: 15}}>and</Typography>
                <Box
                    title={
                        <Typography variant="h6">who did</Typography>
                    }
                    collapsible
                    defaultExpanded
                >
                    {
                        positiveEvents.map((event, index) =>
                            <div key={event.name + index}>
                                <div style={{
                                    position: 'absolute',
                                    zIndex: 10,
                                    right: 120,
                                    marginTop: 15
                                }}>
                                    <Tooltip id={"attribute-filter-remove-" + index} title="Remove Event" placement="top">
                                        <IconButton aria-label="Remove" onClick={() => this.deletePositiveEvent(index)} style={{height: 'auto', width: 'auto'}}>
                                            <RemoveIcon color="secondary"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <EventBuilder {...this.props} positive {...event} handleUpdate={this.handlePositiveEventUpdate(index)}/>
                                { index < positiveEvents.length - 1 && <Condition index={index} condition={condition} handleUpdate={(state) => this.setState(state, this.handleUpdate)}/> }
                            </div>
                        )
                    }
                    <Button color="primary" onClick={() => this.addEvent(1)}>
                        Add Event
                        <EventAvailableIcon />
                    </Button>
                </Box>
                <Typography variant="h6" style={{margin: 15}}>and</Typography>
                <Box
                    title={
                        <Typography variant="h6">who didn't do</Typography>
                    }
                    collapsible
                    defaultExpanded
                >
                    {
                        negativeEvents.map((event, index) =>
                            <div key={"not" + event.name + index}>
                                <div style={{
                                    position: 'absolute',
                                    zIndex: 10,
                                    right: 120,
                                    marginTop: 15
                                }}>
                                    <Tooltip id={"attribute-filter-remove-" + index} title="Remove Event" placement="top">
                                        <IconButton aria-label="Remove" onClick={() => this.deleteNegativeEvent(index)} style={{height: 'auto', width: 'auto'}}>
                                            <RemoveIcon color="secondary"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <EventBuilder {...this.props} {...event} handleUpdate={this.handleNegativeEventUpdate(index)}/>
                                { index < negativeEvents.length - 1 && <Condition index={index} condition={CONDTIONAL_OPERATORS.AND} disabled handleUpdate={(state) => this.setState(state, this.handleUpdate)}/> }
                            </div>
                        )
                    }
                    <Button color="primary" onClick={() => this.addEvent(0)}>
                        Add Event
                        <EventBusyIcon />
                    </Button>
                </Box>
            </Box>
        )
    }

}

/**
 * Can be used anywhere
 * Required props:
 * getAttributes(appId),
 * getAttributeValues(appId, attribute, q),
 * handleQueryUpdate(query)
 */
export class UserAndSessionProperties extends React.Component {

    constructor(props){
        super(props);
        const {
            params: { appId },
            segmentBuilder: {
                query: {
                    user = [],
                    session = [],
                } = {}
            } = { query: {} }
        } = this.props;
        this.state = { user, session };
        this.appId = appId;
    }

    componentWillMount(){
        const { getAttributes, handleQueryUpdate, query = { user: [], session: [] } } = this.props;
        getAttributes(this.appId);
        if(query && (query.user.length > 0 || query.session.length > 0)){
            handleQueryUpdate(query);
            this.setState(query);
        }
    }

    handleUpdate = (getCount = true) => {
        const { handleQueryUpdate, onQueryUpdate = () => null  } = this.props;
        const query = {...this.state};
        handleQueryUpdate(query);
        onQueryUpdate(query);
    };

    componentWillUnmount(){
        const { resetQuery, noReset = false} = this.props;
        if(!noReset && typeof resetQuery === 'function'){
            resetQuery();
        }
    }

    render(){
        let {
            segmentBuilder: {
                user_attributes, session_attributes, attribute_values
            },
            withoutBox = false, onlyEquality = false,
            filterAPXProps_session = false, filterAPXProps_user = false
        } = this.props;
        const {
            segmentBuilder: {
                query: {
                    user = [],
                    session = [],
                } = {}
            } = { query: {} }
        } = this.props;

        if (filterAPXProps_session) {
            session_attributes = session_attributes.filter(x => x && !x.startsWith("apx_"));
        }
        if (filterAPXProps_user) {
            user_attributes = user_attributes.filter(x => x && !x.startsWith("apx_"));
        }

        if(withoutBox){
            return(
                <div style={{width: '100%'}}>
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="User Properties"
                        attributes={user}
                        data={user_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({user: attributes}, this.handleUpdate)}
                        withoutBox={false}
                    />
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="Session Properties"
                        attributes={session}
                        data={session_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({session: attributes}, this.handleUpdate)}
                        withoutBox={false}
                    />
                </div>
            );
        }else{
            return(
                <Box
                    icon={<FilterIcon />}
                    title="Property Filters"
                    headerWithBorder
                >
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="User Properties"
                        attributes={user}
                        data={user_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({user: attributes}, this.handleUpdate)}
                    />
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="Session Properties"
                        attributes={session}
                        data={session_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({session: attributes}, this.handleUpdate)}
                    />
                </Box>
            )
        }
    }

}

/**
 * can be used anywhere
 * Required Props:
 * getEvents(appId),
 * getEventAttributes(appId, event),
 * getEventAttributeValues(appId, event, attribute, q),
 * handleQueryUpdate(query)
 */
export class EventProperties extends React.Component {

    constructor(props){
        super(props);
        const {
            params: { appId },
            event = [],
            condition = CONDTIONAL_OPERATORS.AND,
        } = props;
        this.state = {
            event, condition,
            positiveEvents: event.filter(o => o.count.value > 0),
            negativeEvents: event.filter(o => o.count.value === 0)
        };
        this.appId = appId;
    }

    handleUpdate = (getCount = true) => {
        const { positiveEvents, negativeEvents } = this.state;
        const { handleQueryUpdate } = this.props;
        handleQueryUpdate({event: [ ...positiveEvents, ...negativeEvents ]});
    };

    addEvent = (countValue = 1) => {
        const newEvent = {
            name: "",
            count: {value: countValue, operator: countValue === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ},
            attributes: []
        };
        if(countValue > 0){
            this.setState({positiveEvents: [...this.state.positiveEvents, newEvent]});
        }else{
            this.setState({negativeEvents: [...this.state.negativeEvents, newEvent]});
        }
    };

    handlePositiveEventUpdate = (index) => (event) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), event, ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    handleNegativeEventUpdate = (index) => (event) => {
        const { negativeEvents } = this.state;
        this.setState({negativeEvents: [...negativeEvents.slice(0, index), event, ...negativeEvents.slice(index + 1)]}, this.handleUpdate);
    };

    deletePositiveEvent = (index) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    deleteNegativeEvent = (index) => {
        const { negativeEvents } = this.state;
        this.setState({negativeEvents: [...negativeEvents.slice(0, index), ...negativeEvents.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            condition,
            positiveEvents = [], negativeEvents = []
        } = this.state;
        return(
            <Box
                title="Event Filters"
                headerWithBorder
            >
                <Typography variant="h6">Users </Typography>
                <Box
                    title={
                        <Typography variant="h6">who did</Typography>
                    }
                    collapsible
                    defaultExpanded
                >
                    {
                        positiveEvents.map((event, index) =>
                            <div key={event.name + index}>
                                <div style={{
                                    position: 'absolute',
                                    zIndex: 10,
                                    right: 30
                                }}>
                                    <Tooltip id={"attribute-filter-remove-" + index} title="Remove Event" placement="top">
                                        <IconButton aria-label="Remove" onClick={() => this.deletePositiveEvent(index)} style={{height: 'auto', width: 'auto'}}>
                                            <RemoveIcon color="secondary"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <EventBuilder {...this.props} positive {...event} handleUpdate={this.handlePositiveEventUpdate(index)}/>
                                { index < positiveEvents.length - 1 && <Condition index={index} condition={condition} handleUpdate={(state) => this.setState(state, this.handleUpdate)}/> }
                            </div>
                        )
                    }
                    <Button color="primary" onClick={() => this.addEvent(1)}>
                        Add Event
                        <EventAvailableIcon />
                    </Button>
                </Box>
                <Typography variant="h6" style={{margin: 15}}>and</Typography>
                <Box
                    title={
                        <Typography variant="h6">who didn't do</Typography>
                    }
                    collapsible
                    defaultExpanded
                >
                    {
                        negativeEvents.map((event, index) =>
                            <div key={"not" + event.name + index}>
                                <div style={{
                                    position: 'absolute',
                                    zIndex: 10,
                                    right: 120,
                                    marginTop: 15
                                }}>
                                    <Tooltip id={"attribute-filter-remove-" + index} title="Remove Event" placement="top">
                                        <IconButton aria-label="Remove" onClick={() => this.deleteNegativeEvent(index)} style={{height: 'auto', width: 'auto'}}>
                                            <RemoveIcon color="secondary"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <EventBuilder {...this.props} {...event} handleUpdate={this.handleNegativeEventUpdate(index)}/>
                                { index < negativeEvents.length - 1 && <Condition index={index} condition={condition} handleUpdate={(state) => this.setState(state, this.handleUpdate)}/> }
                            </div>
                        )
                    }
                    <Button color="primary" onClick={() => this.addEvent(0)}>
                        Add Event
                        <EventBusyIcon />
                    </Button>
                </Box>
            </Box>
        )
    }

}

//
// export class FunnelBuilder extends React.Component {
//
//     constructor(props){
//         super(props);
//         const {
//             event = [],
//             condition = CONDTIONAL_OPERATORS.AND,
//             params: { appId }
//         } = props;
//         this.state = {
//             time: 5 * 60,
//             steps: event,
//             saveClicked: false,
//             funnelSaveClicked: false,
//             funnelName: '',
//             condition
//         };
//         this.appId = appId;
//     }
//
//     handleUpdate = (getCount = true) => {
//         const { steps, time} = this.state;
//         const { handleQueryUpdate, updateFunnelQuery } = this.props;
//         // handleQueryUpdate({event: steps});
//         updateFunnelQuery({events: steps, time});
//         this.setState({saveClicked: false});
//     };
//
//     addEvent = (countValue = 1) => {
//         const newStep = {
//             name: "",
//             count: {value: countValue, operator: countValue === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ},
//             attributes: []
//         };
//         this.setState({steps: [...this.state.steps, newStep]});
//     };
//
//     handleStepUpdate = (index) => (step) => {
//         const { steps } = this.state;
//         this.setState({steps: [...steps.slice(0, index), step, ...steps.slice(index + 1)]}, this.handleUpdate);
//     };
//
//     handleStepDelete = (index) => () => {
//         const { steps } = this.state;
//         this.setState({steps: [...steps.slice(0, index), ...steps.slice(index + 1)]}, this.handleUpdate);
//     };
//
//     handleAddStepAfter = (index) => () => {
//         const {steps} = this.state;
//         const newStep = {
//             name: "",
//             count: {value: index + 1, operator: index === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ},
//             attributes: []
//         };
//         this.setState({
//             steps: [...steps.slice(0, index + 1), newStep, ...steps.slice(index + 1)]
//         }, this.handleUpdate);
//     };
//
//     handleSave = () => {
//         this.setState({saveClicked: true});
//         const { handleSave = () => null} = this.props;
//         console.log(handleSave);
//         console.log(this.props);
//         handleSave();
//     };
//
//     handleFunnelSave = () => {
//         this.setState({funnelSaveClicked: true});
//         const {handleFunnelSave = () => null} = this.props;
//         const {steps, time, funnelName = "new-funnel-1"} = this.state;
//         handleFunnelSave({events: steps, time, funnelName});
//     };
//
//     componentWillReceiveProps(nextProps) {
//     }
//
//     render(){
//         const {
//             time, steps,
//             saveClicked
//         } = this.state;
//         console.log("check for Event Attrs here");
//         console.log(this.props);
//
//         return(
//             <Box
//                 collapsible
//                 title={"New Funnel"}
//                 defaultExpanded
//                 controls={
//                     <MultiSelect
//                         value={time}
//                         options={TIME_SLOTS}
//                         placeholder="Select saved Funnel"
//                         single
//                         handleChange={(changed) => console.log(changed)}
//                         clearable
//                         style={{maxWidth: 460}}
//                     />
//                 }
//                 icon={<FilterIcon color={"primary"}/>}
//                 footer={
//                     <Grid container spacing={16} justify="flex-end">
//                         <Grid item xs={6} md>
//                             <MultiSelect
//                                 placeholder="Time to complete Funnel"
//                                 value={time}
//                                 options={TIME_SLOTS}
//                                 handleChange={time => this.setState({time}, this.handleUpdate)}
//                                 style={{width: 200, marginLeft: 12}}
//                                 single
//                                 margin="dense"
//                             />
//                         </Grid>
//                         <Grid item xs={4} md={3} lg={2}>
//                             <Button
//                                 style={{marginTop: 8, marginRight: 8}}
//                                 size="small"
//                                 disabled={saveClicked || !(time && steps.length > 1 && steps[1].name && steps[1].name.length > 0)}
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={this.handleSave}
//                             >
//                                 <DoneIcon/>
//                                 Get Funnel
//                             </Button>
//
//                             <Button
//                                 style={{marginTop: 8}}
//                                 size="small"
//                                 disabled={saveClicked || !(time && steps.length > 1 && steps[1].name && steps[1].name.length > 0)}
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={this.handleFunnelSave}
//                             >
//                                 <SaveIcon/>
//                                 Save Funnel
//                             </Button>
//                         </Grid>
//                     </Grid>
//                 }
//             >
//                 {
//                     steps.map((step, index) =>
//                         <Grid container key={step.name + index}>
//                             <Grid item xs style={{paddingLeft: 12}}>
//                                 <EventBuilder {...this.props} {...step} title={`Step ${index + 1}`}
//                                               handleUpdate={this.handleStepUpdate(index)}/>
//                             </Grid>
//                             <Grid item xs={1}>
//                                 <IconButton style={{display: 'block', margin: '0 auto'}}
//                                             onClick={this.handleStepDelete(index)}>
//                                     <RemoveIcon color="error"/>
//                                 </IconButton>
//                                 {(index >= 0 && index + 1 !== steps.length) &&
//                                 <Tooltip title={"Add New Step Below"} placement={"bottom"}>
//                                     <IconButton style={{display: 'block', margin: '0 auto'}}
//                                                 onClick={this.handleAddStepAfter(index)}>
//                                         <PlaylistAddIcon color="primary"/>
//                                     </IconButton>
//                                 </Tooltip>
//                                 }
//                             </Grid>
//                         </Grid>
//                     )
//                 }
//                 <Button color="primary" style={{marginLeft: 12}} onClick={() => this.addEvent(1)}>
//                     Add Step {steps.length + 1}
//                 </Button>
//             </Box>
//         )
//     }
//
// }

/**
 * For Funnels
 */
export class PropertiesFilter extends React.Component {

    handleUpdate = (getCount = true) => {
        const { handleQueryUpdate, onQueryUpdate = () => null  } = this.props;
        const query = {...this.state};
        handleQueryUpdate(query);
        onQueryUpdate(query);
    };

    componentWillUnmount(){
        const { resetQuery, noReset = false} = this.props;
        if(!noReset && typeof resetQuery === 'function'){
            resetQuery();
        }
    }

    render(){
        const {
            segmentBuilder: {
                user_attributes, session_attributes, attribute_values
            },
            properties = [...user_attributes, ...session_attributes],
            propertyValues = attribute_values
        } = this.props;
        const {
            segmentBuilder: {
                query: {
                    group_by = []
                } = {}
            } = { query: {} }
        } = this.props;
            return(
                <div style={{width: '100%'}}>
                    {
                        Array.isArray(properties) && properties.length > 0 && <HybridAttributeBuilderWrapper
                            {...this.props}
                            title="Properties"
                            attributes={ group_by }
                            data={properties}
                            values={propertyValues}
                            handleUpdate={attributes => this.setState({group_by: attributes}, this.handleUpdate)}
                            withoutBox
                            forSegments
                            addPropertyKey="Group By"
                        />
                    }
                </div>
            );
        }


}
