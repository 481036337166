/**
 * Created by Rakesh Peela
 * Date: 12-Aug-2019
 * Time: 6:22 PM
 */

import {
    FETCH_PENDING_APP_APPROVALS, FETCH_PENDING_APP_APPROVALS_FAILED,
    FETCH_PENDING_APP_APPROVALS_PENDING,
    GET_USER_ROLE, GET_USER_ROLE_FAILED, GET_USER_ROLE_PENDING,
    PASSWORD_UPDATE,
    PASSWORD_UPDATE_FAILED,
    PASSWORD_UPDATE_PENDING,
    PROFILE_DATA_UPDATE, PROFILE_DATA_UPDATE_FAILED,
    PROFILE_DATA_UPDATE_PENDING, RESET_STATE
} from "./actionTypes";
import {SET_USER_PROFILE, LOGOUT_USER_SUCCESS} from "../../Auth/actionTypes";
import {fetchPendingAppApprovalsAPI, getUserRoleAPI, updatePasswordAPI, updateProfileDetailsAPI} from "./api";

export const updatePassword = (body) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [PASSWORD_UPDATE_PENDING, PASSWORD_UPDATE, PASSWORD_UPDATE_FAILED],
            payload: {
                promise: updatePasswordAPI(getState().auth, body)
                    .then((res) => {
                        if (res.message === "Successfully updated password.") {
                            setTimeout(() => {
                                dispatch({type: RESET_STATE});
                                dispatch({type: LOGOUT_USER_SUCCESS});
                            }, 2000)
                        }
                        return res
                    })
            },
            meta: {}
        })
    }
};

export const updateCustomerProfileInfo = (body) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [PROFILE_DATA_UPDATE_PENDING, PROFILE_DATA_UPDATE, PROFILE_DATA_UPDATE_FAILED],
            payload: {
                promise: updateProfileDetailsAPI(getState().auth, body)
                    .then((res) => {
                        dispatch({type: SET_USER_PROFILE, payload: res});
                        return res
                    })
            },
            meta: {}
        })
    }
};

export const resetToDefaults = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_STATE
        })
    }
};

export const getUserRoleAction = (userId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                GET_USER_ROLE_PENDING,
                GET_USER_ROLE,
                GET_USER_ROLE_FAILED
            ],
            payload: {
                promise: getUserRoleAPI(getState().auth, userId)
                .then((res) => {
                    return res
                })
            },
            meta: {}
        })
    }
};

export const fetchPendingAppApprovalsAction = (userId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                FETCH_PENDING_APP_APPROVALS_PENDING,
                FETCH_PENDING_APP_APPROVALS,
                FETCH_PENDING_APP_APPROVALS_FAILED
            ],
            payload: {
                promise: fetchPendingAppApprovalsAPI(getState().auth, userId)
                .then((res) => {
                    return res
                })
            },
            meta: {}
        })
    }
};