/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 10:40 AM
 */

import {Grid, Tooltip, Typography, withStyles} from "@material-ui/core";
import React from "react";
import {APP_PLATFORMS} from "../../../../../../../../constants";
import {SECTIONS_ENUM} from "../utils";
import ClassicHolderCard from "./ClassicHolderCard";
import {EmptyStateImage} from "./Flow/assets/EmptyStateImage";

const styles = theme => ({
    toggleContainer: {
        height: 56,
        padding: `6px 12px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: `6px 0`,
        background: "transparent",
    },
});

function checkMessageStatus(message, sectionFilter, messageStatusMap) {
    if (sectionFilter === SECTIONS_ENUM.ALL)
        return messageStatusMap[message._id] !== SECTIONS_ENUM.DRAFTS;
    else
        return messageStatusMap[message._id] === sectionFilter;
}

class MessagesListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationIndex: 0,
            pageSize: 10,
        };
    }

    render() {
        const {paginationIndex, pageSize} = this.state;
        const {messageStatusMap, sectionFilter = "", messagesList = [], appId, dispatch, progressState, appMeta, basicMetrics = {}, appPlatform} = this.props;
        const filteredMessagesList = messagesList.filter((messageObj) => checkMessageStatus(messageObj, sectionFilter, messageStatusMap));
        const categoryWord = sectionFilter === SECTIONS_ENUM.DRAFTS ? "Draft" : (sectionFilter === SECTIONS_ENUM.ALL ? "" : sectionFilter);
        return (
            <div>
                {/*{ appMeta.api_pending &&*/}
                {/*// <Loading    */}
                {/*}*/}
                {filteredMessagesList.length === 0 && !appMeta.api_pending &&
                <div style={{padding: 36, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{
                        width: 200,
                        height: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#3e50b52e",
                        borderRadius: "100%"
                    }}>
                        <EmptyStateImage/>
                    </div>
                    <Typography variant={"subtitle2"} style={{marginTop: 32}}>
                        {`You have no ${categoryWord} Walkthroughs`}
                    </Typography>
                </div>}
                {filteredMessagesList.length > 0 &&
                <Grid spacing={16} container justify={"flex-start"} alignItems={"center"}
                      style={{
                          padding: "32px 16px 8px 16px",
                          top: 0
                      }}>
                    <Grid item xs={1}>
                        <Typography variant={"subtitle2"} gutterBottom style={{color: "#9e9e9e"}}>
                            Status
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={sectionFilter !== SECTIONS_ENUM.DRAFTS ? 4 : (appPlatform === APP_PLATFORMS.omni ? 5 : 6)}
                        style={{textAlign: "left"}}
                    >
                        <Typography variant={"subtitle2"} gutterBottom style={{color: "#9e9e9e"}}>
                            Campaign Name
                        </Typography>
                    </Grid>
                    {
                        appPlatform === APP_PLATFORMS.omni
                        && <Grid item xs={1} style={{textAlign: "center"}}>
                            <Typography variant={"subtitle2"} gutterBottom style={{color: "#9e9e9e"}}>
                                Platform
                            </Typography>
                        </Grid>
                    }
                    {
                        sectionFilter !== SECTIONS_ENUM.DRAFTS && <>
                            <Grid item xs={1} style={{textAlign: "center"}}>
                                <Tooltip title={"Impressions of number of Users who received the Campaign"}>
                                    <Typography variant={"subtitle2"} gutterBottom
                                                style={{color: "#9e9e9e", cursor: "help"}}>
                                        Reached
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={1} style={{textAlign: "center"}}>
                                <Tooltip
                                    title={"Impressions of number of Users who completed all steps of the Campaign"}>
                                    <Typography variant={"subtitle2"} gutterBottom
                                                style={{color: "#9e9e9e", cursor: "help"}}>
                                        Engaged
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </>
                    }
                    <Grid item xs={1} style={{textAlign: "left"}}>
                        <Typography variant={"subtitle2"} gutterBottom style={{color: "#9e9e9e"}}>
                            Start Date
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{textAlign: "left"}}>
                        <Typography variant={"subtitle2"} gutterBottom style={{color: "#9e9e9e"}}>
                            End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {/*<Typography variant={"subtitle2"} gutterBottom style={{color: "#9e9e9e"}}>*/}
                        {/*    Actions*/}
                        {/*</Typography>*/}
                    </Grid>
                </Grid>}
                {
                    filteredMessagesList.length > 0 &&
                    // .slice(paginationIndex * pageSize, (paginationIndex + 1) * pageSize)
                    filteredMessagesList
                    .map((message) => {
                        const noOfMessages = message.uis.length;
                        const shownId = noOfMessages > 0 ? (message._id + "-" + 1) : message._id;
                        const engagedId = noOfMessages > 0 ? (message._id + "-" + noOfMessages) : message._id;
                        return (
                            <ClassicHolderCard
                                progressState={progressState}
                                key={message._id}
                                message={message}
                                appId={appId}
                                appPlatform={appPlatform}
                                dispatch={dispatch}
                                statusType={messageStatusMap[message._id]}
                                basicMetrics={{
                                    shown: basicMetrics[shownId] || "NA",
                                    engaged: basicMetrics[engagedId] || "NA"
                                }}
                            />
                        )
                    })
                }
            </div>
        );
    }
}

export default withStyles(styles)(MessagesListing);
