/**
 * Created by Rakesh Peela
 * Date: 10-Apr-2020
 * Time: 11:00 PM
 */
import {
    BOT_DELETE,
    BOT_DELETE_FAILED,
    BOT_DELETE_PENDING,
    BOT_UPDATE,
    BOT_UPDATE_FAILED,
    BOT_UPDATE_PENDING,
    BOTS,
    BOTS_FAILED,
    BOTS_PENDING
} from "./actionTypes";

import {deleteBotAPI, getBotsAPI, updateBotAPI} from './api';

export const getBots = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                BOTS_PENDING,
                BOTS,
                BOTS_FAILED
            ],
            payload: {
                promise: getBotsAPI(appId, getState().auth)
                .then((res) => {
                    return res;
                }),
            },
            meta: {
                appId
            }
        });
    };
};

export const updateBot = (appId, bot) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                BOT_UPDATE_PENDING,
                BOT_UPDATE,
                BOT_UPDATE_FAILED
            ],
            payload: {
                promise: updateBotAPI(appId, getState().auth, bot)
                .then((res) => {
                    return res;
                }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, updated) => {
                    if (updated) {
                        dispatch(getBots(appId));
                    }
                }
            }
        });
    };
};

export const deleteBot = (appId, botId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                BOT_DELETE_PENDING,
                BOT_DELETE,
                BOT_DELETE_FAILED
            ],
            payload: {
                promise: deleteBotAPI(appId, getState().auth, botId)
                .then((res) => {
                    return res;
                }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, deleted) => {
                    if (deleted) {
                        dispatch(getBots(appId));
                    }
                }
            }
        });
    };
};
