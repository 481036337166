/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    USER_PROFILE_DYNAMIC_BASIC_INFO, USER_PROFILE_STATIC_BASIC_INFO, USER_PROFILE_FAILED, USER_PROFILE_PENDING,
    USER_PROFILE_SESSION_TIMELINE, USER_PROFILE_SESSIONS_LIST, USER_PROFILE_SESSIONS_TIME_SERIES,
    USER_PROFILE_SESSION_INFO, USER_PROFILE_USP_PERFORMANCE, USER_PROFILE_USP_STABILITY, USER_PROFILE_USP_FUNCTIONALITY,
    USER_PROFILE_USP_USABILITY, USER_PROFILE_CRASH_INFO, USER_PROFILE_TRANSACTION_INFO,
    RESET_USER_PROFILE, USER_PROFILE_SESSION_TIMELINE_FAILED, USER_PROFILE_SESSION_TIMELINE_PENDING,
    USER_PROFILE_SESSIONS_LIST_FAILED, USER_PROFILE_SESSIONS_LIST_PENDING, RESET_PROFILE_SESSIONS_LIST,
    UPDATE_SESSIONS_PAGINATION_COUNT, UPDATE_SESSIONS_PAGINATION_ORDER_BY,
    UPDATE_SESSIONS_PAGINATION_PAGE, UPDATE_SESSIONS_PAGINATION_SORT_ORDER
} from './actionTypes';
import {RESET_ALL} from '../../../../../../../constants/ActionTypes';
import { stackedTimeline } from '../../../../../../../utils';

/**
 *
 * @param currentState
 * @param payload
 * @param meta
 * @returns {{session_list: [*,*], meta_info}}
 */
function updateUserSessions(currentState, payload, meta){
    let sessionList = [...currentState.session_list, ...payload.session_list];
    if(meta.dataVersion < 2){ //Handling Toggle with STATUS here
        sessionList = payload.session_list;
    }
    return {
        session_list: sessionList,
        meta_info: payload.meta_info
    }
}

const INITIAL_SESSIONS_LIST_FLAG_STATE = {
    sessions_pending: false,
    sessions_failed: false
};
const INITIAL_STATE = Object.assign({
    static_basic_info: {},
    dynamic_basic_info: {},
    session_info: {},
    crash_info: {},
    transaction_info: {},
    functionality: 0,
    usability: 0,
    stability: 0,
    performance: 0,
    session_time_series: [],
    sessions: {
        meta_info: {},
        session_list: []
    },
    timeline_pending: false,
    timeline: [],
    actual_timeline: [],
    timeline_failed: false,
    pagination: {
        page: 1,
        orderBy: null,
        sortOrder: null,
        count: 10 //TODO:
    },
}, INITIAL_SESSIONS_LIST_FLAG_STATE);

function userReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case USER_PROFILE_STATIC_BASIC_INFO:
            return Object.assign({}, state, {
                static_basic_info: action.payload
            });

        case USER_PROFILE_DYNAMIC_BASIC_INFO:
            return Object.assign({}, state, {
                dynamic_basic_info: action.payload
            });

        case USER_PROFILE_SESSION_INFO:
            return Object.assign({}, state, {
                session_info: action.payload
            });

        case USER_PROFILE_CRASH_INFO:
            return Object.assign({}, state, {
                crash_info: action.payload
            });

        case USER_PROFILE_TRANSACTION_INFO:
            return Object.assign({}, state, {
                transaction_info: action.payload
            });

        case USER_PROFILE_USP_USABILITY:
            return Object.assign({}, state, {
                usability: action.payload
            });

        case USER_PROFILE_USP_STABILITY:
            return Object.assign({}, state, {
                stability: action.payload
            });

        case USER_PROFILE_USP_PERFORMANCE:
            return Object.assign({}, state, {
                performance: action.payload
            });

        case USER_PROFILE_USP_FUNCTIONALITY:
            return Object.assign({}, state, {
                functionality: action.payload
            });

        case USER_PROFILE_SESSIONS_TIME_SERIES:
            return Object.assign({}, state, {
                session_time_series: action.payload
            });

        case RESET_PROFILE_SESSIONS_LIST:
            return Object.assign({}, state, INITIAL_SESSIONS_LIST_FLAG_STATE, {
                sessions: {
                    meta_info: {},
                    session_list: []
                }
            });

        case USER_PROFILE_SESSIONS_LIST_PENDING:
            return Object.assign({}, state, INITIAL_SESSIONS_LIST_FLAG_STATE, {
                sessions_pending: true
            });

        case USER_PROFILE_SESSIONS_LIST_FAILED:
            return Object.assign({}, state, INITIAL_SESSIONS_LIST_FLAG_STATE, {
                sessions_failed: true
            });

        case USER_PROFILE_SESSIONS_LIST:
            return Object.assign({}, state, {
                sessions: updateUserSessions(state.sessions, action.payload, action.meta)
            });

        case USER_PROFILE_SESSION_TIMELINE_PENDING:
            return Object.assign({}, state, {
                timeline_failed: false,
                timeline_pending: true
            });

        case USER_PROFILE_SESSION_TIMELINE:
            return Object.assign({}, state, {
                timeline: stackedTimeline(action.payload),
                actual_timeline: action.payload,
                timeline_failed: false,
                timeline_pending: false
            });

        case USER_PROFILE_SESSION_TIMELINE_FAILED:
            return Object.assign({}, state, {
                timeline_failed: true,
                timeline_pending: false,
                timeline: [] //clearing existing timeline
            });

        case UPDATE_SESSIONS_PAGINATION_ORDER_BY:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    orderBy: action.payload
                })
            });

        case UPDATE_SESSIONS_PAGINATION_SORT_ORDER:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    sortOrder: action.payload
                })
            });

        case UPDATE_SESSIONS_PAGINATION_COUNT:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    count: action.payload
                })
            });

        case UPDATE_SESSIONS_PAGINATION_PAGE:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    page: action.payload
                })
            });

        case RESET_USER_PROFILE:
        case RESET_ALL:
            return INITIAL_STATE;

        case USER_PROFILE_PENDING:
        case USER_PROFILE_FAILED:
        default:
            return state;
    }
}

export default userReducer;