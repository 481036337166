
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateHeading, resetHeading} from '../../Navigation/actions';

import {
     FunnelHolder
} from './components';
import {
    getFunnel,
    updateFunnelQuery,
    getFunnelGroupedBy,
    getFunnelOpportunity,
    saveFunnel, deleteFunnelAction,
    getFunnelsList, updateComparingFunnels, getFunnelTimeseries
} from "./actions";
import {
    getAttributes,
    getAttributeValues,
    getEventAttributes, getEventAttributeValues, getEvents,
    resetQuery, updateQuery
} from "../Segments/NewSegment/actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        funnels: state.funnels,
        filters: state.filters,
        meta: state.meta,
        segmentBuilder: state.segmentBuilder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getFunnel: (appId) => dispatch(getFunnel(appId)),
        getFunnelsList: (appId) => dispatch(getFunnelsList(appId)),
        getFunnelGroupedBy: (appId) => dispatch(getFunnelGroupedBy(appId)),
        getFunnelOpportunity: (appId, attributes) => dispatch(getFunnelOpportunity(appId, attributes)),
        updateFunnelQuery: (query) => dispatch(updateFunnelQuery(query)),
        saveFunnel: (appId, funnelName) => dispatch(saveFunnel(appId, funnelName)),
        updateFunnelComparables: (appId, funnelList, force) => dispatch(updateComparingFunnels(appId, funnelList, force)),
        getFunnelTimeseries: (appId, funnelId) => dispatch(getFunnelTimeseries(appId, funnelId)),
        deleteFunnel: (appId, funnelId) => dispatch(deleteFunnelAction(appId, funnelId)),
        //From NewSegment
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getEvents: (appId) => dispatch(getEvents(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getEventAttributeValues: (appId, event, attribute, q) => dispatch(getEventAttributeValues(appId, event, attribute, q)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery()),
        bindedDispatch: dispatch
    };
}

class Funnels extends Component {

    constructor(props) {
        super(props);
        this.appId = this.props.params.appId;
        this.state = {}
    }

    componentWillMount(){
        const {  updateHeading, getAttributes, getEvents, getFunnelsList } = this.props;
        updateHeading("Funnels");
        getAttributes(this.appId);
        getEvents(this.appId);
        getFunnelsList(this.appId);
    }

    componentWillUnmount(){
        this.props.resetQuery();
    }

    componentWillReceiveProps(nextProps) {
        const { meta } = nextProps;
        //reloading data for updated filters
        const {
            params: {appId},
            funnels: {comparing_funnels},
            meta: {globalFiltersChanged},
            getFunnel,
            getFunnelGroupedBy,
            updateFunnelComparables
        } = this.props;
        if (meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged) {
            getFunnel(appId);
            getFunnelGroupedBy(appId);
            updateFunnelComparables(appId, comparing_funnels, true);
        }
    }

    render() {

        return (
            <section className="content" style={{marginBottom: 240}}>
                <FunnelHolder {...this.props}/>
            </section>
        );
    }
}

Funnels.propTypes = {

};

Funnels.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Funnels);