import React from 'react';
import moment from 'moment';
import MultiSelect from "../../../../../components/reusable/MaterialUi/MultiSelect";
import {areArraysEqual} from "../../../../../utils";
import Button from "@material-ui/core/Button";
import Apxor from 'apxor';
import { APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../constants';
import {session} from "../../../../../utils/Storage";

export class CohortFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCohort: null
        }
    }

    handleCohortChange = (cohortObj) => {
        const {navigation: {heading}} = this.props;
        Apxor.logEvent("SelectCohort", {cohort: cohortObj, navigation: heading}, APXOR_SDK_CATEGORY_CONSTANTS.COHORT);
        this.setState({currentCohort: cohortObj});
        this.props.updateCohortFilter(cohortObj);
        addParamToLocalStorage(this.props.params.appId,{"cohort": cohortObj});
        this.props.applyGlobalFilter();
    };

    componentWillMount() {
        const { params, filters } = this.props;
        this.props.getCohortsData(params.appId, filters);
        let currentCohort = null;
        try {
            currentCohort = JSON.parse(window.localStorage.getItem(params.appId)).cohort || []; //reads from persistentStorage if not set initially
        } catch (e) {
            currentCohort = null;
        }
        this.props.updateCohortFilter(currentCohort);
        this.setState({currentCohort});
    }

    render() {
        const { appState, cohorts : { cohorts }} = this.props;
        const { currentCohort } = this.state;
        return (
            <div style={{display: "flex", minWidth: 220}}>
                {
                    <MultiSelect
                        clearable={true}
                        handleChange={e => this.handleCohortChange(e)}
                        options={cohorts.map(o => ({value: o.cohort_id, label: o.name}))}
                        placeholder="Select Cohort"
                        single
                        style={{marginRight: 12}}
                        value={currentCohort}
                    />
                }
            </div>
        )
    }

}

function addParamToLocalStorage(appId, obj) {
    let existingStorageObj = {};
    try {
        existingStorageObj = JSON.parse(window.localStorage.getItem(appId));
        // console.log("Existing StorageObj")
        window.localStorage.setItem(appId, JSON.stringify({...existingStorageObj, ...obj}));
    } catch {
        window.localStorage.setItem(appId, JSON.stringify({...obj}));
    }
}
export class VersionFilter extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            currentVersions : []
        };
    }

    handleVersionsUpdate = (newVersions) => {
        const { params: { appId } } = this.props;
        const {navigation: {heading}} = this.props;
        session.set(appId, JSON.stringify({"app_version": newVersions})); //FIXME: figureout good way here
        Apxor.logEvent("VersionsChanged", {navigation: heading, selected_versions: newVersions}, APXOR_SDK_CATEGORY_CONSTANTS.VERSION)
        addParamToLocalStorage(this.props.params.appId, { "app_version" : newVersions }); //FIXME: figureout good way here
        this.props.updateVersions(newVersions);
        this.props.applyGlobalFilter();
    };

    componentWillMount(){
        const { params } = this.props;
        //FIXME: use persistentStorage
        let currentVersions = [];
        try {
            const fromSession = session.getParsed(params.appId);
            currentVersions = fromSession ? (fromSession.app_version || []) : []; //reads from persistentStorage if not set initially
        } catch (e) {
            currentVersions = [];
        }
        this.props.updateVersions(currentVersions); //FIXME: list of all needed
        this.setState({currentVersions});
    }

    handleChange = (versions) => {
        Apxor.logEvent("SelectVersion", {version: versions}, "");
        this.setState({currentVersions: versions});
    };

    render() {
        const { appState, filters: { versions }} = this.props;
        const allVersions = appState.app ? appState.appVersions : [];
        const { currentVersions } = this.state;
        const changed = !areArraysEqual(versions, currentVersions);
        return (
            <div style={{display: 'flex', minWidth: 220}}>
                <MultiSelect
                    clearable={false}
                    handleChange={this.handleChange}
                    multiple
                    options={allVersions.map(o => ({value: o, label: o}))}
                    placeholder="Select Version"
                    value={currentVersions}
                    valueRenderer={value => value.length === 0 ? "All Versions" : value.join(", ")}
                />
                {
                    changed && <Button
                        style={{marginLeft: 4}}
                        color="primary"
                        variant="contained"
                        onClick={e => {
                            this.handleVersionsUpdate(currentVersions);
                        }}
                        size="small"
                    >Apply</Button>
                }
            </div>
        );
    }
}

const COMPARABLE_DATE_FORMAT = "DDMMYYYY";

/**
 *
 * @param a [Moment]
 * @param b [Moment]
 * @returns {boolean}
 */
function areSame(a, b){
    return a.format(COMPARABLE_DATE_FORMAT) === b.format(COMPARABLE_DATE_FORMAT);
}

function isValidChange(existing, changed, start, end) {
    return !areSame(existing, changed) && ((start && start.diff(changed) <= 0) || (end && end.diff(changed) >= 0));
    /*if(!areSame(existing, changed)){
        return true;
    }else {
        if (start && start.diff(changed) <= 0) return true;
        if (end && end.diff(changed) >= 0) return true;
    }
    return false;*/
}

/**
 * @deprecated
 */
export class TimeFilter extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            minDate: moment(new Date("07-01-2017")),
            maxDate: moment(),
            startDate: moment().subtract(6, 'days'),
            endDate: moment()
        }
    }

    updateTime = (start, end) => { //normalized updation
        this.setState({startDate: start, endDate: end}); //if came form props
        const normalizedStartTime = new Date(start.format("YYYY-MM-DD"));
        const normalizedEndTime = new Date(end.format("YYYY-MM-DD"));
        this.props.onFilterChange(normalizedStartTime, normalizedEndTime);
    };

    componentWillMount(){
        const { startDate, endDate } = this.state;
        const { since = startDate.toISOString(), till = endDate.toISOString() } = this.props.filters;
        this.updateTime(moment(since), moment(till));
    }

    handleStartDateChange = startDate => {
        if(isValidChange(this.state.startDate, startDate, undefined, this.state.endDate)) {
            this.updateTime(startDate, this.state.endDate);
            this.setState({startDate}, this.props.applyGlobalFilter);
        }
    };

    handleEndDateChange = endDate => {
        if(isValidChange(this.state.endDate, endDate, this.state.startDate, undefined)) {
            this.updateTime(this.state.startDate, endDate);
            this.setState({endDate}, this.props.applyGlobalFilter);
        }
    };

    render(){
        return (
            <div style={{display: 'flex', maxWidth: 260}}>
                {/*<DatePicker disabled={disabled} value={startDate} handleChange={this.handleStartDateChange} />
                &nbsp;
                <DatePicker disabled={disabled} value={endDate} handleChange={this.handleEndDateChange} />*/}
            </div>
        );
    }
}