/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    GET_APP, EXIT_DASHBOARD,
    GET_PATH_IDS, GET_SESSION_GROUP_IDS,
    GET_USER_GROUP_IDS, TOTAL_INSTALLS,
    TOTAL_UNINSTALLS, ALL_PENDING, ALL_FAILED,
    RESET_APP_DATA, GET_APP_EVENTS, APP_SCREEN_NAMES,
    APP_ATTRIBUTES, APP_SEGMENTS, APP_TEST_DEVICES, APP_CONTEXTS, APP_VERSIONS
} from './actionTypes';
import { appFromList } from '../../../../../../utils';
import logger from '../../../../../../utils/Logger';
import {
    getPathIdsAPI, getSessionGroupIdsAPI,
    getUserGroupIdsAPI, getTotalInstallsAPI,
    getTotalUninstallsAPI, getAppAPI, getAppEventsAPI, getAppScreenNamesAPI,
    getAttributesAPI, getAppVersionsAPI
} from './api';
import { resetAll } from '../../../../../../actions';
import {getBots} from "../BotsList/actions";
import {getApps} from "../actions";
import {getAttributeDistributionsAPI, segmentsAPI, testDevicesAPI} from "../../../../../../api";
import {getDashboards} from "../../Dashboard/CustomDashboards/actions";
import {getContextsAPI} from "../../Dashboard/Contexts/api";

/**
 * Called when URL directly from browser window
 * @param appId
 * @param force
 * @returns {function(*, *)}
 */
export const getApp = (appId, force = false) => {
    return (dispatch, getState) => {
        const apps = getState().apps.apps;
        if(!force && apps && apps.length > 0){
            dispatch(enterDashboard(appId));
        }else{
            return dispatch({
                types: [
                    ALL_PENDING,
                    GET_APP,
                    ALL_FAILED
                ],
                payload: {
                    promise: getAppAPI(getState().auth, appId)
                        .then((res) => {
                            //getMetaDataOfApp(appId); Why here? Immature programming days..!
                            return res;
                        })
                },
                meta: {
                    //If Any
                }
            });
        }
    };
};

export const getMetaDataOfApp = (appId) => {
    return (dispatch, getState) => {
        //Getting meta for app
        //dispatch(getUserGroupIds(appId));
        //dispatch(getPathIds(appId));
        //dispatch(getSessionGroupIds(appId));
        dispatch(getAppVersions(appId));
        dispatch(getAppEvents(appId));
        // dispatch(getAppScreens(appId));
        dispatch(getAttributes(appId));
        dispatch(getAppSegments(appId));
        dispatch(getAppTestDevices(appId));
        dispatch(getDashboards(appId));
        dispatch(getAppContexts(appId));
        dispatch(getBots(appId));
    };
};

/**
 * It hydrates appReducer with current App context i.e AppModel
 * @param appId
 * @returns {function(*, *)}
 */
export const enterDashboard = (appId) => {
    return (dispatch, getState) => {
        const apps = getState().apps.apps;
        if( !apps || apps.length === 0) {
            dispatch(getApps(appId));
        }else {
            return dispatch({
                type: GET_APP,
                payload: appFromList(apps, appId),
                meta: {
                    //If Any
                }
            });
        }
    };
};

/**
 * It dehydrates appReducer.
 * It is called when we enter myApps page
 * @returns {function(*, *)}
 */
export const exitDashboard = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: EXIT_DASHBOARD,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getTotalInstalls = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [ALL_PENDING, TOTAL_INSTALLS, ALL_FAILED],
            payload: {
                promise: getTotalInstallsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getTotalUninstalls = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [ALL_PENDING, TOTAL_UNINSTALLS, ALL_FAILED],
            payload: {
                promise: getTotalUninstallsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param groupId
 * @returns {function(*, *)}
 */
export const getUserGroupIds = (appId, groupId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                ALL_PENDING,
                GET_USER_GROUP_IDS,
                ALL_FAILED
            ],
            payload: {
                promise: getUserGroupIdsAPI(getState().auth, appId)
                    .then((res) => {
                        return res;
                    }).catch((err) => {
                        logger.error("Error for APP Meta userGroups ::", err);
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAppEvents = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                ALL_PENDING,
                GET_APP_EVENTS,
                ALL_FAILED
            ],
            payload: {
                promise: getAppEventsAPI(getState().auth, appId)
                    .then((res) => {
                        return res;
                    }).catch((err) => {
                        logger.error("Error for App Events ::", err);
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAppScreens = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                ALL_PENDING,
                APP_SCREEN_NAMES,
                ALL_FAILED
            ],
            payload: {
                promise: getAppScreenNamesAPI(getState().auth, appId)
                    .then((res) => {
                        return res;
                    }).catch((err) => {
                        logger.error("Error for App Events ::", err);
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAppSegments = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                ALL_PENDING,
                APP_SEGMENTS,
                ALL_FAILED
            ],
            payload: {
                promise: segmentsAPI(appId, getState().auth)
                    .then((res) => {
                        return res;
                    }).catch((err) => {
                        logger.error("Error for App Events ::", err);
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAppTestDevices = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                ALL_PENDING,
                APP_TEST_DEVICES,
                ALL_FAILED
            ],
            payload: {
                promise: testDevicesAPI(appId, getState().auth, {})
                    .then((res) => {
                        return res;
                    }).catch((err) => {
                        logger.error("Error for App Events ::", err);
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param group
 * @returns {function(*, *)}
 */
export const updateUserGroupIds = (group) => {
    return (dispatch, getState) => {
        let existingGroups = getState().app.userGroupIds;
        existingGroups.push(group); //adding new group to existing groups
        return dispatch({
            type: GET_USER_GROUP_IDS,
            payload: existingGroups,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getPathIds = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [ALL_PENDING, GET_PATH_IDS, ALL_FAILED],
            payload: {
                promise: getPathIdsAPI(getState().auth, appId)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getSessionGroupIds = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [ALL_PENDING, GET_SESSION_GROUP_IDS, ALL_FAILED],
            payload: {
                promise: getSessionGroupIdsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetAppData = () => {
    return (dispatch, getState) => {
        dispatch(resetAll()); //resetting All Dashboard Data
        return dispatch({
            type: RESET_APP_DATA,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

export const getAttributes = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters};
        return dispatch({
            types: [
                ALL_PENDING,
                APP_ATTRIBUTES,
                ALL_FAILED
            ],
            payload: {
                promise: getAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    const { user, session } = attributes;
                    if(Array.isArray(user) || Array.isArray(session)) {
                        //TODO:
                    }else{
                        logger.error("No attributes fetched..!");
                    }
                }
            }
        });
    };
};

export const getAppContexts = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters};
        return dispatch({
            types: [
                ALL_PENDING,
                APP_CONTEXTS,
                ALL_FAILED
            ],
            payload: {
                promise: getContextsAPI(appId, getState().auth, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAppVersions = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, of: 'users', attribute: "apx_version"};
        return dispatch({
            types: [
                ALL_PENDING,
                APP_VERSIONS,
                ALL_FAILED
            ],
            payload: {
                // promise: getAttributeDistributionsAPI(appId, getState().auth, filters)
                promise: getAppVersionsAPI(appId, getState().auth)
                    .then((res) => {
                        return res.filter(x => x && x !== "").sort((a, b) => b.localeCompare(a));
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};