/**
 * Created by Rakesh Peela
 * Date: 19-Dec-2019
 * Time: 6:03 PM
 */

import {ButtonBase, Grid, withStyles} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {APP_PLATFORMS} from "../../../../../../../../../../constants";
import {capitalizeEachWord} from "../../../../../../../../../../utils";
import ClassicCard from "../../ClassicCard";
import {ACTION_CLASSES, ACTION_OPTIONS} from "../models/AvailableOptions";
import {getActionClassLogo} from "../utils";
import DummyInsetContainer from "./DummyInsetContainer";
import ExpansionModuleConfig from "./ExpansionModuleConfig";

const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 540,
    },
    listSection: {
        backgroundColor: "inherit",
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0,
    },
    listSubheaderCustom: {
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
        textTransform: "capitalize"
    }
});

class ActionTypeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewJSON: null,
            selectedItem: null
        };
    }

    getActionDisplayText = (action) => {
        switch (action) {
            case ACTION_CLASSES.TOOLTIP:
                return "Anchor Tooltips to UI Element";
            case ACTION_CLASSES.COACHMARK:
                return "Choose Coachmark to Highlight UI Element";
            case ACTION_CLASSES.INAPP:
                return "Communicate and Prompt Actions via In App Messages";
            case ACTION_CLASSES.NUDGE:
                return "Grab users attention by Nudging them passively";
            default:
                return capitalizeEachWord(action);
        }
    };

    render() {
        const {messages: {new_message: {meta: {platform}}}} = this.props;
        const {
            previewJSON, selectedItem
        } = this.state;
        const isDevEnv = process.env.NODE_ENV !== "production";

        let defaultActionOptions = ACTION_OPTIONS();

        return (
            <Grid container spacing={16} justify={"center"}>
                <Grid item md={isDevEnv ? 6 : 12}>
                    <DummyInsetContainer style={{height: 500, overflowY: "scroll", overflowX: "hidden"}}>
                        {
                            Object.keys(defaultActionOptions).filter(action => defaultActionOptions[action].length > 0).map((action, index) => (
                                <ExpansionModuleConfig
                                    title={action}
                                    titleTypoStyles={{textTransform: "capitalize", fontWeight: 600, fontSize: 16}}
                                    description={this.getActionDisplayText(action)}
                                    descriptionTypoStyles={{
                                        textTransform: "capitalize",
                                        color: "#929292",
                                        fontWeight: 400
                                    }}
                                    key={`exp-${index}-${action}`}
                                    defaultExpanded={true}
                                >
                                    <Grid container spacing={16} alignItems={"stretch"} style={{padding: "12px 0"}}>
                                        {
                                            defaultActionOptions[action]
                                            .filter(option => {
                                                if (platform === APP_PLATFORMS.omni) {
                                                    return true;
                                                }
                                                if (defaultActionOptions[action].length <= 0) {
                                                    return false
                                                }
                                                let valid = false;
                                                if (Array.isArray(option.requisites)) {
                                                    for (let i = 0; i < option.requisites.length; i++) {
                                                        if (option.requisites[i].platform === platform) {
                                                            valid = true;
                                                            break;
                                                        }
                                                    }
                                                }
                                                return valid;
                                            })
                                            .map(actionItem => (
                                                <Grid item xs={6} md={6}
                                                      key={`${action}-${actionItem["type"]}-grid`}>
                                                    <ButtonBase
                                                        style={{
                                                            borderRadius: 10,
                                                            textAlign: "inherit",
                                                            width: "100%",
                                                        }}
                                                        key={`${action}--${actionItem["type"]}`}
                                                        disableRipple disableTouchRipple
                                                        disabled={!actionItem.available}
                                                        onDoubleClick={() => {
                                                            const {updateNewStepData, successCallback} = this.props;
                                                            updateNewStepData({
                                                                ...actionItem.data,
                                                                display_text: actionItem["display_text"]
                                                            });
                                                            this.setState({
                                                                selectedItem: action + "-" + actionItem["type"],
                                                                previewJSON: actionItem.data
                                                            }, () => {
                                                                successCallback();
                                                            })
                                                        }}
                                                        onClick={() => {
                                                            const {updateNewStepData} = this.props;
                                                            updateNewStepData({
                                                                ...actionItem.data
                                                            });
                                                            this.setState({
                                                                selectedItem: action + "-" + actionItem["type"],
                                                                previewJSON: actionItem.data
                                                            })
                                                        }}
                                                    >
                                                        <ClassicCard
                                                            style={{width: "100%"}}
                                                            disabled={!actionItem.available}
                                                            selected={`${action}-${actionItem["type"]}` === selectedItem}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={16}
                                                                alignItems={"center"}
                                                            >
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        width: "100%",
                                                                        borderRadius: 8,
                                                                        background: "#F2F2F2",
                                                                        minHeight: 98,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    {getActionClassLogo(action, actionItem["type"])}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        width: "100%",
                                                                        padding: 8,
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={`${actionItem["display_text"]}`}
                                                                        primaryTypographyProps={{style: {fontWeight: 600}}}
                                                                        // secondary={`${actionItem.requisites.map(req => (req.platform + ">=" + req.minSDK))}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </ClassicCard>
                                                    </ButtonBase>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </ExpansionModuleConfig>
                            ))
                        }
                    </DummyInsetContainer>
                </Grid>
                {
                    isDevEnv && <Grid item md={6}>
                        <p>
                            {selectedItem}
                        </p>
                        <pre style={{maxHeight: 400, overflowY: "scroll", background: "#cccccc", padding: 8}}>
                                {JSON.stringify(previewJSON, null, 2)}
                            </pre>
                    </Grid>
                }
            </Grid>
        );
    }

}

export default withStyles(styles)(ActionTypeSelector);