/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {RESET_ALL} from '../../../../../constants/ActionTypes';
import * as Actions from "./actionTypes";
import {
    BOT_DELETE,
    BOT_DELETE_FAILED,
    BOT_DELETE_PENDING,
    BOT_UPDATE,
    BOT_UPDATE_FAILED,
    BOT_UPDATE_PENDING,
    BOTS,
    BOTS_FAILED,
    BOTS_PENDING
} from "./BotsList/actionTypes";

const INITIAL_FLAG_STATE = {
    appsLoaded: false,
    appsLoading: false,
    appsFailed: false,
    deletingApp: false,
    deleteSuccess: false,
    deleteFailed: false,
    approveAppSuccess: false,
    approveAppPending: false,
    approveAppFailed: false,
    showAddAppDialog: false
};

const INITIAL_BOTS_FLAG_STATE = {
    bots_pending: false,
    bots_failed: false
};

const INITIAL_BOT_UPDATE_STATE = {
    bot_update_pending: false,
    bot_update_failed: false
};

const INITIAL_BOT_DELETE_STATE = {
    bot_delete_pending: false,
    bot_delete_failed: false
};


const INITIAL_STATE = {
    apps : [],
    ...INITIAL_FLAG_STATE,
    bots: {},
    bot_updated: false,
    ...INITIAL_BOT_DELETE_STATE,
    ...INITIAL_BOT_UPDATE_STATE,
    ...INITIAL_BOTS_FLAG_STATE
};

function appsReducer(state = INITIAL_STATE, action = {}) {
    const { meta, payload} = action;
    switch (action.type) {

        case Actions.GET_APPS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                apps : action.payload,
                appsLoaded: true
            });

        case Actions.GET_APPS_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                appsLoading: true
            });

        case Actions.GET_APPS_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                appsFailed: true
            });

        case Actions.APPROVE_APP:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                approveAppSuccess:true
            };

        case Actions.APPROVE_APP_FAILED:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                approveAppFailed: true,
            };

        case Actions.APPROVE_APP_PENDING:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                approveAppPending: true
            };

        case Actions.DELETE_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                apps : state.apps.filter(app => {
                    return app.app_id !== action.payload.appId
                }),
                deleteSuccess: true
            });

        case Actions.DELETING_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                deletingApp: true
            });

        case Actions.DELETE_FAILED:
            return Object.assign({}, state, {
                deleteFailed: true
            });

        case Actions.RESET_CREATE_APP_DIALOG:
            return {
                ...state,
                showAddAppDialog: true
            };

        case Actions.HIDE_CREATE_APP_DIALOG:
            return {
                ...state,
                showAddAppDialog: false
            };

        case Actions.SHOW_CREATE_APP_DIALOG:
            return {
                ...state,
                showAddAppDialog: true
            };

        case Actions.RESET_APP_APPROVAL:
            return {
                ...state,
                approveAppSuccess: false,
                approveAppPending: false,
                approveAppFailed: false,
                showAddAppDialog: true
            };
        case BOTS_PENDING:
            return {
                ...state,
                ...INITIAL_BOTS_FLAG_STATE,
                bots_pending: true
            };

        case BOTS:
            return {
                ...state,
                ...INITIAL_BOTS_FLAG_STATE,
                bots: {
                    ...state.bots,
                    [meta.appId]: payload
                }
            };

        case BOTS_FAILED:
            return {
                ...state,
                ...INITIAL_BOTS_FLAG_STATE,
                bots_failed: true
            };

        case BOT_UPDATE_PENDING:
            return {
                ...state,
                ...INITIAL_BOT_UPDATE_STATE,
                bot_update_pending: true
            };

        case BOT_UPDATE:
            return {
                ...state,
                ...INITIAL_BOT_UPDATE_STATE,
                bot_updated: true
            };

        case BOT_UPDATE_FAILED:
            return {
                ...state,
                ...INITIAL_BOT_UPDATE_STATE,
                bot_update_failed: true
            };

        case BOT_DELETE_PENDING:
            return {
                ...state,
                ...INITIAL_BOT_DELETE_STATE,
                bot_delete_pending: true
            };

        case BOT_DELETE:
            return {
                ...state,
                ...INITIAL_BOT_DELETE_STATE,
                bot_deleted: true
            };

        case BOT_DELETE_FAILED:
            return {
                ...state,
                ...INITIAL_BOT_DELETE_STATE,
                bot_delete_failed: true
            };
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;

    }
}

export default appsReducer;