/**
 * Created by Rakesh Peela
 * Date: 10-Apr-2020
 * Time: 11:00 PM
 */
import {callApi} from '../../../../../../api';
import {makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';

const BOTS_API = "bots";

export function getBotsAPI(appId, auth) {
    const url = makeDefaultQueryString(BOTS_API, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateBotAPI(appId, auth, botId, bot) {
    const url = makeDefaultQueryString(BOTS_API, auth, appId);
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(bot)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteBotAPI(appId, auth, botId) {
    const url = makeDefaultQueryString(BOTS_API, auth, appId);
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}