/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */

//FIXME: Remove usage, moved the below to constants
export const SERVER_IDENTIFIERS = {
    google: "google",
    azure: "azure",
    aws: "aws"
};

export const ANDROID_SDK_REPOSITORIES = {
    repo1: "http://repo1.apxor.com",
    repo2: "http://repo2.apxor.com"
};

export const DASHBOARD_ENDPOINTS = {
    ALERTS: "alerts",
    APPS: "apps",
    APPROVAL: "approve",
    AUDIENCE: "audience",
    CONTEXTS: "contexts",
    CUSTOM_GROUPING: "custom-grouping",
    CUSTOM_REPORTS: "custom-reports",
    DASHBOARDS: "dashboards",
    DOWNLOADS: "downloads",
    DROP_OFF: "drop-off",
    ENGAGEMENT_REPORT: "reports/key-metrics",
    EVENT_ANALYSIS: "event-analysis",
    FUNNELS: "funnels",
    HEALTH: "health",
    INCIDENTS: "incidents",
    INTEGRATION: "integration",
    ISSUE_DETAILS: "issues",
    LOGIN: "login",
    MESSAGES: "messages",
    MESSAGES2: "walkthroughs",
    OVERVIEW: "overview",
    PATH: "path",
    PREPROCESS: "preprocess",
    PROFILE: "profile",
    PUSH: "push",
    REPORTS: "reports",
    RESET_PASSWORD: "reset-password",
    RETENTION: "retention",
    SEGMENTS: "segments",
    SETTINGS: "settings",
    SETTINGS_DATA: "settings/downloads",
    SETTINGS_IAM: "settings/iam",
    SETTINGS_SDK: "settings/sdk",
    SETTINGS_SESSIONS: "settings/sessions",
    SETTINGS_TEST_DEVICES: "settings/test-devices",
    SETTINGS_USERS: "settings/users",
    SIGN_UP: "sign_up",
    SURVEYS: "surveys",
    UNAUTHORISED: "unauthorised",
    UNINSTALL_REPORT: "reports/day0-analysis",
    UNINSTALLS: "uninstalls",
    USER: "user",
    USER_EXPLORER: "user-explorer",
    USERS: "users"
};

export const DATE_FILTERS_INVALID_PATHS = [ //TODO: more will come
    DASHBOARD_ENDPOINTS.DOWNLOADS,
    DASHBOARD_ENDPOINTS.SETTINGS_USERS,
    DASHBOARD_ENDPOINTS.SETTINGS,
    DASHBOARD_ENDPOINTS.ENGAGEMENT_REPORT,
    DASHBOARD_ENDPOINTS.MESSAGES,
    DASHBOARD_ENDPOINTS.MESSAGES2,
    DASHBOARD_ENDPOINTS.SEGMENTS + "$",
    DASHBOARD_ENDPOINTS.SETTINGS_TEST_DEVICES,
    DASHBOARD_ENDPOINTS.DASHBOARDS + "$",
    DASHBOARD_ENDPOINTS.CONTEXTS,
    DASHBOARD_ENDPOINTS.SURVEYS + "$",
    DASHBOARD_ENDPOINTS.PREPROCESS,
    DASHBOARD_ENDPOINTS.PUSH,
    DASHBOARD_ENDPOINTS.APPROVAL
];

export const DATE_FILTERS_DISABLED_PATHS = [ //TODO: more will come
    //`${DASHBOARD_ENDPOINTS.SEGMENTS}/new`
];

export const NON_DASHBOARD_ENDPOINTS = [
    DASHBOARD_ENDPOINTS.LOGIN,
    DASHBOARD_ENDPOINTS.SIGN_UP,
    DASHBOARD_ENDPOINTS.INTEGRATION,
    DASHBOARD_ENDPOINTS.APPROVAL
];

export const API_QUERY_PARAMS = {
    customerId: "customerId",
    appId: "appId",
    modeId: "modeId",
    groupId: "groupId",
    sessionGroupId: "sessionGroupId",
    pathId: "pathId",
    issueId: "issueId",
    sessionId: "sessionId",
    activityId: "activityId",
    customer: "customer",
    since: "since",
    till: "till",
    versions: "versions",
    modes: "modes",
    language: "language",
    country: "country",
    devices: "devices",
    os_version: "os_version",
    acquisition: "acquisition",
    event: "event",
    attribute: "attribute",
    q: "q",
    limit: "limit",
    prelaunchId: "prelaunchId", //FIXME: not this until server fixes
    preLaunchId: "preLaunchId",
    status: "status",
    version: "version",
    sessionParam: "sessionParam",
    userState: "userState",
    granularity: "granularity",
    metric: "metric",
    issueStatus: "issueStatus",
    userId: "userId",
    nodeIndex: "nodeIndex",
    dateTime: "dateTime",
    navigations: "navigations",
    installationDate: "installationDate",
    incident_type: "incident_type",
    startDate: "startDate",
    endDate: "endDate"
};

export const SERVER_ENDPOINTS = { //TODO:

};

export const AUTH_ERROR_ENUM = { //re login require messages
    UNAUTHORISED:  "Please login to continue..!",
    MISSING_TOKEN: "Session expired. Please login to continue..!",
    MISSING_HOST: "Something went wrong. Please login again..!"
};

export const MATTERMOST_DASHBOARDS_EVENTS_API = "https://mattermost.apxor.com/hooks/unz39stej7fabrr6kzcw399tsc";
