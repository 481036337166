/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 6:33 PM
 */

import debounce from "lodash/debounce";
import React from "react";
import {APP_PLATFORMS} from "../../../../../../../../../constants";
import action_buttons_tooltip from './assets/action_buttons_tooltip.png';
import basic_tooltip from './assets/basic_tooltip.png';
import custom_html from './assets/custom_html.png';
import footer_inapp from './assets/footer_inapp.png';
import header_inapp from './assets/header_inapp.png';
import image_text_tooltip from './assets/image_text_tooltip.png';
import modal_inapp from './assets/modal_inapp.png';
import nudge from "./assets/nudge.png";
import coachmark from "./assets/coachmark.png";
import {AndroidLogo, AppleLogo, WebLogo} from "./assets/Logos";
import ActionsTextField from "./components/ActionsTextField";
import ModuleConfigSection from "./components/modules/ModuleConfigSection";
import {ACTION_CLASSES} from "./models/AvailableOptions";
import {INAPP_LAYOUT_TYPES, MESSAGE_TOOLTIP_LAYOUT_TYPES} from "./models/common";
import styled from 'styled-components';

const hasContent = (prop) => typeof prop !== 'undefined' && prop.length > 0;

const isJson = (prop) => typeof prop !== 'undefined' && Object.keys(prop).length > 0;

export const DEFAULT_IMAGE_SIZE_TYPES = ['Cover', 'Contain', 'Stretch'];

const translateToStyles = (config) => {
    const {color, font_family, size, style} = config;
    return `color: ${color}; font-family: ${font_family}; font-size: ${size}px; font-style: ${style !== "bold" ? style : "normal"}; font-weight: ${style === "bold" ? style : "normal"}; margin: 0; padding-top: 5px; line-height: ${Number(size) + 2}px;`;
};

const getTextContent = (config, only_image = false, style = "") => {

    if (!isJson(config) || only_image) {
        return ``;
    }

    return `<div style="padding: 4px; ${style};">
      <p style="${translateToStyles(config)};">${config.text}</p>   
    </div>`;
};

const WITH_HTML_HOLDER = (content) => {
    return `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                *{
                    box-sizing: border-box;
                }
            </style>
        </head>
        <body style="margin: 0; padding: 0;">
            ${content}
        </body>
    </html>
    `;
};

const IconImageComponent = (imageSrc, imageAlt, respectHeight) => {
    let style = {
        margin: "auto"
    };

    if (respectHeight)
        style = {
            maxWidth: 128,
            maxHeight: 72,
            margin: "auto"
        };

    return <img src={imageSrc} alt={imageAlt} style={style}/>
};

export const getActionClassLogo = (action_class, item_type, respectHeight) => {

    if (action_class === ACTION_CLASSES.TOOLTIP) {
        switch (item_type) {
            case MESSAGE_TOOLTIP_LAYOUT_TYPES.DEFAULT:
                return IconImageComponent(basic_tooltip, "basic_tooltip");
            case MESSAGE_TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS:
                return IconImageComponent(image_text_tooltip, "image_text_tooltip");
            case MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS:
                return IconImageComponent(action_buttons_tooltip, "action_buttons_tooltip");
            default:
                return ""
        }
    }

    if (action_class === ACTION_CLASSES.INAPP) {
        switch (item_type) {
            case INAPP_LAYOUT_TYPES.FOOTER:
                return IconImageComponent(footer_inapp, "footer_inapp", respectHeight);
            case INAPP_LAYOUT_TYPES.HEADER:
                return IconImageComponent(header_inapp, "header_inapp", respectHeight);
            case INAPP_LAYOUT_TYPES.CUSTOM_HTML:
                return IconImageComponent(custom_html, "header_inapp", respectHeight);
            case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
            case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
                return IconImageComponent(modal_inapp, "modal_inapp", respectHeight);
            default:
                return "";
        }
    }

    if (action_class === ACTION_CLASSES.NUDGE) {
        return IconImageComponent(nudge, action_class, respectHeight);
    }

    if (action_class === ACTION_CLASSES.COACHMARK) {
        return IconImageComponent(coachmark, action_class, respectHeight);
    }

};

/**
 * Constructs the href string based on the given configuration
 *
 * @param button_config
 * @returns {string}
 */
const getHrefFromActionConfig = (button_config) => {
    const {text_config, action_config: {action, activity, parameters = [], share_options = {}, deep_link = {}}} = button_config;

    let href = "apxor://";
    switch (action) {
        case "redirect":
            if (deep_link instanceof Object && deep_link && Object.keys(deep_link).length > 0) {
                // If deep_link is configured
                const {uri, intent_action = "android.intent.action.VIEW"} = deep_link;

                href += "dl_url?uri=" + uri + "&action=" + intent_action + "&buttonName=" + text_config.text;
            } else {
                href += "dl?activity=" + activity + "&buttonName=" + text_config.text;
                if (parameters.length > 0) {
                    // Iterate over all configured parameters, and append them to the URL
                    // &<PARAM_DATATYPE>___<PARAM_KEY>___<PARAM_VALUE>
                    parameters.forEach((param) => {
                        href += "&" + param.datatype + "__" + param.key + "__" + param.value;
                    });
                }
            }
            break;
        case "cancel":
            href += "cancel?buttonName=" + text_config.text;
            break;
        case "share":
            if (Object.keys(share_options) > 0) {
                href += "share?type=text/plain&action=android.intent.action.SEND&extraText=" + share_options['extra_text'] + "&title=" + share_options['title'] + "&package=" + share_options['package'];
            } else {
                href += "close?buttonName=" + text_config.text;
            }
            break;
        default:
            href += "close?buttonName=" + text_config.text;
            break;
    }

    return href;
};

export const getScreenNameForPlatform = (platform) => {
    switch (platform) {
        case APP_PLATFORMS.ios:
            return "View Controller";
        case APP_PLATFORMS.web:
            return "Page";
        default:
            return "Activity"
    }
};

/**
 * Constructs the HTML layout for buttons
 *
 * @param config
 * @param only_image
 * @param style
 * @returns {string}
 *          HTML String if `only_image` flag is FALSE
 *          Otherwise, returns EMPTY string
 * @constructor
 */
const WITH_BUTTONS = (config, only_image = false, style = "") => {
    if (config === undefined || config === null || only_image) {
        return ``;
    }

    let buttonsHtml = `<div style="display: flex; flex-direction: row; justify-content: flex-end; padding: 4px;">`;

    config.forEach((button, index) => {
        if (isJson(button)) {
            buttonsHtml += `<a role="button" href="${getHrefFromActionConfig(button)}" style="text-decoration: none; background-color: ${button.color}; ${translateToStyles(button.text_config)}; padding: 14px; line-height: 0; width: 100%; margin-right: ${(index + 1) === config.length ? 0 : '8px'}; text-align: center; border-radius: 4px; ${style}">${button.text_config.text}</a>`;
        }
    });

    buttonsHtml += `</div>`;

    return buttonsHtml;
};

const HEADER_OR_FOOTER_CONTENT = (new_ui, isBottom = false) => {
    const {only_image, bg_image, image_size, touch_redirect_url} = new_ui;

    if (only_image) {
        return `
        <div style="overflow: hidden; display: flex; justify-content: ${isBottom ? 'flex-end' : 'flex-start'}; flex-direction: column;">
            <div style="width: 100%; height: 100%; background-position: center; background-repeat: no-repeat; background-image: url(${bg_image}); background-size: ${image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase()}; border-radius: ${!isBottom ? '0px' : border_radius + 'px'} ${!isBottom ? '0px' : border_radius + 'px'} ${isBottom ? '0px' : border_radius + 'px'} ${isBottom ? '0px' : border_radius + 'px'}" onclick="window.open('apxor://dl_url?uri=${touch_redirect_url}&action=android.intent.action.VIEW&buttonName=Image')">
            </div>
        </div>
        `;
    }

    const {
        title_config, text_config, buttons_config, border_radius,
        bg_color, image
    } = new_ui;

    return `
    <div style="overflow: hidden; display: flex; justify-content: ${isBottom ? 'flex-end' : 'flex-start'}; flex-direction: column;">
        <div style="width: 100%; height: 100%; background-color: ${bg_color}; border-radius: ${!isBottom ? '0px' : border_radius + 'px'} ${!isBottom ? '0px' : border_radius + 'px'} ${isBottom ? '0px' : border_radius + 'px'} ${isBottom ? '0px' : border_radius + 'px'}">
            <div style="justify-content: center; margin: -4px; display: flex; flex-wrap: wrap;">
                <div style="padding: 4px; margin-left: 10px; margin-top: 10px;">
                    <div style="width: 64px; height: 64px; min-width: 64px; min-height: 64px; padding: 0;">
                        <img alt="icon" src="${image}" style="width: 100%; height: 100%;"/>
                    </div>
                </div>
                <div style="align-content: center; flex-grow: 1; max-width: 100%; flex-basis: 0; padding: 4px;">
                    ${getTextContent(title_config, false)}
                    ${getTextContent(text_config, false)}
                </div>
            </div>
            <div style="margin-top: 8px; margin-bottom: 8px;">
                ${WITH_BUTTONS(buttons_config, false)}
            </div>
        </div>
    </div>
    `;
};

const POPOUT_MODAL_CONTENT = (new_ui) => {

    const {only_image, bg_image, image_size, touch_redirect_url, orientation} = new_ui;

    if (only_image) {
        return `
        <div style="overflow: hidden; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%;">
            <a href="apxor://xclose" style="margin: -8px 12px; right: 0px; font-size: 16px; line-height: 12px; width: 18px; height: 18px; text-align: center; background: url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png') 0% 0% / cover no-repeat rgb(255, 255, 255); border-radius: 36px; color: rgb(248, 248, 248); border: 2px solid white; display: flex; justify-content: flex-end; flex-direction: row; align-self: flex-end; z-index: 999; margin-right: ${orientation === "Portrait" ? '12px' : '36px'};"></a>
            <div style="overflow: hidden; width: 85%; max-height: ${orientation === "Portrait" ? '99%' : '95%'}; min-height: 50%; display: flex; flex-direction: column; border-radius: 8px; background-position: center; background-repeat: no-repeat; background-image: url(${bg_image}); background-size: ${image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase()};" onclick="window.open('apxor://dl_url?uri=${touch_redirect_url}&action=android.intent.action.VIEW&buttonName=Image')">
            </div>
        </div>
        `;
    }

    const {image, title_config, text_config, buttons_config, bg_color} = new_ui;

    return `
    <div style="overflow: hidden; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%;">
        <a href="apxor://xclose" style="margin: -8px 12px; right: 0px; font-size: 16px; line-height: 12px; width: 18px; height: 18px; text-align: center; background: url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png') 0% 0% / cover no-repeat rgb(255, 255, 255); border-radius: 36px; color: rgb(248, 248, 248); border: 2px solid white; display: flex; justify-content: flex-end; flex-direction: row; align-self: flex-end; z-index: 999; margin-right: ${orientation === "Portrait" ? '12px' : '36px'};"></a>
        <div style="overflow: hidden; width: 85%; max-height: ${orientation === "Portrait" ? '99%' : '95%'}; display: flex; flex-direction: column; border-radius: 8px; background-color: ${bg_color};">
            <div style="width: 100%; margin-top: 15px; justify-content: center; display: flex; flex-wrap: wrap;">
                <div style="padding: 4px; margin: 0; ">
                    <div style="min-width: 64px; min-height: 64px; padding: 0; width: 160px; height: 120px;">
                        <img alt="icon" src="${image}" style="width: 100%; height: 100%;"/>
                    </div>
                </div>
            </div>
            <div style="width: 100%; justify-content: center; display: flex; flex-wrap: wrap;">
                <div style="flex-grow: 1; max-width: 100%; flex-basis: 0; align-self: center;">
                    ${getTextContent(title_config, false, "text-align: center; ")}
                    ${getTextContent(text_config, false, "text-align: center; padding-top: 0px;")}
                </div>
            </div>
            <div style="width: 100%; display: flex; flex-wrap: wrap;">
                <div style="display: flex; flex-direction: row; justify-content: flex-end; padding: 4px; width: 100%;">
                    <a role="button" href="${getHrefFromActionConfig(buttons_config[0])}" style="text-decoration: none; background-color: ${buttons_config[0].color}; ${translateToStyles(buttons_config[0].text_config)}; padding: 14px; line-height: 0; width: 100%; text-align: center; border-radius: 4px; margin: 8px;">${buttons_config[0].text_config.text}</a>
                </div>
            </div>
        </div>
    </div>
    `;
};

const FULL_SCREEN_MODAL_CONTENT = (new_ui) => {

    const {only_image, touch_redirect_url, bg_image, image_size} = new_ui;

    if (only_image) {
        return `
        <div style="overflow: hidden; width: 100%; height: 100%; position: relative; background-position: center; background-repeat: no-repeat; background-image: url(${bg_image}); background-size: ${image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase()};" onclick="window.open('apxor://dl_url?uri=${touch_redirect_url}&action=android.intent.action.VIEW&buttonName=Image')">
            <a href="apxor://xclose" style="top: 3%; right: 2%; font-size: 16px; line-height: 12px; position: absolute; width: 18px; height: 18px; text-align: center; background: url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png') 0% 0% / cover no-repeat rgb(255, 255, 255); border-radius: 36px; color: rgb(255, 255, 255); border: 2px solid white;"></a>
        </div>
        `;
    }

    const {bg_color} = new_ui;

    return `
    <div style="overflow: hidden; width: 100%; height: 100%; position: relative; background-color: ${bg_color};">
        <a href="apxor://xclose" style="top: 3%; right: 2%; font-size: 16px; line-height: 12px; position: absolute; width: 18px; height: 18px; text-align: center; background: url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png') 0% 0% / cover no-repeat rgb(255, 255, 255); border-radius: 36px; color: rgb(255, 255, 255); border: 2px solid white;"></a>
        ${getFullScreenModalBody(new_ui)}
    </div>
    `;
};

const getFullScreenModalBody = (new_ui) => {
    const {image, title_config, buttons_config, image_size} = new_ui;

    return `
    <div style="width: 100%; height: 100%; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center; text-align: center; padding: 12px;">
        <div style="margin: 0px 0px 6px 0px;">
            ${getTextContent(title_config, false, "text-align: center; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical; align-self: center;")}
        </div>
        <div style="margin: 0; min-height: 70%; max-height: 70%; overflow: hidden; border-top-left-radius: 8px; border-top-right-radius: 8px;">
            <div style="width: 100%; height: 100%; padding: 0; background-image: url(${image}); background-size: ${image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase()}; background-repeat: no-repeat; border: 0px;"></div>
        </div>
        <div style="overflow: hidden; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; margin: 0; margin-top: -2px; ">
            <div style="display: flex; flex-direction: row; justify-content: flex-end; padding: 0px; border: 0px;">
                <a role="button" href="${getHrefFromActionConfig(buttons_config[0])}" style="text-decoration: none; background-color: ${buttons_config[0].color}; ${translateToStyles(buttons_config[0].text_config)}; padding: 14px; line-height: 0; width: 100%; text-align: center; border-radius: 0px;">${buttons_config[0].text_config.text}</a>
            </div>
        </div>
    </div>
    `;
};

/**
 * Constructs the HTML based on layout type and returns the constructed HTML back
 *
 * @param new_ui
 * @returns {*}
 */
export const constructHTML = (new_ui) => {
    const {layout_type} = new_ui;
    let html_data = new_ui.html_config.data;
    switch (layout_type) {
        case INAPP_LAYOUT_TYPES.HEADER:
            html_data = WITH_HTML_HOLDER(
                HEADER_OR_FOOTER_CONTENT(new_ui)
            );
            break;
        case INAPP_LAYOUT_TYPES.FOOTER:
            html_data = WITH_HTML_HOLDER(
                HEADER_OR_FOOTER_CONTENT(new_ui, true)
            );
            break;
        case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
            html_data = WITH_HTML_HOLDER(
                POPOUT_MODAL_CONTENT(new_ui)
            );
            break;
        case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
            html_data = WITH_HTML_HOLDER(
                FULL_SCREEN_MODAL_CONTENT(new_ui)
            );
            break;

    }
    new_ui.html_config.data = html_data.replace('#', '%23');
    return new_ui;
};

export const PLATFORM_IMAGES = (platform, color = "#212121", width = 32) => {
    switch (platform) {
        case APP_PLATFORMS.android:
            return <AndroidLogo color={color} width={width}/>;
        case APP_PLATFORMS.ios:
            return <AppleLogo color={color} width={width}/>;
        case APP_PLATFORMS.web:
            return <WebLogo color={color} width={width}/>;
        case APP_PLATFORMS.omni:
            return <img alt={"OMNI_APP"} src={"assets/img/omni.png"} width={width}/>;
        default:
            return "";
    }
};

export class Button extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {styles, text, selected, index, handleOnSelect} = this.props;
        return (
            <div className="button" style={{
                userSelect: 'none',
                position: 'relative',
                display: 'inline-block',
                padding: '.4em',
                border: '1px solid #123',
                borderRadius: '3px',
                marginRight: '-1px',
                backgroundColor: selected ? "#abc" : "#fff",
                ...styles
            }} onClick={() => handleOnSelect(index)}>
                {text}
            </div>
        );
    }
}

const StyledDiv = styled.div`
    user-select: none;
    display: 'flex';
    font-weight: 400;
    .button:hover {
        cursor: pointer;
    }
    .button:first-child:not(:only-child) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .button:last-child:not(:only-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .button:not(:first-child):not(:last-child) {
        border-radius: 0 !important;
    }
`;

export class ButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: props.selectedIndex || 0,
        }
    }

    handleOnSelect = (index) => {
        this.setState({
            selectedIndex: index
        }, () => {
            this.props.onButtonSelected(index)
        });
    };

    componentWillReceiveProps(nextProps) {
        const {selectedIndex} = this.state;

        if (nextProps.selectedIndex !== selectedIndex) {
            this.setState({
                selectedIndex: nextProps.selectedIndex
            });
        }
    }

    render() {
        const {buttons} = this.props;
        const {selectedIndex} = this.state;

        return (
            <StyledDiv>
                {buttons.map((button, index) => (
                    <Button key={index} index={index} text={button} selected={selectedIndex === index}
                            handleOnSelect={this.handleOnSelect}/>))}
            </StyledDiv>
        );
    }
}

/**
 * A wrapper to Action Field to maintain it's own state
 */
export class CustomActionField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataKey: props.dataKey || '',
            value: this.props.value
        };

        this.debounce = debounce((fn, data) => {
            fn(data)
        }, 300);
    }

    onChange = (e) => {
        const textValue = e.target.value;

        const {onActionFieldValueChange, dataKey} = this.props;
        this.setState({
            [dataKey]: textValue,
            value: textValue
        }, () => {
            this.debounce(onActionFieldValueChange, textValue);
        });
    };

    render() {
        const {value} = this.state;
        const {placeholder, title, style, isPure = false} = this.props;

        const component = (
            <ActionsTextField
                style={{marginBottom: 0}}
                placeholder={placeholder}
                value={value}
                onChange={this.onChange}
            />
        );

        if (!isPure) {
            return (
                <ModuleConfigSection title={title} style={style}>
                    {component}
                </ModuleConfigSection>
            );
        } else {
            return component;
        }
    }
}