/**
 * Created by Araja Jyothi Babu on 15-Nov-16.
 */
/**
 * Sorts the data based on indices
 * @param sortIndexes
 * @param sortedDataList
 * @returns {Array}
 * @constructor
 */
export default function SortedData(sortIndexes, sortedDataList){
    var newList = [];
    var size = sortIndexes.length;
    for (var index = 0; index < size; index++) {
        newList.push(sortedDataList[sortIndexes[index]]);
    }
    return newList;
}