/**
 * Created by Rakesh Peela
 * Date: 06-Dec-2019
 * Time: 6:49 PM
 */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0,0,0,.125)',
        boxShadow: 'none',
        marginBottom: 4,
        '&:before': {
            display: 'none',
        }
    },
    expanded: {
        margin: 'auto',
        marginBottom: 4,
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: "#fff",
        minHeight: 36,
        padding: "4px 12px",
        borderRadius: 4,
        '&$expanded': {
            minHeight: 36,
            borderRadius: 4
        },
    },
    content: {
        margin: "4px 0",
        '&$expanded': {
            margin: '4px 0',
        },
    },
    expanded: {
        borderRadius: 4
    },
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        borderTop: "1px solid rgba(0,0,0,.125)",
        padding: "12px",
        display: "block"
    },
}))(MuiExpansionPanelDetails);

class ExpansionModuleConfig extends React.Component {
    render() {
        const {children, titleTypoStyles = {}, title, description, descriptionTypoStyles, defaultExpanded} = this.props;
        return (
            <ExpansionPanel
                defaultExpanded={defaultExpanded || false}
                square={false}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <div style={{display: "inline-block"}}>
                        {
                            title
                            && <Typography style={{...titleTypoStyles}}>
                                {title || ""}
                            </Typography>
                        }
                        {
                            description
                            && <Typography style={{...descriptionTypoStyles}}>
                                {description || ""}
                            </Typography>
                        }
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

ExpansionModuleConfig.propTypes = {
    title: PropTypes.string.isRequired,
    defaultExpanded: PropTypes.bool,
    titleTypoStyles: PropTypes.object,
    titleBgColor: PropTypes.string,
};

export default ExpansionModuleConfig;