/**
 * Created by Araja Jyothi Babu on 28-Oct-16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../../../../../components/reusable/MaterialUi/Snackbar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Loading from "../../../../../components/reusable/Loading";
import {isValidEmail} from "../../../../../utils";

class SignUpForm extends React.Component {

    state = {
        name: '',
        email: '',
        password: ''
    };

    isFormValid = () => {
        const {name, email, password} = this.state;
        return name && name.trim().length > 1 && password && password.trim().length > 5 && isValidEmail(email);
    };

    render() {
        const {
            handleSubmit,
            isPending,
            hasError,
            error
        } = this.props;
        const {name, email, password} = this.state;
        return (
            <Card elevation={0}>
                <CardHeader
                    title="Create Account"
                />
                <CardContent>
                    {hasError && <Snackbar><strong>{error}</strong></Snackbar>}
                    {this.state.formError && <Snackbar><strong>All fields required..!</strong></Snackbar>}
                    <form name="signup" onSubmit={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        handleSubmit(name, email, password);
                    }}>
                        <TextField
                            type="text"
                            id="name"
                            value={name}
                            fullWidth
                            required
                            name="name"
                            placeholder="e.g. John"
                            onChange={e => this.setState({name: e.target.value})}
                            label="Name"
                            margin="dense"
                        />
                        <TextField
                            type="email"
                            id="email"
                            value={email}
                            fullWidth
                            name="email"
                            required
                            placeholder="john@company.com"
                            onChange={e => this.setState({email: e.target.value})}
                            label="Email"
                            margin="dense"
                        />
                        <TextField
                            id="password"
                            value={password}
                            fullWidth
                            required
                            name="password"
                            placeholder="P@$$w0rd"
                            onChange={e => this.setState({password: e.target.value})}
                            label="Password"
                            type="password"
                            margin="dense"
                        />
                        <Grid container style={{marginTop: 20}}>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!this.isFormValid()}
                                >
                                    {isPending && <Loading size={20}/>}
                                    {!isPending && <span>Continue</span>}
                                </Button>
                            </Grid>
                            <Grid item xs/>
                        </Grid>
                    </form>
                    <Typography style={{marginTop: 10}}>
                        Already have an account? <Link to="/login">Sign In</Link>
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}


SignUpForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isPending: PropTypes.bool,
    hasError: PropTypes.bool
};

export default SignUpForm