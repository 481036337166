import {
    eventDistributionAPI, dauWithEventAPI, activeEventHourDistributionAPI, retentionAPI,
    groupStatisticsAPI, versionStatisticsAPI, usersForEventAPI
} from '../api';
import {
    SELF_EVENT_DISTRIBUTION_PENDING, SELF_EVENT_DISTRIBUTION, SELF_EVENT_DISTRIBUTION_FAILED,
    SELF_EVENT_COUNT_PENDING, SELF_EVENT_COUNT, SELF_EVENT_COUNT_FAILED,
    SELF_EVENT_HOUR_DISTRIBUTION_PENDING, SELF_EVENT_HOUR_DISTRIBUTION, SELF_EVENT_HOUR_DISTRIBUTION_FAILED,
    SELF_RETENTION_PENDING, SELF_RETENTION, SELF_RETENTION_FAILED,
    SELF_STATISTICS_PENDING, SELF_STATISTICS, SELF_STATISTICS_FAILED, SELF_USERS_FOR_EVENT_PENDING,
    SELF_USERS_FOR_EVENT, SELF_USERS_FOR_EVENT_FAILED, SELF_EVENT_RELATED, SELF_EVENT_RELATED_PENDING,
    SELF_EVENT_RELATED_FAILED, SELF_SEGMENT_RELATED_PENDING, SELF_SEGMENT_RELATED, SELF_SEGMENT_RELATED_FAILED,
    SELF_GLOBAL_RELATED_PENDING, SELF_GLOBAL_RELATED, SELF_GLOBAL_RELATED_FAILED
} from "../actionTypes";
import {RETENTION_ENUM} from "../../Retention/actionTypes";
import {
    countAPI,
    eventRelatedActiveHourDistributionAPI,
    eventRelatedCountAPI,
    eventRelatedCountDistributionAPI,
    eventRelatedTimeSeriesAPI,
    segmentRelatedCountAPI,
    segmentsTimeSeriesAPI,
    segmentTimeSeriesAPI,
    timeSeriesAPI,
    timeSpentAPI,
    getFunnelAPI,
    getEventPathAPI,
    getScreenPathAPI,
    getRetentionUsageAPI,
    getVOVWeightedAverageTimeSpentPerDayAPI,
    getVOVDAUPercentAPI,
    getVOVAverageEventsPerDayAPI,
    eventRelatedStickinessAPI,
    getConversionRateAPI,
    getConversionRateTrendAPI,
    getConversionRateHourlyDistributionAPI
} from "../../../../../../../api";
import {getGroupNameFromList, toTitleCase} from "../../../../../../../utils";
import {ALL_USERS} from "../../../../../../../constants";
import {
    CUSTOM_REPORT_EXPLORER,
    CUSTOM_REPORT_EXPLORER_FAILED,
    CUSTOM_REPORT_EXPLORER_PENDING,
    CUSTOM_REPORT_FLAT_TABLE,
    CUSTOM_REPORT_FLAT_TABLE_FAILED,
    CUSTOM_REPORT_FLAT_TABLE_PENDING
} from "../../CustomReports/actionTypes";
import {getExplorerAPI, getFlatTableAPI} from "../../CustomReports/api";

const makeQueryParams = (state) => (...queryParamObjects) => {
    const { group = ALL_USERS } = state.dashboards;
    return { ...queryParamObjects.reduce((a, b) => ({...a, ...b}), {}), group };
};

/**
 * @deprecated
 * @param appId
 * @param event
 * @param key
 * @param type
 * @param extraFilters
 * @returns {function(*, *)}
 */
export const getEventDistribution = (appId, event, key, type, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters);
        return dispatch({
            types: [
                SELF_EVENT_DISTRIBUTION_PENDING,
                SELF_EVENT_DISTRIBUTION,
                SELF_EVENT_DISTRIBUTION_FAILED
            ],
            payload: {
                promise: eventDistributionAPI(getState().auth, appId, filters, event, type)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getUsersForEvent = (appId, event, key, params) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, params);
        return dispatch({
            types: [
                SELF_USERS_FOR_EVENT_PENDING,
                SELF_USERS_FOR_EVENT,
                SELF_USERS_FOR_EVENT_FAILED
            ],
            payload: {
                promise: usersForEventAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

/**
 *
 * @param appId
 * @param event
 * @param key
 * @param extraFilters
 * @returns {function(*, *)}
 */
export const getEventCount = (appId, event, key, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, extraFilters);
        return dispatch({
            types: [
                SELF_EVENT_COUNT_PENDING,
                SELF_EVENT_COUNT,
                SELF_EVENT_COUNT_FAILED
            ],
            payload: {
                promise: dauWithEventAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

const customOverFilters = (state, extraFilters) => {
    const { segmentBuilder: { query = {user: [], session: [], event: []} } } = state;
    /**
     * FIXME: Don't know how this is going to be..! :( Na jeevithammmm..
     */
    const { user = [], session = [] } = extraFilters;
    return { ...query, ...extraFilters, user: [...user, ...query.user], session: [...session, ...query.session] };
};

export const getGlobalRelatedCount = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: countAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getGlobalRelatedTimeSeries = (appId, key, queryParams, extraFilters, sub_key, event) => {
    return (dispatch, getState) => {
        let attributesList = ["apx_date"];
        if(queryParams.hasOwnProperty("groupBy")) attributesList.push(queryParams.groupBy);
        const {groupBy, ...otherQueryParams} = queryParams;
        const filters = makeQueryParams(getState())(getState().filters, {
            ...otherQueryParams,
            event: event || "apx_app_opened",
            attribute: attributesList
        });
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: eventRelatedTimeSeriesAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        if(queryParams.groupBy || (Array.isArray(queryParams.days) && queryParams.days.length > 0)){
                            return res;
                        }else {
                            return res.map(o => ({key: o.key, [toTitleCase(queryParams.of)]: o.value}));
                        }
                    }),
            },
            meta: {
                key: key,
                sub_key
            }
        });
    };
};

export const getGlobalRelatedTimeSpent = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: timeSpentAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        if(queryParams.groupBy){
                            return res;
                        }else {
                            return res.map(o => ({key: o.key, "Time": o.value}));
                        }
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getEventRelatedCount = (appId, event, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams, {event: event});
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: eventRelatedCountAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getEventRelatedTimeSeries = (appId, event, key, queryParams, extraFilters, sub_key) => {
    return (dispatch, getState) => {
        let attributesList = ["apx_date"];
        if(queryParams.hasOwnProperty("groupBy")) attributesList.push(queryParams.groupBy);
        const {groupBy, ...otherQueryParams} = queryParams;
        const filters = makeQueryParams(getState())(getState().filters, otherQueryParams, {event: event, attribute: attributesList});
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: eventRelatedTimeSeriesAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                .then((res) => {
                    if (queryParams.groupBy || (Array.isArray(queryParams.days) && queryParams.days.length > 0)) {
                        return res.sort((a, b) => a["key"].localeCompare(b["key"]));
                    } else {
                        return res.map(o => ({
                            key: o.key,
                            [event]: o.value
                        })).sort((a, b) => a["key"].localeCompare(b["key"]));
                    }
                }),
            },
            meta: {
                key: key,
                sub_key
            }
        });
    };
};

export const getEventRelatedCountDistribution = (appId, event, key, queryParams, extraFilters, type) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams, {event: event});
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: eventRelatedCountDistributionAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters), type)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getEventRelatedActiveHourDistribution = (appId, event, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams, {event: event});
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: eventRelatedActiveHourDistributionAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getSegmentRelatedCount = (appId, segmentId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_SEGMENT_RELATED_PENDING,
                SELF_SEGMENT_RELATED,
                SELF_SEGMENT_RELATED_FAILED
            ],
            payload: {
                promise: segmentRelatedCountAPI(appId, getState().auth, segmentId, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getSegmentRelatedTimeSeries = (appId, segmentId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        const { appSegments = [] } = getState().app;
        const segmentName = getGroupNameFromList(appSegments, segmentId);
        return dispatch({
            types: [
                SELF_SEGMENT_RELATED_PENDING,
                SELF_SEGMENT_RELATED,
                SELF_SEGMENT_RELATED_FAILED
            ],
            payload: {
                promise: segmentTimeSeriesAPI(appId, getState().auth, segmentId, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        if(queryParams.groupBy || (Array.isArray(queryParams.days) && queryParams.days.length > 0)){
                            return res;
                        }else {
                            return res.map(o => ({key: o.key, [segmentName]: o.value}));
                        }
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getSegmentsRelatedTimeSeries = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_SEGMENT_RELATED_PENDING,
                SELF_SEGMENT_RELATED,
                SELF_SEGMENT_RELATED_FAILED
            ],
            payload: {
                promise: segmentsTimeSeriesAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        if(Array.isArray(res) && res.length > 0){ //modifying list of time series into time series
                            const [first] = res;
                            const data = first.data.map(o => ({key: o.key})) || [];
                            const segmentNames = res.map(o => o.name);
                            res.forEach((segment, i) => {
                                segment.data.forEach((d, j) => {
                                    data[j][segment.name] = d.value;
                                });
                            });
                            return {
                                segmentNames,
                                data
                            };
                        }
                        return {
                            segmentNames: [],
                            data: []
                        };
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

/**
 * @deprecated
 * @param appId
 * @param event
 * @param key
 * @returns {function(*, *)}
 */
export const getActiveEventHourDistribution = (appId, event, key) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters);
        return dispatch({
            types: [
                SELF_EVENT_HOUR_DISTRIBUTION_PENDING,
                SELF_EVENT_HOUR_DISTRIBUTION,
                SELF_EVENT_HOUR_DISTRIBUTION_FAILED
            ],
            payload: {
                promise: activeEventHourDistributionAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

/**
 *
 * @param queryObj
 * @returns {*}
 */
function normalizedQuery(queryObj) {
    const query = {...queryObj};
    return Object.keys(query) > 0 ? query : null;
}

/**
 *
 * @param appId
 * @param key
 * @param retentionType
 * @param extraFilters
 * @param queryParams
 * @returns {function(*, *)}
 */
export const getRetention = (appId, key, retentionType, extraFilters = {}, queryParams = {}) => {
    return (dispatch, getState) => {
        let filters = makeQueryParams(getState())({...getState().filters, ...queryParams});
        const { first_event, next_event, ...otherFilters } = extraFilters;
        const propertyFilters = customOverFilters(getState(), otherFilters);
        let compatibleQuery = {
            first_event: normalizedQuery({...extraFilters.first_event}),
            next_event: normalizedQuery({...extraFilters.next_event}),
            ...propertyFilters
        };
        if(retentionType !== RETENTION_ENUM.FTU){
            compatibleQuery = {first_event: compatibleQuery.first_event, ...propertyFilters}; //FIXME: What contract is this ? :(
        }
        return dispatch({
            types: [
                SELF_RETENTION_PENDING,
                SELF_RETENTION,
                SELF_RETENTION_FAILED
            ],
            payload: {
                promise: retentionAPI(getState().auth, appId, filters, compatibleQuery, retentionType)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

export const getGroupStatistics = (appId, key, sub_key, queryStrings, body) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryStrings);
        return dispatch({
            types: [
                SELF_STATISTICS_PENDING,
                SELF_STATISTICS,
                SELF_STATISTICS_FAILED
            ],
            payload: {
                promise: groupStatisticsAPI(getState().auth, appId, filters, {...body, generic: getState().segmentBuilder.query})
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key,
                sub_key
            }
        });
    };
};

export const getVersionStatistics = (appId, key, sub_key, queryStrings, body) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryStrings);
        return dispatch({
            types: [
                SELF_STATISTICS_PENDING,
                SELF_STATISTICS,
                SELF_STATISTICS_FAILED
            ],
            payload: {
                promise: versionStatisticsAPI(getState().auth, appId, filters, {...body, generic: getState().segmentBuilder.query})
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key,
                sub_key
            }
        });
    };
};

export const getFunnel = (appId, funnelFilters, key) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getFunnelAPI(appId, getState().auth, filters, funnelFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getEventPath = (appId, key, pathFilters, queryParams) => {
    return (dispatch, getState) => {
        const qParams = { ...getState().filters, ...queryParams, startEvent: queryParams.start, endEvent: queryParams.end };
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getEventPathAPI(appId, getState().auth, qParams, pathFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getScreenPath = (appId, key, pathFilters, queryParams) => {
    return (dispatch, getState) => {
        const qParams = { ...getState().filters, ...queryParams, startScreen: queryParams.start, endScreen: queryParams.end };
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getScreenPathAPI(appId, getState().auth, qParams, pathFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getRetentionUsage = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const qParams = { ...getState().filters, ...queryParams};
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getRetentionUsageAPI(appId, getState().auth, qParams, extraFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};
/**
 * Version On Version
 */

/**
 *
 * @param appId
 * @param key
 * @param queryParams
 * @param extraFilters
 * @returns {function(*, *): *}
 */
export const getVOVWeightedAverageTimeSpentPerDay = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const qParams = { ...getState().filters, ...queryParams};
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getVOVWeightedAverageTimeSpentPerDayAPI(appId, getState().auth, qParams, extraFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getVOVDAUPercent = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const qParams = { ...getState().filters, ...queryParams};
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getVOVDAUPercentAPI(appId, getState().auth, qParams, extraFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getVOVAverageEventsPerDay = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const qParams = { ...getState().filters, ...queryParams};
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getVOVAverageEventsPerDayAPI(appId, getState().auth, qParams, extraFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getEventRelatedStickiness = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const qParams = {...getState().filters, since: undefined, ...queryParams}; //TODO: apply date filters
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: eventRelatedStickinessAPI(appId, getState().auth, qParams, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};


/**
 * Conversion Rate
 */

export const getConversionRate = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: getConversionRateAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getConversionRateTrend = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: getConversionRateTrendAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getConversionRateHourlyDistribution = (appId, key, queryParams, extraFilters) => {
    return (dispatch, getState) => {
        const filters = makeQueryParams(getState())(getState().filters, queryParams);
        return dispatch({
            types: [
                SELF_EVENT_RELATED_PENDING,
                SELF_EVENT_RELATED,
                SELF_EVENT_RELATED_FAILED
            ],
            payload: {
                promise: getConversionRateHourlyDistributionAPI(appId, getState().auth, filters, customOverFilters(getState(), extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

/**
 * Explorer && CustomReports
 */

const replacer = (extraFilters, keys = ["dimensions", "filters"]) => {
    let result = {
        ...extraFilters
    }
    keys.forEach(key => {
        result[key] = extraFilters[key].map(eachFilter => {
            if (eachFilter.hasOwnProperty("name")) {
                return {
                    ...eachFilter,
                    name: eachFilter.name === "event_name" ? "apx_event_name" : eachFilter.name,
                }
            } else if (eachFilter.hasOwnProperty("property")) {
                return {
                    ...eachFilter,
                    property: eachFilter.property === "event_name" ? "apx_event_name" : eachFilter.property
                }
            }
        })
    })
    return result;
}

export const getExplorer = (appId, extraFilters, key) => {
    return (dispatch, getState) => {
        const { group, limit} = getState().customReport;
        const filters = { ...getState().filters, group, limit };
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED
            ],
            payload: {
                promise: getExplorerAPI(getState().auth, appId, filters, replacer(extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};

export const getFlatTable = (appId, extraFilters, key) => {
    return (dispatch, getState) => {
        const { group, limit } = getState().customReport;
        const filters = { ...getState().filters, group, limit };
        return dispatch({
            types: [
                SELF_GLOBAL_RELATED_PENDING,
                SELF_GLOBAL_RELATED,
                SELF_GLOBAL_RELATED_FAILED            ],
            payload: {
                promise: getFlatTableAPI(getState().auth, appId, filters, replacer(extraFilters))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key
            }
        });
    };
};
