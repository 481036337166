/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {UPDATE_GLOBAL_MODES, UPDATE_GLOBAL_VERSIONS,
    UPDATE_GLOBAL_DATE_TIME,
    UPDATE_PATH_ID, UPDATE_SELECTED_PATH_LIST,
    UPDATE_USER_GROUP_ID, UPDATE_PAGINATION_COUNT,
    UPDATE_PAGINATION_ORDER_BY, UPDATE_PAGINATION_PAGE,
    UPDATE_PAGINATION_SORT_ORDER, RESET_FILTERS,
    UPDATE_SESSION_GROUP_ID, UPDATE_COHORT_FILTER,
    RESET_PATH_FILTERS
} from './actionTypes';
import moment from 'moment';
import { MODES_ENUM} from '../../../../../constants';
import { RESET_ALL } from '../../../../../constants/ActionTypes';
import {normalizedDate} from "../../../../../utils";

const INITIAL_STATE = {
    versions: [],
    modes: [ MODES_ENUM.PRODUCTION, MODES_ENUM.NON_PRODUCTION ], //default production
    since: normalizedDate(moment().subtract(6, "days")),
    till : normalizedDate(moment()),
    user_group : {
        groupId: 1  //FIXME: remove groupId dependency
    },
    session_group: {
        sessionGroupId: 1
    },
    path: {
        pathId: 1
    },
    cohort: []
};

function filtersReducer(state = INITIAL_STATE, action = {}) {

    switch (action.type) {

        case UPDATE_COHORT_FILTER:
            return Object.assign({}, state, {
                cohort: action.payload === "" ? [] : action.payload
        })

        case UPDATE_GLOBAL_MODES:
            return Object.assign({}, state,{
                modes: action.payload
            });

        case UPDATE_GLOBAL_VERSIONS:
            return Object.assign({}, state,{
                versions: action.payload
            });

        case UPDATE_GLOBAL_DATE_TIME:
            return Object.assign({}, state,{
                since: action.payload.since,
                till: action.payload.till
            });

        case UPDATE_SESSION_GROUP_ID:
            return Object.assign({}, state, {
                session_group : Object.assign({}, state.session_group, {
                    sessionGroupId : action.payload
                })
            });

        case UPDATE_USER_GROUP_ID:
            return Object.assign({}, state, {
                user_group : Object.assign({}, state.user_group, {
                    groupId : action.payload
                })
            });

        case UPDATE_PATH_ID:
            return Object.assign({}, state, {
                path : Object.assign({}, state.path, {
                    pathId : action.payload
                })
            });

        case UPDATE_SELECTED_PATH_LIST:
            return Object.assign({}, state, {
                path : Object.assign({}, state.path, {
                    navigation_ids : action.payload
                })
            });

        case RESET_PATH_FILTERS:
            return Object.assign({}, state, {
                path : {
                    pathId: null,
                    name: null,
                    navigation_ids: []
                }
            });

        case UPDATE_PAGINATION_ORDER_BY:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    order_by: action.payload
                })
            });

        case UPDATE_PAGINATION_SORT_ORDER:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    sort_order: action.payload
                })
            });

        case UPDATE_PAGINATION_COUNT:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    count: action.payload
                })
            });

        case UPDATE_PAGINATION_PAGE:
            return Object.assign({}, state, {
                pagination : Object.assign({}, state.pagination, {
                    page: action.payload
                })
            });

        case RESET_FILTERS:
        case RESET_ALL:
            return Object.assign({}, INITIAL_STATE);

        default:
            return state;
    }
}
export default filtersReducer;