import { callApi } from '../../../../../../../api';
import {appendEmptyValueForSequence, makeDefaultQueryString, withQueryStrings} from '../../../../../../../utils';
import {API_QUERY_PARAMS} from "../../../../../../../constants/EndPoints";
import {RETENTION_ENUM} from "../../Retention/actionTypes";

const EVENT_DISTRIBUTION_API = "events/distribution/count";
const DAU_WITH_EVENT_API = "dau";
const ACTIVE_EVENT_HOURS_API = "events/distribution/hour";
const RETENTION_APIS = {
    [RETENTION_ENUM.FTU]: "ftu-retention",
    [RETENTION_ENUM.ALL]: "all-retention",
    [RETENTION_ENUM.RETURNING]: "returning-retention"
};

const STATISTICS_FOR_GROUP_API = "statistics/groups";
const STATISTICS_FOR_VERSION_API = "statistics/versions";

const USERS_FOR_EVENT_API = "events/users";

/**
 * @deprecated
 * @param auth
 * @param appId
 * @param filters
 * @param event
 * @param extraFilters
 * @param key
 * @returns {Promise}
 */
export function eventDistributionAPI(auth, appId, filters, event, extraFilters, key = "users") {
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return appendEmptyValueForSequence(json, "key", key, 0);
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param event
 * @returns {Promise}
 */
export function dauWithEventAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(DAU_WITH_EVENT_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({user: [], session: [], event: []})
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * @deprecated
 * @param auth
 * @param appId
 * @param filters
 * @param event
 * @returns {Promise}
 */
export function activeEventHourDistributionAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(ACTIVE_EVENT_HOURS_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({user: [], session: [], event: []})
    };
    const onSuccessCallback = json => {
        return appendEmptyValueForSequence(json, "key", "value", 0).map(o => ({hour: o.key, "Event Count": o.value, index: 1}));
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function retentionAPI(auth, appId, filters, body, retentionType = RETENTION_ENUM.ALL) {
    const url = makeDefaultQueryString(RETENTION_APIS[retentionType], auth, appId) + withQueryStrings({...filters, [API_QUERY_PARAMS.installationDate]: filters.till, retentionDays: 7});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function groupStatisticsAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(STATISTICS_FOR_GROUP_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function versionStatisticsAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(STATISTICS_FOR_VERSION_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param event
 * @returns {Promise}
 */
export function usersForEventAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(USERS_FOR_EVENT_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}