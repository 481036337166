import React from 'react';
import {
    COUNT_TYPE_ENUM
} from '../../../../../../../constants/index';
import Box from "../../../../../../../components/reusable/Box";
import Grid from '@material-ui/core/Grid';
import Count from "./Count";
import {uniqueKeyFromFilters, getGroupNameFromList } from "../../../../../../../utils/index";
import Trend from "./Trend";
import TimeSpent from "./TimeSpent";
import DistributionOverEvent from "./DistributionOverEvent";
import HourlyEventDistribution from "./HourlyEventDistribution";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AggregatedTrend, { AGGREGATE_ENUM } from "./AggregatedTrend";
import RetentionTrend from "./RetentionTrend";

import withSelfComponent from './SelfComponent';
import TabLabel from "../../../../../../../components/reusable/TabLabel";
import Stickiness from "./Stickiness";

class TabbedWidget extends React.Component { //FIXME: need better name

    state = {
        currentSegment: 0,
        currentEvent: 0,
        currentGlobal: 0,
        widgetLocalData: {}
    };

    getDataKey = (props) => {
        const { name, queryParams, extraFilters, extension  } = props;
        const keyFromQueryParams = Object.keys(queryParams).map(o => [o, queryParams[o]].join("_")).join("_");
        return [ name, keyFromQueryParams, uniqueKeyFromFilters(extraFilters), extension ].filter(o => o.length > 0).join("_");
    };

    getCustomLabel = (props) => {
        const { name, extension  } = props;
        return `${name} ${extension}`;
    };

    render(){
        const {
            customQueryBuilder: { group },
            appState: { appSegments = [] },
            selfParams: { segment, event, extraFilters, queryParams, customLabel }
        } = this.props;
        let segmentName = null;
        if(segment){
            segmentName = getGroupNameFromList(appSegments, segment);
        }
        let groupName = null;
        groupName = ""; //readableUserGroupName(group);FIXME: remove this seamlessly
        const { currentEvent, currentGlobal, currentSegment, widgetLocalData } = this.state;
        return(
            <Box
                title={customLabel}
            >
                {
                    segment &&
                    <Grid container spacing={16}>
                        <Grid item xs>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={currentSegment}
                                    onChange={(e, currentSegment) => this.setState({currentSegment})}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Users Count" />
                                    <Tab label="Users Trend" />
                                </Tabs>
                            </AppBar>
                            {currentSegment === 0 && <div>
                                <Count
                                    {...this.props}
                                    parentCompLabel={customLabel}
                                    selfParams={{
                                        segment: segment,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: segmentName,
                                            queryParams,
                                            extraFilters,
                                            extension: "Users"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: segmentName,
                                            extension: `Users`
                                        }),
                                        segmentName: segmentName,
                                        of: COUNT_TYPE_ENUM.users
                                    }}
                                />
                            </div>}
                            {currentSegment === 1 && <div>
                                <Trend
                                    {...this.props}
                                    selfParams = {{
                                        segment: segment,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: getGroupNameFromList(appSegments, segment),
                                            queryParams,
                                            extraFilters,
                                            extension: "Trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: getGroupNameFromList(appSegments, segment),
                                            extension: `Users Trend`
                                        }),
                                        segmentName: segmentName,
                                        of: "users"
                                    }}
                                />
                            </div>}
                        </Grid>
                    </Grid>
                }
                {
                    event &&
                    <Grid container spacing={16}>
                        <Grid item xs>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={currentEvent}
                                    onChange={(e, currentEvent) => this.setState({currentEvent})}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    fullWidth
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label={<TabLabel help="Event, User and Session metrics for selected event">Metrics</TabLabel>} />
                                    <Tab label="Event Trend" />
                                    <Tab label="Users Trend" />
                                    <Tab label="Sessions Trend" />
                                    <Tab label={<TabLabel help="Average number of times the selected metric is performed per day">Feature Engagement (Day)</TabLabel>} />
                                    <Tab label={<TabLabel help="Average number of times the selected metric is performed per session">Feature Engagement (Session)</TabLabel>} />
                                    <Tab label={<TabLabel help="% of daily active users who perform the selected metric">Feature Consumption (Trend)</TabLabel>} />
                                    <Tab label={<TabLabel help="Hourly distribution of selected events">Hourly Distribution</TabLabel>} />
                                    <Tab label={<TabLabel help="Number of users who consume a feature -  number of times">Feature Consumption (Strength)</TabLabel>} />
                                    {/*<Tab label="Session Distribution" />*/}
                                    <Tab label={<TabLabel help="Average session lengths per feature consumption">Feature Consumption Vs Engagement</TabLabel>} />
                                    <Tab label="Stickiness" />
                                </Tabs>
                            </AppBar>
                            {currentEvent === 0 && <div>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={4}>
                                        <Count
                                            {...this.props}
                                            parentCompLabel={customLabel}
                                            selfParams={{
                                                event: event,
                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                extraFilters,
                                                dataKey: this.getDataKey({
                                                    name: event,
                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                    extraFilters,
                                                    extension: "Count"
                                                }),
                                                customLabel: this.getCustomLabel({
                                                    name: event,
                                                    extension: `Event Count`
                                                }),
                                                of: COUNT_TYPE_ENUM.event
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Count
                                            {...this.props}
                                            parentCompLabel={customLabel}
                                            selfParams={{
                                                event: event,
                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                extraFilters,
                                                dataKey: this.getDataKey({
                                                    name: event,
                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                    extraFilters,
                                                    extension: "Users"
                                                }),
                                                customLabel: this.getCustomLabel({
                                                    name: event,
                                                    extension: `Users`
                                                }),
                                                of: COUNT_TYPE_ENUM.users
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Count
                                            {...this.props}
                                            parentCompLabel={customLabel}
                                            selfParams={{
                                                event: event,
                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                                extraFilters,
                                                dataKey: this.getDataKey({
                                                    name: event,
                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                                    extraFilters,
                                                    extension: "Sessions"
                                                }),
                                                customLabel: this.getCustomLabel({
                                                    name: event,
                                                    extension: `Sessions`
                                                }),
                                                of: COUNT_TYPE_ENUM.sessions
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>}
                            {currentEvent === 1 && <div>
                                <Trend
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                            extraFilters,
                                            extension: "Trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Event Trend`
                                        }),
                                        of: COUNT_TYPE_ENUM.event
                                    }}
                                />
                            </div>}
                            {currentEvent === 2 && <div>
                                <Trend
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                            extraFilters,
                                            extension: "users_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Users Trend`
                                        }),
                                        of: COUNT_TYPE_ENUM.users
                                    }}
                                />
                            </div>}
                            {currentEvent === 3 && <div>
                                <Trend
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                            extraFilters,
                                            extension: "sessions_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Sessions Trend`
                                        }),
                                        of: COUNT_TYPE_ENUM.sessions
                                    }}
                                />
                            </div>}
                            {currentEvent === 4 && <div>
                                <AggregatedTrend
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams,
                                            extraFilters,
                                            extension: "aggregate_per_user_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Events Per User`
                                        }),
                                        aggregateType: AGGREGATE_ENUM.PER_USER
                                    }}
                                />
                            </div>}
                            {currentEvent === 5 && <div>
                                <AggregatedTrend
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams,
                                            extraFilters,
                                            extension: "aggregate_per_session_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Events Per Session`
                                        }),
                                        aggregateType: AGGREGATE_ENUM.PER_SESSION
                                    }}
                                />
                            </div>}
                            {currentEvent === 6 && <div>
                                <AggregatedTrend
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams,
                                            extraFilters,
                                            extension: "aggregate_percent_of_dau_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Events Done By Percentage Of DAU`
                                        }),
                                        aggregateType: AGGREGATE_ENUM.PERCENT_OF_DAU
                                    }}
                                />
                            </div>}
                            {currentEvent === 7 && <div>
                                <HourlyEventDistribution
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                            extraFilters,
                                            extension: "hourly_distribution"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Hourly Distribution`
                                        }),
                                        of: COUNT_TYPE_ENUM.event
                                    }}
                                />
                            </div>}
                            {currentEvent === 8 && <div>
                                <DistributionOverEvent
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams,
                                            extraFilters,
                                            extension: "users_distribution"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Users Distribution`
                                        }),
                                        of: COUNT_TYPE_ENUM.users
                                    }}
                                />
                            </div>}
                            {/*{currentEvent === 9 && <div>
                                <DistributionOverEvent
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams,
                                            extraFilters,
                                            extension: "sessions_distribution"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Sessions Distribution`
                                        }),
                                        of: COUNT_TYPE_ENUM.sessions
                                    }}
                                />
                            </div>}*/}
                            {currentEvent === 9 && <div>
                                <DistributionOverEvent
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams,
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams,
                                            extraFilters,
                                            extension: "session_length_distribution"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: event,
                                            extension: `Avg Session Length Distribution (Sec)`
                                        }),
                                        of: COUNT_TYPE_ENUM.session_length
                                    }}
                                />
                            </div>}
                            {currentEvent === 10 && <div>
                                <Stickiness
                                    {...this.props}
                                    selfParams={{
                                        event: event,
                                        queryParams: {...queryParams, event, ...widgetLocalData},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: event,
                                            queryParams: {...queryParams, event},
                                            extraFilters,
                                            extension: "Stickiness"
                                        }),
                                        customLabel: `${event} Stickiness ${widgetLocalData.groupBy ? ("grouped by " + widgetLocalData.groupBy) : ""}`
                                    }}
                                    raw
                                    handleLocalUpdate={widgetLocalData => this.setState({widgetLocalData})}
                                />
                            </div>}
                        </Grid>
                    </Grid>
                }
                {
                    !segment && !event &&
                    <Grid container spacing={16}>
                        <Grid item xs>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={currentGlobal}
                                    onChange={(e, currentGlobal) => this.setState({currentGlobal})}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    fullWidth
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label={<TabLabel help="Event, User and Session metrics for selected event">Metrics</TabLabel>} />
                                    <Tab label="Users Trend" />
                                    <Tab label="Sessions Trend" />
                                    <Tab label="Time Spent Trend" />
                                    <Tab label="Retention Trend" />
                                </Tabs>
                            </AppBar>
                            {currentGlobal === 0 && <div>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={6}>
                                        <Count
                                            {...this.props}
                                            parentCompLabel={customLabel}
                                            selfParams={{
                                                queryParams: {group, of: COUNT_TYPE_ENUM.users},
                                                extraFilters,
                                                dataKey: this.getDataKey({
                                                    name: groupName,
                                                    queryParams: {group, of: COUNT_TYPE_ENUM.users},
                                                    extraFilters,
                                                    extension: "users_count"
                                                }),
                                                customLabel: this.getCustomLabel({
                                                    name: groupName,
                                                    extension: "Users"
                                                }),
                                                of: COUNT_TYPE_ENUM.users
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Count
                                            {...this.props}
                                            parentCompLabel={customLabel}
                                            selfParams={{
                                                queryParams: {group, of: COUNT_TYPE_ENUM.sessions},
                                                extraFilters,
                                                dataKey: this.getDataKey({
                                                    name: groupName,
                                                    queryParams: {group, of: COUNT_TYPE_ENUM.sessions},
                                                    extraFilters,
                                                    extension: "sessions_count"
                                                }),
                                                customLabel: this.getCustomLabel({
                                                    name: groupName,
                                                    extension: "Sessions"
                                                }),
                                                of: COUNT_TYPE_ENUM.sessions
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>}
                            {currentGlobal === 1 && <div>
                                <Trend
                                    {...this.props}
                                    selfParams={{
                                        queryParams: {group, of: COUNT_TYPE_ENUM.users},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: groupName,
                                            queryParams: {group, of: COUNT_TYPE_ENUM.users},
                                            extraFilters,
                                            extension: "users_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: groupName,
                                            extension: "Users Trend"
                                        }),
                                        of: COUNT_TYPE_ENUM.users
                                    }}
                                />
                            </div>}
                            {currentGlobal === 2 && <div>
                                <Trend
                                    {...this.props}
                                    selfParams={{
                                        queryParams: {group, of: COUNT_TYPE_ENUM.sessions},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: groupName,
                                            queryParams: {group, of: COUNT_TYPE_ENUM.sessions},
                                            extraFilters,
                                            extension: "sessions_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: groupName,
                                            extension: "Sessions Trend"
                                        }),
                                        of: COUNT_TYPE_ENUM.sessions
                                    }}
                                />
                            </div>}
                            {currentGlobal === 3 && <div>
                                <TimeSpent
                                    {...this.props}
                                    selfParams={{
                                        queryParams: {group},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: groupName,
                                            queryParams: {group},
                                            extraFilters,
                                            extension: "time_spent"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: groupName,
                                            extension: "Time Spent"
                                        })
                                    }}
                                />
                            </div>}
                            {currentGlobal === 4 && <div>
                                <RetentionTrend
                                    {...this.props}
                                    selfParams={{
                                        queryParams: {group},
                                        extraFilters,
                                        dataKey: this.getDataKey({
                                            name: groupName,
                                            queryParams: { group },
                                            extraFilters,
                                            extension: "retention_trend"
                                        }),
                                        customLabel: this.getCustomLabel({
                                            name: groupName,
                                            extension: "Retention Trend"
                                        }),
                                        of: COUNT_TYPE_ENUM.users
                                    }}
                                />
                            </div>}
                        </Grid>
                    </Grid>
                }
            </Box>
        )
    }

}

export default withSelfComponent(TabbedWidget);