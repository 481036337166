/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateHeading, resetHeading } from "../../Navigation/actions";
import DashboardList from "./components/DashboardsList";
import {
  getCustomOverviewConfig,
  updateCustomOverviewConfig,
  getDashboards,
  saveDashboard,
  deleteDashboard,
} from "./actions";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Placeholder from "../../../../../../components/reusable/Placeholder";
import { updateGlobalVersions } from "../../Filters/actions";

function mapStateToProps(state) {
  return {
    ...state,
    session: state.auth,
    router: state.routing,
    appState: state.app,
    meta: state.meta,
    self: state.self,
    dashboards: state.dashboards,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetHeading: () => dispatch(resetHeading()),
    updateHeading: (heading) => dispatch(updateHeading(heading)),
    getCustomOverviewConfig: (appId) =>
      dispatch(getCustomOverviewConfig(appId)),
    updateCustomOverviewConfig: (appId, config) =>
      dispatch(updateCustomOverviewConfig(appId, config)),
    getDashboards: (appId) => dispatch(getDashboards(appId)),
    saveDashboard: (appId, dashboard) =>
      dispatch(saveDashboard(appId, dashboard)),
    deleteDashboard: (appId, dashboardId) =>
      dispatch(deleteDashboard(appId, dashboardId)),
    updateGlobalVersions: (versions) =>
      dispatch(updateGlobalVersions(versions)),
    dispatch,
  };
}

class Dashboards extends Component {
  componentWillMount() {
    const {
      updateHeading,
      getDashboards,
      params: { appId },
    } = this.props;
    updateHeading("Dashboards");
    getDashboards(appId);
  }

  componentWillUnmount() {
    //this.props.resetData();
  }

  render() {
    const {
      dashboards: { dashboards = [] },
      session,
      updateGlobalVersions,
    } = this.props;
    if (session.user.email === "info@ucobot.com") {
      updateGlobalVersions(["6534f47e-df64-4ff5-b8e7-996c44912d43"]);
    } else if (
      session.user.email === "info@optibot.com" ||
      session.user.email === "prabhu@apxor.com" ||
      session.user.email === "prabhukonchada@gmail.com" ||
      session.user.email === "infodemo@optibot.com"
    ) {
      updateGlobalVersions(["3fddfe37-b81d-4e90-907e-acc79fd83e09"]);
    } else if (
      session.user.email === "marketingexecutive-2@gem.gov.in" ||
      session.user.email === "iamrohitganesh@gmail.com"
    ) {
      updateGlobalVersions(["ddf2af37-d142-42b3-b0bf-282661bfebe3"]);
    }
    return (
      <section className="content">
        {Array.isArray(dashboards) && (
          <DashboardList {...this.props} router={this.context.router} />
        )}
        {!this.props.meta.api_pending &&
          (!Array.isArray(dashboards) || dashboards.length === 0) && (
            <Placeholder
              withIcon
              icon={
                <DashboardIcon
                  style={{ opacity: 0.2, width: 150, height: 150 }}
                />
              }
              text="To create your first custom Dashboard, tap the plus on the top right of the dashboard."
            />
          )}
      </section>
    );
  }
}

Dashboards.propTypes = {};

Dashboards.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
