/**
 * Created by Rakesh Peela
 * Date: 14-Feb-2020
 * Time: 7:50 PM
 */

import IconButton from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import React from 'react';
import {Link} from 'react-router';

export default class DownloadButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadInProgress: false,
            hasEncounteredError: false,
        }
    }

    render() {
        const {url, data, method, children, appId} = this.props;
        const {downloadInitiateSuccess, hasEncounteredError, downloadInProgress} = this.state;
        return (
            <>
                {!downloadInProgress && <Tooltip id="tooltip-fab-download" title="Download as CSV" placement="bottom">
                    <IconButton style={{margin: '0 10px'}} color="inherit" onClick={() => {
                        this.setState({
                            downloadInProgress: true
                        });

                        axios
                        .request({
                            url,
                            method,
                            data,
                            withCredentials: true
                        })
                        .then(response => {
                            if (response.data.hasOwnProperty("id")) {
                                this.setState({
                                    downloadInProgress: false,
                                    downloadInitiateSuccess: true
                                });
                            }

                        })
                        .catch(error => {
                            console.log(error);
                            if (error) {
                                this.setState({
                                    hasEncounteredError: true,
                                    downloadInProgress: false
                                });
                            }
                        });
                    }}>
                        {children}
                    </IconButton>
                </Tooltip>}
                {
                    downloadInProgress && <CircularProgress size={24} color="primary"/>
                }
                {
                    downloadInitiateSuccess
                    && <Snackbar
                        open={downloadInitiateSuccess}
                        autoHideDuration={4000}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        message={<p>Download Initiated Successfully. Please check
                            <Link to={`/apps/${appId}/settings/downloads`}> Downloads </Link> Page</p>}
                        onClose={() => {
                            this.setState({
                                downloadInitiateSuccess: false
                            })
                        }}/>
                }

                {
                    hasEncounteredError
                    && <Snackbar
                        open={hasEncounteredError}
                        autoHideDuration={4000}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        message={<p>Unknown error encountered while downloading CSV. Please try again after
                            sometime.</p>}
                        onClose={() => {
                            this.setState({
                                hasEncounteredError: false
                            })
                        }}/>
                }
            </>
        );
    }
}