import React from 'react'
import {render} from 'react-dom'
import {browserHistory} from 'react-router'
import {syncHistoryWithStore} from 'react-router-redux'
import Root from './containers/Root'
import configureStoreProd from "./store/configureStore.prod";
import configureStoreDev from "./store/configureStore.dev";

let store = null;
if (process.env.NODE_ENV === "production") {
    store = configureStoreProd({});
} else {
    store = configureStoreDev({});
}
const history = syncHistoryWithStore(browserHistory, store);

/**
 * For Loader
 * @type {HTMLElement | null}
 */
const loader = document.getElementById("loader");
document.body.style.overflowY = "overlay";
loader.remove();
render(
    <Root store={store} history={history}/>,
    document.getElementById('root')
);
